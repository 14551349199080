import * as React from 'react';

function SvgOperationalPortfolio() {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4741 1.53201C12.2088 1.26667 11.8109 1.26667 11.5457 1.53201C11.2804 1.79735 11.2804 2.19536 11.5457 2.4607C12.7393 3.65473 13.4025 5.24677 13.4025 6.97148C13.4025 8.69619 12.7393 10.2882 11.5457 11.4823C10.352 12.6763 8.76039 13.3396 7.03618 13.3396C5.57723 13.3396 4.11828 12.809 2.99091 11.8803L3.85301 10.9516C4.78143 11.6813 5.9088 12.0129 6.96986 12.0129C8.29618 12.0129 9.55618 11.5486 10.5509 10.5536C12.5404 8.56352 12.5404 5.31311 10.5509 3.38939C8.56144 1.39934 5.31196 1.39934 3.3888 3.38939C1.59827 5.18044 1.39932 8.03284 2.92459 10.0229L1.53196 11.4823C1.26669 11.7476 1.26669 12.1456 1.53196 12.411C2.99091 13.8703 4.91407 14.6663 6.96986 14.6663C9.02565 14.6663 10.9488 13.8703 12.4078 12.411C13.8667 10.9516 14.6625 9.02787 14.6625 6.97148C14.7288 4.9151 13.933 2.99138 12.4741 1.53201ZM4.38354 4.31808C5.11301 3.5884 6.10775 3.19039 7.03618 3.19039C8.03091 3.19039 8.95934 3.5884 9.68881 4.31808C11.1478 5.77745 11.1478 8.16551 9.68881 9.62488C8.22986 11.0843 5.84249 11.0843 4.38354 9.62488C2.92459 8.16551 2.92459 5.77745 4.38354 4.31808Z"
        fill="#d156a1"
      />
    </svg>
  );
}

export default SvgOperationalPortfolio;
