import * as React from 'react';

function ClockSvg(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM14.0667 9.8C14.5085 9.8 14.8667 9.44183 14.8667 9C14.8667 8.55817 14.5085 8.2 14.0667 8.2C13.6248 8.2 13.2667 8.55817 13.2667 9C13.2667 9.44183 13.6248 9.8 14.0667 9.8ZM6.33333 5.53333C6.33333 5.97516 5.97516 6.33333 5.53333 6.33333C5.09151 6.33333 4.73333 5.97516 4.73333 5.53333C4.73333 5.09151 5.09151 4.73333 5.53333 4.73333C5.97516 4.73333 6.33333 5.09151 6.33333 5.53333ZM12.4667 6.33333C12.9085 6.33333 13.2667 5.97516 13.2667 5.53333C13.2667 5.09151 12.9085 4.73333 12.4667 4.73333C12.0248 4.73333 11.6667 5.09151 11.6667 5.53333C11.6667 5.97516 12.0248 6.33333 12.4667 6.33333ZM6.33333 12.4667C6.33333 12.9085 5.97516 13.2667 5.53333 13.2667C5.09151 13.2667 4.73333 12.9085 4.73333 12.4667C4.73333 12.0248 5.09151 11.6667 5.53333 11.6667C5.97516 11.6667 6.33333 12.0248 6.33333 12.4667ZM12.4667 13.2667C12.9085 13.2667 13.2667 12.9085 13.2667 12.4667C13.2667 12.0248 12.9085 11.6667 12.4667 11.6667C12.0248 11.6667 11.6667 12.0248 11.6667 12.4667C11.6667 12.9085 12.0248 13.2667 12.4667 13.2667ZM4.73333 9C4.73333 9.44183 4.37516 9.8 3.93333 9.8C3.49151 9.8 3.13333 9.44183 3.13333 9C3.13333 8.55817 3.49151 8.2 3.93333 8.2C4.37516 8.2 4.73333 8.55817 4.73333 9ZM9 10.8667C9.88366 10.8667 10.6 10.1503 10.6 9.26667C10.6 8.38301 9.53333 3.13333 9 3.13333C8.46667 3.13333 7.4 8.38301 7.4 9.26667C7.4 10.1503 8.11634 10.8667 9 10.8667Z"
      />
    </svg>
  );
}

export default ClockSvg;
