// @flow

/**
 * Helper functions
 * @module Helpers
 */

import { last, omitBy, orderBy, some, sortBy, get } from 'lodash';
import { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { envConfig } from '../app';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from './timeUtils';

/**
 * Generate reducer.
 *
 * @param {Object} initialState
 * @param {Object} handlers
 * @returns {function}
 */
export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if ({}.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
}

/**
 * Create request types for contants
 * @param {string} base
 * @returns {Object}
 */
export function createRequestTypes(base) {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

/**
 * Convert data attributes to Object
 * @param {Element} elem
 * @returns {{}}
 */
export function datasetToObject(elem) {
  const data = {};
  [].forEach.call(elem.attributes, (attr) => {
    /* istanbul ignore else */
    if (/^data-/.test(attr.name)) {
      const camelCaseName = attr.name.substr(5).replace(/-(.)/g, ($0, $1) => $1.toUpperCase());
      data[camelCaseName] = attr.value;
    }
  });
  return data;
}

export function getNextId(elements) {
  const sortedElements = sortBy(elements, 'id');
  return (sortedElements.length && last(sortedElements).id + 1) || 1;
}

export const wrapWithPromise = (fn, ...args) =>
  new Promise((resolve) => {
    setTimeout(() => {
      fn(...args);
      resolve();
    }, 500);
  });

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const removeIdField = (object, extraField) =>
  omitBy(object, (value, key) => key === 'id' || (extraField && key === extraField) || value === '');

export default function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
  useEffect(() => (ro.observe(ref.current), ro.disconnect), []);
  return [{ ref }, bounds];
}

let _clipPathSupported = null;

// Check if clip-path is supported. From http://stackoverflow.com/a/30041538.
export function clipPathSupported() {
  if (_clipPathSupported != null) {
    return _clipPathSupported;
  }
  if (typeof document === 'undefined') {
    _clipPathSupported = false;
    return false;
  }

  const base = 'clipPath';
  const prefixes = ['Webkit'];
  const properties = [base, ...prefixes.map((prefix) => prefix + base.charAt(0).toUpperCase() + base.slice(1))];
  const testElement = document.createElement('testelement');
  const attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

  // Interate over the properties and see if they pass two tests.
  for (const property of properties) {
    // First, they need to even support clip-path (IE <= 11 does not)...
    if (testElement.style[property] === '') {
      // Second, we need to see what happens when we try to create a CSS shape...
      testElement.style[property] = attribute;
      if (testElement.style[property] !== '' && testElement.style[property] !== 'none') {
        _clipPathSupported = true;
        return true;
      }
    }
  }
  _clipPathSupported = false;
  return false;
}

export const sortValues = (data) => [...data].sort((a, b) => a?.name?.localeCompare(b?.name));

export const eq = (value) => (object) => object === value;
export const nEq = (value) => (object) => object !== value;

const RAG_STATUS_SORT = {
  Green: 3,
  Amber: 2,
  Red: 1,
};

export const orderArrayBy = (array, field, order) => {
  const extracted = (object) => {
    const fieldValue = get(object, field);

    if (field === 'priorityValue') {
      return fieldValue;
    }

    if (typeof fieldValue === 'string' && field === 'ragStatus') {
      return RAG_STATUS_SORT[fieldValue];
    }

    if (typeof fieldValue === 'string' && DateTime.fromFormat(fieldValue, DATE_FORMAT.date).isValid) {
      return DateTime.fromFormat(fieldValue, DATE_FORMAT.date);
    }

    if (typeof fieldValue === 'string') {
      return fieldValue.toLowerCase();
    }

    if (fieldValue !== null && typeof fieldValue === 'object') {
      const dataValue = fieldValue.props?.['data-value'];
      if (dataValue !== undefined) {
        return dataValue;
      }
      const priorityValue = object['priorityValue'];
      if (priorityValue !== undefined) {
        return priorityValue;
      }
    }

    return fieldValue;
  };

  return orderBy(array, [extracted], [order]);
};

export const hasJiraBE = () => envConfig.tooling === 'jira';
export const getToolingName = (withFeature) =>
  envConfig.tooling === 'jira' ? `Jira ${withFeature ? 'Epic' : ''}` : `Azure DevOps ${withFeature ? 'Feature' : ''}`;

export const getIssueTypeName = () => (envConfig.tooling === 'jira' ? 'Epic' : 'Feature');

export const date = (dateString) => DateTime.fromISO(dateString);

export const getPiDates = (pi) => {
  const startDate = date(pi.sprints?.[0]?.startDate);
  const endDate = date(pi.sprints?.[pi.sprints.length - 1]?.endDate);
  const length = endDate.diff(startDate, 'days').toObject();

  return { startDate: startDate, endDate: endDate, length: length?.days || 0 };
};

export const toDisplayDate = (dateString) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_SHORT);
};

export const filterProductTheme = (selectedProductTheme, element) => {
  if (!selectedProductTheme || selectedProductTheme === 'all') {
    return true;
  }
  // If 'None' is selected, filter for features without a product theme
  else if (selectedProductTheme === 'x') {
    // Still not convinced by the 'x' representative, but it works!
    // This checks if the feature's productThemes array is empty or not set
    return !element.productThemes || element.productThemes.length === 0;
  }
  // Otherwise, filter by the selected product theme
  else {
    return some(element.productThemes, (theme) => theme.id === selectedProductTheme);
  }
};
