import React from 'react';
import Tippy from '@tippyjs/react/headless';
import { useSpring, animated } from '@react-spring/web';
import { useMantineTheme } from '@mantine/core';

export default function Tooltip({ noTooltip, content, children, visible }) {
  const config = { tension: 300, friction: 15 };
  const initialStyles = { opacity: 0, transform: 'scale(0.5)' };
  const [props, setSpring] = useSpring(() => initialStyles);
  const theme = useMantineTheme();

  const onMount = () => {
    setSpring({
      opacity: 1,
      transform: 'scale(1)',
      onRest: () => {},
      config,
    });
  };

  const onHide = ({ unmount }) => {
    setSpring(initialStyles);
  };

  if (noTooltip) return <>{children}</>;

  return (
    <Tippy
      render={(attrs) => (
        <animated.div
          {...attrs}
          style={{
            background: '#333',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: 4,
            ...props,
          }}>
          {content}
        </animated.div>
      )}
      animation={true}
      onShow={onMount}
      onHide={onHide}
      visible={visible}>
      {children}
    </Tippy>
  );
}
