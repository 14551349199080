export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  planToggle: {
    fontSize: 16,
  },
  appBar: {
    marginBottom: 10,
    paddingLeft: '0',
    minHeight: 50,
    fontSize: 20,
    color: '#426CB4',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 90,
  },
  dashboardSelect: {
    textAlign: 'left',
    flex: 1,
  },
  title: {
    marginRight: 10,
    borderRight: '0.1em solid #426CB4',
    paddingRight: 10,
  },
  tableCell: {
    borderBottom: '0',
    textAlign: 'center',
    borderRight: `${theme.palette.mode === 'light' ? '1px' : '3px'} solid ${theme.palette.color.borderPaper}`,
    padding: '10px 0px 10px 0px',
    width: '10%',
  },
  table: {
    borderBottom: '0',
  },
  tableHeadRow: {
    // color: theme.palette.color.gunMetal,
    textAlign: 'center',
    borderBottom: '0',
    borderRight: `${theme.palette.mode === 'light' ? '1px' : '3px'} solid ${theme.palette.color.borderPaper}`,
    padding: '4px 24px 4px 24px',
  },
  tableHeadCol: {
    fontSize: 14,
    fontWeight: 500,
    // color: theme.palette.color.dark,
    borderBottom: '0',
    borderRight: `${theme.palette.mode === 'light' ? '1px' : '3px'} solid ${theme.palette.color.borderPaper}`,
    width: '12.5%',
  },
  tableHeadObj: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '0',
    borderRight: `${theme.palette.mode === 'light' ? '1px' : '3px'} solid ${theme.palette.color.borderPaper}`,
    backgroundColor: theme.palette.color.paper,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '60px',
  },
  sprintDates: {
    fontSize: 11,
  },
  sprintItems: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeight.values.medium,
  },
});
