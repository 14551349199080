import { alpha } from '@mui/material/styles';

export const styles = (theme) => ({
  appBar: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 10,
    paddingLeft: 0,
    height: 50,
    fontSize: 20,
  },
  toolbarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 4,
  },
  toolbarRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 35,
    marginTop: 8,
    marginBottom: 4,
  },
  search: {
    position: 'relative',
    borderRadius: '3px',
    border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    backgroundColor: theme.palette.color.paper,
    '&:hover': {
      backgroundColor: theme.palette.color.paper,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.color.blueGrey,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '14ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
});
