import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { styles } from './styles';
import { RiskArea } from './riskArea';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RISKS_FOR_PI, UPDATE_RISK } from './graphql';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import Risk from './risk';
import { removeIdField } from '../../../../utils/helpers';
import { openDrawer } from '../../../../reactiveVariables';

const useStyles = makeStyles(styles);

export default function ProgramRisks() {
  const classes = useStyles();

  const { data: { risks: risks = [] } = {} } = useQuery(GET_RISKS_FOR_PI);
  const [updateRisk] = useMutation(UPDATE_RISK);

  const openRisks = risks.filter((risk) => risk.state === 'open');
  const resolvedRisks = risks.filter((risk) => risk.state === 'resolved');
  const ownedRisks = risks.filter((risk) => risk.state === 'owned');
  const acceptedRisks = risks.filter((risk) => risk.state === 'accepted');
  const mitigatedRisks = risks.filter((risk) => risk.state === 'mitigated');

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const sensors = useSensors(mouseSensor);

  const [activeId, setActiveId] = useState(null);

  const handleClick = (risk) => openDrawer(risk, 'risk');

  const handleDragEnd = (event) => {
    if (event.over) {
      const risk = { ...event.active.data.current, state: event.over.id };
      updateRisk({
        variables: { risk: removeIdField(risk), riskId: risk.id },
        optimisticResponse: { risk: risk },
      });
    }
    setActiveId(null);
  };

  const handleDragStart = (event) => {
    setActiveId({ id: event.active.id, state: event.active.data.current.state });
  };

  const onDragOver = (event) => {
    setActiveId({ ...activeId, state: event.over.id });
  };

  const renderDragOverlay = (riskData) => {
    const risk = risks.find((risk) => risk.id === riskData.id);

    return <Risk risk={{ ...risk, state: riskData.state }} classes={classes} handleClick={handleClick} />;
  };

  return (
    <DndContext onDragOver={onDragOver} onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
      <div className={classes.root}>
        <Grid key="container" className={classes.container} container justifyContent="center" spacing={2}>
          <Grid item xs={12 / 5}>
            <RiskArea key="open" id="open" title="Open" risks={openRisks} />
          </Grid>
          <Grid item xs={12 / 5}>
            <RiskArea key="resolved" id="resolved" title="Resolved" risks={resolvedRisks} />
          </Grid>
          <Grid item xs={12 / 5}>
            <RiskArea key="owned" id="owned" title="Owned" risks={ownedRisks} />
          </Grid>
          <Grid item xs={12 / 5}>
            <RiskArea key="accepted" id="accepted" title="Accepted" risks={acceptedRisks} />
          </Grid>
          <Grid item xs={12 / 5}>
            <RiskArea key="mitigated" id="mitigated" title="Mitigated" risks={mitigatedRisks} />
          </Grid>
        </Grid>
        <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
      </div>
    </DndContext>
  );
}
