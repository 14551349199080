import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Search = (props) => {
  const { handleChange, fullWidth } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        type="search"
        onChange={handleChange}
        placeholder="Search..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        sx={fullWidth && { width: '100%' }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};

Search.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default Search;
