import React from 'react';
import { trim } from 'lodash';
import { useTheme } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';

const ProgramIncrementVelocity = ({ programIncrements }) => {
  const theme = useTheme();
  const colors = ['#D8A24E', '#EAA4A5', '#4F74CB', '#B4CD67'];

  const data = programIncrements
    .map((pi) => pi.sprints)
    .flat()
    .map((sprint) => ({
      x: trim(sprint.name.replace('Sprint', '')),
      y: sprint?.metrics?.completedFeatures || 0,
    }));

  const config = {
    series: [
      {
        name: 'Features Delivered',
        data: data,
      },
    ],
    options: {
      chart: {
        height: 100,
        type: 'bar',
        background: theme.palette.color.paper,
      },
      stroke: {
        width: 5,
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          columnWidth: '10%',
          distributed: true,
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: { colors: theme.palette.text.secondary },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
        title: {
          text: 'Features Delivered',
          style: { color: theme.palette.text.secondary },
        },
      },
      xaxis: {
        type: 'category',
        group: {
          style: {
            fontSize: '10px',
            fontWeight: 700,
          },
          groups: [
            { title: '2019', cols: 4 },
            { title: '2020', cols: 4 },
          ],
        },
        categories: programIncrements
          .map((pi) => pi.sprints)
          .flat()
          .map((sprint) => trim(sprint.name.replace('Sprint', ''))),
        axisTicks: { show: false },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
      },
    },
  };

  return <ReactApexChart options={config.options} series={config.series} type="line" height="100%" />;
};

export default ProgramIncrementVelocity;
