export const SHOW_DEPENDENCIES = 'All Dependencies';

export const TOGGLE_VALUES = [
  { value: 'features', label: 'Features' },
  { value: 'objectives', label: 'Objectives' },
];

export const TOGGLE_PLAN_VALUES = [
  { value: 'originalPlan', label: 'Original Plan' },
  { value: 'currentPlan', label: 'Current Plan' },
];

export const TOGGLE_POINTS_VALUES = [
  { value: 'storyPoints', label: 'Story Points' },
  { value: 'storyCount', label: 'Story Count' },
];

export const TOGGLE_INCLUDES = [
  { value: 'increment', label: 'Increment' },
  { value: 'overall', label: 'Overall' },
];
