import * as React from 'react';

function SvgLogo(props) {
  return (
    <svg width={'100%'} height={'100%'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#Logo_svg__clip0_290_50)">
        <path
          d="M9.084 2.6a3.202 3.202 0 014.315 1.076l.058.097 5.198 9.003a3.202 3.202 0 01-5.487 3.298l-.058-.096L9.445 9.63a.716.716 0 011.203-.774l.037.058 3.664 6.347a1.77 1.77 0 003.112-1.688l-.045-.082-5.198-9.004a1.77 1.77 0 00-2.252-.733l-.096.046-.066.078-.109.142-.14.194-.26.383-.323.491-.384.6L7.9 6.788l-.723 1.175-.935 1.541L4.925 11.7l-1.503 2.53-.591 1.002a.716.716 0 01-1.233-.727l2.045-3.452.722-1.209L5.65 7.716l.652-1.065.422-.682.532-.847.338-.527.245-.376.326-.489.235-.338.163-.226.142-.187.093-.114.055-.064.05-.054.045-.045a.716.716 0 01.136-.102z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="Logo_svg__clip0_290_50">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLogo;
