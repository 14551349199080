import styled from '@emotion/styled';
import { Badge, Grid } from '@mantine/core';
import React from 'react';
import { Chip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import classes from './InitiativeDashboard.module.css';

export const Column = styled((props) => <Grid.Col span={3} p={0} {...props} />)(({ theme, ...props }) => ({
  border: `1px solid ${theme.palette.color.tableColumn}`,
  borderLeft: 'none',
  ...(props.noBorder && { borderTop: 'none', borderBottom: 'none' }),
  ...((!props.lastChild || props.expanded) && { borderBottom: 'none' }),
  ...(props.firstChild && { borderTop: 'none' }),
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.color.backgroundColor,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.color.oddColumn,
  },
}));

export const DivColumn = ({ children, noBorder, bottomBorder, firstChild, style }) => (
  <div
    data-noborder={noBorder}
    data-bottomBorder={bottomBorder}
    data-firstChild={firstChild}
    className={classes.column}
    style={style}>
    {children}
  </div>
);

export const CenteredCol = styled((props) => <Grid.Col p={21} {...props} />)(({ theme, ...props }) => ({
  alignItems: props.align ?? 'center',
  justifyContent: props.justify ?? 'flex-start',
}));

export const Display2 = styled((props) => <Badge radius="8px" h={22} {...props} />)(({ theme, ...props }) => ({
  backgroundColor: `${alpha(props.bgColor, 0.15)} !important`,
  color: `${props.bgColor} !important`,
  border: `1px solid ${props.bgColor} !important`,
  height: '20px',
  padding: '2px',
  '& .MuiChip-label': {
    padding: '0 3px 0 3px',
  },
  '& > span': {
    width: 'fit-content',
    overflow: 'visible',
  },
}));
