import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import React, { useEffect, useRef, useState } from 'react';
import LuxonUtils from '@mui/lab/AdapterLuxon';
import { Box, Button, Collapse, Grid, IconButton, OutlinedInput, Paper } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { DateTime } from 'luxon';
import GrowingInput from '../../Common/GrowingInput';
import { Controller, useController, useFieldArray } from 'react-hook-form';
import { last } from 'lodash';

const EditDateComponent = ({ sprint, index, control }) => {
  const { field: startDateField } = useController({ control, name: `sprints[${index}].startDate` });
  const { field: endDateField } = useController({ control, name: `sprints[${index}].endDate` });

  return (
    <LocalizationProvider dateAdapter={LuxonUtils} locale="en-gb">
      <DateRangePicker
        startText="Start Date"
        endText="End Date"
        value={[DateTime.fromISO(startDateField.value), DateTime.fromISO(endDateField.value)]}
        onChange={(newValue) => {
          startDateField.onChange(newValue[0]?.toISODate());
          endDateField.onChange(newValue[1]?.toISODate());
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <OutlinedInput
              {...startProps}
              notched={false}
              placeHolder="Start Date"
              size="small"
              sx={{ height: '40px' }}
            />
            <Box sx={{ mx: 2 }}> to </Box>
            <OutlinedInput {...endProps} label={null} placeHolder="End Date" size="small" sx={{ height: '40px' }} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

const Header = () => (
  <Grid item xs={12}>
    <Paper elevation={0} sx={{ height: '30px', backgroundColor: 'color.table' }}>
      <Grid item container alignItems={'flex-end'} height="100%" xs={12}>
        <Grid item xs={3} paddingLeft="32px">
          Name
        </Grid>
        <Grid item xs={3}>
          Sprints Starts
        </Grid>
        <Grid item width="45px" />
        <Grid item xs={3}>
          Sprints Ends
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Paper>
  </Grid>
);

const GetSprint = React.forwardRef(
  ({ firstRender, sprint, index, style, fieldArrayMethods: { remove }, control }, ref) => {
    const [exiting, setExiting] = useState(false);
    const [entering, setEntering] = useState(false);

    useEffect(() => {
      setEntering(true);
    }, []);

    useEffect(() => {
      if (exiting) {
        // this timeout needs to be the same duration of the CSSTransition so that the removal happens at the same time as unmount
        setTimeout(() => remove(index), 500);
      }
    }, [exiting]);

    return (
      <Collapse
        key={sprint.formId}
        unmountOnExit={true}
        in={firstRender || (entering && !exiting)}
        component={GridItemXs12}>
        <Paper ref={ref} style={style} elevation={0} sx={{ height: '60px', backgroundColor: 'color.row' }}>
          <Grid container xs={12} alignItems="center" height="100%">
            <Grid item xs={3} paddingLeft="32px">
              <Controller
                key={sprint.formId}
                name={`sprints[${index}].name`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <GrowingInput onChange={onChange} value={value} key={sprint.formId} placeholder="Sprint Name" />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <EditDateComponent sprint={sprint} index={index} control={control} />
            </Grid>
            <Grid item xs={2}>
              <span style={{ color: '#df6262', marginLeft: '12px', paddingTop: '5px' }}>Delete</span>
              <IconButton aria-label="delete" color="error" size="small" onClick={() => setExiting(true)}>
                <Cancel fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    );
  },
);

const GridItemXs12 = React.forwardRef((props, ref) => <Grid item xs={12} {...props} ref={ref} />);

const getElementToSplit = (lastSprint) => {
  if (lastSprint.name.indexOf('.') > 0) {
    return '.';
  }
  if (lastSprint.name.indexOf('-') > 0) {
    return '-';
  }
  if (lastSprint.name.indexOf(' ') > 0) {
    return ' ';
  }
};

export default function Sprints({ control, watch, piName }) {
  const ref = useRef(null);
  const { fields, ...fieldArrayMethods } = useFieldArray({
    control,
    name: 'sprints',
    keyName: 'formId',
  });

  const sprints = watch('sprints');

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const addSprint = () => {
    const lastSprint = last(sprints);
    if (!lastSprint) {
      const numbers = piName?.match(/\d+/g);
      return fieldArrayMethods.append({
        name: numbers ? `${numbers[0]}.1` : 'Sprint 1',
        startDate: DateTime.now().toISODate(),
        endDate: DateTime.now().plus({ weeks: 2 }).toISODate(),
        jiraIds: {},
      });
    }

    const lastSprintEndDate = DateTime.fromISO(lastSprint.endDate);
    const sprintLength = lastSprintEndDate.diff(DateTime.fromISO(lastSprint.startDate), 'days');
    const startDate = lastSprintEndDate.plus({ days: 1 });
    const endDate = startDate.plus(sprintLength);
    const splitElement = getElementToSplit(lastSprint);
    const splitName = lastSprint.name.split(splitElement);

    fieldArrayMethods.append({
      name: `${splitName[0]}${splitElement}${parseInt(splitName[1]) ? parseInt(splitName[1]) + 1 : 1}`,
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      jiraIds: {},
    });

    setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth' }), 200);
  };

  return (
    <Grid container spacing="2px" sx={{ backgroundColor: 'color.table' }}>
      <Grid item xs={12} margin="10px" style={{ paddingTop: '16px' }}>
        <span style={{ fontSize: 16, fontWeight: 500 }}>Sprints</span>
        <IconButton aria-label="add" size="small">
          <AddBoxRoundedIcon fontSize="small" onClick={addSprint} />
        </IconButton>
      </Grid>
      <Header />
      {fields.map((field, index) => (
        <GetSprint
          key={field.formId}
          sprint={field}
          index={index}
          control={control}
          firstRender={firstRender}
          fieldArrayMethods={fieldArrayMethods}
        />
      ))}
      <Grid item xs={12}>
        <Paper
          ref={ref}
          elevation={0}
          sx={{ backgroundColor: 'color.table', height: '60px', alignItems: 'center', padding: '10px' }}>
          <Button onClick={addSprint} variant="text" sx={{ textTransform: 'none' }}>
            Add sprint ...
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
