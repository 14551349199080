import { useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { Combobox, ScrollArea, TextInput, useCombobox, Checkbox, ActionIcon, Group } from '@mantine/core';
import classes from './TransferList.module.css';

function RenderList({ options, onTransfer, type }) {
  const combobox = useCombobox();
  const [value, setValue] = useState([]);
  const [search, setSearch] = useState('');

  const handleValueSelect = (val) => {
    setValue((current) => (current.includes(val) ? current.filter((v) => v !== val) : [...current, val]));
  };

  const items = options
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    .map((item) => (
      <Combobox.Option
        value={item}
        key={item.value}
        active={value.includes(item)}
        onMouseOver={() => combobox.resetSelectedOption()}>
        <Group gap="sm">
          <Checkbox
            checked={value.includes(item)}
            onChange={() => {}}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
          />
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div className={classes.renderList} data-type={type}>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
        <Combobox.EventsTarget>
          <Group wrap="nowrap" gap={0} className={classes.controls}>
            <TextInput
              placeholder="Search work item types"
              classNames={{ input: classes.input }}
              style={{ flexGrow: 1 }}
              value={search}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
                combobox.updateSelectedOptionIndex();
              }}
            />
            <ActionIcon
              radius={0}
              variant="default"
              size={36}
              className={classes.control}
              onClick={() => {
                onTransfer(value);
                setValue([]);
              }}>
              <IconChevronRight className={classes.icon} />
            </ActionIcon>
          </Group>
        </Combobox.EventsTarget>

        <div className={classes.list}>
          <Combobox.Options>
            <ScrollArea.Autosize mah={200} type="scroll">
              {items.length > 0 ? items : <Combobox.Empty>Nothing found....</Combobox.Empty>}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}

export function TransferList({ value, onChange }) {
  const handleTransfer = (transferFrom, options) => {
    const transferTo = transferFrom === 0 ? 1 : 0;
    const transferFromData = value[transferFrom].filter((item) => !options.includes(item));
    const transferToData = [...value[transferTo], ...options];

    const result = [];
    result[transferFrom] = transferFromData;
    result[transferTo] = transferToData;

    onChange(result);
  };

  return (
    <div className={classes.root}>
      <RenderList type="forward" options={value[0]} onTransfer={(options) => handleTransfer(0, options)} />
      <RenderList type="backward" options={value[1]} onTransfer={(options) => handleTransfer(1, options)} />
    </div>
  );
}
