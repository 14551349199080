import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Avatar, Button, Card, CardContent, Chip, Grid, MenuItem, Typography } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { BUTTONS, DEV_STATUS, RAG_STATUS } from 'utils/formConstants';
import { find, isEmpty } from 'lodash';
import { GET_FEATURES } from '../../../containers/Product/Features/graphql';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_PROGRAM_INCREMENTS, GET_SELECTED_PI } from '../../../containers/Settings/ProgramIncrement/graphql';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import {
  DELETE_DEPENDENCY,
  INSERT_DEPENDENCY,
  UPDATE_DEPENDENCY,
} from '../../../containers/Delivery/components/Dependencies/graphql';
import { useForm } from 'react-hook-form';
import {
  AutoCompleteWrapper,
  Field,
  FormContext,
  renderSelectField,
  renderTextField,
} from '../../Common/FormFieldsHooks';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { resetDrawerDetails, selectedProgrammeVar } from '../../../reactiveVariables';
import SelectStories from './SelectStories';
import { showNotification } from '@mantine/notifications';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';
import { Event } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import { sortBy } from 'lodash';

const sortValues = (data) => [...data].sort((a, b) => a?.name?.localeCompare(b.name));

const DependencyDetails = (props, ref) => {
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const [insertDependency] = useMutation(INSERT_DEPENDENCY);
  const [updateDependency] = useMutation(UPDATE_DEPENDENCY);
  const [deleteDependency] = useMutation(DELETE_DEPENDENCY);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: (props.element && {
      ...props.element,
      issuesNeeded: props.element.issuesNeeded || [],
    }) || {
      programIncrement: selectedPI,
      status: 'To Do',
      ragStatus: 'Green',
      issuesNeeded: [],
    },
  });

  const { element } = props;

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (element?.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  useEffect(() => {
    if (props.element) {
      register('id', { value: props.element.id });
    }
  }, []);

  const onSubmit = (values) => {
    // eslint-disable-next-line no-unused-vars
    const { targetFeatures, id, ...dependencyToSave } = values;

    if (props?.element?.id) {
      updateDependency({
        variables: {
          dependencyId: props.element.id,
          targetFeatures: values.targetFeatures.map((feature) => ({
            dependencyId: props.element.id,
            featureId: feature.id,
          })),
          dependency: { ...dependencyToSave },
        },
      }).then(() => {
        showNotification({
          title: 'Dependency Updated',
          message: `Dependency ${values?.id} was successfully updated`,
        });
        resetDrawerDetails();
      });
    } else {
      insertDependency({
        update: insertUpdate('dependencie'),
        variables: {
          dependency: {
            ...dependencyToSave,
            programmeId: selectedProgramme,
            targetFeatures: isEmpty(values.targetFeatures)
              ? null
              : {
                  data: values.targetFeatures.map((feature) => ({
                    featureId: feature.id,
                  })),
                },
          },
        },
      }).then(() => {
        showNotification({
          title: 'Dependency Created',
          message: `Dependency was successfully created`,
        });
        resetDrawerDetails();
      });
    }
  };

  const handleDeleteConfirm = () => {
    if (props?.element?.id) {
      deleteDependency({ variables: { id: props.element.id }, update: deleteUpdate('dependencie') });
      resetDrawerDetails();
    }
  };

  const renderSelectItems = (data) =>
    data.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ));

  const dependency = watch();
  const mappedFeatures = features
    ?.filter((feature) => feature.name)
    .map((feature) => ({ id: feature.id, name: feature.name }));

  const pi = find(programIncrements, { id: dependency.programIncrement }) || {};
  const team = find(teams, { id: dependency.teamId }) || {};
  const createdBy = dependency.createdBy && find(users, { id: dependency.createdBy });

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            {props?.element?.id && (
              <Grid container justifyContent="flex-start" item xs={12} spacing={2}>
                <Grid item xs="auto">
                  <Chip
                    sx={{
                      backgroundColor: 'color.paper',
                      fontWeight: 'bold',
                      borderRadius: 2,
                    }}
                    label={`ID - ${props.element.id}`}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Chip
                    avatar={<Event />}
                    sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                    label={`Created: ${DateTime.fromISO(props.element.createdAt).toFormat(DATE_FORMAT.date)}`}
                  />
                </Grid>
                {createdBy && (
                  <Grid container item xs={6} alignItems="center">
                    <Typography color="textSecondary">Created by:</Typography>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        fontSize: 10,
                        fontWeight: 'bold',
                        backgroundColor: '#d156a1',
                        marginLeft: '10px',
                        marginRight: '5px',
                      }}>
                      {(createdBy.firstName + ' ' + createdBy.lastName)
                        ?.split(' ')
                        .map((n) => n[0])
                        .join('')}
                    </Avatar>
                    <Typography color="textSecondary">{createdBy.firstName + ' ' + createdBy.lastName}</Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field name="teamId" renderField={renderSelectField} label="Team" size={12}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {sortValues(
                teams.filter((team) => team.programmes?.includes(props?.element?.programmeId || selectedProgramme)),
              ).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Owner"
              name="owner"
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
            <Field required name="programIncrement" renderField={renderSelectField} label="Increment" size={12}>
              {renderSelectItems(
                programIncrements.filter((pi) => pi.programme === (props?.element?.programmeId || selectedProgramme)),
              )}
            </Field>
            <Field name="neededBySprint" renderField={renderSelectField} label="Needed By" size={12}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {renderSelectItems(pi.sprints || [])}
            </Field>
            <Field name="ragStatus" renderField={renderSelectField} label="Rag Status" size={12}>
              {Object.entries(RAG_STATUS).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={`${value}`}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <Field name="status" renderField={renderSelectField} label="Dev Status" size={12}>
              {Object.entries(DEV_STATUS).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={`${value}`}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Target Features"
              name="targetFeatures"
              multiple={true}
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortValues(mappedFeatures)}
              getOptionLabel={(option) =>
                option.feature
                  ? `${option.feature.id} - ${option.feature.name}`
                  : option.name
                  ? `${option.id} - ${option.name}`
                  : `${option.id} - ${
                      (mappedFeatures.find((opt) => opt?.id === option || opt?.id === option?.id) || {}).name
                    }`
              }
              size={12}
            />
            {dependency.teamId && (
              <SelectStories
                value={dependency.issuesNeeded}
                connectorId={team.connectorId}
                errors={errors}
                idField="id"
                label="Stories Needed"
                name="issuesNeeded"
                multiple={true}
                control={control}
                size={12}
                setValue={setValue}
              />
            )}
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete dependency: ${dependency?.name}`}
        text="Are you sure you want to delete this dependency ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(!openConfirmation)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button onClick={onSubmit} color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(DependencyDetails);
