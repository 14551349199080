import { Group, Title } from '@mantine/core';
import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import SvgHexagone from '../../../../assets/images/Hexagon';
import Objectives from './Objectives';
import Metrics from './Metrics';

const OKRs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Group>
        <Title order={3} style={{ marginLeft: '16px' }}>
          OKRs
        </Title>
      </Group>
      <Box sx={{ borderBottom: 1, margin: '0 -16px', borderColor: 'divider' }}>
        <Tabs
          sx={{ margin: '0 16px', height: '60px' }}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit">
          <Tab icon={<SvgHexagone />} iconPosition="start" label="Objectives" />
          {/*<Tab icon={<SvgHexagone />} iconPosition="start" label="Metrics" />*/}
        </Tabs>
      </Box>
      {value === 0 && <Objectives />}
      {value === 1 && <Metrics />}
    </>
  );
};

export default OKRs;
