import { FlexDiv, PaperCard } from '../../../../../components/Common/Elements';
import { Button, Chip, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import { useController } from 'react-hook-form';

const Size = styled(Chip)(({ theme, ...props }) => ({
  marginRight: '3.5px',
  height: '30px',
  width: '30px',
  '&:hover': {
    backgroundColor: '#1E1E29',
  },
  '& .MuiChip-label': {
    padding: 0,
  },
}));

const Sizing = ({ fieldName, defaultValue, control }) => {
  const sizes = [1, 2, 3, 5, 8, 13, 21, 34];

  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: fieldName,
    control,
    defaultValue: defaultValue || '',
  });

  const [selectedSize, setSelectedSize] = useState(name);

  return (
    <PaperCard
      key={2}
      variant="outlined"
      sx={{
        justifyContent: 'flex-start',
        padding: open ? '8px' : 0,
        margin: '8px 0 8px 0',
        backgroundColor: '#56565D',
      }}>
      <>
        <Typography sx={{ textAlign: 'start' }}>Sizing</Typography>
        <Typography sx={{ fontSize: 11, color: 'color.subtitle', textAlign: 'start' }}>
          Use sizing ladder to relatively estimate stories
        </Typography>
        <FlexDiv sx={{ padding: '10px 0 10px 0' }} ref={ref}>
          {sizes.map((size) => (
            <Size
              key={size}
              label={size}
              color={selectedSize === size ? 'selectedChip' : 'chip'}
              onClick={() => {
                setSelectedSize(size);
                onChange(size);
              }}
            />
          ))}
        </FlexDiv>
      </>
    </PaperCard>
  );
};

export default Sizing;
