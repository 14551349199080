import React, { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';
import { groupBy, mean } from 'lodash';
import { useTheme } from '@emotion/react';

const DaysToClose = ({ programIncrements, features }) => {
  const theme = useTheme();
  const colors = ['#D8A24E', '#EAA4A5', '#4F74CB', '#B4CD67'];

  const daysToClose = useMemo(
    () =>
      features
        .filter((feature) => feature.statusChangelog && feature.status === 'Done')
        .map((feature) => {
          const createdAt = feature.createdAt;
          const inProgressDate =
            feature.statusChangelog?.['In Progress']?.[0]?.startDate ||
            feature.statusChangelog?.status?.['In Progress']?.[0]?.startDate;
          const doneDate =
            feature.statusChangelog?.['Done']?.[0]?.startDate ||
            feature.statusChangelog?.status?.['Done']?.[0]?.startDate;

          return {
            id: feature.id,
            cycleTimeDays: DateTime.fromISO(doneDate).diff(DateTime.fromISO(inProgressDate), ['days']).days,
            leadTimeDays: DateTime.fromISO(doneDate).diff(DateTime.fromISO(createdAt), ['days']).days,
            doneDate,
            pi: feature.programIncrement,
          };
        }),
    [features],
  );

  const daysToClosePerPi = groupBy(daysToClose, 'pi');

  const config = {
    series: [
      {
        name: 'Cycle Time',
        data: programIncrements.map(
          (pi) =>
            Math.round(
              mean(daysToClosePerPi[pi.id]?.filter((data) => data.cycleTimeDays).map((data) => data.cycleTimeDays)) *
                10,
            ) / 10,
        ),
      },
    ],
    options: {
      chart: {
        height: 100,
        type: 'bar',
        background: theme.palette.color.paper,
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '10%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: { colors: theme.palette.text.secondary },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
        title: {
          text: 'Cycle Time',
          style: { color: theme.palette.text.secondary },
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: programIncrements.map((pi) => pi.name),
        axisTicks: { show: false },
        labels: {
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
    },
  };

  return <ReactApexChart options={config.options} series={config.series} type="bar" height="100%" />;
};

export default DaysToClose;
