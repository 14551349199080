import React from 'react';
import { Route } from 'react-router-dom';
import Steps from './Steps';
import BigAgileTable from '../../components/Common/BigAgileTable';
import FlowDashboard from './Flow';
import InitiativesDashboard from './InitiativesDashboard';
import InitiativesKanban from './Kanban';
import InitiativeView from './InitiativeView';

const getValueStreamRoutes = (user) => (
  <>
    <Route user={user} path="/valueStream" element={<InitiativesDashboard />} />
    <Route user={user} path="/valueStream/steps" element={<Steps />} />
    <Route user={user} path="/valueStream/initiatives" element={<BigAgileTable type="initiative" />} />
    <Route user={user} path="/valueStream/initiative/:initiativeId?" element={<InitiativeView />} />
    <Route user={user} path="/valueStream/kanban" element={<InitiativesKanban />} />
    <Route user={user} path="/valueStream/milestones" element={<BigAgileTable type="milestone" />} />
    <Route user={user} path="/valueStream/flow" element={<FlowDashboard />} />
    <Route user={user} path="/valueStream/productThemes" element={<BigAgileTable type="productTheme" />} />
  </>
);

export default getValueStreamRoutes;
