import { animated, config, useChain, useSpring, useSpringRef, useTransition } from '@react-spring/web';
import { IconButton, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FlexDiv, GrowingText } from '../../../../../components/Common/Elements';
import DeleteIcon from '@mui/icons-material/Delete';
import Sizing from '../Sizing';
import Select from '../../../../../components/Common/Select';

const AnimatedPaper = animated(Paper);

const StoryForm = ({
  style,
  options,
  addStory,
  removeStory,
  index,
  setValue,
  id,
  control,
  reset,
  register,
  unregister,
}) => {
  const [isExpanded, setExpanded] = useState(index === 0);
  const [auto, setAuto] = useState(false);

  const lineRef = useSpringRef();
  const { y2 } = useSpring({
    ref: lineRef,
    from: { y2: 0 },
    to: { y2: isExpanded ? 24 : 0 },
  });

  useEffect(() => {
    if (isExpanded) {
      register(`stories.${id}.title`);
      register(`stories.${id}.sprint`);
      register(`stories.${id}.jiraSprint`);
    } else {
      unregister(`stories.${id}.title`);
      unregister(`stories.${id}.sprint`);
      unregister(`stories.${id}.jiraSprint`);
    }
  }, [isExpanded]);

  const springApi = useSpringRef();
  const springStyle = useSpring({
    ref: springApi,
    config: config.stiff,
    from: {
      width: '110px',
      height: '34px',
      borderRadius: '100px',
      lineHeight: '34px',
      padding: '0px',
    },
    to: {
      width: isExpanded ? '300px' : '110px',
      height: isExpanded ? '225px' : '34px',
      borderRadius: isExpanded ? '5px' : '100px',
      lineHeight: isExpanded ? undefined : '34px',
      padding: isExpanded ? '8px' : '0px',
    },
    onRest: (result) => isExpanded && setTimeout(() => setAuto(true), 800),
  });

  const items = [
    {
      id: 'name',
      value: (
        <FlexDiv key={1} sx={{ justifyContent: 'space-between', padding: 0 }}>
          <GrowingText
            multiline
            sx={{ padding: '8px 0 0 0' }}
            placeholder="Story Title"
            onChange={(event) => setValue(`stories.${id}.title`, event.target.value)}
          />
          <IconButton
            onClick={() => {
              setExpanded(false);
              removeStory(id);
              unregister(`stories.${id}.title`);
              unregister(`stories.${id}.sprint`);
            }}>
            <DeleteIcon />
          </IconButton>
        </FlexDiv>
      ),
    },
    { id: 'estimate', value: <Sizing fieldName={`stories.${id}.sizing`} control={control} /> },
    {
      id: 'sprint',
      value: (
        <Select
          key={3}
          title="Sprint"
          padding={0}
          options={[{ value: null, label: 'Unassigned' }, ...options]}
          displayStyle={{ justifyContent: 'space-between', width: '100%' }}
          onChange={(event, value) => {
            setValue(`stories.${id}.sprint`, value?.id);
            setValue(`stories.${id}.jiraSprint`, value?.jiraId);
          }}
          rootStyle={{ width: '100%', padding: 0 }}
          sx={{ width: '100%', border: '1px solid rgba(255, 255, 255, 0.12)', backgroundColor: '#56565D' }}
          placeholder="Select Sprint"
        />
      ),
    },
  ];

  const transApi = useSpringRef();
  const transition = useTransition(isExpanded ? items : [], {
    ref: transApi,
    trail: 400 / 3,
    key: (item) => item.id,
    config: config.stiff,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  const transApi2 = useSpringRef();
  const transition2 = useTransition(isExpanded ? [] : [{ id: 'addStory', value: 'Add Story' }], {
    ref: transApi2,
    trail: 200,
    key: (item) => item.id,
    expires: 1,
    config: {
      duration: 100,
    },
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    onRest: (result) => !isExpanded && setAuto(false),
  });

  useChain(isExpanded ? [transApi2, springApi, transApi, lineRef] : [lineRef, transApi, springApi, transApi2], [
    0,
    isExpanded ? 0.1 : 0.4,
    isExpanded ? 0.2 : 0.7,
    isExpanded ? 0.5 : 0.8,
  ]);

  const height = auto && isExpanded ? { minHeight: '170px', height: 'auto' } : {};

  return (
    <FlexDiv sx={{ padding: 0, flexDirection: 'column' }} style={style}>
      <AnimatedPaper
        style={{ ...springStyle, ...height }}
        sx={{
          backgroundColor: '#4B4B54',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          position: 'relative',
        }}
        onClick={() => {
          !isExpanded && setExpanded(!isExpanded);
          !isExpanded && addStory();
        }}>
        {transition2((style, item) => (
          <animated.div
            style={{
              ...style,
              width: '100px',
              position: 'absolute',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {item.value}
          </animated.div>
        ))}
        {transition((style, item) => (
          <animated.div style={{ ...style, width: '100%' }}>{item.value}</animated.div>
        ))}
      </AnimatedPaper>
      <svg width={22} height={24} xmlns="http://www.w3.org/2000/svg">
        <animated.line x1="11" y1="0" x2="11" y2={y2} width="2" stroke="white" />
      </svg>
    </FlexDiv>
  );
};

export default animated(StoryForm);
