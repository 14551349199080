import { grey } from '@mui/material/colors';
import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

export const PIDates = styled('div')(({ theme, ...props }) => ({
  backgroundColor: props.backgroundColor,
  borderRadius: '2px',
  margin: '0 18px 10px 0',
  paddingLeft: '8px',
  color: props.color,
}));

export const PIName = styled('div')(({ theme, ...props }) => ({
  fontSize: 15,
  fontWeight: theme.typography.fontWeight.values.medium,
  '&::after': {
    content: '""',
    display: 'block',
  },
  ...(props.future && {
    marginTop: '31px',
  }),
}));

export const ColumnHeader = styled(TableCell)(({ theme }) => ({
  padding: 0,
  paddingBottom: '10px',
  paddingLeft: '18px',
  width: '18vw',
  minWidth: '200px',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.color.background,
  textAlign: 'left',
  borderBottom: '0',
  '& h6': {
    textAlign: 'left',
    color: 'rgba(30, 35, 42, 0.51)',
  },
}));

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  paper: {
    overflow: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: {
    borderBottom: '0',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableCell: {
    borderBottom: '0',
    textAlign: 'center',
    border: `1px solid ${theme.palette.color.borderPaper}`,
    padding: 4,
    // minWidth: 200,
  },
  currentSprint: {
    backgroundColor: 'rgba(255,73,158, 0.08)',
  },
  tableCellNoBorder: {
    borderBottom: '0',
    textAlign: 'center',
    padding: '4px',
  },
  tableHeadRow: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.color.background,
    textAlign: 'left',
    borderBottom: '0',
    padding: '4px 18px',
    '& h6': {
      textAlign: 'left',
      color: 'rgba(30, 35, 42, 0.51)',
    },
  },
  tableHeadCol: {
    fontSize: '12px',
    backgroundColor: theme.palette.color.background,
    border: `1px solid ${theme.palette.color.borderPaper}`,
  },
  teamName: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadObj: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '0',
    borderRight: `1px solid  ${theme.palette.color.borderPaper}`,
    backgroundColor: '#191919',
  },
  row: {
    backgroundColor: theme.palette.background.default,
  },
  scroll: {
    height: '100%',
    width: '100%',
  },
  tableHeadCellRoot: {
    padding: 0,
    paddingBottom: '10px',
    paddingLeft: '18px',
    width: '18vw',
    minWidth: '200px',
  },
  button: {
    color: theme.palette.text.primary,
    '& g': {
      fill: theme.palette.color.purpulishPink,
    },
  },
  tableTeamCell: {
    fontSize: '12px',
    backgroundColor: 'rgba(201, 172, 172, 0.04)',
    border: `1px solid ${theme.palette.color.borderPaper}`,
    borderBottom: '0',
    width: '12.5%',
  },
  typo: {
    lineHeight: '1.375em',
    letterSpacing: 0,
  },
  metricsProgress: {
    paddingTop: 8,
  },
  linearProgressBox: {
    width: '100%',
    padding: '8px 6px',
  },
  linearProgress: {
    height: 7,
    borderRadius: 100,
  },
  colorPrimary: {
    backgroundColor: theme.palette.color.silver,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.color.butterscotch,
  },
  barColorPrimaryGreen: {
    backgroundColor: theme.palette.color.algaeGreen,
  },
  card: {
    borderLeft: '0.3em solid #426CB4',
    margin: '10px',
  },
  cardContent: {
    padding: 10,
    maxWidth: 195,
  },
  sprintDates: {
    fontSize: 11,
    color: theme.palette.text.secondary,
  },
  sprintItems: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeight.values.medium,
    '&::after': {
      content: '',
      display: 'block',
      borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
    },
  },
  dot: {
    height: '8px',
    width: '8px',
    borderRadius: 50,
    display: 'inline-block',
    marginRight: '6px',
  },
});
