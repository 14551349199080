import React from 'react';
import PropTypes from 'prop-types';

import { styles } from './styles';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(styles);

const SectionHeaderWrapper = (props) => {
  const { children } = props;
  const classes = useStyles(props);
  return <header className={classes.header}>{children}</header>;
};

SectionHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionHeaderWrapper;
