export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '7%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    width: '30%',
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
    width: '10%',
  },
  {
    id: 'connector',
    field: 'connector',
    label: 'Connector',
    width: '20%',
  },
  {
    id: 'board',
    field: 'board',
    label: 'Board',
    width: '25%',
  },
  {
    id: 'description',
    field: 'description',
    label: 'Description',
    width: '18%',
  },
];
