import * as React from 'react';

function SvgDetailedView(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_799_38301)">
        <path d="M16.1958 16.4125L16.0339 16.9269C15.818 17.404 15.448 17.7283 14.9237 17.8997C14.3994 18.0712 13.8828 18.0227 13.3739 17.7544L10.2282 16.4125L16.1958 16.4125ZM18 12.2528C18 12.7598 17.815 13.1921 17.4449 13.5499C17.0748 13.9078 16.6276 14.0867 16.1033 14.0867L11.3153 14.0867L17.3755 12.0292C17.4988 11.9994 17.6068 11.9546 17.6993 11.895C17.7918 11.8354 17.8921 11.7608 18 11.6714L18 12.2528ZM17.4449 8.0484C17.5837 8.3615 17.5528 8.6895 17.3523 9.03242C17.1519 9.37533 16.8743 9.62134 16.5197 9.77043L4.51503 14.042C4.16037 14.191 3.81342 14.1761 3.47417 13.9972C3.13493 13.8183 2.8882 13.5425 2.734 13.1698L0.0740165 6.05801C-0.0493452 5.7151 -0.0185048 5.37964 0.166538 5.05163C0.35158 4.72363 0.629144 4.48508 0.999228 4.33598L12.8651 0.0644583C13.2506 -0.0399061 13.6284 -0.0175406 13.9985 0.131551C14.3685 0.280645 14.623 0.541559 14.7618 0.914291L17.4449 8.0484Z" />
      </g>
      <defs>
        <clipPath id="clip0_799_38301">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDetailedView;
