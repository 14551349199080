import { Card, CardContent, CardHeader, Paper, Typography } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import React from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
  padding: 0,
  paddingTop: '16px',
  paddingBottom: '16px',
  marginLeft: '16px',
  marginRight: '16px',
}));

export const StyledCard = styled(Card)(
  ({ theme }) => `
  box-shadow: 0 1px 1px 0 rgba(61, 71, 86, 0.05);
  border: ${theme.palette.mode === 'light' ? 'solid 1px #f2f2f3' : 'none'};
`,
);

export const StyledPaper = styled(Paper)(
  ({ theme }) => `
  box-shadow: 0 1px 1px 0 rgba(61, 71, 86, 0.05);
  border: solid 1px  ${theme.palette.color.border};
  background-color: ${theme.palette.color.paper};
`,
);

export const Grid = styled(MuiGrid)(({ theme }) => ({
  backgroundColor: theme.palette.color.background,
}));

export const Dot = styled('span')(({ theme, ...props }) => ({
  height: '8px',
  width: '8px',
  borderRadius: 50,
  display: 'inline-block',
  marginRight: '6px',
  backgroundColor: props.color ? props.color : theme.palette.text.primary,
}));

export const MetricPaper = ({ icon, title, xs = 3, children }) => {
  return (
    <Grid item xs={xs}>
      <StyledPaper sx={{ padding: '10px' }}>
        <MuiGrid container>
          <MuiGrid item xs={12}>
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography style={{ fontWeight: 600 }} variant="body1">
                {title}
              </Typography>
              {icon}
            </div>
          </MuiGrid>
          {children}
        </MuiGrid>
      </StyledPaper>
    </Grid>
  );
};

export const Metric = ({ value, name, dotColor, xs = 6 }) => {
  return (
    <MuiGrid item xs={xs}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {dotColor && <Dot sx={{ backgroundColor: dotColor }} />}
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </div>
      <Typography variant={'body2'} color="#93a0b5">
        {name}
      </Typography>
    </MuiGrid>
  );
};

export const DashboardCard = ({ title, cardStyle, headerStyle, gridStyle, contentStyle, children, xs = 6 }) => {
  return (
    <Grid item xs={xs} sx={gridStyle}>
      <StyledCard sx={{ height: '60vh', maxHeight: '400px', ...cardStyle }}>
        <StyledCardHeader
          sx={headerStyle}
          titleTypographyProps={{
            variant: 'body1',
            style: { fontWeight: 600 },
          }}
          title={title}
        />
        <CardContent style={{ height: '100%', ...contentStyle }}>{children}</CardContent>
      </StyledCard>
    </Grid>
  );
};

export const nivoTheme = () => {
  const muiTheme = useTheme();

  return {
    tooltip: {
      container: {
        background: muiTheme.palette.background.default,
      },
    },
    axis: {
      legend: {
        text: {
          fontSize: 14,
          fill: '#93a0b5',
        },
      },
      ticks: {
        text: {
          fontSize: 11,
          fill: '#93a0b5',
        },
      },
      domain: {
        line: {
          stroke: '#eaeaeb',
          strokeWidth: 2,
        },
      },
    },
  };
};
