export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'plannedIn',
    field: 'plannedIn',
    label: 'Planned In',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

export const LOAD_PI = {
  REQUEST: 'LOAD_PI_REQUEST',
  SUCCESS: 'LOAD_PI_SUCCESS',
  FAILURE: 'LOAD_PI_FAILURE',
};

export const SAVE_PI = {
  REQUEST: 'SAVE_PI_REQUEST',
  SUCCESS: 'SAVE_PI_SUCCESS',
  FAILURE: 'SAVE_PI_FAILURE',
};

export const DELETE_PI = {
  REQUEST: 'DELETE_PI_REQUEST',
  SUCCESS: 'DELETE_PI_SUCCESS',
  FAILURE: 'DELETE_PI_FAILURE',
};

export const SYNC_SPRINTS = {
  REQUEST: 'SYNC_SPRINTS_REQUEST',
  SUCCESS: 'SYNC_SPRINTS_SUCCESS',
  FAILURE: 'SYNC_SPRINTS_FAILURE',
};

export const ACTIVATE_PI = {
  REQUEST: 'ACTIVATE_PI_REQUEST',
  SUCCESS: 'ACTIVATE_PI_SUCCESS',
  FAILURE: 'ACTIVATE_PI_FAILURE',
};

export const FETCH_TEAM_SPRINTS = {
  REQUEST: 'FETCH_TEAM_SPRINTS_REQUEST',
  SUCCESS: 'FETCH_TEAM_SPRINTS_SUCCESS',
  FAILURE: 'FETCH_TEAM_SPRINTS_FAILURE',
};

export const PI_STATUSES = [
  { value: 'planning', label: 'PLANNING' },
  { value: 'active', label: 'ACTIVE' },
  { value: 'complete', label: 'COMPLETE' },
];

export const PI_STATUSES_DISPLAY = {
  planning: 'Planning',
  active: 'Active',
  complete: 'Complete',
};
