import { selectedProgrammeVar } from '../../../reactiveVariables';
import React, { useMemo } from 'react';
import { CustomGroupingSelect } from '../Select/CustomSelect';

const ProgrammeSelector = ({ valueStreams, value, handleChange }) => {
  const activeValueStreams = useMemo(() => valueStreams.filter((stream) => stream.isActive === true), [valueStreams]);

  if (!value && activeValueStreams[0]) {
    selectedProgrammeVar(activeValueStreams[0]?.programmes[0]?.id);
    window.localStorage.setItem('selectedProgramme', activeValueStreams[0]?.programmes[0]?.id);
    window.localStorage.setItem('selectedValueStream', activeValueStreams[0]?.id);
  }

  return (
    <CustomGroupingSelect
      onChange={handleChange}
      value={value}
      groupedOptions={activeValueStreams
        .filter((vs) => vs.programmes?.length)
        .map((valueStream) => ({
          name: valueStream.name,
          options: valueStream.programmes,
        }))}
    />
  );
};

export default ProgrammeSelector;
