export const styles = (theme) => ({
  button: {
    marginRight: 15,
    height: 40,
    width: 150,
    color: theme.palette.color.white,
    backgroundColor: theme.palette.color.purpulishPink,
    padding: '12px 14px',
    borderRadius: 22,
  },
  noWidth: {
    marginRight: 15,
    height: 40,
    color: theme.palette.color.white,
    backgroundColor: theme.palette.color.purpulishPink,
    padding: '12px 14px',
    borderRadius: 22,
  },
  buttonText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeight.medium,
  },
});
