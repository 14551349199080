import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Card, CardContent, MenuItem } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import {
  AutoCompleteWrapper,
  Field,
  FormContext,
  renderSelectField,
  renderTextField,
} from 'components/Common/FormFieldsHooks';
import { useForm } from 'react-hook-form';
import { BUTTONS } from 'utils/formConstants';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_PROGRAM_INCREMENTS,
  GET_PROGRAM_INCREMENTS_FOR_PROGRAMME,
  GET_SELECTED_PI,
} from '../../containers/Settings/ProgramIncrement/graphql';
import { DELETE_RISK, INSERT_RISK, UPDATE_RISK } from '../../containers/Delivery/components/Risks/graphql';
import { deleteUpdate, insertUpdate } from '../../utils/graphQLUtils';
import { resetDrawerDetails, selectedProgrammeVar } from '../../reactiveVariables';
import { GET_TEAMS } from '../../containers/Settings/GraphQL/teams';
import { sortValues } from '../../utils/helpers';
import { sortBy } from 'lodash';
import { GET_USERS } from '../../containers/Settings/GraphQL/users';
import { showNotification } from '@mantine/notifications';

const RisksDetails = (props, ref) => {
  const { element: risk } = props;
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [insertRisk] = useMutation(INSERT_RISK);
  const [updateRisk] = useMutation(UPDATE_RISK);
  const [deleteRisk] = useMutation(DELETE_RISK);

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: risk || {
      name: '',
      programIncrement: selectedPI,
      state: 'open',
    },
  });

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteRisk({
      variables: { riskId: props.element.id },
      update: deleteUpdate('risk'),
    });
    resetDrawerDetails();
  };

  const onSubmit = (values) => {
    risk?.id
      ? updateRisk({ variables: { risk: values, riskId: risk.id } }).then(() => {
          showNotification({
            title: 'Risk Updated',
            message: `Risk ${risk.id} was successfully updated`,
          });
          resetDrawerDetails();
        })
      : insertRisk({ variables: { risk: values }, update: insertUpdate('risk') }).then(() => {
          showNotification({
            title: 'Risk Created',
            message: `Risk was successfully created`,
          });
          resetDrawerDetails();
        });
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" required size={12} />
            <Field name="programIncrement" required renderField={renderSelectField} label="Increment" size={12}>
              {programIncrements.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <Field name="state" renderField={renderSelectField} label="State" size={12}>
              <MenuItem key="open" value="open">
                Open
              </MenuItem>
              <MenuItem key="resolved" value="resolved">
                Resolved
              </MenuItem>
              <MenuItem key="owned" value="owned">
                Owned
              </MenuItem>
              <MenuItem key="accepted" value="accepted">
                Accepted
              </MenuItem>
              <MenuItem key="mitigated" value="mitigated">
                Mitigated
              </MenuItem>
            </Field>
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field name="team" renderField={renderSelectField} label="Team" size={12}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {sortValues(teams.filter((team) => team.programmes?.includes(selectedProgramme))).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Owner"
              name="owner"
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete risk: ${risk?.name}`}
        text="Are you sure you want to delete this risk ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(RisksDetails);
