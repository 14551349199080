import { ActionIcon, Menu, rem, useMantineTheme } from '@mantine/core';
import SvgMoreAction from '../../../../assets/images/MoreAction';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

const ActionMenu = ({ action, element }) => {
  const theme = useMantineTheme();

  return (
    <Menu>
      <Menu.Target>
        <ActionIcon
          variant="transparent"
          color="primary"
          aria-label="Settings"
          onClick={(event) => event.stopPropagation()}>
          <SvgMoreAction color={theme.other.colours.paper} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown zIndez={10000} style={{ zIndex: 100000 }}>
        <Menu.Item
          onClick={() => action({ variables: { id: element.id } })}
          leftSection={<DeleteIcon style={{ width: rem(14), height: rem(14) }} />}>
          Remove
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ActionMenu;
