import React, { forwardRef, memo } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useMergedRef } from '@mantine/hooks';

const Draggable = ({ idPrefix, children, enabled = true, element, data, style, ...rest }, ref) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: idPrefix ? `${idPrefix}-${element?.id}` : element?.id,
    data: data,
    disabled: !enabled,
  });
  const mergedRef = useMergedRef(setNodeRef, ref);

  return (
    <div
      style={{ cursor: isDragging ? 'grabbing' : 'grab', opacity: isDragging ? 0.5 : 1, ...style }}
      {...rest}
      ref={mergedRef}
      {...listeners}
      {...attributes}>
      {children}
    </div>
  );
};

export default memo(forwardRef(Draggable));
