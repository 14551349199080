import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../../../../../Reports/Roadmap/styles';
import Reports from './components/Reports';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_TEAMS } from '../../../../../Settings/GraphQL/teams';
import { GET_FEATURES_FOR_PI } from '../../../../../Product/Features/graphql';
import { GET_SELECTED_PI_FULL } from '../../../../../Settings/ProgramIncrement/graphql';
import { storiesTooltipLabel, storyPointsTooltipLabel } from './constants';
import { selectedInitiativeVar, selectedTeamVar } from '../../../../../../reactiveVariables';

const getFeatures = (filteredFeaturesByBacklog, teams, type) =>
  filteredFeaturesByBacklog
    .filter(({ teamId, metrics }) => teamId && metrics)
    .sort((a, b) => a.teamId > b.teamId)
    .map((feature) => {
      const {
        id,
        team: teamId,
        status,
        name,
        metrics: {
          [type]: { total = 0, done = 0 },
        },
      } = feature;
      const originalCount = feature.originalState?.[type] || 0;
      const additionalStories = total - originalCount;

      return {
        id,
        status,
        name,
        teamName: teams.find(({ id }) => id === teamId)?.name ?? '',
        originalStoryCount: originalCount,
        additionalStories: additionalStories > 0 ? additionalStories : 0,
        done,
      };
    });

const useStyles = makeStyles(styles);

const AdditionalStoriesCharts = ({ backlog, scope }) => {
  const classes = useStyles();

  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);

  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (!backlog || feature.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, backlog, selectedInitiative, selectedInitiative, selectedTeams],
  );

  const additionalStoriesFeatures = getFeatures(filteredFeatures, teams, 'storyCount');
  const additionalStoryPointsFeatures = getFeatures(filteredFeatures, teams, 'storyPoints');

  return (
    <div className={classes.container}>
      {scope === 'storyCount'
        ? !!additionalStoriesFeatures.length && (
            <Reports labels={storiesTooltipLabel} features={additionalStoriesFeatures} />
          )
        : !!additionalStoryPointsFeatures.length && (
            <Reports labels={storyPointsTooltipLabel} features={additionalStoryPointsFeatures} />
          )}
    </div>
  );
};

export default AdditionalStoriesCharts;
