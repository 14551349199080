import { Box, CardHeader } from '@mui/material';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROGRAM_INCREMENTS } from '../../Settings/ProgramIncrement/graphql';
import { mean, sum, takeRight } from 'lodash';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { DashboardCard, Grid, Metric, MetricPaper } from '../components/Common';
import { GET_OBJECTIVES } from '../../Delivery/components/Objectives/graphql';
import OKRTable from '../components/OKRTable';
import SvgPortfolio from '../../../assets/images/Portfolio';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';
import FlowLoad from '../components/FlowLoad';
import FlowTime from '../components/FlowTime';
import FlowDistribution from '../components/FlowDistribution';
import MetricTable from '../components/MetricTable';
import { Text } from '@mantine/core';

export const PortfolioIcon = styled(SvgPortfolio)(({ theme }) => ({
  '& path': {
    '&.dimmed': {
      fill: alpha(
        theme.palette.mode === 'light' ? theme.palette.color.greyIcon : theme.palette.color.purpulishPink,
        0.3,
      ),
    },
    '&.normal': {
      fill: theme.palette.mode === 'light' ? theme.palette.color.greyIcon : theme.palette.color.purpulishPink,
    },
  },
}));

const TopLine = ({ avgPiMetrics }) => (
  <Grid sx={{ height: '130px' }} container item spacing={1}>
    <MetricPaper title="Overview" xs={4} icon={<PortfolioIcon />}>
      <Metric value={12} name="Teams" />
      <Metric value={2} name="Trains" />
    </MetricPaper>
    <MetricPaper title="Features" xs={4} icon={<PortfolioIcon />}>
      <Metric value={3} name="Avg Cycle Time" />
      <Metric value={8} name="Avg Lead Time" />
    </MetricPaper>
    <MetricPaper title="Team Objectives" xs={4} icon={<PortfolioIcon />}>
      <Metric value={avgPiMetrics.avgObjectivePlannedPerTeam.toFixed()} name="Avg Committed" xs={4} />
      <Metric value={avgPiMetrics.avgObjectiveDeliveredPerTeam.toFixed()} name="Avg Delivered" xs={4} />
    </MetricPaper>
  </Grid>
);

const BottomLine = ({ lastFourPIs, features, objectives }) => (
  <React.Fragment>
    <DashboardCard title="Load" key="load">
      <FlowLoad />
    </DashboardCard>
    <DashboardCard title="Distribution" key="Distribution">
      <FlowDistribution />
    </DashboardCard>
    <DashboardCard title="Velocity" key="Velocity">
      <FlowLoad />
    </DashboardCard>
    <DashboardCard title="Time" key="Time">
      <FlowTime />
    </DashboardCard>
    {/*<DashboardCard*/}
    {/*  title="Work Distribution"*/}
    {/*  cardStyle={{ height: null }}*/}
    {/*  headerStyle={{ borderBottom: 0 }}*/}
    {/*  contentStyle={{ padding: 0 }}>*/}
    {/*  <MetricTable programIncrements={lastFourPIs} />*/}
    {/*</DashboardCard>*/}
  </React.Fragment>
);

const FlowDashboard = () => {
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES);

  const lastFourPIs = takeRight(
    programIncrements.filter((pi) => pi.status !== 'planning'),
    4,
  );

  const totalPiMetrics = lastFourPIs.map((pi) => {
    const piObjectives = objectives.filter((objective) => objective.programIncrement === pi.id);
    return {
      planned: sum(piObjectives.map((objective) => objective.plannedValue)) / (pi?.metrics?.teamCount || 1),
      delivered: sum(piObjectives.map((objective) => objective.deliveredValue)) / (pi?.metrics?.teamCount || 1),
    };
  });

  const avgPiMetrics = {
    avgObjectivePlannedPerTeam: mean(totalPiMetrics.map((metric) => metric.planned)),
    avgObjectiveDeliveredPerTeam: mean(totalPiMetrics.map((metric) => metric.delivered)),
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Text size="lg" fw={700} mb={16}>
        Flow Metrics
      </Text>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1} sx={{ paddingBottom: '10px' }}>
          <BottomLine lastFourPIs={lastFourPIs} features={features} objectives={objectives} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FlowDashboard;
