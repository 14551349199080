import React, { useRef, useEffect } from 'react';
import { Group, Button, Textarea, Text } from '@mantine/core';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { NotesContainer, NotesArea, Note, NoteInformationContainer } from './styles';
import { color, lightColors } from '../../shared/styles/color';
import Avatar from '@mui/material/Avatar';
import { timeAgo } from '../../utils/timeUtils';

type note = {
  note: string;
  user: string;
  created_at: number;
};
interface NotesComponentProps {
  onNotesCancelClick: () => void;
  onNotesAddClick: () => void;
  onNotesTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
  notes: note[] | null;
  disabled?: boolean;
}

export const NotesComponent: React.FC<NotesComponentProps> = ({
  onNotesCancelClick,
  onNotesAddClick,
  onNotesTextChange,
  notes,
  style,
  disabled,
}) => {
  const notesAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (notesAreaRef.current) {
      notesAreaRef.current.scrollTop = notesAreaRef.current.scrollHeight;
    }
  }, [notes]);

  return (
    <NotesContainer style={style}>
      {notes?.length && (
        <NotesArea ref={notesAreaRef}>
          {notes.map((note) => (
            <Note>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: 10,
                  fontWeight: 'bold',
                  backgroundColor: '#d156a1',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}>
                {note.user
                  ?.split(' ')
                  .map((n) => n[0])
                  .join('')}
              </Avatar>
              <NoteInformationContainer>
                <Text mb={3} style={{ fontSize: '12px', marginTop: '2px' }}>
                  {note.user}
                  <span
                    style={{
                      marginLeft: '10px',
                      color: color.secondary,
                      display: 'inline-block',
                      fontSize: '12px',
                    }}>
                    {timeAgo(note.created_at)}
                  </span>
                </Text>
                <Text>{note.note}</Text>
              </NoteInformationContainer>
            </Note>
          ))}
        </NotesArea>
      )}

      <Textarea minRows={2} placeholder="Add note" onChange={onNotesTextChange} autoFocus />
      <Group justify="flex-end" mt={10}>
        <Button onClick={onNotesCancelClick} variant="subtle" color="gray">
          Cancel
        </Button>

        <Button
          variant="outline"
          onClick={onNotesAddClick}
          style={{ borderColor: lightColors.tileBorder, fontSize: '14px' }}
          color="dark"
          disabled={disabled}
          rightSection={<EastOutlinedIcon style={{ color: color.secondary }} />}>
          Add Note
        </Button>
      </Group>
    </NotesContainer>
  );
};
