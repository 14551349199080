import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mantine/hooks';
import { BasicTooltip } from '@nivo/tooltip';
import { capitalize, round } from 'lodash';

const ProgressionChart = ({ renderData, title, showLegend = true, isDone }) => {
  const [data, setData] = useState([]);
  const muiTheme = useTheme();
  const matches = useMediaQuery('(min-width: 1400px)');

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    useEffect(() => {
      const newData = renderData;
      setTimeout(() => setData(newData), 200);
    }, [renderData]);

    return (
      <g>
        <text
          x={centerX}
          y={centerY - 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '20px',
            fontWeight: 600,
          }}>
          {round(total, 1)}
        </text>
        <text
          x={centerX}
          y={centerY + 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '12px',
            fontWeight: 500,
            color: '#93a0b5',
          }}>
          {title}
        </text>
      </g>
    );
  };

  const commonProperties = {
    margin: { top: 10, right: 80, bottom: 15, left: 50 },
    animate: true,
    activeOuterRadiusOffset: 8,
  };

  return (
    <ResponsivePie
      {...commonProperties}
      data={data}
      innerRadius={0.8}
      padAngle={isDone ? 0 : 1.5}
      colors={{ datum: 'data.color' }}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      animate={true}
      arcLinkLabel={(d) => `${d.label} (${d.formattedValue})`}
      activeInnerRadiusOffset={commonProperties.activeOuterRadiusOffset}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      theme={{
        tooltip: {
          container: {
            background: muiTheme.palette.background.default,
          },
        },
      }}
      legends={
        matches &&
        showLegend && [
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateY: 0,
            itemsSpacing: 10,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]
      }
    />
  );
};

export default ProgressionChart;
