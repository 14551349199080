import React from 'react';
import { Route } from 'react-router-dom';
import NotFoundPage from 'containers/NotFoundPage';
import { Routes } from 'react-router';
import getProductRoutes from '../../Product/routes';
import getDeliveryRoutes from '../../Delivery/routes';
import getAdminRoutes from '../../Settings/routes';
import getValueStreamRoutes from '../../ValueStream/routes';
import ProtectedLayout from './ProtectedLayout';
import getPlannerRouter from '../../Planner/routes';
import getOrganisationRoutes from '../../Organisation/routes';

const AppRoutes = ({ open, user, mainRef }) => (
  <Routes>
    <Route element={<ProtectedLayout open={open} mainRef={mainRef} />}>
      {getProductRoutes(user, mainRef)}
      {getDeliveryRoutes(user, mainRef)}
      {getAdminRoutes(user, mainRef)}
      {getValueStreamRoutes(user, mainRef)}
      {getPlannerRouter(user, mainRef)}
      {getOrganisationRoutes(user, mainRef)}
      <Route component={NotFoundPage} />
    </Route>
  </Routes>
);

export default AppRoutes;
