import React, { useContext, useMemo } from 'react';
import { AppBar, Avatar, MenuItem, Toolbar, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/Menu';
import classNames from 'classnames';
import { styles } from './styles';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { makeStyles, useTheme } from '@mui/styles';
import { ColorModeContext } from '../../containers/App/components/theme';
import { styled } from '@mui/system';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  loggedInUserVar,
  resetDrawerDetails,
  selectedInitiativeVar,
  selectedPiVar,
  selectedProductThemeVar,
  selectedProgrammeVar,
  selectedTeamVar,
  selectedValueStreamVar,
  selectedOwnerVar,
  selectedStateVar,
  selectedSubmitterVar,
  selectedUsersVar,
  selectedThinkTankValueStreamVar,
} from '../../reactiveVariables';
import ToggleIcon from '../Common/ToggleIcon';
import { GET_PROGRAM_INCREMENTS } from '../../containers/Settings/ProgramIncrement/graphql';
import ProgrammeSelector from '../Common/Selectors/ProgramSelector';
import IncrementSelector from '../Common/Selectors/IncrementSelector';
import { GET_VALUE_STREAMS } from '../../containers/Settings/Streams/graphql';
import { GET_PROGRAMMES } from '../../containers/Settings/GraphQL/programmes';
import { useMantineColorScheme } from '@mantine/core';
import { last, uniq } from 'lodash';
import TeamSelector from '../Common/Selectors/TeamSelector';
import { GET_TEAMS } from '../../containers/Settings/GraphQL/teams';
import { TransitionGroup } from 'react-transition-group';
import ValueStreamSelector from '../Common/Selectors/ValueStreamSelector';
import { GET_INITIATIVES } from '../../containers/ValueStream/Initiatives/graphql';
import InitiativeSelector from '../Common/Selectors/InitiativeSelector';
import { GET_PRODUCT_THEMES } from '../../containers/ValueStream/ProductTheme/graphql';
import ProductThemeSelector from '../Common/Selectors/ProductThemeSelector';
import OwnerSelector from '../Common/Selectors/OwnerSelector';
import SubmitterAndStateSelector from '../Common/Selectors/SubmitterAndStateSelector';
import { GET_FEATURES_FOR_PROGRAMME } from '../../containers/Product/Features/graphql';

const useStyles = makeStyles(styles);

const SUPPORT_URL = 'http://bigagile.io/support';

const ToolbarLeft = styled('div')(({ theme, ...props }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
}));

const shouldShowTeamFilter = () => {
  return ['/', '/product/ideation', '/product/prioritisation', '/product/roadmap'].includes(location.pathname);
};

const shouldShowIncrementFilter = () => {
  return (
    location.pathname.startsWith('/delivery') || location.pathname === '/product/ideation' || location.pathname === '/'
  );
};

const shouldHideProgramSelector = () => {
  return (
    location.pathname.startsWith('/valueStream') ||
    location.pathname.startsWith('/organisation') ||
    (location.pathname.startsWith('/admin') && !location.pathname.startsWith('/admin/increments'))
  );
};

const shouldShowValueStreamSelector = () => {
  return (
    shouldHideProgramSelector &&
    location.pathname.startsWith('/valueStream') &&
    !location.pathname.startsWith('/valueStream/initiative/')
  );
};

const shouldShowInitiativeFilter = () => {
  return ['/', '/delivery/summary', '/delivery/detailed'].includes(location.pathname);
};

const shouldShowProductThemeFilter = () => {
  return [
    '/valueStream',
    '/product/ideation',
    '/product/prioritisation',
    '/product/roadmap',
    '/product/features',
    '/valueStream/kanban',
    '/organisation/portfolio',
    '/valueStream/initiatives',
  ].includes(location.pathname);
};

const shouldShowOwnerFilter = () => {
  return ['/valueStream/kanban', '/organisation/portfolio'].includes(location.pathname);
};

const shouldShowStateAndSubmitterFilter = () => {
  return ['/organisation/thinkTank'].includes(location.pathname);
};

const Filters = ({
  selectedProgramme,
  selectedPi,
  selectedValueStream,
  programmes,
  programIncrements,
  valueStreams,
}) => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { productThemes = [] } = {} } = useQuery(GET_PRODUCT_THEMES);
  const { data: { features: ideas = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedUsers = useReactiveVar(selectedUsersVar);
  const selectedSubmitters = useReactiveVar(selectedSubmitterVar);
  const selectedStates = useReactiveVar(selectedStateVar);
  const selectedThinkTankValueStream = useReactiveVar(selectedThinkTankValueStreamVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);

  const isInValueStream = programmes.some((pi) => pi.id === selectedProgramme);
  const isInProgramme = programIncrements.some((pi) => pi.id === selectedPi);

  const filteredPis = useMemo(
    () => programIncrements?.filter((pi) => pi.programme === selectedProgramme) || [],
    [programIncrements, selectedProgramme],
  );
  const filteredTeams = useMemo(
    () => teams?.filter((team) => team.programmes?.includes(selectedProgramme)) || [],
    [teams, selectedProgramme],
  );

  const ownersList = useMemo(
    () => initiatives.map((initiative) => initiative.owner).filter((owner) => owner !== null),
    [initiatives],
  );

  const ownedByList = useMemo(
    () =>
      uniq(ideas.filter((idea) => !!idea.assignedTo).map((idea) => idea.assignedTo)).map((idea) => {
        return {
          label: idea,
          value: idea,
        };
      }),
    [ideas],
  );

  if (selectedProgramme && !isInValueStream) selectedProgrammeVar(null);
  if (selectedPi && !isInProgramme) selectedPiVar(null);

  const handleChangeProgramme = (event, value) => {
    const programme = programmes.find((programme) => programme.id === value);
    const selectedPIs = programIncrements?.filter((pi) => pi.programme === value);
    const activePis = selectedPIs.filter((pi) => pi.status === 'active');
    const planningPis = selectedPIs.filter((pi) => pi.status === 'planning');
    const newPi = (last(activePis) || planningPis?.[0] || last(selectedPIs))?.id || 0;

    resetDrawerDetails();
    window.localStorage.setItem('selectedValueStream', programme?.valueStream?.id);
    window.localStorage.setItem('selectedProgramme', value);
    window.localStorage.setItem('selectedPI', newPi);
    selectedValueStreamVar(programme?.valueStream?.id);
    selectedProgrammeVar(value);
    selectedPiVar(newPi);
  };

  const handleChangeVS = (event, value) => {
    if (value === 'All') {
      selectedValueStreamVar(value);
      return;
    }

    const programme = programmes.find((programme) => programme.valueStream?.id === value);
    const selectedPIs = programIncrements?.filter((pi) => pi.programme === value);
    const activePi = selectedPIs.filter((pi) => pi.status === 'active');
    const newPi = (last(activePi) || last(selectedPIs))?.id || 0;

    resetDrawerDetails();
    window.localStorage.setItem('selectedValueStream', value);
    window.localStorage.setItem('selectedProgramme', programme?.id);
    window.localStorage.setItem('selectedPI', newPi);
    selectedValueStreamVar(value);
    selectedProgrammeVar(programme?.id);
    selectedPiVar(newPi);
  };

  const handleChangeTeam = (value) => selectedTeamVar(value);
  const handleChangeOwner = (value) => selectedOwnerVar(value);
  const handleChangeState = (value) => selectedStateVar(value);
  const handleChangeSubmitter = (value) => selectedSubmitterVar(value);
  const handleChangeUsers = (value) => selectedUsersVar(value);
  const thinkTankValueStreamChange = (value) => selectedThinkTankValueStreamVar(value);
  const handleChangeInitiative = (value) => selectedInitiativeVar(Number(value));
  const handleChangeProductTheme = (event, value) => selectedProductThemeVar(value);

  const handleChangePi = (event, value) => {
    resetDrawerDetails();
    window.localStorage.setItem('selectedPI', value);
    selectedPiVar(value);
  };

  return (
    <TransitionGroup component={null}>
      {!!programmes.length && !shouldHideProgramSelector() && (
        <Zoom>
          <div style={{ marginLeft: '95px' }}>
            <ProgrammeSelector
              handleChange={handleChangeProgramme}
              value={selectedProgramme || ''}
              valueStreams={valueStreams}
            />
          </div>
        </Zoom>
      )}
      {!!programmes.length && shouldShowValueStreamSelector() && (
        <Zoom>
          <div style={{ marginLeft: '95px' }}>
            <ValueStreamSelector
              handleChange={handleChangeVS}
              value={selectedValueStream || ''}
              valueStreams={activeValueStreams}
            />
          </div>
        </Zoom>
      )}
      {!!filteredPis.length && shouldShowIncrementFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <IncrementSelector handleChange={handleChangePi} value={selectedPi || ''} options={filteredPis} />
          </div>
        </Zoom>
      )}
      {selectedProgramme && !!filteredTeams.length && shouldShowTeamFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <TeamSelector handleChange={handleChangeTeam} value={selectedTeams || ''} options={filteredTeams} />
          </div>
        </Zoom>
      )}
      {selectedProgramme && !!initiatives.length && shouldShowInitiativeFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <InitiativeSelector
              handleChange={handleChangeInitiative}
              value={selectedInitiative || ''}
              initiatives={initiatives}
            />
          </div>
        </Zoom>
      )}
      {selectedProgramme && !!productThemes.length && shouldShowProductThemeFilter() && (
        <Zoom>
          <div style={{ marginLeft: '/organisation/portfolio'.includes(location.pathname) ? '95px' : '24px' }}>
            <ProductThemeSelector
              handleChange={handleChangeProductTheme}
              value={selectedProductTheme || ''}
              productThemes={productThemes}
            />
          </div>
        </Zoom>
      )}
      {selectedProgramme && !!initiatives.length && shouldShowOwnerFilter() && (
        <Zoom>
          <div
            style={{
              marginLeft:
                '/organisation/portfolio'.includes(location.pathname) && !productThemes.length ? '95px' : '24px',
            }}>
            <OwnerSelector handleChange={handleChangeOwner} value={selectedOwners || ''} options={ownedByList} />
          </div>
        </Zoom>
      )}
      {selectedProgramme && !!initiatives.length && shouldShowStateAndSubmitterFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <SubmitterAndStateSelector
              handleStateChange={handleChangeState}
              handleSubmitterChange={handleChangeSubmitter}
              handleUsersChange={handleChangeUsers}
              thinkTankValueStreamChange={thinkTankValueStreamChange}
              submittersValue={selectedSubmitters || ''}
              statesValue={selectedStates || ''}
              usersValue={selectedUsers || ''}
              thinkTankValueStreamValue={selectedThinkTankValueStream || 'All'}
              valueStreams={activeValueStreams}
            />
          </div>
        </Zoom>
      )}
    </TransitionGroup>
  );
};

function HeaderBar(props) {
  const { anchorEl, open, handleDrawerClick, handleLogout, handleClick, handleClose } = props;

  const theme = useTheme();
  const classes = useStyles();
  const colorMode = useContext(ColorModeContext);
  const user = useReactiveVar(loggedInUserVar);
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedPi = useReactiveVar(selectedPiVar);

  const initials = user.firstName?.charAt(0) + user.lastName?.charAt(0);

  const { loading: valueStreamLoading, data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { loading: piLoading, data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { loading: programmesLoading, data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  const filter = { selectedValueStream, selectedProgramme, selectedPi, valueStreams, programmes, programIncrements };

  return (
    <AppBar className={classNames(classes.appBar, open)} variant="outlined">
      <Toolbar className={classes.toolbar} disableGutters>
        <ToolbarLeft open={open}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90px' }}>
            <IconButton
              aria-label="open drawer"
              classes={{
                label: 'buttonLabel',
              }}
              color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}
              className={classNames(classes.menuButton, open)}
              size="large"
              onClick={handleDrawerClick}>
              <MenuIcon />
            </IconButton>
          </div>
          <span className={classes.logoText}>BigAgile</span>
          {!valueStreamLoading && !piLoading && !programmesLoading && <Filters {...filter} />}
        </ToolbarLeft>
        <div className={classes.toolbarRight}>
          <IconButton style={{ marginLeft: 1 }}>
            <NotificationsIcon />
          </IconButton>
          <IconButton
            style={{ marginLeft: 1 }}
            onClick={() => {
              colorMode.toggleColorMode(setColorScheme);
            }}>
            <ToggleIcon on={colorScheme === 'dark'} onIcon={<Brightness7Icon />} offIcon={<Brightness4Icon />} />
          </IconButton>
          <div className={classes.accountIconDelimiter} />
          <IconButton aria-label="Account" className={classes.accountIconButton} onClick={handleClick} size="large">
            <Avatar sx={{ bgcolor: theme.palette.color.done }}>{initials}</Avatar>
          </IconButton>
          <MenuList id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem key={0} onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;
