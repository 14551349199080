import React from 'react';
import { Typography } from '@mui/material';
import { Dot } from '../../ValueStream/components/Common';
import { valueStreamStatusColor } from '../../../utils/piColors';

function createData(item, theme) {
  const { id, name, programmes, isActive } = item;

  return {
    id,
    name,
    isActive: (
      <div style={{ display: 'flex', alignItems: 'center' }} data-value={isActive}>
        <Dot sx={{ backgroundColor: valueStreamStatusColor(isActive, theme), marginRight: '10px' }} />
        <Typography>{isActive ? 'Active' : 'Inactive'}</Typography>
      </div>
    ),
    programmes: programmes?.length,
  };
}

export function getRowsData(data, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, theme));
  }
  return rows;
}
