import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { Text } from '@mantine/core';
import { GET_PROGRAM_INCREMENTS, GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import ValueStreamMetrics from './ValueStreamMetrics';
import { GET_MILESTONES_WITH_FEATURES } from '../Milestones/graphql';
import { DateTime } from 'luxon';
import { getEndDate, getStartDate } from './FeatureRow';
import { getColumnDates, getPredictabilityForPeriod, getPreviousPeriod } from './utils';
import { GET_PROGRAMMES } from '../../Settings/GraphQL/programmes';
import { GET_FEATURES, GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { getRoadmapProjection } from './get-roadmap-projection';
import { keyBy, some, sortBy } from 'lodash';
import { date, filterProductTheme } from '../../../utils/helpers';
import TimeLineDashboard from './TimeLineDashboard';
import { useProjectedFeatures } from '../../../utils/hooks';
import { selectedProductThemeVar } from '../../../reactiveVariables';
import { initiativeWithDates } from '../utils';

const InitiativesDashboard = ({}) => {
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES_WITH_FEATURES);
  const { data: { sprints = [] } = {}, loading } = useQuery(GET_SPRINTS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);

  const [dateType, setDateType] = useState('quarter');
  const [columDates, setColumnDates] = useState(getColumnDates(dateType));
  const projectedFeatures = useProjectedFeatures();

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const predictability = useMemo(
    () => getPredictabilityForPeriod(features, columDates, programIncrements),
    [features, columDates, programIncrements],
  );
  const previousPredictability = useMemo(
    () => getPredictabilityForPeriod(features, getPreviousPeriod(dateType, columDates), programIncrements),
    [features, columDates, programIncrements],
  );

  const onDateTypeChange = (value) => {
    setColumnDates(getColumnDates(value));
    setDateType(value);
  };

  // Existing useMemo for initiativesWithDates with adjusted logic, refactor later methinks!
  const initiativesWithDates = useMemo(
    () =>
      sortBy(
        initiatives
          .filter((initiative) => filterProductTheme(selectedProductTheme, initiative))
          .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures)),
        'rank',
      ),
    [selectedProductTheme, initiatives, sprints, projectedFeatures], // Ensuring all relevant dependencies are listed..
  );

  return (
    <div>
      <ValueStreamMetrics
        previousPredictability={previousPredictability}
        predictability={predictability}
        milestones={milestones}
        initiatives={initiativesWithDates}
        dateType={dateType}
        setDateType={onDateTypeChange}
      />
      <Text size="lg" fw={700} mb={16}>
        Initiatives Breakdown
      </Text>
      <TimeLineDashboard
        initiatives={initiativesWithDates}
        projectedFeatures={projectedFeatures}
        columDates={columDates}
        loading={loading}
        setColumnDates={setColumnDates}
        dateType={dateType}
      />
    </div>
  );
};

export default InitiativesDashboard;
