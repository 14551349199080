function createData(item) {
  const { id, name, type, apiUrl } = item;

  return {
    id,
    name,
    type,
    apiUrl,
  };
}

export function getRowsData(data) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item));
  }
  return rows;
}
