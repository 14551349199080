import * as React from 'react';

function SvgSteps(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="13" height="5" rx="1" />
      <rect x="1" y="6.5" width="16" height="5" rx="1" />
      <rect x="7" y="13" width="11" height="5" rx="1" />
    </svg>
  );
}

export default SvgSteps;
