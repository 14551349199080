export const styles = (theme) => ({
  header: {
    height: 122,
    width: '100%',
    backgroundColor: (props) => (props.isDefault ? theme.palette.background.default : theme.palette.color.background),
    borderBottom: `${theme.palette.mode === 'light' ? '1px' : '2px'} solid ${theme.palette.color.border}`,
    display: 'flex',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexWrap: 'wrap',
    '&:after': {
      content: 'dkwmd',
      borderBottom: `${theme.palette.mode === 'light' ? '1px' : '2px'} solid ${theme.palette.color.border}`,
    },
  },
});
