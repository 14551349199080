import * as React from 'react';

function SvgShowDependencies(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4 4.9998C3.84183 4.9998 4.2 4.64163 4.2 4.1998C4.2 3.75798 3.84183 3.3998 3.4 3.3998C2.95817 3.3998 2.6 3.75798 2.6 4.1998C2.6 4.64163 2.95817 4.9998 3.4 4.9998ZM5.66344 4.9998C5.33397 5.93196 4.44498 6.5998 3.4 6.5998C2.07452 6.5998 1 5.52529 1 4.1998C1 2.87432 2.07452 1.7998 3.4 1.7998C4.44498 1.7998 5.33397 2.46765 5.66344 3.3998H9.8C11.5673 3.3998 13 4.83249 13 6.5998C13 8.36711 11.5673 9.7998 9.8 9.7998H8.2C7.31634 9.7998 6.6 10.5161 6.6 11.3998C6.6 12.2835 7.31634 12.9998 8.2 12.9998H12.3366C12.666 12.0676 13.555 11.3998 14.6 11.3998C15.9255 11.3998 17 12.4743 17 13.7998C17 15.1253 15.9255 16.1998 14.6 16.1998C13.555 16.1998 12.666 15.532 12.3366 14.5998H8.2C6.43269 14.5998 5 13.1671 5 11.3998C5 9.63249 6.43269 8.1998 8.2 8.1998H9.8C10.6837 8.1998 11.4 7.48346 11.4 6.5998C11.4 5.71615 10.6837 4.9998 9.8 4.9998H5.66344Z"
      />
    </svg>
  );
}

export default SvgShowDependencies;
