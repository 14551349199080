import { selectedPiVar } from '../../../reactiveVariables';
import { piStatusColor } from '../../../utils/piColors';
import { find, last } from 'lodash';
import React, { useEffect } from 'react';
import { BigAgileSelect } from '../Select/CustomSelect';
import { PI_STATUSES_DISPLAY } from '../../../containers/Settings/ProgramIncrement/constants';
import { useTheme } from '@emotion/react';
import { Dot } from '../../../containers/ValueStream/components/Common';

const IncrementSelector = ({ options, value, handleChange }) => {
  const theme = useTheme();
  const values = options.map((pi) => ({
    id: pi.id,
    name: `${pi.name} ${PI_STATUSES_DISPLAY[pi.status]}`,
    color: piStatusColor(pi.status, theme),
  }));
  const selectedOption = find(options, { id: value });

  useEffect(() => {
    if ((!value && options[0]) || !options.some((pi) => pi.id === value)) {
      const selectedProgram = options.filter((pi) => pi.status === 'active');
      const newPi = (last(selectedProgram) || last(options))?.id;
      selectedPiVar(newPi);
      window.localStorage.setItem('selectedPI', newPi);
    }
  }, [value, options]);

  const renderValue = (option) => {
    return (
      <span style={{ color: piStatusColor(selectedOption?.status, theme) }}>
        <Dot style={{ height: '8px', width: '8px', backgroundColor: piStatusColor(selectedOption?.status, theme) }} />
        {option?.label}
      </span>
    );
  };

  return <BigAgileSelect options={values} value={value} onChange={handleChange} renderValue={renderValue} />;
};

export default IncrementSelector;
