import React from 'react';
import { useTheme } from '@emotion/react';
import { Select, Text } from '@mantine/core';
import { sortBy } from 'lodash';
import classes from './InitiativeSelector.module.css';

const InitiativeSelector = ({ initiatives = [], value, handleChange }) => {
  const filteredInitiatives = initiatives.filter((value) => value.initiative_state !== 'Done');
  const values = filteredInitiatives.map((initiative) => ({ value: String(initiative.id), label: initiative.name }));
  const theme = useTheme();

  return (
    <Select
      data={sortBy(values, 'name')}
      value={value}
      searchable
      clearable
      placeholder="Select an initiative:"
      classNames={{
        label: classes.label,
        wrapper: classes.wrapper,
        option: classes.item,
        input: classes.input,
        section: classes.section,
      }}
      leftSection={
        <Text c={theme.palette.text.secondary} style={{ paddingLeft: '12px', marginRight: '8px' }}>
          Initiative:{' '}
        </Text>
      }
      onChange={handleChange}
    />
  );
};

export default InitiativeSelector;
