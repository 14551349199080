import React, { useRef } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { mean, sum } from 'lodash';
import { BasicTooltip } from '@nivo/tooltip';
import { nivoTheme } from '../Common';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@mui/material';

const FlowDistribution = ({ programIncrements, objectives }) => {
  const rectRef = useRef(null);
  const theme = useTheme();

  const data = [
    { month: 'Jan', Features: 60, Unknown: 25, Bugs: 5, Maintenance: 10 },
    { month: 'Feb', Features: 59, Unknown: 27, Bugs: 6, Maintenance: 8 },
    { month: 'Mar', Features: 75, Unknown: 10, Bugs: 3, Maintenance: 12 },
    { month: 'Apr', Features: 80, Unknown: 6, Bugs: 8, Maintenance: 6 },
    { month: 'May', Features: 40, Unknown: 20, Bugs: 15, Maintenance: 25 },
    { month: 'Jun', Features: 68, Unknown: 15, Bugs: 9, Maintenance: 8 },
    { month: 'Jul', Features: 70, Unknown: 10, Bugs: 6, Maintenance: 14 },
    { month: 'Aug', Features: 58, Unknown: 24, Bugs: 12, Maintenance: 6 },
    { month: 'Sep', Features: 76, Unknown: 12, Bugs: 8, Maintenance: 4 },
    { month: 'Oct', Features: 65, Unknown: 8, Bugs: 10, Maintenance: 17 },
    { month: 'Nov', Features: 70, Unknown: 14, Bugs: 8, Maintenance: 8 },
    { month: 'Dec', Features: 57, Unknown: 20, Bugs: 15, Maintenance: 8 },
  ];

  const ticks = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const commonProps = {
    margin: { top: 60, right: 80, bottom: 90, left: 80 },
    // data: [{ id: 'Stuff', label: 'Stuff', data: [{ month: '', days: 0 }, ...data] }],
    padding: 0.1,
    innerPadding: 3,
    indexBy: 'month',
    keys: ['Unknown', 'Bugs', 'Maintenance', 'Features'],
  };

  const colors = {
    Features: theme.palette.color.inProgress,
    Unknown: theme.palette.color.subtitle,
    Bugs: theme.palette.color.cancel,
    Maintenance: theme.palette.color.done,
  };

  return (
    <ResponsiveBar
      {...commonProps}
      ref={rectRef}
      data={data}
      theme={nivoTheme()}
      colors={({ id, data }) => colors[id]}
      borderRadius={2}
      groupMode="stacked"
      axisLeft={{
        tickSize: 0,
        tickValues: 5,
        tickPadding: 12,
        legend: 'Percentage',
        legendPosition: 'middle',
        legendOffset: -60,
        format: (value) => `${value}%`,
      }}
      enableLabel={false}
      axisBottom={{
        tickSize: 0,
        tickPadding: 12,
        legend: 'Months',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      indexScale={{ type: 'band', round: false }}
      isInteractive={true}
      animate={true}
      enableGridY={false}
    />
  );
};

export default FlowDistribution;
