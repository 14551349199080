import Grow from '@mui/material/Grow';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { styles } from './styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PROGRAM_INCREMENTS } from './graphql';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { openDrawer, selectedProgrammeVar } from '../../../reactiveVariables';
import EntityTable from '../../../components/Common/EntityTable';
import { useTheme } from '@emotion/react';
import { exportCSVData, getFieldValue } from 'utils/export';

const useStyles = makeStyles(styles);

const ProgramIncrements = ({ searchQuery }, ref) => {
  const classes = useStyles();
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);

  const theme = useTheme();
  const filteredPis = programIncrements.filter((pi) => !selectedProgramme || pi.programme === selectedProgramme);

  useImperativeHandle(ref, () => ({
    export: () => exportIntegration(),
  }));

  const exportIntegration = useCallback(() => {
    const data = programIncrements?.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Value Streams');
  }, [programIncrements]);

  const onRowClick = (event, row) => {
    const pi = filteredPis.find((pi) => pi?.id === row.id);
    openDrawer(pi, 'increment');
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          data={filteredPis}
          headCells={headCells}
          rows={getRowsData(filteredPis, features, theme)}
          searchQuery={searchQueryLowerCase}
          expand={false}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};
export default forwardRef(ProgramIncrements);
