import { DateTime } from 'luxon';
import { getEndDate, getStartDate } from './InitiativesDashboard/FeatureRow';
import { date } from '../../utils/helpers';

export const getInitiativeBgColor = (initiative, theme) => {
  const ragStatus = initiative.ragStatus;
  const isDone =
    initiative.features?.nodes.length && initiative.features?.nodes.every((feature) => feature.status === 'Done');

  return isDone
    ? theme.palette.color.done
    : ragStatus === 'amber'
    ? theme.palette.color.amber
    : ragStatus === 'red'
    ? theme.palette.color.red
    : theme.palette.color.green;
};

export const getMantineColor = (initiative) => {
  const ragStatus = initiative.ragStatus;
  const isDone =
    initiative.features?.nodes.length && initiative.features?.nodes.every((feature) => feature.status === 'Done');

  return isDone ? 'done.5' : ragStatus === 'amber' ? 'amber.5' : ragStatus === 'red' ? 'baRed.0' : 'baGreen.6';
};

export const getMilestoneRagStatus = (milestone, theme) => {
  const ragStatus = milestone.features.some((feature) => feature.feature.ragStatus !== 'Green') ? 'red' : 'green';
  const isDone = milestone.features.every((feature) => feature.feature.status === 'Done');

  return isDone
    ? theme.palette.color.done
    : ragStatus === 'amber'
    ? theme.palette.color.amber
    : ragStatus === 'red'
    ? theme.palette.color.red
    : theme.palette.color.green;
};

export const initiativeWithDates = (initiative, sprints, projectedFeatures) => {
  const features = initiative?.features?.nodes || [];
  const endDate = features
    .map((feature) => DateTime.fromISO(getEndDate(feature, sprints, projectedFeatures)))
    .filter((date) => date.isValid)
    .sort((a, b) => a.diff(b))
    .pop();
  const startDate = features
    .map((feature) => DateTime.fromISO(getStartDate(feature, sprints, projectedFeatures)))
    .filter((date) => date.isValid)
    .sort((a, b) => a.diff(b))[0];
  const featuresRag = initiative.features?.nodes
    ?.filter((feature) => feature.status !== 'Done')
    .some((feature) => ['Red', 'Amber'].includes(feature.ragStatus))
    ? 'red'
    : 'green';
  const targetDate = date(initiative.plannedRealisationDate);
  const isDone =
    initiative.features?.nodes.length && initiative.features?.nodes.every((feature) => feature.status === 'Done');
  const ragStatus = initiative.plannedRealisationDate
    ? targetDate < endDate
      ? 'red'
      : featuresRag === 'red'
      ? 'amber'
      : 'green'
    : featuresRag;
  return { ...initiative, startDate, endDate, ragStatus, isDone };
};

export const countStatuses = (initiative) => {
  const statusCounts = {
    Done: 0,
    'In Progress': 0,
    'To Do': 0,
  };

  initiative.features.nodes.forEach((node) => {
    if (node.status in statusCounts) {
      statusCounts[node.status]++;
    }
  });

  return statusCounts;
};
