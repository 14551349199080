export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: 2,
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    width: 5,
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'featuresIn',
    field: 'featuresIn',
    label: 'Features In',
    width: 2,
  },
  {
    id: 'initiativesIn',
    field: 'initiativesIn',
    label: 'Initatives In',
    width: 2,
  },
  {
    id: 'progress',
    field: 'progress',
    label: 'Progress',
    width: 4,
  },
  {
    id: 'plannedRealisationDate',
    field: 'plannedRealisationDate',
    label: 'Planned Realisation Date',
    width: 3,
  },
];
