/**
 * Error used to handle network errors
 * Keeps track of status code and message
 *
 * TODO:
 * - localized error messages
 * - error messages based on codes
 *
 * @export
 * @class NetworkError
 * @extends {Error}
 */
export class NetworkError extends Error {
  /**
   * Creates an instance of NetworkError.
   * @param {number} status
   * @param {string} message
   * @memberof NetworkError
   */
  constructor(status, message) {
    super(status, message);

    this.status = status;
    this.message = message;

    this.constructor = NetworkError;
    this.__proto__ = NetworkError.prototype;
  }
}
