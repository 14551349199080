import React from 'react';
import { Box, Grid } from '@mui/material';
import { IdeationSortOptions } from '../constants';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useDroppable } from '@dnd-kit/core';
import Idea from './idea';
import Draggable from '../../Delivery/components/Risks/draggable';
import { getColumnStyle } from './helper';
import { useTheme } from '@emotion/react';
import Select from '../../../components/Common/Select';
import { useIsFirstRender } from '../../../utils/hooks';
import { useQuery } from '@apollo/client';
import { GET_SUBTASKS } from '../../Settings/GraphQL/configuration';

const IdeationColumn = ({
  column,
  sortValue,
  sortByOption,
  sortBy,
  classes,
  displaySplit = true,
  sortListOptions = IdeationSortOptions,
  initiatives = [],
}) => {
  const { data: { subtasks = [] } = {} } = useQuery(GET_SUBTASKS);
  const { setNodeRef, isOver } = useDroppable({ id: column.value, data: { column } });
  const theme = useTheme();
  const isFirst = useIsFirstRender();

  const checked = ['done', 'inProgress', 'planned'].includes(column.value);

  const renderIdea = (feature, index, style = {}) => {
    return (
      <Flipped flipId={`${column.value}-${feature.id}`} key={feature.id}>
        <Draggable key={feature.id} element={feature} style={style} data={{ feature, column }}>
          <Idea
            show={!isFirst || index < 10}
            key={feature.id}
            columnSortBy={sortBy[column.value]}
            column={column}
            feature={feature}
            subtasks={subtasks}
            checked={checked}
            sortListOptions={sortListOptions}
            initiatives={initiatives}
          />
        </Draggable>
      </Flipped>
    );
  };

  return (
    <>
      {column.value === 'candidate' && displaySplit && <Grid key="line" className={classes.line} item />}
      <Grid key={column.value} className={classes.column} item>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className={classes.columnTitle}>{`${column.title} (${column.data?.length})`}</Box>
          <Select
            onChange={(event, value) => sortByOption(column.value, value)}
            defaultValue={sortValue}
            placeholder="Sort By"
            border={false}
            toggleStyle={{ justifyContent: 'flex-end' }}
            options={sortListOptions[column.value]}
          />
        </div>
        <Flipper className={classes.flexColScroll} flipKey={sortValue}>
          <div ref={setNodeRef} style={getColumnStyle({}, isOver, theme)}>
            {column.data.map((feature, index) => renderIdea(feature, index))}
          </div>
        </Flipper>
      </Grid>
    </>
  );
};

export default React.memo(IdeationColumn);
