import * as React from 'react';

function SvgHexagone(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2L3 7.45455V16.585L12 22L21 16.5455V7.45455L12 2ZM12 15.0433C13.7397 15.0433 15.15 13.6806 15.15 11.9998C15.15 10.3189 13.7397 8.9563 12 8.9563C10.2603 8.9563 8.84997 10.3189 8.84997 11.9998C8.84997 13.6806 10.2603 15.0433 12 15.0433Z"
      />
    </svg>
  );
}

export default SvgHexagone;
