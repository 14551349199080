import { DateTime } from 'luxon';
import { TableCell } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { useDroppable } from '@dnd-kit/core';

const useStyles = makeStyles(styles);

const SummaryTeamSprint = ({ sprint, team, children }) => {
  const classes = useStyles();

  const { setNodeRef, isOver } = useDroppable({
    id: `${team.id}-${sprint.id}`,
    data: { team, sprint },
  });

  const isCurrentSprint =
    DateTime.fromISO(sprint.startDate).startOf('day') <= DateTime.local().startOf('day') &&
    DateTime.local().startOf('day') <= DateTime.fromISO(sprint.endDate).startOf('day');

  return (
    <TableCell
      sx={{ backgroundColor: isOver ? 'color.paper' : null }}
      ref={setNodeRef}
      key={sprint.id}
      className={cn(classes.tableCell, isCurrentSprint && classes.currentSprint)}>
      {children}
    </TableCell>
  );
};

export default SummaryTeamSprint;
