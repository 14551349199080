import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SectionHeader from 'components/Common/SectionHeader';
import { TABS } from './constants';
import SectionHeaderWrapper from 'components/Common/SectionHeader/HeaderWrapper';
import Roadmap from './Roadmap';
import Progression from './Progression';
import AdditionalStoriesCharts from '../Delivery/components/Dashboard/Reporting/AdditionalStoriesCharts';
import { useQuery } from '@apollo/client';
import { GET_PROGRAM_INCREMENTS, GET_SELECTED_PI } from '../Settings/ProgramIncrement/graphql';
import { GET_TEAMS } from '../Settings/GraphQL/teams';
import { selectedPiVar } from '../../reactiveVariables';

const REPORTS = 'Metrics';

function Reports() {
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { selectedPI = {} } = {} } = useQuery(GET_SELECTED_PI);
  // eslint-disable-next-line no-unused-vars
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const [value, setValue] = useState(0);

  function onHandleChangePi(val) {
    window.localStorage.setItem('selectedPI', val);
    selectedPiVar(val);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <SectionHeaderWrapper isDefault={false}>
        <SectionHeader
          value={value}
          title={REPORTS}
          programIncrements={programIncrements}
          selectedPi={selectedPI}
          handleChangePi={onHandleChangePi}
          tabs={TABS}
          handleChange={handleTabChange}
        />
      </SectionHeaderWrapper>
      <div style={{ padding: 20 }}>
        {value === 0 && <Roadmap />}
        {value === 1 && <Progression />}
        {value === 2 && <AdditionalStoriesCharts />}
      </div>
    </React.Fragment>
  );
}

export default Reports;
