export const styles = (theme) => ({
  root: {
    height: '100%',
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    height: '64px',
  },
  button: {
    color: theme.palette.text.primary,
    fontSize: 18,
    marginRight: '10px',
    '& svg': {
      fill: theme.palette.color.buttonIcon,
    },
    '& path': {
      fill: theme.palette.color.buttonIcon,
    },
  },
  drawerPaper: {
    zIndex: theme.zIndex.drawer + 2,
    height: '100%',
    width: '700px',
    backgroundColor: theme.palette.color.paper,
    overflow: 'hidden',
  },
  drawerPaperOpen: {
    zIndex: theme.zIndex.drawer + 2,
    height: '100%',
    width: '700px',
    backgroundColor: theme.palette.color.paper,
    overflow: 'hidden',
  },
  flex: {
    width: '60%',
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerInner: {
    width: '700px',
    height: '-webkit-fill-available',
  },
  drawerBody: {
    overflow: 'auto',
    height: '100%',
  },
  title: {
    marginBottom: 16,
    fontSize: 110,
    color: theme.palette.text.secondary,
  },
});
