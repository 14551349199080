import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Progress, Tooltip, useMantineTheme } from '@mantine/core';
import { alpha } from '@mui/material/styles';

const LinearProgressWithLabel = (props, minWidth = 40) => {
  const theme = useMantineTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!props.hideLabel && (
        <Box sx={{ minWidth: minWidth, marginRight: '10px' }}>
          <Typography fontSize={14} fontWeight={550} textAlign="right" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      )}
      <Box sx={{ width: '100%' }}>
        <Progress
          {...props}
          size={10}
          transitionDuration={200}
          styles={{
            section: {
              transition: 'width 250ms ease',
            },
            root: {
              backgroundColor: alpha(props.color ?? theme.other.colours.done, 0.15),
            },
          }}
        />
      </Box>
    </Box>
  );
};

const LinearWithValueLabel = ({ value, color, tooltip, hideLabel }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => setProgress(value), 100);
  }, [value]);

  return (
    <Tooltip label={tooltip} zIndex={5000} opened={!tooltip ? false : null} transitionProps={{ transition: 'scale' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel value={progress} color={color} hideLabel={hideLabel} />
      </Box>
    </Tooltip>
  );
};

export default LinearWithValueLabel;
