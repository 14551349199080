import { grey } from '@mui/material/colors';

export const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
  progress: {
    position: 'absolute',
    fontSize: '20px',
    top: 'calc(45% - 10px)',
    left: 'calc(50% - 10px)',
  },
  progressBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .3)',
  },
  appBar: {
    marginBottom: '5',
    marginTop: '0',
    paddingLeft: '0',
    minHeight: 50,
    fontSize: 20,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      background: '#FAFAFA',
    },
    tableHeadRow: {
      backgroundColor: grey[700],
      color: grey[200],
      textAlign: 'left',
      borderBottom: '0',
      borderRight: '1px solid ' + grey[200],
      padding: '4px 24px 4px 24px',
    },
    button: {
      marginRight: 5,
      nativeColor: '#426CB4',
      width: '30px',
      height: '30px',
    },
    icon: {
      color: 'white',
    },
  },
});
