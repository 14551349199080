import { Divider, SimpleGrid, Text, Title } from '@mantine/core';
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import {
  Field,
  renderCombobox,
  renderCreatableCombobox,
  renderDate,
  renderInput,
  renderNumberInput,
} from '../../../../components/Common/FormFieldsHooks/MantineField';

const FirstPage = ({ keyResult, objective }) => {
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  return (
    <>
      <Field name="name" data-autofocus required renderField={renderInput} label="Name" />
      <Field name="description" renderField={renderInput} label="Description" mt="md" />
      <Field
        name="owner"
        required
        renderField={renderCombobox}
        label="Owner"
        mt="md"
        data={users.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))}
        size={6}
      />
      <Divider mt={12} mb={12} />
      <Title order={6}>Key Result Settings</Title>
      <Text c="dimmed" mb={12}>
        Set specifics for key results
      </Text>
      <SimpleGrid cols={2}>
        <Field name="startingValue" required renderField={renderNumberInput} label="Starting Value" />
        <Field name="targetValue" required renderField={renderNumberInput} label="Target Value" />
        <Field
          name="startingDate"
          required
          renderField={renderDate}
          label="Starting Date"
          maxDate={new Date(objective.targetDate)}
        />
        <Field
          name="targetDate"
          required
          renderField={renderDate}
          label="Target Date"
          minDate={new Date(keyResult.startingDate)}
          maxDate={new Date(objective.targetDate)}
        />
        <Field
          name="unit"
          required
          renderField={renderCreatableCombobox}
          label="Unit"
          options={['£', '$', '€', '%', 'Points']}
        />
        <Field
          name="reviewFrequency"
          required
          renderField={renderCombobox}
          label="Review Frequency"
          data={[
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'quarterly', label: 'Quarterly' },
            { value: '6months', label: 'Every 6 months' },
          ]}
        />
      </SimpleGrid>
    </>
  );
};

export default FirstPage;
