import * as React from 'react';

function SvgAccount(props) {
  return (
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 14.08c-2.455 0-4.714.44-6.508 2.245-.301.303-.405.824-.077 1.157A9.212 9.212 0 0011 20.24a9.21 9.21 0 006.586-2.76c.327-.332.224-.853-.078-1.157C15.715 14.52 13.455 14.08 11 14.08zm0-9.68a3.52 3.52 0 100 7.04 3.52 3.52 0 000-7.04z" />
    </svg>
  );
}

export default SvgAccount;
