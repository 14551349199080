import React from 'react';
import { Text, Tooltip } from '@mantine/core';
import {
  FormHeader,
  Wrapper,
  TopSection,
  Button,
  CompanyName,
  BottomSection,
  UserIdeasContainer,
  IdeaCard,
  BoxCount,
  Count,
  BoxCountContainer,
} from '../styles';
import config from 'Config';
import { color } from '../../../shared/styles/color';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_MOST_RECENT_IDEAS, GET_ALL_IDEAS } from '../graphql';
import { truncate } from '../../../utils/stringUtils';
import Calendar from '../../../assets/images/Calendar';
import SvgBigAgileLogo from '../../../assets/images/BigAgileLogo';
import { map, uniq, filter, size } from 'lodash';

const IdeaFormIndex = () => {
  const navigate = useNavigate();

  const { data: { ideas = [] } = {} } = useQuery(GET_ALL_IDEAS);

  const { data: recentIdeas = {} } = useQuery(GET_MOST_RECENT_IDEAS, {
    variables: { limit: 15, order: 'desc' },
  });

  const hostname = location.hostname;
  const company = config[hostname];

  return (
    <Wrapper>
      <TopSection>
        <CompanyName>
          <Text>{company.name}</Text>
        </CompanyName>

        <div style={{ width: '125px', margin: '0 auto 50px', cursor: 'pointer' }} onClick={() => navigate(`/`)}>
          <SvgBigAgileLogo />
        </div>
        <FormHeader>
          Ideas. <span style={{ color: 'black' }}>The form.</span>
        </FormHeader>

        <Text c="dimmed" size="16px" lh={'20px'} mb={40}>
          Your ideas can make a big difference. Click the link below to share your thoughts and influence{' '}
          <span style={{ color: color.companyColor }}>{company.name}&apos;s</span> direction.
        </Text>

        <Button onClick={() => navigate(`/submit-idea/new`)}>Submit an idea</Button>

        <BoxCountContainer style={{ maxWidth: '274px' }}>
          <BoxCount>
            <Count>{uniq(map(ideas, 'user_id')).length}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Users
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{ideas.length}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Ideas
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{size(filter(ideas, { state: 'Accepted' }))}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Features
            </Text>
          </BoxCount>
        </BoxCountContainer>
      </TopSection>

      {recentIdeas?.ideas?.length > 0 && (
        <BottomSection>
          <Text c="dimmed" size="12px" lh={'20px'} mb={40} mt={50}>
            Recent submissions
          </Text>
          <UserIdeasContainer>
            {recentIdeas.ideas.map((idea) => (
              <IdeaCard key={idea.id}>
                <Text>{truncate(idea.idea.name, 35)}</Text>
                <Text c="dimmed" size="12px" lh={'20px'}>
                  {truncate(idea.idea.description, 38)}
                </Text>
                <Tooltip label={`State: ${idea.state}`}>
                  <div style={{ position: 'absolute', right: '12px', bottom: '20px', cursor: 'pointer' }}>
                    <Calendar />
                  </div>
                </Tooltip>
              </IdeaCard>
            ))}
          </UserIdeasContainer>
        </BottomSection>
      )}
    </Wrapper>
  );
};

export default IdeaFormIndex;
