import React from 'react';

import { Route } from 'react-router-dom';

import BigAgileTable from '../../components/Common/BigAgileTable';
import IdeaBoard from './IdeaBoard';
import Prioritisation from './Prioritisation';
import ProductRoadMap from './ProductRoadmap';

const getProductRoutes = (user) => (
  <>
    <Route user={user} path="/product/ideation" element={<IdeaBoard />} />
    <Route user={user} path="/product/roadmap" element={<ProductRoadMap />} />
    <Route user={user} path="/product/prioritisation" element={<Prioritisation user={user} />} />
    <Route user={user} path="/product/features" element={<BigAgileTable type="feature" />} />
  </>
);

export default getProductRoutes;
