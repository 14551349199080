import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useRef, useState } from 'react';
import Idea from '../../Product/IdeaBoard/idea';
import { DateTime } from 'luxon';
import { get } from 'lodash';
import { piStatusColor } from 'utils/piColors';
import { getColumns, getIdeationMessage, sortFeaturesBy, validateColumns } from '../../Product/IdeaBoard/helper';
import { styles } from '../../Product/IdeaBoard/styles';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_SELECTED_PI_FULL } from '../../Settings/ProgramIncrement/graphql';
import { UPDATE_FEATURE_STAGE, GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import IdeationColumn from '../../Product/IdeaBoard/ideationColumn';
import { filterProductTheme, removeIdField } from '../../../utils/helpers';
import { selectedTeamVar } from '../../../reactiveVariables';
import { GET_INITIATIVES } from '../../../containers/ValueStream/Initiatives/graphql';
import { useTheme } from '@emotion/react';
import { showNotification } from '@mantine/notifications';
import { FeatureKanbanSortOptions } from '../../Product/constants';

const useStyles = makeStyles(styles);

export default function FeatureKanban({ selectedProductTheme, selectedOwners }) {
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { features: ideas = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const theme = useTheme();

  const styleProps = {
    backgroundColor: piStatusColor(selectedPiFull?.status, theme, true),
  };
  const classes = useStyles(styleProps);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const sensors = useSensors(mouseSensor);
  const selectedTeams = useReactiveVar(selectedTeamVar);

  const droppedRef = useRef(null);
  const [droppedId, setDroppedId] = useState(null);
  const [animateId, setAnimateId] = useState(null);
  const [backlog, setBacklog] = useState(0);
  const [activeId, setActiveId] = useState(null);

  const [sortBy, setSorBy] = useState({
    funnel: window.localStorage.getItem('funnelSortBy') || 'name',
    analysis: window.localStorage.getItem('analysisSortBy') || 'name',
    backlog: window.localStorage.getItem('backlogSortBy') || 'name',
    candidate: window.localStorage.getItem('candidateSortBy') || 'name',
    planned: window.localStorage.getItem('plannedSortBy') || 'name',
    inProgress: window.localStorage.getItem('inProgressSortBy') || 'name',
    done: window.localStorage.getItem('doneSortBy') || 'name',
  });

  const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);

  const getIdeasForStage = (stage) => {
    const data = ideas.filter(
      (idea) =>
        filterProductTheme(selectedProductTheme, idea) &&
        idea.stage === stage &&
        (selectedOwners.length === 0 || selectedOwners.includes(idea.assignedTo)),
    );
    return sortFeaturesBy(data, sortBy[stage]);
  };

  const funnelFeatures = useMemo(
    () => getIdeasForStage('funnel'),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.funnel, selectedProductTheme, selectedOwners],
  );
  const analysisFeatures = useMemo(
    () => getIdeasForStage('analysis'),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.analysis, selectedProductTheme, selectedOwners],
  );
  const backlogFeatures = useMemo(
    () => getIdeasForStage('backlog'),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.backlog, selectedProductTheme, selectedOwners],
  );
  const candidateFeatures = useMemo(
    () => getIdeasForStage('candidate', true),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.candidate, selectedProductTheme, selectedOwners],
  );
  const plannedFeatures = useMemo(
    () => getIdeasForStage('planned', true),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.planned, selectedProductTheme, selectedOwners],
  );
  const implementationFeatures = useMemo(
    () => getIdeasForStage('inProgress', true),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.inProgress, selectedProductTheme, selectedOwners],
  );
  const doneFeatures = useMemo(
    () => getIdeasForStage('done', true),
    [backlog, selectedTeams, selectedPiFull, ideas, sortBy.done, selectedProductTheme, selectedOwners],
  );

  const columns = getColumns(
    funnelFeatures,
    analysisFeatures,
    backlogFeatures,
    candidateFeatures,
    plannedFeatures,
    implementationFeatures,
    doneFeatures,
  );

  const scrollToRef = (ref, droppedId) => {
    if (!droppedId || !ref.current) return;
    ref.current.focus();
    setAnimateId(droppedId);
    setDroppedId(null);
  };

  const onDragEnd = (event) => {
    if (!event || !event.over) return;
    const initialColumn = event.active?.data?.current?.column?.value;
    const droppedColumn = event.over.id;

    if (initialColumn === droppedColumn) return;

    const feature = event.active?.data?.current?.feature;
    const column = event.over?.data?.current?.column;

    if (column.isPerPI && !selectedPiFull) {
      showNotification({
        title: 'Error: No Increment',
        message: 'Cannot move feature into a Increment specific column.',
        color: 'red',
      });
      return;
    }

    if (validateColumns(event.over.id, feature)) {
      showNotification({
        title: 'Warning',
        message: getIdeationMessage(),
        color: 'orange.4',
      });
    }

    const featureToSave = {
      stage: event.over.id,
      ...(column.isPerPI && { programIncrement: selectedPiFull?.id }),
      missingFields: validateColumns(event.over.id, feature),
    };

    updateFeature({
      variables: {
        feature: removeIdField(featureToSave, 'milestones'),
        featureId: feature.id,
      },
      optimisticResponse: {
        feature: { ...feature, stage: event.over.id, updatedAt: DateTime.local().toISO() },
      },
    });

    setActiveId(null);
  };

  const sortByOption = (stage, sortOption) => {
    setSorBy({ ...sortBy, [stage]: sortOption });
    window.localStorage.setItem(`${stage}SortBy`, sortOption);
  };
  const handleDragStart = (event) => {
    setActiveId(event.active?.data?.current);
  };

  const renderDragOverlay = (data) => {
    return <Idea isOverlay={true} key="overlay" sortBy={sortBy} column={data.column} feature={data.feature} />;
  };

  scrollToRef(droppedRef, droppedId);

  return (
    <div className={classes.container}>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
        <Grid key="container" container className={classes.flexSection} justifyContent="center" spacing={1}>
          {columns.map((column, index) => (
            <IdeationColumn
              sortListOptions={FeatureKanbanSortOptions}
              classes={classes}
              initiatives={initiatives}
              animateId={animateId}
              displaySplit={false}
              key={column.value}
              index={index}
              column={column}
              sortBy={sortBy}
              sortValue={sortBy[column.value]}
              sortByOption={sortByOption}
            />
          ))}
        </Grid>
        <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
      </DndContext>
    </div>
  );
}
