import { useDroppable } from '@dnd-kit/core';
import { DateTime } from 'luxon';
import { TableCell } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { get } from 'lodash';
import FeatureCard from '../../../../components/Dashboard/FeatureBoard/FeatureCard';
import ObjectiveCard from '../../../../components/Dashboard/FeatureBoard/ObjectiveCard';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../../../../components/Dashboard/FeatureBoard/styles';
import Draggable from '../Risks/draggable';

const useStyles = makeStyles(styles);

const TeamSprint = ({
  enableDrag,
  sprintField,
  team,
  sprint,
  teamFeatures,
  teamObjectives,
  selectedMilestone,
  showObjectives,
  progressBy,
  deferredFeatures,
  pi,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `${team.id}-${sprint.id}`,
    data: { team, sprint },
  });
  const classes = useStyles();

  const isCurrentSprint =
    DateTime.fromISO(sprint.startDate) < DateTime.local() && DateTime.local() < DateTime.fromISO(sprint.endDate);

  const getItemsForSprint = (teamFeatures, teamObjectives, sprint, selectedMilestone, showObjectives) => {
    const features = teamFeatures
      .filter((feature) => get(feature, sprintField) === sprint.id)
      .map((feature) => (
        <Draggable key={feature.id} enabled={enableDrag} element={feature} data={feature}>
          <FeatureCard
            selectedMilestone={selectedMilestone}
            key={feature.id}
            feature={feature}
            progressBy={progressBy}
          />
        </Draggable>
      ));
    const deferredFeatureCards = deferredFeatures
      .filter(
        (feature) =>
          feature.completedIncrements?.[pi?.id]?.[
            sprintField === 'committedSprint' ? 'committedSprint' : 'originalCommittedSprint'
          ] === sprint.id,
      )
      .map((feature) => (
        <FeatureCard
          selectedMilestone={selectedMilestone}
          key={feature.id}
          feature={{ ...feature, isDescoped: true }}
          progressBy={progressBy}
        />
      ));
    const objectives = teamObjectives
      .filter((objective) => objective.sprintId === sprint.id)
      .map((objective) => <ObjectiveCard key={objective.id} objective={objective} />);
    return (
      <>
        {showObjectives && <span>{objectives}</span>}
        {!showObjectives && (
          <span>
            {features}
            {deferredFeatureCards}
          </span>
        )}
      </>
    );
  };

  return (
    <TableCell
      sx={{ backgroundColor: isOver ? 'color.paper' : 'color.background', verticalAlign: 'top' }}
      ref={setNodeRef}
      key={sprint.id}
      className={cn(classes.tableCell, isCurrentSprint && classes.currentSprint)}>
      {getItemsForSprint(teamFeatures, teamObjectives, sprint, selectedMilestone, showObjectives)}
    </TableCell>
  );
};

export default TeamSprint;
