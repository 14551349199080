const border = 'solid 1px #d5d5d5';

export const styles = (theme) => ({
  buttonsWrapper: {
    minWidth: 190,
    padding: 3,
    margin: '0 15px',
    backgroundColor: theme.palette.color.selector,
    borderRadius: 60,
  },
  buttonRoot: {
    '&.MuiButtonBase-root:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      color: theme.palette.color.slateGrey,
      backgroundColor: theme.palette.color.selector,
    },
  },
  toggleButton: {
    borderRadius: 60,
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: theme.palette.color.selector,
    color: theme.palette.text.primary,
    fontSize: 13,
    '&.MuiButton-contained:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
    },
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  toggleButtonActive: {
    color: theme.palette.color.purplePink,
    backgroundColor: 'rgba(219, 54, 192, 0.18)',
    fontWeight: 600,
    '&.MuiButton-contained:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(219, 54, 192, 0.18)',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(219, 54, 192, 0.18)',
    },
  },
});
