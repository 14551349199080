export const headCells = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'username',
    field: 'username',
    label: 'Username',
  },
  {
    id: 'email',
    field: 'email',
    label: 'Email',
  },
  {
    id: 'role',
    field: 'role',
    label: 'Role',
  },
];
