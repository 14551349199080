import * as React from 'react';

function SvgSummaryView(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3C1.00736 3 0 4.00736 0 5.25C0 6.49264 1.00736 7.5 2.25 7.5H4.5C5.61513 7.5 6.54079 6.68877 6.71901 5.62427H8.62427V12.375C8.62427 12.669 8.79339 12.9234 9.03964 13.0464C9.14074 13.0972 9.2549 13.1257 9.37573 13.1257H11.2814C11.4605 14.1892 12.3856 14.9993 13.5 14.9993H15.75C16.9926 14.9993 18 13.9919 18 12.7493C18 11.5066 16.9926 10.4993 15.75 10.4993H13.5C12.3846 10.4993 11.4588 11.3109 11.2809 12.3757H9.37427V5.62427H11.281C11.4592 6.68877 12.3849 7.5 13.5 7.5H15.75C16.9926 7.5 18 6.49264 18 5.25C18 4.00736 16.9926 3 15.75 3H13.5C12.3854 3 11.4601 3.81049 11.2812 4.87427H6.71877C6.53994 3.81049 5.61462 3 4.5 3H2.25Z"
      />
    </svg>
  );
}

export default SvgSummaryView;
