import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import Grow from '@mui/material/Grow';
import makeStyles from '@mui/styles/makeStyles';
import { headCells } from './constant';
import { getRowsData } from './helper';
import { exportCSVData, getFieldValue } from '../../../utils/export';
import { styles } from './styles';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_THEMES } from './graphql';
import { openDrawer } from '../../../reactiveVariables';
import EntityTable from '../../../components/Common/EntityTable';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles(styles);

const ProductTheme = (props, ref) => {
  const { searchQuery } = props;
  const classes = useStyles();
  const theme = useTheme();
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { productThemes = [] } = {} } = useQuery(GET_PRODUCT_THEMES);

  useImperativeHandle(ref, () => ({
    export: () => exportProductThemes(),
  }));

  const exportProductThemes = useCallback(() => {
    const data = productThemes.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'ProductThemes');
  }, [productThemes]);

  const onRowClick = (event, row) => {
    const productTheme = productThemes.find((productTheme) => productTheme.id === row.id);
    openDrawer(productTheme, 'productTheme');
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          headCells={headCells}
          rows={getRowsData(productThemes, theme)}
          searchQuery={searchQueryLowerCase}
          expand={false}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(ProductTheme);
