export const styles = (theme) => ({
  section: {
    width: '100%',
    height: 'calc(100% - 50px)',
    overflowY: 'auto',
    paddingTop: 20,
    flexWrap: 'wrap',
    backgroundColor: (props) => (props.isDefault ? theme.palette.background.default : theme.palette.color.background),
  },
});
