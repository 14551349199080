import {
  ActionIcon,
  Divider,
  Group,
  Menu,
  Paper,
  Popover,
  rem,
  rgba,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import SvgAdd from '../../../../../assets/images/Add';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_FEATURES, GET_FEATURES_FOR_VS } from '../../../../Product/Features/graphql';
import { GET_ALL_INITIATIVES, GET_INITIATIVES } from '../../../../ValueStream/Initiatives/graphql';
import SvgHorizontalMore from '../../../../../assets/images/HorizontalMoreAction';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import { difference, sortBy } from 'lodash';
import { DatePickerInput } from '@mantine/dates';
import SearchIcon from '@mui/icons-material/Search';
import SvgHexagone from '../../../../../assets/images/Hexagon';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from './KeyResultDetails.module.css';
import { getFeatureBackgroundColor, getStatusColorMantine } from '../../../../../utils/piColors';

const FeatureSelector = ({ elements, keyResult, setValue }) => {
  const icon = <SearchIcon style={{ width: rem(24), height: rem(24) }} />;
  const theme = useMantineTheme();
  const [search, setSearch] = useState('');

  const data = search
    ? elements.filter((row) =>
        Object.values(row).some((fieldValue) => fieldValue?.toString().toLowerCase().includes(search.toLowerCase())),
      )
    : elements;

  const updateElement = (element) => {
    if (element.__typename === 'features') {
      setValue('features', [...keyResult.features, element.id], { shouldDirty: true });
    } else {
      setValue('initiatives', [...keyResult.initiatives, element.id], { shouldDirty: true });
    }
  };

  return (
    <Popover width={300} trapFocus zIndex={5000} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon variant="transparent" color="primary" aria-label="Settings">
          <SvgAdd stroke={theme.other.colours.secondary} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown bg="var(--mantine-color-body)" w={500} p={12} style={{ borderRadius: 12 }}>
        <TextInput
          variant="unstyled"
          leftSectionPointerEvents="none"
          leftSection={icon}
          placeholder="Find an feature or initiative..."
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
        <Divider ml={-12} mr={-12} mt={8} />
        <ScrollArea h={500}>
          {data.map((element) => {
            const color = element.__typename === 'features' ? theme.other.colours.done : theme.other.colours.green;
            return (
              <Group
                p={8}
                onClick={() => updateElement(element)}
                preventGrowOverflow
                wrap="nowrap"
                mt={8}
                className={classes.card}>
                <ThemeIcon variant="outline" style={{ backgroundColor: rgba(color, 0.15) }} color={color}>
                  <SvgHexagone />
                </ThemeIcon>
                <Stack gap={0}>
                  <Text lineClamp={1}>{element.name}</Text>
                  <Text lineClamp={1} c="dimmed">
                    {element.team?.name}
                  </Text>
                </Stack>
              </Group>
            );
          })}
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  );
};

const Output = ({ objective, keyResult, setValue }) => {
  const theme = useMantineTheme();

  const { data: { initiatives = [] } = {} } = useQuery(objective.valueStream ? GET_INITIATIVES : GET_ALL_INITIATIVES, {
    variables: { valueStream: objective.valueStream },
  });
  const { data: { features = [] } = {} } = useQuery(objective.valueStream ? GET_FEATURES_FOR_VS : GET_FEATURES, {
    variables: { valueStream: objective.valueStream },
  });

  const keyResultFeatures = features.filter((feature) => keyResult.features.includes(feature.id));
  const keyResultInitiatives = initiatives.filter((initiative) => keyResult.initiatives.includes(initiative.id));

  const elements = sortBy([...keyResultFeatures, ...keyResultInitiatives], 'name');
  const allElements = sortBy(
    [...difference(features, keyResultFeatures), ...difference(initiatives, keyResultInitiatives)],
    'name',
  );

  const removeElement = (element) => {
    if (element.__typename === 'features') {
      setValue(
        'features',
        keyResult.features.filter((f) => f !== element.id),
        { shouldDirty: true },
      );
    } else {
      setValue(
        'initiatives',
        keyResult.initiatives.filter((i) => i !== element.id),
        { shouldDirty: true },
      );
    }
  };

  return (
    <>
      <Group gap={1}>
        <Title order={5}>Output</Title>
        <FeatureSelector elements={allElements} keyResult={keyResult} setValue={setValue} />
      </Group>
      <Text mb={12} c="dimmed">
        You can sync more initiatives and features if required
      </Text>
      <ScrollArea h="60vh">
        {elements.map((element) => (
          <Paper
            withBorder
            p={12}
            radius={8}
            mt={8}
            key={element.id}
            style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
            <Group preventGrowOverflow justify="space-between" wrap="nowrap">
              <Group preventGrowOverflow wrap="nowrap">
                <Text c="dimmed">#{element.id}</Text>
                <Text>{element.name}</Text>
              </Group>
              <Group preventGrowOverflow wrap="nowrap">
                <Display2
                  variant="light"
                  bgColor={element.__typename === 'features' ? theme.other.colours.done : theme.other.colours.green}>
                  {element.__typename === 'features' ? 'feature' : 'initiative'}
                </Display2>
                <Display2 variant="light" bgColor={getStatusColorMantine(element, theme)}>
                  {Math.round(element.percentageDone || element.features?.aggregate?.avg.percentageDone || 0)}%
                </Display2>
                <Menu>
                  <Menu.Target>
                    <ActionIcon variant="transparent" color="primary" aria-label="Settings">
                      <SvgHorizontalMore stroke={theme.other.colours.secondary} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown zIndez={10000} style={{ zIndex: 100000 }}>
                    <Menu.Item
                      onClick={() => removeElement(element)}
                      leftSection={<DeleteIcon style={{ width: rem(14), height: rem(14) }} />}>
                      Remove
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Group>
          </Paper>
        ))}
      </ScrollArea>
    </>
  );
};

export default Output;
