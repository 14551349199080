import { gql } from '@apollo/client';

export const DEPENDENCY_FIELDS = gql`
  fragment DependencyFields on dependencies {
    description
    id
    issuesNeeded
    name
    owner
    neededBySprint
    errors
    programIncrement
    ragStatus
    status
    programmeId
    teamId
    targetFeatures {
      id: featureId
      feature {
        name
        id
        teamId
        status
        ragStatus
      }
    }
    updatedAt
    createdAt
    createdBy
  }
`;

export const INSERT_DEPENDENCY = gql`
  ${DEPENDENCY_FIELDS}
  mutation InsertDependency($dependency: [dependencies_insert_input!] = {}) {
    dependencie: insert_dependencies(objects: $dependency) {
      returning {
        ...DependencyFields
      }
    }
  }
`;

export const UPDATE_DEPENDENCY = gql`
  ${DEPENDENCY_FIELDS}
  mutation UpdateDependency(
    $dependencyId: Int!
    $dependency: dependencies_set_input = {}
    $targetFeatures: [features_dependencies_insert_input!] = []
  ) {
    delete_features_dependencies(where: { dependencyId: { _eq: $dependencyId } }) {
      returning {
        featureId
        dependencyId
      }
    }
    insert_features_dependencies(objects: $targetFeatures) {
      returning {
        featureId
        dependencyId
      }
    }
    update_dependencies_by_pk(pk_columns: { id: $dependencyId }, _set: $dependency) {
      ...DependencyFields
    }
  }
`;

export const DELETE_DEPENDENCY = gql`
  mutation DeleteDependency($id: Int!) {
    dependency: delete_dependencies_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_DEPENDENCY_FIELDS = gql`
  ${DEPENDENCY_FIELDS}
  mutation UpdateFeature($dependencyId: Int!, $dependency: dependencies_set_input = {}) {
    dependency: update_dependencies_by_pk(pk_columns: { id: $dependencyId }, _set: $dependency) {
      ...DependencyFields
    }
  }
`;

export const GET_DEPENDENCIES = gql`
  ${DEPENDENCY_FIELDS}
  query GetDependencies {
    dependencies: dependencies(order_by: { id: asc }) {
      ...DependencyFields
    }
  }
`;

export const GET_DEPENDENCIES_FOR_PI = gql`
  ${DEPENDENCY_FIELDS}
  query GetDependenciesForPi($pi: Int) {
    selectedPI @client @export(as: "pi")
    dependencies: dependencies(where: { programIncrement: { _eq: $pi } }) {
      ...DependencyFields
    }
  }
`;

export const GET_DEPENDENCIES_FOR_PROGRAMME = gql`
  ${DEPENDENCY_FIELDS}
  query GetDependenciesForPi($programme: Int) {
    selectedProgramme @client @export(as: "programme")
    dependencies: dependencies(where: { programmeId: { _eq: $programme } }) {
      ...DependencyFields
    }
  }
`;

export const SUBSCRIBE_DEPENDENCIES = gql`
  ${DEPENDENCY_FIELDS}
  subscription SubscribeToDependencie {
    dependencies: dependencies(order_by: { id: asc }) {
      ...DependencyFields
    }
  }
`;

export const SEARCH_STORIES = gql`
  query SearchStories($connectorId: String!, $query: String!) {
    stories(connectorId: $connectorId, query: $query)
      @rest(type: "Story", path: "/api/jira/{args.connectorId}/issues/search/{args.query}") {
      id: key
      summary
      summaryText
    }
  }
`;
