export const colors = {
  originalStoryCount: '#00afff',
  additionalStories: '#ff7066',
  done: '#00d153',
};
export const storyPointsTooltipLabel = {
  originalStoryCount: 'Original Story Points',
  additionalStories: 'Additional Story Points',
  done: 'Complete (Total Delivered)',
};

export const storiesTooltipLabel = {
  originalStoryCount: 'Original Story Count',
  additionalStories: 'Additional Story Count',
  done: 'Complete (Total Delivered)',
};

export const storyPointsReportKeys = ['done', 'originalStoryCount', 'additionalStories'];
