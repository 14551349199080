import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AppBar, Button, Chip, Grid, Tab, Tabs } from '@mui/material';
import Sprints from 'components/ProgramIncrements/Sprints';
import TeamSelector from 'components/ProgramIncrements/TeamSelector';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { BUTTONS } from 'utils/formConstants';
import { keyBy, mapValues, omitBy, some } from 'lodash';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import { GET_CONNECTORS } from '../../../containers/Settings/GraphQL/connectors';
import {
  ACTIVATE_PI,
  buildUpdatePI,
  DELETE_PROGRAM_INCREMENT,
  GET_PROGRAM_INCREMENTS,
  INSERT_PROGRAM_INCREMENT,
} from '../../../containers/Settings/ProgramIncrement/graphql';
import { removeIdField } from '../../../utils/helpers';
import { useTheme } from '@mui/styles';
import { Field, FormContext, renderTextField } from '../../Common/FormFieldsHooks';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import Select from '../../Common/Select';
import { PI_STATUSES } from '../../../containers/Settings/ProgramIncrement/constants';
import TextInput from '../../Common/TextInput';
import { piStatusColor } from '../../../utils/piColors';
import { resetDrawerDetails, selectedProgrammeVar } from '../../../reactiveVariables';
import { showNotification } from '@mantine/notifications';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  name: {
    '& label': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
        display: 'none',
      },
      '& input': {
        padding: '5px 5px 5px 0px',
      },
      '&:hover fieldset': {
        borderColor: 'black',
        display: 'inline-flex',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        display: 'inline-flex',
      },
    },
  },
}));

const ProgramIncrementsDetails = (props, ref) => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);

  const { id, name, defaultConnectorId, defaultProjectKey, status, sprints = [] } = props.element || {};
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const programmeTeams = teams.filter((team) => team.programmes.includes(selectedProgramme));

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      id,
      name,
      defaultConnectorId: defaultConnectorId || connectors?.[0]?.id,
      defaultProjectKey,
      status: status || 'planning',
      sprints,
    },
  });

  const FONT_SIZE = 22;
  const DEFAULT_INPUT_WIDTH = 200;

  const [inputWidth, setInputWidth] = useState(
    name?.length * FONT_SIZE > DEFAULT_INPUT_WIDTH ? (name?.length + 1) * FONT_SIZE : DEFAULT_INPUT_WIDTH,
  );

  const theme = useTheme();
  const classes = useStyles();
  const pi = watch();

  const getSprintsToSave = () => {
    const existingSprintIds = sprints.map((sprint) => sprint.id) || [];
    // eslint-disable-next-line no-unused-vars
    const addedSprints = (pi.sprints || [])
      .filter((sprint) => !sprint.id)
      .map((sprint) => ({ ...sprint, programIncrement: pi.id }));
    const updatedSprints = (pi.sprints || []).filter((sprint) => existingSprintIds.includes(sprint.id));
    const deleteSprintIds = existingSprintIds.filter((id) => !some(pi.sprints || [], { id: id }));

    return { addedSprints, updatedSprints, deleteSprintIds };
  };

  const { updatedSprints } = getSprintsToSave();

  const [insertProgramIncrement] = useMutation(INSERT_PROGRAM_INCREMENT, {
    refetchQueries: [GET_PROGRAM_INCREMENTS, 'GetProgramIncrements'],
  });
  const [updateProgramIncrement] = useMutation(buildUpdatePI(updatedSprints, pi.id));
  const [deleteProgramIncrement] = useMutation(DELETE_PROGRAM_INCREMENT);
  const [activatePi] = useMutation(ACTIVATE_PI);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (pi?.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const changeTab = (event, value) => {
    setTabValue(value);
  };

  const onSubmit = (values) => {
    const piToSave = omitBy(pi, (property, key) => property === '' || key === 'id' || key === 'sprints');
    const { addedSprints, updatedSprints, deleteSprintIds } = getSprintsToSave();
    const sprintsById = keyBy(updatedSprints, (sprint) => `sprint${sprint.id}`);
    const sprintsToSave = mapValues(sprintsById, removeIdField);

    const variable = {
      variables: {
        ...sprintsToSave,
        id: pi.id,
        programIncrement: { ...piToSave },
        addedSprints: addedSprints,
        deletedSprintIds: deleteSprintIds,
      },
    };

    const piPromise = pi.id
      ? updateProgramIncrement(variable).then(() =>
          showNotification({
            title: 'Increment Updated',
            message: `Increment ${values.name} was successfully updated`,
          }),
        )
      : insertProgramIncrement({
          variables: {
            programIncrement: { ...piToSave, sprints: { data: addedSprints }, programme: selectedProgramme },
          },
        }).then(() =>
          showNotification({
            title: 'Increment Created',
            message: `Increment was successfully created`,
          }),
        );

    piPromise.then(() => {
      if ((!status || status === 'planning') && values.status === 'active') {
        activatePi({ variables: { pi: pi.id, input: { pi: pi.id } } });
        showNotification({
          title: 'Increment Activated',
          message: `Increment ${values.name} was successfully activated`,
        });
      }
      resetDrawerDetails();
    });
  };

  const handleDeleteConfirm = () => {
    deleteProgramIncrement({
      variables: { id: pi.id },
      refetchQueries: [GET_PROGRAM_INCREMENTS, 'GetProgramIncrements'],
    }).then(() => resetDrawerDetails());
  };

  const handleCancel = () => setOpenConfirmation(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContext.Provider value={{ control, errors }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          padding="30px"
          sx={{ paddingTop: '10px', paddingBottom: '10px', width: '100%', height: '100%' }}>
          <Field
            renderField={renderTextField}
            placeholder="Add Increment Name"
            sx={{ paddingTop: '10px', paddingLeft: 0 }}
            InputProps={{
              style: {
                paddingLeft: '8px',
                maxWidth: '100%',
                width: `${inputWidth}px`,
                fontSize: 22,
                fontWeight: 600,
              },
            }}
            className={classes.name}
            name="name"
            size={12}
          />
          <Grid item xs={12} style={{ paddingLeft: 0, paddingTop: 0 }}>
            <Select
              title="Status"
              border={false}
              onChange={(event, value) => setValue('status', value)}
              defaultValue={pi.status}
              placeholder="Choose a default connector"
              options={PI_STATUSES}
              hasDot={true}
              dense={false}
              spacing="150px"
              backgroundColor={piStatusColor(pi?.status, theme)}
              align="right"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 0, paddingTop: 0 }}>
            <Select
              title="Connector"
              border={false}
              defaultValue={pi.defaultConnectorId}
              onChange={(event, value) => setValue('defaultConnectorId', value)}
              placeholder="Choose a default connector"
              spacing="150px"
              dense={false}
              options={connectors.map((connector) => ({
                value: connector.id,
                label: connector.name,
              }))}
              align="right"
            />
          </Grid>
          <TextInput
            title="Default Project ID"
            value={pi?.defaultProjectKey}
            onChange={(event) => setValue('defaultProjectKey', event.target.value)}
          />
        </Grid>
        <AppBar elevation={0} position="static" style={{ marginTop: '10px' }}>
          <Tabs
            value={tabValue}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: theme.palette.color.paper }}>
            <Tab
              label="Sprints"
              icon={
                <Chip
                  label={pi.sprints?.length || 0}
                  sx={{
                    backgroundColor: '#D3D3D5',
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'background.default',
                    borderRadius: '3px',
                  }}
                />
              }
              iconPosition="end"
            />
            <Tab
              label="Teams"
              icon={
                <Chip
                  label={programmeTeams.length}
                  sx={{
                    backgroundColor: '#D3D3D5',
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'background.default',
                    borderRadius: '3px',
                  }}
                />
              }
              iconPosition="end"
            />
          </Tabs>
        </AppBar>
        {tabValue === 0 && <Sprints control={control} watch={watch} piName={pi.name} />}
        {tabValue === 1 && (
          <TeamSelector pi={pi} teams={programmeTeams} sprints={pi.sprints || []} setValue={setValue} />
        )}
        <ConfirmationDialog
          open={openConfirmation}
          title={`Delete Increment:`}
          text="Are you sure you want to delete this Increment ? This will not delete the elements associated to it"
          handleOk={handleDeleteConfirm}
          handleCancel={handleCancel}
        />
        <ConfirmationDialog
          open={openWarning}
          title="Unsaved changes"
          text="You have unsaved changes. What would you like to do?">
          <Button onClick={onSubmit} color="primary">
            {BUTTONS.SAVE}
          </Button>
          <Button onClick={() => resetDrawerDetails()} color="primary">
            {BUTTONS.DISCARD}
          </Button>
          <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
            {BUTTONS.CANCEL}
          </Button>
        </ConfirmationDialog>
      </FormContext.Provider>
    </form>
  );
};

export default forwardRef(ProgramIncrementsDetails);
