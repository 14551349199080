import { gql } from '@apollo/client';
import { TEAM_FIELDS } from './teams';

export const CONNECTOR_FIELDS = gql`
  ${TEAM_FIELDS}
  fragment ConnectorFields on connectors {
    activeStatus
    apiUrl
    epicNameField
    name
    id
    storyPointsField
    username
    type
    project
    issueTypesMapping
    issueStatesMapping
    authenticationType
    debtsIssueTypes
    featuresIssueTypes
    defectsIssueTypes
    risksIssueTypes
    teams {
      ...TeamFields
    }
  }
`;

export const INSERT_CONNECTOR = gql`
  ${CONNECTOR_FIELDS}
  mutation InsertConnector($connector: connectors_insert_input! = {}) {
    connector: insert_connectors_one(object: $connector) {
      ...ConnectorFields
    }
  }
`;

export const UPDATE_CONNECTOR = gql`
  ${CONNECTOR_FIELDS}
  mutation UpdateConnector($connectorId: Int!, $connector: connectors_set_input = {}) {
    update_connectors_by_pk(pk_columns: { id: $connectorId }, _set: $connector) {
      ...ConnectorFields
    }
  }
`;

export const DELETE_CONNECTOR = gql`
  mutation DeleteConnector($id: Int!) {
    connector: delete_connectors_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_CONNECTORS = gql`
  ${CONNECTOR_FIELDS}
  query GetConnectors {
    connectors: connectors {
      ...ConnectorFields
    }
  }
`;

export const GET_JIRA_BOARDS = gql`
  query GetJiraBoards($connectorId: String!) {
    boards(connectorId: $connectorId) @rest(type: "Board", path: "/api/jira/{args.connectorId}/boards") {
      id
      name
      location
      type
    }
  }
`;

export const GET_AZURE_BOARDS = gql`
  query GetAzureBoards($connectorId: String!) {
    boards(connectorId: $connectorId) @rest(type: "Board", path: "/api/azure/{args.connectorId}/teams") {
      id
      name
      projectId
      projectName
      type
    }
  }
`;

export const GET_JIRA_ISSUE_TYPES = gql`
  query GetJiraBoards($connectorId: String!) {
    issueTypes(connectorId: $connectorId) @rest(type: "Board", path: "/api/jira/{args.connectorId}/issueTypes") {
      id
      name
      hierarchyLevel
      scope {
        type
        project {
          id
        }
      }
    }
  }
`;
