import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
} from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Field,
  FormContext,
  renderSearchSelectField,
  renderSelectField,
  renderTextField,
} from '../../Common/FormFieldsHooks';
import { BUTTONS } from '../../../utils/formConstants';
import { useMutation, useQuery } from '@apollo/client';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { GET_AZURE_BOARDS, GET_CONNECTORS, GET_JIRA_BOARDS } from '../../../containers/Settings/GraphQL/connectors';
import { DELETE_TEAM, GET_TEAMS, INSERT_TEAM, UPDATE_TEAM } from '../../../containers/Settings/GraphQL/teams';
import { GET_PROGRAMMES } from '../../../containers/Settings/GraphQL/programmes';
import { mapValues, omitBy, sortBy } from 'lodash';

const TeamDetails = (props, ref) => {
  const [insertTeam] = useMutation(INSERT_TEAM);
  const [updateTeam] = useMutation(UPDATE_TEAM);
  const [deleteTeam] = useMutation(DELETE_TEAM);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [connector, setConnector] = useState(props.element?.connector || {});

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      programmes: [],
      ...props.element,
    },
  });

  const team = watch();

  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { data: { boards = [] } = {} } = useQuery(
    connector.type === 'azureDevops' ? GET_AZURE_BOARDS : GET_JIRA_BOARDS,
    {
      variables: { connectorId: connector.id },
    },
  );

  const board = useMemo(() => boards.find((board) => String(board.id) === String(team.board)), [boards, team.board]);

  useEffect(() => {
    if (props.element) {
      register('programmes', { value: props.element.programmes });
      register('id', { value: props.element.id });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteTeam({
      variables: { id: team.id },
      refetchQueries: [GET_TEAMS, 'GetTeams'],
    }).then(() => resetDrawerDetails());
  };

  const onConnectorChange = (id) => {
    const connector = connectors.find((connector) => connector.id === id);
    setConnector(connector);
    setValue('connectorId', id);
  };

  const onProgrammeChange = (event, programme) => {
    if (event.target.checked) {
      setValue('programmes', [...team.programmes, programme.id]);
    } else {
      setValue(
        'programmes',
        team.programmes?.filter((p) => p !== programme.id),
      );
    }
  };

  const onSubmit = (values) => {
    const teamToSave = mapValues(
      omitBy(values, (property, key) => key === 'id' || key === 'connector'),
      (property) => (property === '' ? null : property),
    );
    const projectId = teamToSave.projectId ?? board?.location?.projectId ?? board?.projectId;

    props.element?.id
      ? updateTeam({
          variables: {
            team: {
              ...teamToSave,
              projectId: projectId && String(projectId),
              boardName: board?.name || null,
              type: board?.type,
            },
            teamId: values.id,
          },
        }).then(() => resetDrawerDetails())
      : insertTeam({
          variables: {
            team: {
              ...teamToSave,
              projectId: projectId && String(projectId),
              boardName: board?.name || null,
              type: board?.type,
            },
          },
          refetchQueries: [GET_TEAMS, 'GetTeams'],
        }).then(() => resetDrawerDetails());
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field
              name="connectorId"
              renderField={renderSelectField}
              label="Connector"
              size={12}
              handleChange={(e) => onConnectorChange(e.target.value)}>
              {connectors.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            {team.connectorId && (
              <Field
                name="board"
                renderField={renderSearchSelectField}
                label="Board"
                size={12}
                options={sortBy(boards, ['projectName', 'name']).map((element) => ({
                  value: String(element.id),
                  label: element.projectName ? `${element.projectName} - ${element.name}` : element.name,
                }))}
              />
            )}
            {team.board && <Field name="projectId" renderField={renderTextField} label="Project Key" size={12} />}
            {board?.type === 'kanban' && (
              <>
                <Field name="throughput" renderField={renderTextField} type="number" label="Throughput" size={12} />
                <Field name="throughputType" renderField={renderSelectField} label="Throughput Type" size={12}>
                  <MenuItem key="storyPoint" value="storyPoint">
                    Story Points
                  </MenuItem>
                  <MenuItem key="storyCount" value="storyCount">
                    Story Count
                  </MenuItem>
                </Field>
              </>
            )}
            <FormControl sx={{ margin: '16px 0 16px 8px' }} component="fieldset" variant="standard">
              <FormLabel component="legend">Programmes</FormLabel>
              <FormGroup>
                {programmes.map((programme) => (
                  <FormControlLabel
                    key={programme.id}
                    control={
                      <Checkbox
                        checked={team.programmes?.includes(programme.id)}
                        onChange={(event) => onProgrammeChange(event, programme)}
                        name={programme.name}
                      />
                    }
                    label={programme.name}
                  />
                ))}
              </FormGroup>
              <FormHelperText>You can select multiple programmes across value streams</FormHelperText>
            </FormControl>
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete team: ${team.name}`}
        text="Are you sure you want to delete this team ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(TeamDetails);
