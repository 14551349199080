import * as React from 'react';

function SvgOKRs(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25169 0C4.42984 0 3.7636 0.671573 3.7636 1.5V3.21429H1.42517C1.19035 3.21429 1 3.40616 1 3.64286V9.21429C1 9.45098 1.19035 9.64286 1.42517 9.64286H3.7636V16.5C3.7636 17.3284 4.42984 18 5.25169 18H6.95236C7.77421 18 8.44045 17.3284 8.44045 16.5V9.64286H13.8107C14.0464 9.64286 14.2715 9.54425 14.4324 9.37056L16.8857 6.72099C17.0381 6.55633 17.0381 6.30081 16.8857 6.13615L14.4324 3.48658C14.2715 3.3129 14.0464 3.21429 13.8107 3.21429H8.44045V1.5C8.44045 0.671573 7.77421 0 6.95236 0H5.25169ZM7.16495 3.21429V1.5C7.16495 1.38165 7.06977 1.28571 6.95236 1.28571H5.25169C5.13428 1.28571 5.0391 1.38165 5.0391 1.5V3.21429H7.16495ZM5.0391 9.64286V16.5C5.0391 16.6183 5.13428 16.7143 5.25169 16.7143H6.95236C7.06977 16.7143 7.16495 16.6183 7.16495 16.5V9.64286H5.0391Z"
      />
    </svg>
  );
}

export default SvgOKRs;
