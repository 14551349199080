import * as React from 'react';

function CloseCollapse(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#DEDDDF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.6 4.533a.5.5 0 0 1 .8 0L11.9 9.2a.5.5 0 0 1-.4.8h-7a.5.5 0 0 1-.4-.8l3.5-4.667z"
        fill="#3D4756"
        {...props}
      />
    </svg>
  );
}

export default CloseCollapse;
