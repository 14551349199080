import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/system';
import ClosePlanner from '../../../assets/images/ClosePlanner';
import { useQuery } from '@apollo/client';
import { GET_FEATURES_FOR_PI } from '../../Product/Features/graphql';
import Typography from '@mui/material/Typography';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import DropDownIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { GET_SELECTED_PI_FULL } from '../../Settings/ProgramIncrement/graphql';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { DateTime } from 'luxon';
import { AddStoriesDialog } from './components/AddStoriesDialog';
import Select from '../../../components/Common/Select';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { TransitionGroup } from 'react-transition-group';
import { GET_STORIES_FOR_TEAM, SUBSCRIBE_STORIES_FOR_TEAM } from '../graphql';
import { isEmpty } from 'lodash';
import { subscribeTo } from '../../../apollo';
import { DndContext, DragOverlay, useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import SortableItem from './components/SortableItem';
import { StyledPaper } from '../../ValueStream/components/Common';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.color.card,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<DropDownIcon />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.color.card,
    padding: 0,
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  backgroundColor: theme.palette.color.card,
}));

export const Header = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
  padding: '14px',
  height: '52px',
}));

export const Body = styled('div')(({ theme }) => ({
  height: '100%',
  paddingTop: '4px',
  paddingBottom: '4px',
}));

export const BoardColumn = styled((props) => (
  <Grid item container xs={2} flexDirection="column" textAlign="center" {...props} />
))(({ theme }) => ({
  height: '100%',
  minWidth: '300px',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: '0px 5px 5px 5px',
  '&:not(:last-child)': {
    borderRight: '2px dashed #63636B',
  },
}));

const DashboardCard = ({
  title,
  cardStyle,
  gridStyle,
  contentStyle,
  onScroll,
  headerStyle,
  children,
  bodyRef,
  action,
  xs = 6,
}) => {
  return (
    <Grid item xs={xs} sx={gridStyle}>
      <StyledPaper sx={{ height: '60vh', maxHeight: '400px', display: 'flex', flexDirection: 'column', ...cardStyle }}>
        <Header
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            backgroundColor: 'color.darkPaper',
            borderBottom: `1px solid color.borderPaper`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            ...headerStyle,
          }}>
          <span style={{ fontWeight: 600, fontSize: '16px' }}>{title}</span>
          {action}
        </Header>
        <Body ref={bodyRef} onScroll={onScroll} sx={contentStyle}>
          {children}
        </Body>
      </StyledPaper>
    </Grid>
  );
};

const FeatureCard = ({ feature, stories, dependencies, addStoryForFeature, ...rest }) => {
  return (
    <div style={{ padding: '4px 8px 4px 8px', width: '100%' }} {...rest}>
      <Paper elevation={0} sx={{ width: '100%', padding: '8px', backgroundColor: 'color.card' }}>
        <span style={{ fontWeight: 500, fontSize: '12px' }}>{feature.name}</span>
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontWeight: 'bold' }}>Add to feature...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Body style={{ height: '100%' }}>
              <Paper variant="outlined" sx={{ backgroundColor: 'color.card', marginBottom: '5px' }}>
                <IconButton aria-label="close" onClick={() => addStoryForFeature(feature)}>
                  <AddIcon />
                </IconButton>
                Add Story ({stories.length})
              </Paper>
              <Paper variant="outlined" sx={{ backgroundColor: 'color.card' }}>
                <IconButton aria-label="close" onClick={() => addStoryForFeature(feature)}>
                  <AddIcon />
                </IconButton>
                Add Dependency (0)
              </Paper>
            </Body>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
};

const StoryCard = ({ story, ...rest }) => {
  return (
    <div style={{ padding: '4px', width: '100%' }} {...rest}>
      <Paper elevation={0} sx={{ width: '100%', padding: '8px', backgroundColor: 'color.card' }}>
        <span style={{ fontWeight: 500, fontSize: '12px' }}>{story.name}</span>
      </Paper>
    </div>
  );
};

const SprintColumn = ({ sprint, selectedPiFull, stories }) => {
  const { setNodeRef } = useDroppable({ id: sprint.id });
  const startDate = DateTime.fromISO(sprint.startDate).toFormat('dd/LL');
  const endDate = DateTime.fromISO(sprint.endDate).toFormat('dd/LL');
  const sprintStories = stories.filter((story) => story.sprintId === sprint.id);

  return (
    <SortableContext items={sprintStories}>
      <BoardColumn key={sprint.id} xs={12 / ((selectedPiFull.sprints?.length || 0) + 1)}>
        <Paper
          elevation={0}
          sx={{ flexShrink: 0, padding: '8px', marginBottom: '5px', backgroundColor: 'color.darkPaper' }}>
          <Typography textAlign="left">
            {sprint.name?.includes('Sprint') ? sprint.name : `Sprint ${sprint.name}`}
          </Typography>
          <Typography textAlign="left">
            {startDate} - {endDate}
          </Typography>
        </Paper>
        <div ref={setNodeRef} style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}>
          <TransitionGroup>
            {sprintStories.map((story) => (
              <Collapse key={story.id}>
                <SortableItem element={story} data={{ story, sprint }}>
                  <StoryCard key={story.id} story={story} />
                </SortableItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </div>
      </BoardColumn>
    </SortableContext>
  );
};

export const TeamBoard = ({}) => {
  const [open, setOpen] = useState(true);
  const [addStoriesFeature, setAddStoriesFeature] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const swimLaneRef = useRef();
  const teamBoardRef = useRef();

  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const [selectedTeam, setSelectedTeam] = useState(teams[0]);

  const { data: { stories = [] } = {}, subscribeToMore } = useQuery(GET_STORIES_FOR_TEAM, {
    variables: { teamId: selectedTeam?.id || 0 },
  });

  const onTopScroll = (e) => {
    swimLaneRef.current.scrollLeft = e.target.scrollLeft;
  };

  const handleDragStart = (event) => setActiveId(event.active?.data?.current);
  const handleDragEnd = (event) => setActiveId(null);

  useEffect(() => {
    subscribeTo(subscribeToMore, SUBSCRIBE_STORIES_FOR_TEAM, 'stories', { teamId: selectedTeam?.id || 0 });
  }, [selectedTeam]);

  if (!selectedTeam && !isEmpty(teams)) {
    setSelectedTeam(teams[0]);
  }

  const teamFeatures = features.filter((feature) => feature.teamId === selectedTeam?.id);

  return (
    <Grid container alignItems="center" rowSpacing={3}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item xs>
          <Chip label={selectedTeam?.name?.charAt(0) || 'BA'} sx={{ borderRadius: '3px', marginRight: '10px' }} />
          <Select
            key={teams.length}
            title="Team"
            rootStyle={{ width: 'auto' }}
            defaultValue={selectedTeam}
            onChange={(event, value) => setSelectedTeam(value)}
            options={teams.map((team) => ({ value: team, label: team.name }))}
            placeholder="Select Teams"
          />
        </Grid>
        <Grid item xs="auto">
          <Stack direction="row" spacing={2}>
            <AvatarGroup max={3}>
              {[1, 2, 3, 4].map((index) => (
                <StyledBadge
                  key={index}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot">
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </StyledBadge>
              ))}
            </AvatarGroup>
          </Stack>
        </Grid>
        <Grid item xs="auto" sx={{ alignItems: 'center' }} marginLeft="10px">
          <Button disableElevation variant="contained">
            SHARE
          </Button>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" xs={12} spacing={1}>
        <DashboardCard
          title="Features"
          tabs={
            <Tabs>
              <Tab label="My Team" />
              <Tab label="All Features" />
            </Tabs>
          }
          action={
            <IconButton aria-label="close" onClick={() => setOpen(!open)}>
              <ClosePlanner />
            </IconButton>
          }
          headerStyle={{
            paddingRight: 0,
            paddingLeft: open ? '16px' : 0,
            justifyContent: open ? 'space-between' : 'flex-end',
            '& span': {
              width: open ? undefined : 0,
              display: open ? 'block' : 'none',
              overflow: 'hidden',
              transition: 'width 300ms',
            },
          }}
          cardStyle={{
            width: open ? '20vw' : '40px',
            maxHeight: null,
            height: 'calc(75vh + 4px)',
            transition: 'width 300ms',
          }}
          contentStyle={{ overflowX: 'auto', height: 'calc(100% - 52px)' }}
          xs="auto">
          {open && (
            <Flipper flipKey={teamFeatures.map((feature) => feature.id).join('')}>
              <TransitionGroup>
                {teamFeatures.map((feature) => (
                  <Collapse key={feature.id}>
                    <Flipped key={feature.id} flipId={feature.id}>
                      <FeatureCard
                        key={feature.id}
                        feature={feature}
                        stories={stories.filter((story) => story.parentKey === feature.epicId)}
                        addStoryForFeature={(feature) => setAddStoriesFeature(feature)}
                      />
                    </Flipped>
                  </Collapse>
                ))}
              </TransitionGroup>
            </Flipper>
          )}
        </DashboardCard>
        <Grid container item xs={true} width="200px" spacing={1} padding={0}>
          <DashboardCard
            title="Team Board"
            contentStyle={{ overflow: 'auto' }}
            onScroll={onTopScroll}
            bodyRef={teamBoardRef}
            cardStyle={{ width: '100%', maxHeight: null, height: 'calc(55vh - 2px)' }}
            xs={12}>
            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Grid container sx={{ height: '100%', minWidth: `${(selectedPiFull?.sprints?.length + 1) * 300}px` }}>
                <BoardColumn xs={12 / ((selectedPiFull?.sprints?.length || 0) + 1)}>
                  <Paper
                    elevation={0}
                    sx={{ flexShrink: 0, marginBottom: '5px', padding: '8px', backgroundColor: 'color.darkPaper' }}>
                    <Typography textAlign="left">Parking Lot</Typography>
                    <Typography textAlign="left">
                      Stories with unassigned sprint
                      <SvgIcon component={InfoRoundedIcon} sx={{ marginLeft: '5px', fontSize: '16px' }} />
                    </Typography>
                  </Paper>
                  <div style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}>
                    <TransitionGroup>
                      {stories
                        .filter((story) => !story.sprintId)
                        .map((story) => (
                          <Collapse key={story.id}>
                            <StoryCard key={story.id} story={story} />
                          </Collapse>
                        ))}
                    </TransitionGroup>
                  </div>
                </BoardColumn>
                {selectedPiFull?.sprints?.map((sprint) => (
                  <SprintColumn key={sprint.id} sprint={sprint} selectedPiFull={selectedPiFull} stories={stories} />
                ))}
              </Grid>
              <DragOverlay>{activeId ? <StoryCard story={activeId.story} /> : null}</DragOverlay>
            </DndContext>
          </DashboardCard>
          <DashboardCard
            title="Features Swim Lane"
            contentStyle={{ overflow: 'auto' }}
            bodyRef={swimLaneRef}
            cardStyle={{ width: '100%', maxHeight: null, height: 'calc(20vh - 2px)' }}
            xs={12}>
            <Grid container sx={{ height: '100%', minWidth: `${(selectedPiFull?.sprints?.length + 1) * 300}px` }}>
              <BoardColumn xs={12 / ((selectedPiFull?.sprints?.length || 0) + 1)}>
                <Paper elevation={0} sx={{ flexShrink: 0, padding: '8px', backgroundColor: 'color.darkPaper' }}>
                  <Typography textAlign="left">Parking Lot</Typography>
                </Paper>
                <div style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}></div>
              </BoardColumn>
              {selectedPiFull?.sprints?.map((sprint) => {
                return (
                  <BoardColumn key={sprint.id} xs={12 / ((selectedPiFull?.sprints?.length || 0) + 1)}>
                    <Paper
                      elevation={0}
                      sx={{ flexShrink: 0, padding: '8px', marginBottom: '5px', backgroundColor: 'color.darkPaper' }}>
                      <Typography textAlign="left">
                        {sprint.name?.includes('Sprint') ? sprint.name : `Sprint ${sprint.name}`}
                      </Typography>
                    </Paper>
                    <div style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}>
                      <TransitionGroup>
                        {teamFeatures
                          .filter((feature) => feature.committedSprint === sprint.id)
                          .map((feature) => (
                            <Collapse key={feature.id}>
                              <StoryCard key={feature.id} story={feature} />
                            </Collapse>
                          ))}
                      </TransitionGroup>
                    </div>
                  </BoardColumn>
                );
              })}
            </Grid>
          </DashboardCard>
        </Grid>
      </Grid>
      {addStoriesFeature && (
        <AddStoriesDialog
          feature={addStoriesFeature}
          open={true}
          pi={selectedPiFull || {}}
          team={selectedTeam}
          onClose={() => setAddStoriesFeature(null)}
        />
      )}
    </Grid>
  );
};
