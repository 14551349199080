export const styles = (theme) => ({
  selector: {
    display: 'flex',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.color.mediumPink,
      borderWidth: 1,
    },
  },
  input: {
    color: theme.palette.text.primary,
    borderRadius: 30,
    minWidth: 160,
    height: 40,
    '& .MuiSelect-select.MuiSelect-select': {
      display: 'flex',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: `rgba(240, 77, 153, 0.15)`,
      color: theme.palette.color.mediumPink,
      borderRadius: 30,
    },
  },
  inputLabel: {
    color: theme.palette.text.primary,
  },
  cssFocused: {
    color: `rgba(240, 77, 153, 0.8) !important`,
  },
  selectedItem: {
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: `rgba(240, 77, 153, 0.15)`,
    },
  },
});
