import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { mapValues, omitBy } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import { deleteUpdate } from '../../../utils/graphQLUtils';
import { useForm } from 'react-hook-form';
import { Field, FormContext, renderTextField } from '../../Common/FormFieldsHooks';
import { resetDrawerDetails, selectedValueStreamVar } from '../../../reactiveVariables';
import { showNotification } from '@mantine/notifications';
import {
  DELETE_PRODUCT_THEME,
  GET_PRODUCT_THEMES,
  INSERT_PRODUCT_THEME,
  UPDATE_PRODUCT_THEME,
} from '../../../containers/ValueStream/ProductTheme/graphql';

const required = (value) => (value ? undefined : 'Required');

const ProductThemeDetails = (props, ref) => {
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const [insertProductTheme] = useMutation(INSERT_PRODUCT_THEME);
  const [updateProductTheme] = useMutation(UPDATE_PRODUCT_THEME);
  const [deleteProductTheme] = useMutation(DELETE_PRODUCT_THEME);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      ...props.element,
    },
  });

  const productTheme = watch();

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => resetDrawerDetails(),
  }));

  const handleDeleteConfirm = () => {
    deleteProductTheme({
      variables: { id: productTheme.id },
      update: deleteUpdate('product_theme', 'productTheme'),
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const productThemeToSave = mapValues(
      omitBy(values, (property, key) => key === 'id'),
      (property) => (property === '' ? null : property),
    );

    values.id
      ? updateProductTheme({
          variables: { productTheme: productThemeToSave, productThemeId: values.id },
        }).then(() => {
          showNotification({
            title: 'Product Theme Updated',
            message: `Product Theme ${values?.id} was successfully updated`,
          });

          resetDrawerDetails();
        })
      : insertProductTheme({
          refetchQueries: [GET_PRODUCT_THEMES, 'GetProductThemes'],
          variables: { productTheme: { ...productThemeToSave, valueStream: selectedValueStream } },
        }).then(() => {
          showNotification({
            title: 'Product Theme Created',
            message: `Product Theme was successfully created`,
          });
          resetDrawerDetails();
        });
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="title" renderField={renderTextField} label="Title" />
            <Field name="strategicTheme" renderField={renderTextField} label="Strategic Theme" />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete Product Theme: ${productTheme.name}`}
        text="Are you sure you want to delete this Product Theme ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </Card>
  );
};

export default forwardRef(ProductThemeDetails);
