import { gql } from '@apollo/client';

export const RISK_FIELDS = gql`
  fragment RiskFields on risks {
    programIncrement
    id
    name
    state
    description
    team
    owner
  }
`;

export const INSERT_RISK = gql`
  ${RISK_FIELDS}
  mutation InsertRisk($risk: risks_insert_input! = {}) {
    risk: insert_risks_one(object: $risk) {
      ...RiskFields
    }
  }
`;

export const UPDATE_RISK = gql`
  ${RISK_FIELDS}
  mutation UpdateRisk($riskId: Int!, $risk: risks_set_input = {}) {
    risk: update_risks_by_pk(pk_columns: { id: $riskId }, _set: $risk) {
      ...RiskFields
    }
  }
`;

export const DELETE_RISK = gql`
  mutation DeleteRisk($riskId: Int!) {
    risk: delete_risks_by_pk(id: $riskId) {
      id
    }
  }
`;

export const GET_RISKS = gql`
  ${RISK_FIELDS}
  query GetRisks {
    risks: risks {
      ...RiskFields
    }
  }
`;

export const GET_RISKS_FOR_PI_AND_STATE = gql`
  ${RISK_FIELDS}
  query GetRisks($pi: Int, $state: String) {
    selectedPI @client @export(as: "pi")
    risks: risks(where: { programIncrement: { _eq: $pi }, state: { _eq: $state } }) {
      ...RiskFields
    }
  }
`;

export const GET_RISKS_FOR_PI = gql`
  ${RISK_FIELDS}
  query GetRisks($pi: Int) {
    selectedPI @client @export(as: "pi")
    risks: risks(where: { programIncrement: { _eq: $pi } }) {
      ...RiskFields
    }
  }
`;

export const SUBSCRIBE_RISKS = gql`
  ${RISK_FIELDS}
  subscription GetRisks($pi: Int) {
    selectedPI @client @export(as: "pi")
    risks: risks(where: { programIncrement: { _eq: $pi } }) {
      ...RiskFields
    }
  }
`;
