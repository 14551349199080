import { ResponsiveLine } from '@nivo/line';
import React, { memo, useMemo } from 'react';
import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useAnimatedPath } from '@nivo/core';
import { animated } from '@react-spring/web';
import { DATE_FORMAT } from '../../../../../utils/timeUtils';
import { date } from '../../../../../utils/helpers';
import { DateTime } from 'luxon';
import { findLast, last } from 'lodash';
import * as d3 from 'd3';

const commonProps = {
  margin: { top: 70, right: 40, bottom: 50, left: 70 },
  animate: true,
  enableSlices: 'x',
};

const Areas = ({ series, areaGenerator, xScale, yScale }) => {
  const { colorScheme } = useMantineColorScheme();

  return series.map(({ id, data, color, showArea }) => (
    <path
      key={id}
      d={areaGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill={showArea ? color : 'none'}
      fillOpacity={colorScheme === 'dark' ? 0.3 : 0.1}
    />
  ));
};

const Chip = memo(({ size = 12, color, style = {} }) => (
  <span style={{ display: 'block', width: size, height: size, background: color, ...style }} />
));

const Lines = ({ series, lineGenerator, xScale, yScale }) => {
  return series
    .filter(({ id }) => !['Features', 'Initiatives'].includes(id))
    .map(({ id, data, color, showArea }) => {
      const path = useMemo(
        () =>
          lineGenerator(
            data.map((d) => ({
              x: xScale(d.data.x),
              y: yScale(d.data.y),
            })),
          ),
        [lineGenerator, series],
      );
      const animatedPath = useAnimatedPath(path);

      return (
        <animated.path key={id} d={animatedPath} fill="none" stroke={color} style={{ strokeWidth: showArea ? 0 : 2 }} />
      );
    });
};

const KeyResultGraph = ({ keyResult }) => {
  const theme = useMantineTheme();

  const nivoTheme = {
    axis: {
      legend: {
        text: {
          fontSize: 14,
          fill: '#93a0b5',
        },
      },
      ticks: {
        text: {
          fontSize: 11,
          fill: '#93a0b5',
        },
      },
      domain: {
        line: {
          stroke: theme.other.colours.graphLine,
          strokeWidth: 1,
        },
      },
    },
    grid: {
      line: {
        stroke: theme.other.colours.graphLine,
        strokeWidth: 1,
      },
    },
    tooltip: {
      container: {
        background: theme.other.colours.background,
      },
    },
  };

  const featuresDoneDates = (keyResult.outputFeatures || [])
    .filter((feature) => feature.status === 'Done')
    .map((feature) => findLast(feature.statusChangelog.Done, (status) => !!status.startDate)?.startDate)
    .filter(Boolean);

  const initDoneDates = (keyResult.outputInitiatives || [])
    .map((initiative) => {
      const lastMetric = last(initiative.metrics);
      return lastMetric && lastMetric.done.storyCount === lastMetric.scope.storyCount ? lastMetric.date : null;
    })
    .filter(Boolean);

  const highestValue = Math.max(
    keyResult.startingValue,
    keyResult.targetValue,
    ...keyResult.trajectory.map((point) => point.expectedValue),
    ...(keyResult.updates?.map((update) => update.value) || []),
  );

  const actual = d3
    .scaleTime()
    .range([keyResult.startingValue, ...(keyResult.updates?.map((update) => update.value) || [])])
    .domain([new Date(keyResult.startingDate), ...(keyResult.updates?.map((update) => new Date(update.date)) || [])]);

  const data = [
    {
      id: 'Expected',
      label: 'Expected',
      color: '#F46EAE',
      showArea: false,
      data: [
        { x: keyResult.startingDate, y: keyResult.startingValue, showPoint: true },
        ...keyResult.trajectory.map((point) => ({ x: point.date, y: point.expectedValue, showPoint: true })),
        { x: keyResult.targetDate, y: keyResult.targetValue, showPoint: true },
      ],
    },
    {
      id: 'Actual',
      label: 'Actual',
      color: '#9D81EF',
      showArea: false,
      data: [
        { x: keyResult.startingDate, y: keyResult.startingValue, showPoint: true },
        ...(keyResult.updates?.map((update) => ({
          x: date(update.date).toJSDate(),
          y: update.value,
          showPoint: true,
        })) || []),
      ],
    },
    {
      id: 'Green',
      color: theme.other.colours.green,
      showArea: true,
      hidden: true,
      data: [
        { x: keyResult.startingDate, y: highestValue, showPoint: false },
        { x: keyResult.targetDate, y: highestValue, showPoint: false },
      ],
    },
    {
      id: 'Amber',
      color: theme.other.colours.amber,
      showArea: true,
      hidden: true,
      data: [
        { x: keyResult.startingDate, y: keyResult.startingValue * 0.8, showPoint: false },
        { x: keyResult.targetDate, y: keyResult.targetValue * 0.8, showPoint: false },
      ],
    },
    {
      id: 'Red',
      color: theme.other.colours.red,
      showArea: true,
      data: [
        { x: keyResult.startingDate, y: keyResult.startingValue * 0.4, showPoint: false },
        { x: keyResult.targetDate, y: keyResult.targetValue * 0.4, showPoint: false },
      ],
    },
    {
      id: 'Features',
      color: theme.other.colours.done,
      showArea: false,
      data: featuresDoneDates
        .filter((date) => new Date(date) > new Date(keyResult.startingDate))
        .map((date) => ({
          x: new Date(date),
          y: actual(new Date(date)),
          showPoint: true,
          type: 'feature',
          showLine: false,
        })),
    },
    {
      id: 'Initiatives',
      color: theme.other.colours.done,
      showArea: false,
      data: initDoneDates
        .filter((date) => new Date(date) > new Date(keyResult.startingDate))
        .map((date) => ({
          x: new Date(date),
          y: actual(new Date(date)),
          showPoint: true,
          type: 'feature',
          showLine: false,
        })),
    },
  ];

  return (
    <ResponsiveLine
      {...commonProps}
      theme={nivoTheme}
      data={data}
      enableArea={true}
      enablePoints={true}
      areaOpacity={0.5}
      areaBlendMode="darken"
      colors={{ datum: 'color' }}
      pointSize={10}
      pointBorderWidth={1}
      pointBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.3]],
      }}
      curve="linear"
      xFormat="time:%d-%m-%Y"
      gridYValues={8}
      xScale={{
        format: '%Y-%m-%d',
        precision: 'day',
        min: keyResult.startingDate,
        max: keyResult.targetDate,
        type: 'time',
        useUTC: true,
      }}
      yScale={{
        type: 'linear',
      }}
      pointSymbol={({ size, color, borderWidth, borderColor, ...rest }) => {
        if (!rest.datum.showPoint) return null;

        if (rest.datum.type === 'feature') {
          return (
            <g>
              <rect
                x={-(size / 2)}
                y={-(size / 2)}
                width="10"
                height="10"
                rx="1.5"
                fill="#2F6DE1"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          );
        }

        if (rest.datum.type === 'initiative') {
          return (
            <g>
              <path
                d="M1.52554 8.14576L2.34754 8.71525L1.52554 8.14576C0.836324 9.14056 1.5483 10.5 2.75853 10.5H11.2415C12.4517 10.5 13.1637 9.14056 12.4745 8.14576L8.23299 2.02373C7.63647 1.16272 6.36353 1.16272 5.76701 2.02373L1.52554 8.14576Z"
                fill="#2F6DE1"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          );
        }

        return (
          <g>
            <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
            <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
          </g>
        );
      }}
      layers={['grid', 'markers', 'axes', Areas, 'crosshair', Lines, 'points', 'slices', 'mesh', 'legends']}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickValues: 5,
        legend: keyResult.unit,
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisBottom={{
        format: '%e %b',
        legendOffset: -12,
        tickValues: 5,
      }}
      sliceTooltip={({ slice }) => (
        <div
          style={{
            background: theme.other.colours.background,
            color: 'inherit',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            padding: '5px 9px',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>
              <strong>{DateTime.fromJSDate(slice.points?.[0].data.x).toFormat(DATE_FORMAT.date)}</strong>
            </span>
          </div>
          {slice.points
            .filter((point) => ['Actual', 'Expected', 'Features', 'Initiatives'].includes(point.serieId))
            .map((point) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                  <Chip color={point.color} style={{ marginRight: '10px' }} />
                  <span style={{ marginRight: '10px' }}>{point.serieId}</span>
                </div>
                <span>
                  <strong>
                    {['£', '$'].includes(keyResult.unit)
                      ? keyResult.unit + point.data.y
                      : `${point.data.y} ${keyResult.unit}`}
                  </strong>
                </span>
              </div>
            ))}
        </div>
      )}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -50,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
          data: [
            {
              id: 'Expected',
              label: 'Expected',
              color: '#F46EAE',
            },
            {
              id: 'Actual',
              label: 'Actual',
              color: '#9D81EF',
            },
          ],
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default KeyResultGraph;
