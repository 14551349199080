import { gql } from '@apollo/client';

export const IDEAS_FIELDS = gql`
  fragment IdeasFields on ideas {
    id
    user_id
    idea
    owner
    state
    created_at
    next_step
    next_step_feature_or_initiative_id
  }
`;

export const GET_IDEAS_BY_USER_ID = gql`
  query GetAllIdeasByUserId($userId: String!) {
    ideas(where: { user_id: { _eq: $userId } }) {
      ...IdeasFields
    }
  }
  ${IDEAS_FIELDS}
`;

export const GET_ALL_IDEAS = gql`
  query GetAllIdeas {
    ideas {
      ...IdeasFields
    }
  }
  ${IDEAS_FIELDS}
`;

export const INSERT_NEW_IDEA = gql`
  mutation InsertNewIdea($userId: String!, $idea: jsonb!) {
    insert_ideas_one(object: { user_id: $userId, idea: $idea }) {
      id
      user_id
      idea
    }
  }
`;

export const GET_MOST_RECENT_IDEAS = gql`
  query GetMostRecentIdeas($limit: Int!, $order: order_by!) {
    ideas(order_by: { created_at: $order }, limit: $limit) {
      ...IdeasFields
    }
  }
  ${IDEAS_FIELDS}
`;

export const UPDATE_IDEA = gql`
  mutation UpdateIdea(
    $id: Int!
    $idea: jsonb!
    $next_step: String!
    $state: String!
    $owner: String!
    $next_step_feature_or_initiative_id: Int
  ) {
    update_ideas_by_pk(
      pk_columns: { id: $id }
      _set: {
        idea: $idea
        next_step: $next_step
        state: $state
        owner: $owner
        next_step_feature_or_initiative_id: $next_step_feature_or_initiative_id
      }
    ) {
      id
      user_id
      idea
      state
      next_step
      owner
      next_step_feature_or_initiative_id
    }
  }
`;
