import * as React from 'react';

function SvgSettings(props) {
  return (
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.854 1a1 1 0 01.979.796l.311 1.495a7.951 7.951 0 011.791.742l1.277-.837a1 1 0 011.256.13l1.207 1.206a1 1 0 01.129 1.256l-.837 1.277a7.96 7.96 0 01.742 1.79l1.495.312a1 1 0 01.796.98v1.707a1 1 0 01-.796.979l-1.494.31a7.951 7.951 0 01-.743 1.792l.837 1.277a1 1 0 01-.13 1.256l-1.206 1.207a1 1 0 01-1.256.129l-1.277-.837a7.951 7.951 0 01-1.79.742l-.312 1.495a1 1 0 01-.98.796h-1.707a1 1 0 01-.979-.796l-.31-1.494a7.951 7.951 0 01-1.792-.743l-1.277.837a1 1 0 01-1.256-.13l-1.207-1.206a1 1 0 01-.129-1.256l.837-1.277a7.951 7.951 0 01-.743-1.792l-1.494-.31a1 1 0 01-.796-.98v-1.707a1 1 0 01.796-.979l1.495-.311a7.951 7.951 0 01.742-1.791l-.837-1.277a1 1 0 01.13-1.256l1.206-1.207a1 1 0 011.256-.129l1.277.837a7.951 7.951 0 011.792-.743l.31-1.494a1 1 0 01.98-.796h1.707zM11 6a5 5 0 100 10 5 5 0 000-10z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSettings;
