import { Grow, Paper, Table, TableBody, TableCell, TableHead, TableRow, Zoom } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { find, split } from 'lodash';

const useStyles = makeStyles(styles);

const FeatureRoadmap = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const renderFeature = (backgroundColor) => {
    return (
      <Zoom in={true}>
        <Paper className={classes.paper} style={{ backgroundColor: backgroundColor }} elevation={1} />
      </Zoom>
    );
  };

  const getItemsForSprint = (feature, sprint) => {
    const estimatedSprint = find(props.pi.sprints, { id: feature.estimatedSprint }) || {};
    const originalCommittedSprint =
      find(props.pi.sprints, {
        id: feature.originalState && feature.originalState.committedSprint,
      }) || {};

    const isInOriginalPlan =
      feature.originalState && feature.originalState.startingSprint
        ? new Date(sprint.startDate) >= new Date(feature.originalState.startingSprint.startDate) &&
          new Date(originalCommittedSprint.startDate) >= new Date(sprint.startDate)
        : false;

    const isInCurrentPlan = feature.startingSprint
      ? new Date(sprint.startDate) >= new Date(feature.startingSprint.startDate) &&
        new Date(estimatedSprint.startDate) >= new Date(sprint.startDate)
      : false;

    const backgroundColor = isInOriginalPlan ? theme.palette.color.done : theme.palette.color.red;
    const features = isInOriginalPlan || isInCurrentPlan ? renderFeature(backgroundColor) : null;

    return <div style={{ padding: '8px 12px' }}>{features}</div>;
  };

  const renderSprintForTeam = (feature, pi, index) => {
    const plannedItems =
      pi.sprints &&
      pi.sprints.map((sprint) => (
        <TableCell key={sprint.startDate} className={classes.tableCell}>
          {getItemsForSprint(feature, sprint)}
        </TableCell>
      ));

    return (
      <TableRow
        key={feature.id}
        style={{
          background: index % 2 ? theme.palette.color.paper : theme.palette.background.default,
        }}>
        <TableCell className={classes.tableHeadCol}>{feature.name}</TableCell>
        {plannedItems}
      </TableRow>
    );
  };

  const { features, pi } = props;

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCol}>FEATURES</TableCell>
                {pi.sprints &&
                  pi.sprints.map((sprint) => {
                    const startDate = split(sprint.startDate, '-');
                    const endDate = split(sprint.endDate, '-');
                    return (
                      <TableCell key={sprint.id} className={classes.tableHeadRow}>
                        <div
                          className={
                            classes.sprintDates
                          }>{`${startDate[2]}/${startDate[1]} - ${endDate[2]}/${endDate[1]}`}</div>
                        <div className={classes.sprintItems}>{sprint.name.replace('Sprint', '').trim()}</div>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {features
                .filter((feature) => !!feature.estimatedSprint && feature.programIncrement === pi.id)
                .map((feature, index) => renderSprintForTeam(feature, pi, index))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Grow>
  );
};

export default FeatureRoadmap;
