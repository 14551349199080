export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // display: 'flex',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    marginTop: '0',
    marginBottom: 10,
    paddingLeft: '0',
    minHeight: 50,
    fontSize: 20,
    color: '#426CB4',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 90,
  },
  dashboardSelect: {
    textAlign: 'left',
    flex: 1,
  },
  title: {
    marginRight: 10,
    borderRight: '0.1em solid #426CB4',
    paddingRight: 10,
  },
});
