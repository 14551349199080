import { Button, Card, CardContent } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContext } from '../FormFieldsHooks';
import { BUTTONS } from '../../../utils/formConstants';
import { resetDrawerDetails } from '../../../reactiveVariables';

const ElementDetails = ({ canEdit, element, defaultValues, title, submitFunc, deleteFunc, children }, ref) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (element) {
      register('id');
      setValue('id', element.id);
    }
  }, [element]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(submitFunc)();
    },
    handleDelete: () => {
      if (element && element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const fields = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(submitFunc)}>
          <FormContext.Provider value={{ control, errors }}>{children}</FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete ${title}: ${fields.name}`}
        text={`Are you sure you want to delete this ${title} ?`}
        handleOk={deleteFunc}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(submitFunc)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(ElementDetails);
