import React, { useMemo, useRef } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { capitalize, sum } from 'lodash';
import { BasicTooltip } from '@nivo/tooltip';
import { nivoTheme } from '../Common';
import { useTheme } from '@mui/material';

const FlowTime = ({ programIncrements, objectives }) => {
  const rectRef = useRef(null);
  const theme = useTheme();

  const data1 = useMemo(() => [
    { x: 'Jan', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Feb', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Mar', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Apr', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'May', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jun', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jul', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Aug', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Sep', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Oct', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Nov', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Dec', y: (Math.random() * 20 + 1).toFixed() },
  ]);

  const data2 = useMemo(() => [
    { x: 'Jan', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Feb', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Mar', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Apr', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'May', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jun', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jul', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Aug', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Sep', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Oct', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Nov', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Dec', y: (Math.random() * 20 + 1).toFixed() },
  ]);

  const data3 = useMemo(() => [
    { x: 'Jan', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Feb', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Mar', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Apr', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'May', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jun', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jul', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Aug', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Sep', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Oct', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Nov', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Dec', y: (Math.random() * 20 + 1).toFixed() },
  ]);

  const data4 = useMemo(() => [
    { x: 'Jan', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Feb', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Mar', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Apr', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'May', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jun', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Jul', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Aug', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Sep', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Oct', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Nov', y: (Math.random() * 20 + 1).toFixed() },
    { x: 'Dec', y: (Math.random() * 20 + 1).toFixed() },
  ]);

  const ticks = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const data = [
    { id: 'features', data: data1, color: theme.palette.color.inProgress },
    { id: 'unknown', data: data2, color: theme.palette.color.subtitle },
    { id: 'bugs', data: data3, color: theme.palette.color.cancel },
    { id: 'maintenance', data: data4, color: theme.palette.color.done },
  ];

  const commonProps = {
    margin: { top: 60, right: 80, bottom: 90, left: 80 },
    // data: [{ id: 'Stuff', label: 'Stuff', data: [{ x: '', y: 0 }, ...data] }],
    padding: 0.1,
    innerPadding: 3,
  };

  return (
    <ResponsiveLine
      {...commonProps}
      ref={rectRef}
      data={data}
      theme={nivoTheme()}
      colors={{ datum: 'color' }}
      curve="monotoneX"
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        legend: 'Days',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      axisBottom={{
        tickValues: ticks,
        legend: 'Months',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      areaOpacity={0.8}
      xScale={{ type: 'point' }}
      useMesh={true}
      yScale={{
        type: 'linear',
        max: 'auto',
      }}
      tooltip={(props) => <BasicTooltip id={capitalize(props?.point?.serieId)} value={`${props?.point?.data?.y}`} />}
      isInteractive={true}
      animate={true}
      enableGridY={false}
    />
  );
};

export default FlowTime;
