import * as React from 'react';

function Import(props) {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_195_20680"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="22">
        <rect width="242" height="22" />
      </mask>
      <g mask="url(#mask0_195_20680)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1296 2.59542C12.6962 1.16207 10.3723 1.16207 8.93897 2.59542L6.36985 5.16454C5.79129 5.74309 5.416 6.49369 5.30029 7.30366C5.0157 9.29575 6.3735 11.1507 8.35843 11.4815L8.8384 11.5615C9.17165 11.617 9.48682 11.3919 9.54236 11.0587C9.59791 10.7254 9.37278 10.4102 9.03953 10.3547L8.55956 10.2747C7.2302 10.0531 6.32084 8.81084 6.51143 7.47668C6.58971 6.92876 6.84358 6.42101 7.23495 6.02964L9.80407 3.46052C10.7596 2.50496 12.3089 2.50496 13.2645 3.46052C14.2201 4.41609 14.2201 5.96538 13.2645 6.92095L13.1203 7.06513C12.8814 7.30402 12.8814 7.69134 13.1203 7.93023C13.3592 8.16913 13.7465 8.16913 13.9854 7.93023L14.1296 7.78605C15.563 6.3527 15.563 4.02877 14.1296 2.59542ZM4.75776 8.50663C4.99665 8.26774 4.99665 7.88042 4.75776 7.64152C4.51887 7.40263 4.13155 7.40263 3.89266 7.64152L2.45081 9.08337C1.09709 10.4371 1.09709 12.6319 2.45081 13.9856L3.02755 14.5624C4.38127 15.9161 6.57609 15.9161 7.92981 14.5624L9.37165 13.1205C9.61055 12.8816 9.61055 12.4943 9.37166 12.2554C9.13276 12.0165 8.74544 12.0165 8.50655 12.2554L7.06471 13.6973C6.18877 14.5732 4.76859 14.5732 3.89266 13.6973L3.31592 13.1205C2.43998 12.2446 2.43998 10.8244 3.31592 9.94847L4.75776 8.50663ZM10.0281 8.66257C9.76427 7.87103 9.14315 7.24991 8.35161 6.98607L8.16895 6.92518C7.84844 6.81834 7.67522 6.47191 7.78206 6.1514C7.8889 5.8309 8.23533 5.65768 8.55583 5.76452L8.7385 5.8254C9.89536 6.21103 10.8032 7.11882 11.1888 8.27569L11.2497 8.45835C11.3565 8.77886 11.1833 9.12529 10.8628 9.23212C10.5423 9.33896 10.1958 9.16575 10.089 8.84524L10.0281 8.66257ZM11.2472 14.4176C12.5213 15.6917 14.587 15.6917 15.8611 14.4176C17.1352 13.1435 17.1352 11.0778 15.8611 9.80372C14.587 8.52963 12.5213 8.52963 11.2472 9.80372C9.9731 11.0778 9.9731 13.1435 11.2472 14.4176ZM11.1073 11.7029L11.1073 12.5185H13.1463V14.5576H13.9619V12.5185L16.001 12.5185V11.7029L13.9619 11.7029L13.9619 9.66381H13.1463L13.1463 11.7029H11.1073Z"
        />
      </g>
    </svg>
  );
}

export default Import;
