import React from 'react';
import { Textarea, TextInput, useMantineTheme } from '@mantine/core';
import { Avatar } from '@mantine/core';
export const StyledAvatar = ({ children, ...props }) => {
  const theme = useMantineTheme();

  return (
    <Avatar
      vars={(theme, props) => ({
        root: { '--avatar-color': theme.other.colours.secondary },
      })}
      variant="filled"
      color={theme.other.colours.grey}
      radius="xl"
      {...props}>
      {children}
    </Avatar>
  );
};

export const InputText = ({ label, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <TextInput
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

export const InputArea = ({ label, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <Textarea
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};
