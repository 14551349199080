import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { openDrawer } from '../../../reactiveVariables';
import { useQuery } from '@apollo/client';
import { GET_ROLES, GET_USERS } from '../../../containers/Settings/GraphQL/users';
import EntityTable from '../../Common/EntityTable';
import { exportCSVData, getFieldValue } from 'utils/export';

const useStyles = makeStyles(styles);

const Users = (props, ref) => {
  const classes = useStyles();
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { roles = [] } = {} } = useQuery(GET_ROLES);

  const onRowClick = (event, row) => {
    const user = users.find((user) => user?.email === row.email);
    openDrawer(user, 'user');
  };
  useImperativeHandle(ref, () => ({
    export: () => exportProgrammes(),
  }));

  const exportProgrammes = useCallback(() => {
    const data = users.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Value Streams');
  }, [users]);

  return (
    <div className={classes.wrapper}>
      <EntityTable
        size="small"
        data={users}
        headCells={headCells}
        rows={getRowsData(users, roles)}
        searchQuery={searchQueryLowerCase}
        expand={false}
        handleRowClick={onRowClick}
      />
    </div>
  );
};

export default forwardRef(Users);
