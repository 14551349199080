import * as React from 'react';

function SvgLinkArrow(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.1312 11.5507C12.8677 11.5457 12.64 11.4521 12.448 11.2701C12.256 11.088 12.1622 10.8701 12.1668 10.6162L12.2439 7.11514L5.43052 13.9285C5.23174 14.1273 5.00052 14.229 4.73686 14.2337C4.47321 14.2384 4.24547 14.1449 4.05366 13.9531C3.86184 13.7613 3.76828 13.5335 3.77299 13.2699C3.77769 13.0062 3.87944 12.775 4.07822 12.5762L10.8916 5.76284L7.34687 5.82609C7.09298 5.83062 6.87741 5.73928 6.70015 5.55209C6.5229 5.36489 6.43174 5.13955 6.42668 4.87607C6.44115 4.61224 6.54529 4.38342 6.7391 4.18961C6.93292 3.99579 7.15677 3.89662 7.41066 3.89209L13.2548 3.80246C13.3915 3.80002 13.5205 3.81968 13.6418 3.86145C13.763 3.90321 13.8668 3.96725 13.9531 4.05357C14.0395 4.13989 14.1035 4.24369 14.1453 4.36497C14.187 4.48625 14.2067 4.61524 14.2043 4.75195L14.1152 10.5668C14.1103 10.8402 14.011 11.0689 13.8174 11.253C13.6237 11.437 13.395 11.5363 13.1312 11.5507Z" />
    </svg>
  );
}

export default SvgLinkArrow;
