import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { find, sortBy } from 'lodash';
import { exportCSVData, getFieldValue } from 'utils/export';
import { GET_DEPENDENCIES_FOR_PI } from './graphql';
import { GET_PROGRAM_INCREMENTS } from '../../../Settings/ProgramIncrement/graphql';
import { GET_TEAMS } from '../../../Settings/GraphQL/teams';
import { useQuery } from '@apollo/client';
import { openDrawer } from '../../../../reactiveVariables';
import EntityTable from '../../../../components/Common/EntityTable';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles(styles);

const Dependencies = (props, ref) => {
  const { searchQuery } = props;
  const classes = useStyles();
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();
  const theme = useTheme();

  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { dependencies = [] } = {} } = useQuery(GET_DEPENDENCIES_FOR_PI);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);

  useImperativeHandle(ref, () => ({
    export: () => exportDependencies(),
  }));

  const onRowClick = (event, row) => {
    const dependency = dependencies.find((dep) => dep?.id === row.id);
    openDrawer(dependency, 'dependency');
  };

  const exportDependencies = useCallback(() => {
    const data = sortBy(dependencies, ['team', 'programIncrement', 'status'])
      ?.map((dependency) => {
        const pi = find(programIncrements, { id: dependency.programIncrement }) || {};
        const team = find(teams, { id: dependency.teamId }) || {};
        const neededBySprint = find(pi.sprints, { id: dependency.neededBySprint }) || {};

        return {
          ...dependency,
          neededBySprint: neededBySprint.name,
          neededBySprintDate: neededBySprint.endDate,
          team: team.name,
        };
      })
      .map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Dependencies');
  }, [dependencies]);

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          handleRowClick={onRowClick}
          headCells={headCells}
          rows={getRowsData(dependencies, teams, programIncrements, theme)}
          searchQuery={searchQueryLowerCase}
          expand={false}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Dependencies);
