import { Grid, Paper, Typography, Zoom } from '@mui/material';
import { Badge, Divider, Text } from '@mantine/core';
import React from 'react';
import { useTheme } from '@emotion/react';
import { Dot } from '../../ValueStream/components/Common';
import { useColorScheme } from '../../../utils/hooks';
import { Attention } from '../../../assets/images';
import { darken } from '@mui/system/colorManipulator';

const getStatusTitle = (feature, piStatus) => {
  if (piStatus === 'planning' || piStatus === 'projected') {
    if (feature.planned) {
      return 'Forecasted';
    }
    return 'Projected';
  }
  if (feature.status === 'Done') {
    return 'Done';
  }

  switch (feature.ragStatus) {
    case 'Red':
      return 'Action required';
    case 'Amber':
      return 'Issues';
    case 'Green':
      return 'On target';
  }
};

const featureStatusColor = (feature, piStatus, theme) => {
  const isDark = theme.palette.mode === 'dark';

  if (piStatus === 'planning' || piStatus === 'projected') {
    if (feature.planned) {
      return isDark ? theme.palette.color.forecasted : darken(theme.palette.color.forecasted, 0.1);
    }
    return isDark ? theme.palette.color.predicted : darken(theme.palette.color.predicted, 0.1);
  }
  if (feature.status === 'Done') {
    return theme.palette.color.done;
  }

  return theme.palette.color[feature.ragStatus?.toLowerCase()];
};

const FeatureCard = ({ piStatus, feature, forecastValue, plannedFeatures, handleFeatureClick, index }) => {
  const theme = useTheme();
  const { isDark } = useColorScheme();

  return (
    <Zoom key={index} in={true}>
      <Paper
        variant="outlined"
        onClick={() => handleFeatureClick(feature)}
        sx={{
          textAlign: 'start',
          padding: '10px',
          marginBottom: '5px',
          ':hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.color.paper,
          },
        }}>
        {forecastValue === 'size' && !feature.size && !feature.planned && (
          <Badge
            size="sm"
            sx={{ marginLeft: '-6px' }}
            leftSection={
              <span style={{ display: 'flex' }}>
                <Attention fill={theme.palette.color.red} />
              </span>
            }
            color="red">
            Unsized Feature
          </Badge>
        )}
        <Text size="sm" fw={500} lineClamp={1}>
          {feature.name}
        </Text>
        <Grid alignItems="center" container>
          <Dot color={featureStatusColor(feature, piStatus, theme)} />
          <Typography color={featureStatusColor(feature, piStatus, theme)} sx={{ fontSize: 12 }}>
            {getStatusTitle(feature, piStatus)}
          </Typography>
          <Divider color={isDark ? 'white' : 'darkGrey.0'} orientation="vertical" style={{ margin: '3px 8px' }} />
          <Text c="darkGrey.0" size="xs">
            {feature.order} / {plannedFeatures.length}
          </Text>
          {forecastValue === 'size' && feature.size ? (
            <>
              <Divider color={isDark ? 'white' : 'darkGrey.0'} orientation="vertical" style={{ margin: '3px 8px' }} />
              <Text c="darkGrey.0" size="xs">{`Size: ${feature.size}`}</Text>
            </>
          ) : null}
        </Grid>
      </Paper>
    </Zoom>
  );
};

export default FeatureCard;
