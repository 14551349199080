import * as React from 'react';

function DownArrow(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.003 5.248a1.5 1.5 0 1 0-3 0v9.667l-2.147-2.326a1.5 1.5 0 1 0-2.204 2.035l4.748 5.143a1.5 1.5 0 0 0 2.204 0l4.748-5.143a1.5 1.5 0 0 0-2.204-2.035l-2.145 2.324V5.248z"
        fill="#CC5348"
      />
    </svg>
  );
}

export default DownArrow;
