import { Button } from '@mui/material';
import React from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_TOOLING_FEATURE } from '../../../containers/Product/Features/graphql';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
import { resetDrawerDetails } from '../../../reactiveVariables';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { showNotification } from '@mantine/notifications';
import { getIssueTypeName, getToolingName } from '../../../utils/helpers';

export default function ExportDialog({ user, handleCancel, openDialog, feature }) {
  const [pushFeature, { loading }] = useMutation(CREATE_TOOLING_FEATURE);
  const name = getToolingName();
  const issueType = getIssueTypeName();

  const handleExport = () => {
    pushFeature({
      variables: {
        connectorId: feature?.team?.connectorId,
        projectId: feature?.team?.projectId,
        input: {
          id: feature.id,
          name: feature.name,
          description: feature.description,
          areaPath: feature?.team?.boardName,
          assignedTo: feature.assignedTo ? feature.assignedTo : user.firstName + ' ' + user.lastName,
        },
      },
    })
      .then((result) => {
        handleCancel();
        resetDrawerDetails();
        showNotification({
          title: `${getToolingName(true)} Created`,
          message: `${issueType} ${result.data?.toolingFeature?.epicId} was successfully created in ${name}`,
        });
      })
      .catch(() => {
        showNotification({
          title: `Error: ${issueType} Creation`,
          message: `Error when trying to create the feature in ${name}`,
          color: 'red',
        });
      });
  };

  return (
    <ConfirmationDialog
      open={openDialog}
      title={`Export to ${name}`}
      text={`Do you want to create this feature in ${name}?`}>
      <Button onClick={handleCancel} color="primary">
        CANCEL
      </Button>
      <Button onClick={handleExport} color="primary">
        CONFIRM
      </Button>
      <Backdrop sx={{ position: 'absolute', color: '#fff' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ConfirmationDialog>
  );
}
