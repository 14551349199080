import { Fieldset, Group, SimpleGrid, Text, Title, useMantineTheme } from '@mantine/core';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../../../Settings/GraphQL/users';
import LinearWithValueLabel from '../../../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import React from 'react';
import {
  Field,
  renderCombobox,
  renderCreatableCombobox,
  renderDate,
  renderInput,
  renderNumberInput,
} from '../../../../../components/Common/FormFieldsHooks/MantineField';
import { calculateOutcome, getProgressColor, getRagColorForKeyResult } from '../../utils';

const KrDetails = ({ keyResult }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const outcome = calculateOutcome(keyResult);
  const color = getRagColorForKeyResult(keyResult, theme);
  return (
    <>
      <Fieldset m="0" radius={8}>
        <Group mb={10} gap={12} justify={'space-between'}>
          <Title order={6}>{keyResult.name}</Title>
          <Group>
            {/*<Display2 bgColor={theme.other.colours.purple}>Likely</Display2>*/}
            <Display2 bgColor={color}>{outcome}%</Display2>
          </Group>
        </Group>
        <LinearWithValueLabel
          tooltip={`${keyResult.output.toFixed()}% complete`}
          value={keyResult.output}
          color={getProgressColor(keyResult.output, theme)}
          hideLabel={true}
        />
      </Fieldset>
      <Fieldset m="12px 0" radius={8}>
        <Title order={6}>General</Title>
        <Text c="dimmed" mb={12}>
          Keep your key result definition clear
        </Text>
        <Field name="name" required renderField={renderInput} label="Name" />
        <Field name="description" renderField={renderInput} label="Description" mt="md" />
        <Field
          name="owner"
          required
          renderField={renderCombobox}
          label="Owner"
          mt="md"
          data={users.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))}
          size={6}
        />
      </Fieldset>
      <Fieldset m="12px 0" radius={8}>
        <Title order={6}>Key Result Settings</Title>
        <Text c="dimmed" mb={12}>
          Set specifics for key results
        </Text>
        <SimpleGrid cols={2}>
          <Field name="startingValue" required renderField={renderNumberInput} label="Starting Value" />
          <Field name="targetValue" required renderField={renderNumberInput} label="Target Value" />
          <Field name="startingDate" required renderField={renderDate} label="Starting Date" />
          <Field name="targetDate" required renderField={renderDate} label="Target Date" />
          <Field
            name="unit"
            required
            renderField={renderCreatableCombobox}
            label="Unit"
            options={['£', '$', '€', '%', 'Points']}
          />
          <Field
            name="reviewFrequency"
            required
            renderField={renderCombobox}
            label="Review Frequency"
            data={[
              { value: 'weekly', label: 'Weekly' },
              { value: 'monthly', label: 'Monthly' },
              { value: 'quarterly', label: 'Quarterly' },
              { value: '6months', label: 'Every 6 months' },
            ]}
          />
        </SimpleGrid>
      </Fieldset>
    </>
  );
};

export default KrDetails;
