import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from 'utils/stringUtils';
import { styles } from './styles';
import { useTheme } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles(styles);

export default function AdditionalStoryPoints({ features, labels }) {
  const classes = useStyles();
  const theme = useTheme();

  const original = features.map((feature) => feature.originalStoryCount);
  const additional = features.map((feature) => feature.additionalStories);
  const names = features.map((feature) => feature.name);

  const config = {
    series: [
      {
        name: labels.originalStoryCount,
        data: original,
        color: theme.palette.color.green,
      },
      {
        name: labels.additionalStories,
        data: additional,
        color: theme.palette.color.red,
      },
    ],
    options: {
      theme: {
        mode: theme.palette.mode,
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        background: theme.palette.color.background,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        background: theme.palette.color.background,
      },
      grid: {
        yaxis: { lines: { show: false } },
        xaxis: { lines: { show: true } },
      },
      xaxis: {
        axisBorder: { show: false },
        categories: names,
      },
      yaxis: {
        axisBorder: { show: false },
        title: {
          text: undefined,
        },
      },
      tooltip: {
        theme: theme.palette.mode,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },
    },
  };

  return (
    <div className={classes.root}>
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="bar"
        height={`${features.length * 40 + 100}px`}
      />
    </div>
  );
}
