import { Avatar, Divider, Group, Paper, SimpleGrid, Text, Title, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';
import classes from './Dashboard.module.css';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import KeyResultGraph from './KeyResultGraph';
import LinearWithValueLabel from '../../../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../../../Settings/GraphQL/users';
import { calculateOutcome, getProgressColor, getRagColorForKeyResult, getUserName, outcomeColor } from '../../utils';
import { last, orderBy, sortBy } from 'lodash';
import { date } from '../../../../../utils/helpers';
import { DATE_FORMAT } from '../../../../../utils/timeUtils';

export const DashboardCard = ({ keyResult, isKrDetails }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const owner = users.find((user) => user.id === keyResult.owner) || {};
  const outcome = calculateOutcome(keyResult);

  return (
    <Paper withBorder p={12} radius={8}>
      <Group align="flex-start" justify="space-between" preventGrowOverflow wrap="noWrap">
        <Group align="flex-start" preventGrowOverflow wrap="noWrap">
          <Tooltip zIndex={5000} label={getUserName(owner)}>
            <Avatar variant="filled" size="sm" color={theme.other.colours.purple} className={classes.avatar}>
              {owner.firstName?.charAt(0)}
            </Avatar>
          </Tooltip>
          <div>
            <Text>{keyResult.name}</Text>
            <Text c="dimmed">{date(keyResult.targetDate).toFormat(DATE_FORMAT.date)}</Text>
          </div>
        </Group>
        <Display2 bgColor={getRagColorForKeyResult(keyResult, theme)}>{outcome}%</Display2>
        {/*<Display2 bgColor={theme.other.colours.purple}>Likely</Display2>*/}
      </Group>
      <div style={{ width: '100%', height: isKrDetails ? '50vh' : '30vh' }}>
        <KeyResultGraph keyResult={keyResult} />
      </div>
      <Divider mb={12} />
      <Group justify="space-between">
        <Text c="dimmed" mb={8}>
          Deliverables
        </Text>
        <Text c="dimmed" mb={8}>
          {keyResult.output.toFixed()}% Complete
        </Text>
      </Group>
      <LinearWithValueLabel
        tooltip={`${keyResult.output.toFixed()}% complete`}
        hideLabel={true}
        value={keyResult.output}
        color={getProgressColor(keyResult.output, theme)}
      />
    </Paper>
  );
};

const Dashboard = ({ keyResults }) => {
  const theme = useMantineTheme();

  return (
    <>
      <SimpleGrid cols={keyResults.length > 1 ? 2 : 1}>
        {keyResults.map((keyResult, index) => (
          <DashboardCard keyResult={keyResult} key={index} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default Dashboard;
