import React from 'react';
import { DateTime } from 'luxon';
import { Checkbox } from '@mui/material';
import { Diamond, DiamondActive } from 'assets/images';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import Tooltip from '../Tooltip';

const useStyles = makeStyles(styles);

const Milestone = (props) => {
  const classes = useStyles(props);
  const {
    milestone,
    selectedMilestone,
    handleMouseLeave,
    handleMouseEnter,
    handleMilestoneClick,
    isVisibleTooltip,
    showAllDependencies,
    showObjectives,
    showTeamDependencies,
  } = props;

  const formattedDate = DateTime.fromISO(milestone.date).toLocaleString(DateTime.DATE_SHORT);

  return (
    <Tooltip
      key={milestone.id + showAllDependencies + showObjectives + !!showTeamDependencies}
      visible={showAllDependencies || showObjectives || !!showTeamDependencies ? undefined : isVisibleTooltip}
      content={`${formattedDate} : ${milestone.name}`}>
      <Checkbox
        checked={milestone.id === selectedMilestone}
        icon={<Diamond />}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onClick={handleMilestoneClick}
        checkedIcon={<DiamondActive className={classes.checkedDiamond} />}
        name={milestone.date}
        sx={{ padding: 0 }}
        classes={{
          colorSecondary: classes.checkBoxColorSecondary,
        }}
      />
    </Tooltip>
  );
};

export default Milestone;
