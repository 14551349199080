import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Event } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Button, Card, CardContent, InputAdornment, Tooltip, Grid, Chip } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';

import { UPDATE_IDEA, GET_ALL_IDEAS } from '../../../containers/Form/graphql';
import { defaultPortfolioStateList } from '../../../containers/Settings/Configuration/PortfolioStates/defaultStateList';
import {
  GET_INITIATIVE_STATE_CONFIG,
  GET_PORTFOLIO_STATES_CONFIG,
} from '../../../containers/Settings/GraphQL/configuration';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';
import { GET_VALUE_STREAMS } from '../../../containers/Settings/Streams/graphql';
import {
  DELETE_INITIATIVE,
  INSERT_INITIATIVE,
  UPDATE_INITIATIVE,
  GET_INITIATIVES,
} from '../../../containers/ValueStream/Initiatives/graphql';
import { GET_MILESTONES } from '../../../containers/ValueStream/Milestones/graphql';
import { GET_OKRS } from '../../../containers/ValueStream/OKRs/graphql';
import { GET_PRODUCT_THEMES } from '../../../containers/ValueStream/ProductTheme/graphql';
import { resetDrawerDetails, selectedValueStreamVar } from '../../../reactiveVariables';
import { openDrawer } from '../../../reactiveVariables';
import { BUTTONS } from '../../../utils/formConstants';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField, sortValues } from '../../../utils/helpers';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import {
  AutoCompleteWrapper,
  Field,
  FormContext,
  renderDatePicker,
  renderRTE,
  renderTextField,
  renderCheckbox,
} from '../../Common/FormFieldsHooks';

const InitiativeDetails = (props, ref) => {
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { okrs = [] } = {} } = useQuery(GET_OKRS);
  const { data: { productThemes = [] } = {} } = useQuery(GET_PRODUCT_THEMES);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { portfolioStates = [] } = {} } = useQuery(GET_PORTFOLIO_STATES_CONFIG);
  const { data: { ideas = [] } = {} } = useQuery(GET_ALL_IDEAS);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const [insertInitiative] = useMutation(INSERT_INITIATIVE);
  const [updateInitiative] = useMutation(UPDATE_INITIATIVE);
  const [deleteInitiative] = useMutation(DELETE_INITIATIVE);
  const [updateIdea] = useMutation(UPDATE_IDEA);

  const { element } = props;

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [isPortfolioState, setIsPortfolioState] = useState(element?.isPortfolioState || false);

  const portfolioStatesConfig = portfolioStates.length ? portfolioStates[0].value.columns : defaultPortfolioStateList;

  const portfolioOptions = portfolioStatesConfig.map((state) => {
    return {
      name: state.name,
      id: state.name,
    };
  });

  const idea = ideas.find((idea) => idea?.next_step_feature_or_initiative_id === element?.id);

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const statesForValueStream = initiativeStates
    .filter((state) => state.value_stream === selectedValueStream && state.inUse === true)
    .map((state) => {
      return {
        id: state.name,
        name: state.name,
      };
    });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...element,
      estimatedCompletionDate: element?.endDate ? DateTime.fromISO(element.endDate).toFormat('MM-dd-yy') : '',
      portfolioValueStreams:
        element?.portfolioValueStreams !== null
          ? element?.portfolioValueStreams
          : [
              {
                id: element?.valueStream.id,
              },
            ],
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id');
      setValue('id', props.element.id);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteInitiative({
      variables: { id: initiative.id },
      update: deleteUpdate('initiative'),
    });
    resetDrawerDetails();
  };

  const openThinkTankIdea = () => {
    openDrawer(idea, 'thinkTank');
  };

  const onSubmit = (values) => {
    const initiativeToSave = removeIdField(values);

    let selectedValueStreamToSave;

    if (!isPortfolioState) {
      const stream = valueStreams.filter((stream) => stream.name === initiativeToSave.valueStream);
      selectedValueStreamToSave = stream[0]?.id;
    } else {
      selectedValueStreamToSave = initiativeToSave.portfolioValueStreams || [];
    }

    values.id
      ? updateInitiative({
          variables: {
            initiative: {
              ...initiativeToSave,
              valueStream: isPortfolioState ? null : selectedValueStreamToSave,
            },
            initiativeId: values.id,
          },
        }).then(() => resetDrawerDetails())
      : insertInitiative({
          variables: {
            initiative: {
              ...initiativeToSave,
              valueStream: isPortfolioState ? null : selectedValueStreamToSave,
              rank: initiatives.length + 1,
            },
          },
          update: insertUpdate('initiative'),
        }).then((savedInitiative) => {
          if (element?.createdFromThinkTank) {
            updateIdea({
              variables: {
                ...element.createdFromThinkTank,
                state: 'Accepted',
                next_step_feature_or_initiative_id: savedInitiative.data.initiative.id,
              },
            });
          }

          resetDrawerDetails();
        });
  };

  const initiative = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Grid container justifyContent="flex-start" item xs={12} spacing={2} mb={1}>
              {element?.created_at && (
                <Grid item xs="auto">
                  <Chip
                    avatar={<Event style={{ width: '22px' }} />}
                    sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                    label={`Created: ${DateTime.fromISO(element.created_at).toFormat(DATE_FORMAT.date)}`}
                  />
                </Grid>
              )}

              {idea && (
                <Grid container item xs={'auto'} alignItems="center">
                  <Chip
                    avatar={<LinkIcon style={{ width: '22px' }} />}
                    onClick={openThinkTankIdea}
                    sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                    label={`Idea: ${idea.id}`}
                  />
                </Grid>
              )}
            </Grid>

            <Field
              name="name"
              renderField={renderTextField}
              label="Name"
              size={12}
              required
              dataTestId="InitiativeDetails-name"
            />
            <Field
              name="description"
              renderField={renderRTE}
              label="Description"
              size={12}
              dataTestId="InitiativeDetails-description"
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Owner"
              name="owner"
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
            <Field
              name="plannedRealisationDate"
              renderField={renderDatePicker}
              label="Target Realisation Date"
              size={12}
            />
            <Field
              name="estimatedCompletionDate"
              renderField={renderDatePicker}
              label="Estimated Completion Date"
              size={12}
              disabled
            />

            <Field
              name="isPortfolioState"
              renderField={renderCheckbox}
              label="Portfolio initiative"
              size={15}
              onClick={() => setIsPortfolioState(!isPortfolioState)}
            />

            <AutoCompleteWrapper
              errors={errors}
              idField="portfolioState"
              label="Portfolio state"
              name="portfolio_state"
              multiple={false}
              control={control}
              options={sortBy(portfolioOptions, 'name').filter((stream) => stream.name)}
              size={12}
              disabled={!isPortfolioState}
            />

            {isPortfolioState ? (
              <AutoCompleteWrapper
                errors={errors}
                idField="portfolioValueStreams"
                label="Value streams"
                name="portfolioValueStreams"
                multiple={true}
                control={control}
                changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
                options={sortBy(activeValueStreams, 'name').filter((stream) => stream.name)}
                size={12}
              />
            ) : (
              <AutoCompleteWrapper
                errors={errors}
                idField="valueStream"
                label="Value stream"
                name="valueStream"
                required
                multiple={false}
                control={control}
                changeFunc={(data) => {
                  return data?.map((option) => ({ id: option.id }));
                }}
                options={sortBy(activeValueStreams, 'name').filter((stream) => stream.name)}
                size={12}
              />
            )}

            <AutoCompleteWrapper
              errors={errors}
              idField="name"
              label="State"
              name="initiative_state"
              multiple={false}
              control={control}
              options={statesForValueStream}
              size={12}
              disabled={isPortfolioState}
            />
            <Field
              name="wikiLink"
              renderField={renderTextField}
              label="Wiki Link"
              size={12}
              InputProps={
                initiative.wikiLink
                  ? {
                      endAdornment: (
                        <InputAdornment>
                          <a href={initiative.wikiLink} target="_blank" style={{ cursor: 'pointer' }} rel="noreferrer">
                            <Tooltip title="Open In Wiki">
                              <img src={require('images/confluenceIcon.png')} width="24" height="24" />
                            </Tooltip>
                          </a>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="OKRs"
              name="okrs"
              multiple={true}
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortValues(okrs).filter((okr) => okr.name)}
              size={12}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Milestones"
              name="milestones"
              multiple={true}
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortBy(milestones, 'name').filter((milestone) => milestone.name)}
              size={12}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Product Themes"
              name="productThemes"
              multiple={true}
              nameField="title"
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortBy(productThemes, 'title')}
              size={12}
            />
            <Field name="businessOutcome" renderField={renderTextField} label="Business Outcome" size={12} multiline />
            <Field
              name="leadingIndicator"
              renderField={renderTextField}
              label="Leading Indicator"
              size={12}
              multiline
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete Initiative: ${initiative.name}`}
        text="Are you sure you want to delete this Initiative ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(InitiativeDetails);
