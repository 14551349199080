import { Avatar, Group, Paper, SimpleGrid, Title, useMantineTheme } from '@mantine/core';
import React from 'react';

const Dashboard = () => {
  const theme = useMantineTheme();

  return (
    <>
      <Title order={4}>Dashboard</Title>
      <SimpleGrid cols={2}>
        <Paper withBorder p="0" ml={32} radius={8}>
          <Group>
            <Avatar
              variant="filled"
              size="sm"
              color={theme.other.colours.purple}
              styles={{ root: { color: theme.other.colours.primary } }}>
              A
            </Avatar>
          </Group>
        </Paper>
        <Paper withBorder p="0" ml={32} radius={8}></Paper>
        <Paper withBorder p="0" ml={32} radius={8}></Paper>
        <Paper withBorder p="0" ml={32} radius={8}></Paper>
      </SimpleGrid>
    </>
  );
};

export default Dashboard;
