import React from 'react';
import { useTheme } from '@emotion/react';
import Select from '../Select';

const OwnerSelector = ({ options, value, handleChange }) => {
  // const values = options.map((owner, index) => ({ value: owner, label: owner }));
  const theme = useTheme();

  return (
    <Select
      title="Owner"
      placeholder="All"
      value={value || []}
      onChange={(event, values) => handleChange(values)}
      rootStyle={{ marginLeft: '-6px', marginRight: '-22px' }}
      toggleStyle={{ borderRadius: '5px', backgroundColor: theme.palette.color.paper }}
      defaultValue={[0]}
      multiple={true}
      options={options}
    />
  );
};

export default OwnerSelector;
