import { gql } from '@apollo/client';

export const PORTFOLIO_OBJECTIVE_FIELDS = gql`
  fragment PortfolioObjectiveFields on portfolio_objectives {
    description
    id
    name
    owner
    reviewFrequency
    targetDate
    valueStreamId
    visibility
    keyResults: portfolio_key_results {
      description
      features
      id
      initiatives
      name
      objectiveId
      owner
      trajectory
      reviewFrequency
      unit
      updates
      startingDate
      startingValue
      targetDate
      targetValue
    }
  }
`;

export const INSERT_PORTFOLIO_OBJECTIVE = gql`
  ${PORTFOLIO_OBJECTIVE_FIELDS}
  mutation InsertPortfolioObjective($portfolioObjective: portfolio_objectives_insert_input! = {}) {
    portfolioObjective: insert_portfolio_objectives_one(object: $portfolioObjective) {
      ...PortfolioObjectiveFields
    }
  }
`;

export const UPDATE_PORTFOLIO_OBJECTIVE = gql`
  ${PORTFOLIO_OBJECTIVE_FIELDS}
  mutation UpdatePortfolioObjective(
    $portfolioObjectiveId: Int!
    $portfolioObjective: portfolio_objectives_set_input = {}
  ) {
    portfolioObjective: update_portfolio_objectives_by_pk(
      pk_columns: { id: $portfolioObjectiveId }
      _set: $portfolioObjective
    ) {
      ...PortfolioObjectiveFields
    }
  }
`;

export const DELETE_PORTFOLIO_OBJECTIVE = gql`
  mutation DeletePortfolioObjective($id: Int!) {
    portfolioObjective: delete_portfolio_objectives_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PORTFOLIO_OBJECTIVES = gql`
  ${PORTFOLIO_OBJECTIVE_FIELDS}
  query GetPortfolioObjectives {
    portfolioObjectives: portfolio_objectives(order_by: { id: asc }) {
      ...PortfolioObjectiveFields
    }
  }
`;

export const INSERT_PORTFOLIO_KEY_RESULT = gql`
  mutation InsertPortfolioKeyResult($keyResult: portfolio_key_results_insert_input! = {}) {
    keyResult: insert_portfolio_key_results_one(object: $keyResult) {
      id
    }
  }
`;

export const UPDATE_PORTFOLIO_KEY_RESULT = gql`
  mutation UpdatePortfolioKeyResult($keyResultId: Int!, $keyResult: portfolio_key_results_set_input = {}) {
    update_portfolio_key_results_by_pk(pk_columns: { id: $keyResultId }, _set: $keyResult) {
      id
    }
  }
`;

export const DELETE_PORTFOLIO_KEY_RESULT = gql`
  mutation DeletePortfolioKeyResult($id: Int!) {
    keyResult: delete_portfolio_key_results_by_pk(id: $id) {
      id
    }
  }
`;
