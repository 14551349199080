import { closeSnackbar, openSnackbar, selectedProgrammeVar } from '../../../../reactiveVariables';
import { compact, flatten, includes, isEmpty, some, uniq, uniqBy } from 'lodash';
import { getFeatureBackgroundColor } from '../../../../utils/piColors';
import { useEffect, useRef } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_SELECTED_PI_FULL } from '../../../Settings/ProgramIncrement/graphql';
import { GET_DEPENDENCIES } from '../Dependencies/graphql';
import { useTheme } from '@emotion/react';
import LeaderLine from '@codehardt/leader-line';
import { GET_FEATURES } from '../../../Product/Features/graphql';

export const useLines = (
  elementDragged,
  selectedMilestone,
  showAllDependencies,
  showTeamDependencies,
  filteredFeatures,
  filteredDependencies,
) => {
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { dependencies: allDependencies = [] } = {} } = useQuery(GET_DEPENDENCIES);
  const { data: { features: allFeatures = [] } = {} } = useQuery(GET_FEATURES);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const externalDeps = flatten(
    filteredFeatures.map((feature) =>
      feature.dependencies.map((dep) => allDependencies.find((dependency) => dependency.id === dep.dependencyId)),
    ),
  ).filter(
    (dependency) =>
      dependency &&
      dependency.neededBySprint &&
      dependency.programIncrement &&
      dependency.programmeId !== selectedProgramme,
  );

  const externalFeatures = flatten(
    filteredDependencies.map((dependency) =>
      dependency.targetFeatures.map((targetFeature) => allFeatures.find((feature) => feature.id === targetFeature.id)),
    ),
  ).filter(
    (feature) =>
      feature && feature.committedSprint && feature.programIncrement && feature.programmeId !== selectedProgramme,
  );

  const lines = useRef([]);
  const selectedMilestoneRef = useRef();
  const theme = useTheme();

  selectedMilestoneRef.current = selectedMilestone;

  useEffect(() => {
    moveLinesToBody();
    lines.current.forEach((line) => line.remove());
    lines.current = [];

    return () => {
      moveLinesToBody();
      lines.current.forEach((line) => line.remove());
      lines.current = [];
    };
  }, [selectedPiFull]);

  const deleteLine = () => {
    closeSnackbar();
    if (!isEmpty(lines.current)) {
      lines.current.forEach((line) => line.hide('draw'));
    }
  };

  const clearLines = () => {
    moveLinesToBody();
    lines.current.forEach((line) => line.remove());
    lines.current = [];
  };

  const drawLineFromDep = (dependency) => {
    if (elementDragged) return;
    openSnackbar(dependency, 'dependency');
    if (selectedMilestone || showAllDependencies || showTeamDependencies) return;

    lines.current = drawLines([dependency]);
  };

  const drawLine = (feature) => {
    if (elementDragged) return;
    const featureDependencies = allDependencies.filter(
      (dependency) => some(dependency.targetFeatures, ['id', feature.id]) && dependency.neededBySprint,
    );

    openSnackbar({ ...feature, dependencies: featureDependencies }, 'feature');
    if (selectedMilestone || showAllDependencies || showTeamDependencies) return;
    lines.current = drawLines(featureDependencies);
  };

  const drawAllLines = () => {
    setTimeout(() => {
      const featureIdList = filteredFeatures.map((feature) => feature.id);
      const externalIds = externalFeatures.map((feature) => feature.id);
      const dependenciesToDraw = filteredDependencies.filter(
        (dependency) =>
          dependency.neededBySprint &&
          some(
            dependency.targetFeatures,
            (feature) => includes(featureIdList, feature.id) || includes(externalIds, feature.id),
          ),
      );
      const externalDepToDraw = externalDeps.filter(
        (dependency) =>
          dependency.neededBySprint &&
          some(
            dependency.targetFeatures,
            (feature) => includes(featureIdList, feature.id) || includes(externalIds, feature.id),
          ),
      );

      lines.current = drawLines([...dependenciesToDraw, ...externalDepToDraw]);
    }, 300);
  };

  const drawTeamLines = (teamId) => {
    setTimeout(() => {
      const featureIdList = filteredFeatures
        .filter((feature) => feature.teamId === teamId)
        .map((feature) => feature.id);
      const dependenciesToDraw = filteredDependencies.filter(
        (dependency) =>
          dependency.neededBySprint &&
          (some(dependency.targetFeatures, (feature) => includes(featureIdList, feature.id)) ||
            dependency.teamId === teamId),
      );

      lines.current = drawLines(dependenciesToDraw);
    }, 300);
  };

  const drawLinesForMilestone = (milestoneId) => {
    setTimeout(() => {
      if (milestoneId !== selectedMilestoneRef.current) return;
      const externalIds = externalFeatures
        .filter((feature) => some(feature.milestones, (milestone) => milestone.id === milestoneId))
        .map((feature) => feature.id);
      const featureIdList = filteredFeatures
        .filter((feature) => some(feature.milestones, (milestone) => milestone.id === milestoneId))
        .map((feature) => feature.id);
      const dependenciesForMilestone = filteredDependencies.filter(
        (dependency) =>
          dependency.neededBySprint &&
          some(
            dependency.targetFeatures,
            (feature) => includes(featureIdList, feature.id) || includes(externalIds, feature.id),
          ),
      );
      const externalDepToDraw = externalDeps.filter(
        (dependency) =>
          dependency.neededBySprint &&
          some(
            dependency.targetFeatures,
            (feature) => includes(featureIdList, feature.id) || includes(externalIds, feature.id),
          ),
      );

      lines.current = drawLines(
        [...dependenciesForMilestone, ...externalDepToDraw],
        [...externalIds, ...featureIdList],
      );
    }, 300);
  };

  const drawLines = (dependencyLists, featureIdList) => {
    if (!isEmpty(lines.current)) {
      moveLinesToBody();
      lines.current.forEach((line) => line.remove());
      lines.current = [];
    }

    if (isEmpty(dependencyLists)) return [];

    const newLines = compact(
      uniqBy(dependencyLists, 'id')
        .map((dep) =>
          dep.targetFeatures
            .filter((feature) => !featureIdList || featureIdList.includes(feature.id))
            .map((feature) => {
              const end = document.getElementById(`f${feature.id}`);
              const start = document.getElementById(`d${dep.id}`);
              if (end && start) {
                const line = new LeaderLine(start, end, { color: getFeatureBackgroundColor(dep, theme), hide: true });
                line.show('draw');
                return line;
              }
            }),
        )
        .flat(),
    );
    moveLinesToWrapper();
    return newLines;
  };

  const moveLinesToWrapper = () => {
    const wrapper = document.getElementById('wrapper');
    wrapper.style.transform = 'none';
    const wrapperRect = wrapper.getBoundingClientRect();
    const renderedLines = document.querySelectorAll('.leader-line');
    wrapper.style.transform =
      'translate(-' + (wrapperRect.left + pageXOffset) + 'px, -' + (wrapperRect.top + pageYOffset) + 'px)';
    renderedLines.forEach((line) => document.getElementById('wrapper').appendChild(line));
  };

  const moveLinesToBody = () => {
    const renderedLines = document.querySelectorAll('.leader-line');
    renderedLines.forEach((line) => document.body.appendChild(line));
  };

  return {
    deleteLine,
    drawLine,
    drawLines,
    drawAllLines,
    drawLineFromDep,
    drawLinesForMilestone,
    drawTeamLines,
    clearLines,
  };
};
