import * as React from 'react';

const UpArrow = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="arrows chevs">
        <path
          id="Right"
          d="M12.4097 6.93575C12.2324 7.10978 12.0204 7.20014 11.7735 7.20683C11.5267 7.21352 11.321 7.12986 11.1564 6.95583L8.89673 4.54619L8.89673 13.7229C8.89673 13.9906 8.81128 14.2149 8.64038 14.3956C8.46948 14.5763 8.25744 14.6667 8.00426 14.6667C7.75107 14.6667 7.53903 14.5763 7.36813 14.3956C7.19723 14.2149 7.11178 13.9906 7.11178 13.7229L7.11178 4.54619L4.81414 6.97591C4.64957 7.14994 4.44702 7.23361 4.20649 7.22691C3.96597 7.22022 3.75709 7.12986 3.57986 6.95583C3.41529 6.76841 3.33301 6.54418 3.33301 6.28314C3.33301 6.02209 3.41529 5.80456 3.57986 5.63053L7.37763 1.63454C7.46624 1.54083 7.56435 1.46721 7.67195 1.41366C7.77956 1.36011 7.89032 1.33334 8.00426 1.33334C8.11819 1.33334 8.22896 1.36011 8.33656 1.41366C8.44416 1.46721 8.54227 1.54083 8.63089 1.63454L12.4097 5.61045C12.5869 5.79786 12.6723 6.01875 12.666 6.2731C12.6597 6.52745 12.5742 6.74833 12.4097 6.93575Z"
          fill="#50A771"
        />
      </g>
    </svg>
  );
};

export default UpArrow;
