import * as React from 'react';

function CopySvg(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66663 3.59967C2.66663 2.39857 3.60278 1.33301 4.87555 1.33301H8.2283C8.92844 1.33301 9.63775 1.59421 10.1735 2.1269L10.18 2.13334L12.591 4.59704C13.1255 5.13067 13.397 5.91232 13.3206 6.63775V12.3997C13.3206 13.6008 12.3844 14.6663 11.1117 14.6663H4.87555C3.60278 14.6663 2.66663 13.6008 2.66663 12.3997V3.59967ZM4.87555 2.66634C4.4049 2.66634 3.99996 3.06744 3.99996 3.59967V12.3997C3.99996 12.9319 4.4049 13.333 4.87555 13.333H11.1117C11.5823 13.333 11.9873 12.9319 11.9873 12.3997V6.59967C11.9873 6.57187 11.989 6.5441 11.9925 6.51651C12.0336 6.18938 11.9042 5.79435 11.6474 5.53911L11.641 5.53267L9.23056 3.06956C8.96198 2.80414 8.59969 2.66634 8.2283 2.66634H4.87555Z"
        fill="black"
      />
    </svg>
  );
}

export default CopySvg;
