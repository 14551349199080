import {
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  Paper,
  rgba,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import SvgHexagone from '../../../../assets/images/Hexagon';
import {
  Field,
  FormContext,
  renderCombobox,
  renderCreatableCombobox,
  renderDate,
  renderInput,
  renderNumberInput,
} from '../../../../components/Common/FormFieldsHooks/MantineField';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import { IconArrowRight } from '@tabler/icons-react';
import {
  DELETE_PORTFOLIO_KEY_RESULT,
  DELETE_PORTFOLIO_OBJECTIVE,
  GET_PORTFOLIO_OBJECTIVES,
  INSERT_PORTFOLIO_KEY_RESULT,
  INSERT_PORTFOLIO_OBJECTIVE,
  UPDATE_PORTFOLIO_KEY_RESULT,
  UPDATE_PORTFOLIO_OBJECTIVE,
} from '../graphql';
import { isEmpty, mapValues, omitBy, sortBy } from 'lodash';
import { showNotification } from '@mantine/notifications';
import { loggedInUserVar, resetDrawerDetails } from '../../../../reactiveVariables';

const CreateUpdateModal = ({ vsColor, update, open, close, keyResult }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const [updateKeyResult] = useMutation(UPDATE_PORTFOLIO_KEY_RESULT, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const user = useReactiveVar(loggedInUserVar);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { unit: keyResult.unit, ...update } });

  const onSubmit = (values) => {
    const updates = sortBy(
      !isEmpty(update)
        ? [...keyResult.updates.filter((u) => u !== update), { ...values, owner: user.id }]
        : [...(keyResult.updates || []), { ...values, owner: user.id }],
      'date',
    );

    updateKeyResult({
      variables: { keyResult: { updates }, keyResultId: keyResult.id },
    }).then(() => {
      showNotification({
        title: 'New Updated Added',
        message: `Key result updated with value ${values.value}`,
      });
      close();
    });
  };

  return (
    <Modal.Root
      style={{
        borderRadius: '12px',
        backgroundColor: theme.other.colours.background,
        boxShadow: '0px 2px 2px 0px #0000000D',
      }}
      opened={open}
      onClose={close}
      size="600px"
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header style={{ alignItems: 'flex-start' }}>
          <Modal.Title>
            <Group wrap="nowrap" align="flex-start">
              <ThemeIcon
                variant="outline"
                radius="xl"
                style={{ marginTop: '4px', backgroundColor: rgba(vsColor, 0.15) }}
                color={vsColor}>
                <SvgHexagone width={18} height={18} />
              </ThemeIcon>
              <div>
                <Title order={4}>Update Progress</Title>
                <Text size="sm">Add the latest value of the Key Result you’re measuring.</Text>
              </div>
            </Group>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Paper bg={theme.other.colours.innerRow} p={8} m="0 16px 0 16px">
          <Text size="sm" c="dimmed">
            Key Result:{' '}
            <Text span c={theme.other.colours.primary}>
              {keyResult.name}
            </Text>
          </Text>
        </Paper>
        <Modal.Body p={16} style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext.Provider value={{ control, errors }}>
              <Grid>
                <Field name="description" data-autofocus required renderField={renderInput} label="Description" />
                <Field
                  name="date"
                  required
                  renderField={renderDate}
                  minDate={new Date(keyResult.startingDate)}
                  maxDate={new Date(keyResult.targetDate)}
                  label="Update Date"
                  size={5}
                />
                <Field name="value" required renderField={renderNumberInput} label="Value" size={5} />
                <Field
                  name="unit"
                  disabled
                  renderField={renderCreatableCombobox}
                  label="Unit"
                  options={['£', '$', '€', '%', 'Points']}
                  size={2}
                />
              </Grid>
            </FormContext.Provider>
          </form>
          <Divider m="12px -12px" />
          <Group justify="flex-end">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="light"
              radius={8}
              color={theme.other.colours.buttonIcon}
              rightSection={<IconArrowRight size={16} />}>
              {isEmpty(update) ? 'Add' : 'Edit'} Update
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateUpdateModal;
