import { NetworkError } from 'utils/network-error';
import keycloak from '../../keycloak';

export function queryString(queryParams) {
  const tokenizer = (tokens, key) => {
    const value = queryParams[key];
    if (value) {
      tokens.push(key + '=' + encodeURIComponent(value));
    }
    return tokens;
  };
  const queryString = Object.keys(queryParams).reduce(tokenizer, []).join('&');

  return queryString.length > 0 ? '?' + queryString : '';
}

class Api {
  constructor() {
    this.request = this.request.bind(this);
  }

  setup({ uri }) {
    this.uri = uri;
  }

  url(pathname) {
    return this.uri + pathname;
  }

  handleNetworkError(error) {
    throw new NetworkError(error.code, error.message);
  }

  handleErrors(status, json) {
    switch (status) {
      case 403:
        throw new NetworkError(status, `${json.message}`);
      default:
        throw new NetworkError(status, `${json.message}`);
    }
  }

  parseResponse(response) {
    return response.text().then((text) => {
      try {
        const json = JSON.parse(text);
        return {
          status: response.status,
          json,
        };
      } catch (err) {
        return {
          status: response.status,
          json: text,
        };
      }
    });
  }

  /**
   * Wrapper for fetch() providing
   * generic response and error handling/parsing
   * If no options param is provided, method defaults to GET
   *
   * @export
   * @param {String} partialUrl - partial api url
   * @param {Object} [{ method = 'GET', ...options }={}] - same as fetch options
   * @throws {Object.<{ message: string, violations: Object }>}
   * @returns {Promise<{ status: number, json: Object }>}
   */
  request(pathname, { method = 'GET', ...options } = {}) {
    const token = keycloak.token;
    return fetch(this.url(pathname), {
      method,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      },
      ...options,
    })
      .catch(this.handleNetworkError)
      .then(this.parseResponse)
      .then(({ status, json }) => {
        if (status >= 400) {
          return this.handleErrors(status, json);
        } else {
          return json;
        }
      });
  }
}

export default Api;
