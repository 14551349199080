import React from 'react';
import PropTypes from 'prop-types';

import { styles } from './styles';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(styles);

const SectionWrapper = (props) => {
  const { children } = props;

  const classes = useStyles(props);

  return <section className={classes.section}>{children}</section>;
};

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionWrapper;
