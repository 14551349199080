function createData(item, roles) {
  const { email, username, firstName, lastName, role } = item;

  return {
    name: `${firstName} ${lastName}`,
    username: username,
    email,
    role: roles.find((r) => r.id === role)?.description || 'Read-Only',
  };
}

export function getRowsData(data, roles) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, roles));
  }
  return rows;
}
