import { DateTime } from 'luxon';
import { DATE_FORMAT } from 'utils/timeUtils';
import { some } from 'lodash';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';
import React from 'react';

function createData(item, features) {
  const { id, name, date } = item;
  const milestoneFeatures = features?.filter((feature) => some(feature.milestones, (milestone) => milestone.id === id));

  const deliveryData = milestoneFeatures.reduce(
    (total, feature) => {
      total.delivered += feature.metrics?.storyCount?.done || 0;
      total.total += feature.metrics?.storyCount?.total || 0;
      return total;
    },
    { delivered: 0, total: 0 },
  );
  const progress = deliveryData.total ? Math.round((deliveryData.delivered / deliveryData.total) * 100) : 0;

  return {
    id,
    name,
    progress: (
      <LinearWithValueLabel
        data-value={progress}
        value={progress.toFixed()}
        tooltip={deliveryData.total > 0 && `Completed Stories: ${deliveryData.delivered} / ${deliveryData.total}`}
      />
    ),
    date: date ? DateTime.fromISO(date).toFormat(DATE_FORMAT.date) : '',
    expand: milestoneFeatures.length > 0,
  };
}

export function getRowsData(data, features) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, features));
  }
  return rows;
}
