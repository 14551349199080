import * as React from 'react';

function SvgProduct(props) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1" y="1" width="20" height="20" rx="2" fill="#93A0B5" {...props} />
      <path
        d="M11.5 16.686V5.314L16 7.593v6.814l-4.5 2.28zm4.61-2.335.09.179-.09-.178z"
        stroke="#fff"
        fill={props.fill}
      />
      <rect x="8.25" y="5.429" width=".917" height="11.143" rx=".3" fill="#fff" />
      <rect x="5.5" y="8.214" width=".917" height="6.5" rx=".3" fill="#fff" />
    </svg>
  );
}

export default SvgProduct;
