import * as React from 'react';

function ClosePlanner(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.002a.75.75 0 0 1-.75.75H9.84l2.946 2.818a.75.75 0 0 1-1.037 1.084l-4.296-4.11a.75.75 0 0 1 0-1.084l4.296-4.11a.75.75 0 0 1 1.037 1.085L9.84 11.252h9.409a.75.75 0 0 1 .75.75zm-8.269 8.002a.75.75 0 1 0 0-1.5h-3.98a2.25 2.25 0 0 1-2.25-2.25V7.75A2.25 2.25 0 0 1 7.75 5.5h3.443a.75.75 0 0 0 0-1.5H7.75A3.75 3.75 0 0 0 4 7.75v8.504a3.75 3.75 0 0 0 3.75 3.75h3.981z"
        fill="#F2F2F3"
      />
    </svg>
  );
}

export default ClosePlanner;
