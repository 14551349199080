import * as React from 'react';

function SvgOnTrack(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.1"
        d="M16.5002 9C16.5002 13.4183 15.5 17 14.2662 17C13.0324 17 12.0322 13.4183 12.0322 9C12.0322 4.58172 13.0324 1 14.2662 1C15.5 1 16.5002 4.58172 16.5002 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4785 4.2C10.3598 4.2 11.0742 3.48366 11.0742 2.6C11.0742 1.71634 10.3598 1 9.4785 1C8.59722 1 7.8828 1.71634 7.8828 2.6C7.8828 3.48366 8.59722 4.2 9.4785 4.2ZM4.5539 5.00585L3.53788 5.64252C3.03985 5.95459 2.64694 6.48546 2.99429 6.96027C3.02609 7.00373 3.06005 7.04401 3.09567 7.07972C3.11082 7.09492 3.1268 7.10943 3.14349 7.1233C3.62867 7.52636 4.3055 7.17746 4.80102 6.78727C4.9667 6.65681 5.14387 6.53277 5.32945 6.43972C5.84008 6.18372 6.60601 6.11972 6.92515 6.11972L5.64859 8.67972C5.13797 9.70372 5.32945 10.3864 5.64859 10.5997L6.60591 11.2397L8.43351 12.385C8.84494 12.6428 8.99347 13.172 8.77653 13.6072L8.01491 15.1348C7.77674 15.6126 8.01575 16.1911 8.52108 16.36C9.06214 16.5407 9.64203 16.2717 9.82501 15.7301C10.0294 15.125 10.2616 14.3902 10.4533 13.7837C10.5768 13.3928 10.6835 13.0553 10.7551 12.84C10.953 12.2445 10.6519 11.9951 10.3579 11.7639L10.2875 11.7084L10.2875 11.7084C10.1457 11.5964 10.0036 11.4842 9.85362 11.384L8.62314 10.5614C8.37632 10.3964 8.2754 10.0827 8.37955 9.8042L9.08328 7.92255C9.11738 7.83136 9.23652 7.81025 9.29973 7.88419L10.4851 9.27084C10.8589 9.70808 11.4045 9.95972 11.9788 9.95972H12.9888C13.5176 9.95972 13.9462 9.52991 13.9462 8.99972C13.9462 8.46953 13.5176 8.03972 12.9888 8.03972H12.3307C11.7509 8.03972 11.2156 7.72823 10.928 7.22351L10.4037 6.30362C10.2143 5.97121 9.95896 5.68122 9.6535 5.45152C9.11652 5.04773 8.50606 4.74712 7.84839 4.61265C7.59303 4.56044 7.35824 4.51986 7.24472 4.51973C7.09452 4.51956 6.66107 4.51955 6.24447 4.51959C5.64664 4.51964 5.06088 4.68817 4.5539 5.00585ZM4.37226 11.88L5.01054 10.6C5.107 10.6322 5.13175 10.7299 5.16346 10.8549C5.19261 10.9699 5.22765 11.1081 5.32968 11.24C5.38544 11.3121 5.48435 11.3736 5.5879 11.4379C5.77069 11.5515 5.96796 11.6741 5.96796 11.88C5.96796 12.648 4.90416 13.6933 4.37226 13.8H2.13828C1.81914 13.8 1.5 13.48 1.5 12.84C1.5 12.328 1.92552 11.9867 2.13828 11.88H4.37226Z"
      />
    </svg>
  );
}

export default SvgOnTrack;
