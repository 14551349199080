import { gql } from '@apollo/client';

export const MILESTONE_FIELDS = gql`
  fragment MilestoneFields on milestones {
    date
    description
    id
    name
    programIncrement
    valueStream
  }
`;

export const INSERT_MILESTONE = gql`
  ${MILESTONE_FIELDS}
  mutation InsertMilestone($milestone: milestones_insert_input! = {}) {
    milestone: insert_milestones_one(object: $milestone) {
      ...MilestoneFields
    }
  }
`;

export const UPDATE_MILESTONE = gql`
  ${MILESTONE_FIELDS}
  mutation UpdateMilestone($milestoneId: Int!, $milestone: milestones_set_input = {}) {
    update_milestones_by_pk(pk_columns: { id: $milestoneId }, _set: $milestone) {
      ...MilestoneFields
    }
  }
`;

export const DELETE_MILESTONE = gql`
  mutation DeleteMilestone($id: Int!) {
    milestone: delete_milestones_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_MILESTONES = gql`
  ${MILESTONE_FIELDS}
  query GetMilestones($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    milestones: milestones(where: { valueStream: { _eq: $valueStream } }, order_by: { id: asc }) {
      ...MilestoneFields
    }
  }
`;

export const GET_MILESTONES_WITH_FEATURES = gql`
  ${MILESTONE_FIELDS}
  query GetMilestonesWithFeatures($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    milestones: milestones(where: { valueStream: { _eq: $valueStream } }, order_by: { date: asc }) {
      ...MilestoneFields
      features: features_milestones {
        feature {
          ragStatus
          status
          id
        }
      }
    }
  }
`;

export const GET_MILESTONES_FOR_PI = gql`
  ${MILESTONE_FIELDS}
  query GetMilestones($pi: Int) {
    selectedPI @client @export(as: "pi")
    milestones: milestones(where: { programIncrement: { _eq: $pi } }) {
      ...MilestoneFields
    }
  }
`;
