import React, { useState } from 'react';
import { Divider, Group, Stack, Text, Checkbox, Box, Title, Container, Grid } from '@mantine/core';
import { Wrapper, Button, FormContainer, FormTitle, CompanyNameBadge } from '../styles';
import config from 'Config';
import { color } from '../../../shared/styles/color';
import { useNavigate } from 'react-router-dom';
import { GET_PRODUCT_THEMES } from '../../ValueStream/ProductTheme/graphql';
import { GET_VALUE_STREAMS } from '../../Settings/Streams/graphql';
import { useQuery, useMutation } from '@apollo/client';
import {
  Field,
  renderCreatableCombobox,
  renderInput,
  FormContext,
  renderInputTextArea,
} from '../../../components/Common/FormFieldsHooks/MantineField';
import { useForm } from 'react-hook-form';
import { INSERT_NEW_IDEA, GET_IDEAS_BY_USER_ID, GET_ALL_IDEAS } from '../graphql';

const IdeaFormPage = ({ user }) => {
  const navigate = useNavigate();

  const { data: { productThemes = [] } = {} } = useQuery(GET_PRODUCT_THEMES);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { refetch: refetchIdeas } = useQuery(GET_ALL_IDEAS);

  const { refetch: refetchIdeasByUserId } = useQuery(GET_IDEAS_BY_USER_ID, {
    variables: { userId: user.id },
  });

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const [insertNewIdea] = useMutation(INSERT_NEW_IDEA);

  const [selectedThemesValues, setSelectedThemesValues] = useState([]);
  const [selectedStreamsValues, setSelectedStreamsValues] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const hostname = location.hostname;
  const company = config[hostname];

  const handleThemesCheckboxChange = (theme) => {
    const { id, title } = theme;

    setSelectedThemesValues((prevSelectedThemesValues) => {
      const exists = prevSelectedThemesValues.some((val) => val.id === id);

      if (exists) {
        return prevSelectedThemesValues.filter((val) => val.id !== id);
      } else {
        return [...prevSelectedThemesValues, { id, title }];
      }
    });
  };

  const handleStreamsCheckboxChange = (stream) => {
    const { id, name } = stream;

    setSelectedStreamsValues((prevSelectedStreamsValues) => {
      const exists = prevSelectedStreamsValues.some((val) => val.id === id);

      if (exists) {
        return prevSelectedStreamsValues.filter((val) => val.id !== id);
      } else {
        return [...prevSelectedStreamsValues, { id, name }];
      }
    });
  };

  const onSubmit = (values) => {
    const formData = {
      ...values,
      themes: selectedThemesValues,
      valueStreams: selectedStreamsValues,
      creator: `${user.firstName} ${user.lastName}`,
      priority: 'No priority',
    };

    insertNewIdea({
      variables: {
        userId: user.id,
        idea: formData,
        next_step: 'Unassigned',
      },
    }).then(() => {
      refetchIdeasByUserId();
      refetchIdeas();
    });

    navigate(`/submit-idea/submitted`);
  };

  return (
    <Wrapper>
      <FormContainer>
        <Text c="dimmed" size="14px" mb={30} mr={5}>
          Idea for:
          <CompanyNameBadge color={'#FF4189'} bgColor="#FF4189" variant="light">
            {company.name}
          </CompanyNameBadge>
        </Text>

        <FormTitle>Submit a new idea</FormTitle>
        <Text c="dimmed" size="12px">
          Outline a problem, suggest an improvement, or propose an innovative idea for the organisation.
        </Text>

        <Divider mb={30} mt={30} />

        <Container w="100%" style={{ maxWidth: '100%' }} p={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext.Provider value={{ control, errors }}>
              <Grid>
                <Group w={'100%'} mb={10} gap={0}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    General
                  </Title>
                  <Field
                    name="name"
                    required
                    renderField={renderInput}
                    label="Title"
                    placeholder="Add a title"
                    style={{ width: '100%' }}
                  />

                  <Field
                    name="description"
                    required
                    renderField={renderInputTextArea}
                    label="Description"
                    placeholder="Give more context"
                    style={{ width: '100%' }}
                  />
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Grid>
                <Group w={'100%'} mb={10} display={'block'}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    Themes
                  </Title>
                  <Text c="dimmed" size="12px" ml={5} mb={10}>
                    Define which of the key priorities your request most aligns to.
                  </Text>

                  <Box
                    style={{
                      border: errors.themes ? '1px solid red' : `1px solid #E8E8E8`,
                      padding: '16px',
                      borderRadius: '4px',
                      marginTop: '20px',
                    }}>
                    <Stack spacing="md">
                      {productThemes.map((theme) => (
                        <Box
                          key={theme.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <span style={{ fontSize: '12px', color: color.secondary }}>{theme.title}</span>
                          <Checkbox
                            checked={selectedThemesValues.some((val) => val.id === theme.id)}
                            onChange={() => handleThemesCheckboxChange(theme)}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Grid>
                <Group w={'100%'} mb={10} display={'block'}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    Problem
                  </Title>

                  <Field
                    name="problem"
                    required
                    renderField={renderInputTextArea}
                    label="When we deliver it, what problem do we solve?"
                    placeholder="When [specific situation], this happens [negative impact]. This is a problem [for user, company, department] because..."
                    style={{ width: '100%', marginBottom: '0px' }}
                    mb={0}
                  />
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Grid>
                <Group w={'100%'} mb={10} display={'block'}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    Benefits
                  </Title>
                  <Text c="dimmed" size="12px" ml={5} mb={10}>
                    What benefits the business with this request.
                  </Text>

                  <Field
                    name="benefitMeasure"
                    required
                    renderField={renderInputTextArea}
                    label="How does the business benefit?"
                    placeholder="What are the quantifiable time savings/cost savings/ revenue generating benefits that solving this problem would likely bring?"
                    style={{ width: '100%' }}
                  />

                  <Field
                    name="successMeasure"
                    required
                    renderField={renderInputTextArea}
                    label="How will we know it’s successful?"
                    placeholder="What changes.."
                    style={{ width: '100%' }}
                  />

                  <Field
                    name="unitMeasure"
                    required
                    renderField={renderCreatableCombobox}
                    label="What will we measure?"
                    options={['£', '$', '€', '%', 'Points']}
                  />
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Grid>
                <Group w={'100%'} mb={10} display={'block'}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    Other
                  </Title>
                  <Text c="dimmed" size="12px" ml={5} mb={10}>
                    Add any other additional comments or factors
                  </Text>

                  <Field
                    name="otherIdeas"
                    required
                    renderField={renderInputTextArea}
                    label="What other ideas did you consider before deciding this?"
                    placeholder="Any other ideas..."
                    style={{ width: '100%' }}
                  />

                  <Field
                    name="teamNeeded"
                    required
                    renderField={renderInput}
                    label="What teams would you need?"
                    placeholder="Development, Data, Technology, Business, etc"
                    style={{ width: '100%' }}
                  />
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Grid>
                <Group w={'100%'} mb={10} display={'block'}>
                  <Title order={6} ml={5} mb={5} fw={500}>
                    Value streams
                  </Title>
                  <Text c="dimmed" size="12px" ml={5} mb={10}>
                    Which value stream do you propose this delivery sits with?
                  </Text>

                  <Box
                    style={{
                      border: errors.streams ? `1px solid red` : `1px solid #E8E8E8`,
                      padding: '16px',
                      borderRadius: '4px',
                      marginTop: '20px',
                    }}>
                    <Stack spacing="md">
                      {activeValueStreams.map((stream) => (
                        <Box
                          key={stream.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <span style={{ fontSize: '12px', color: color.secondary }}>{stream.name}</span>
                          <Checkbox
                            checked={selectedStreamsValues.some((val) => val.id === stream.id)}
                            onChange={() => handleStreamsCheckboxChange(stream)}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Group>
              </Grid>

              <Divider mb={30} mt={30} />

              <Title order={6} fw={500}>
                Submit for review
              </Title>
              <Text c="dimmed" size="12px" mb={10}>
                Fill in all the sections, double check your information, click submit and we will take a look!
              </Text>

              <Group justify="flex-end" mt={40}>
                <Button
                  type="submit"
                  variant="light"
                  radius={8}
                  style={{ width: '114px', height: '41px', color: 'white', backgroundColor: color.companyColor }}>
                  Submit form
                </Button>
              </Group>
            </FormContext.Provider>
          </form>
        </Container>
      </FormContainer>
    </Wrapper>
  );
};

export default IdeaFormPage;
