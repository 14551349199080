import { styled } from '@mui/system';
import { lightColors, color } from '../../shared/styles/color';
import { Badge } from '@mantine/core';
import { alpha } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
}));

export const Center = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
}));

export const CompanyName = styled('div')(() => ({
  fontSize: '16px',
  padding: '12px 16px',
  backgroundColor: 'white',
  borderRadius: '8px',
  width: 'max-content',
  margin: '100px auto 50px',
}));

export const TopSection = styled('div')(() => ({
  maxWidth: '473px',
  textAlign: 'center',
  margin: '0 auto',
}));

export const FormHeader = styled('h1')(() => ({
  fontSize: '24px',
  fontWeight: 700,
  color: color.companyColor,
  textAlign: 'center',
}));

export const FormTitle = styled('h1')(() => ({
  fontSize: '21px',
  fontWeight: 500,
  margin: 0,
}));

export const Button = styled('button')(() => ({
  width: '335px',
  height: '42px',
  borderRadius: '8px',
  border: `1px solid ${lightColors.tileBorder}`,
  backgroundColor: 'white',
  fontSize: '14px',
  cursor: 'pointer',
}));

export const FormContainer = styled('div')(() => ({
  width: '580px',
  margin: '80px auto 50px',
}));

export const BottomSection = styled('div')(() => ({
  width: '920px',
  textAlign: 'center',
  margin: '0 auto 50px',
}));

export const UserIdeasContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
}));

export const IdeaCard = styled('div')(() => ({
  border: `1px solid ${lightColors.tileBorder}`,
  padding: '12px',
  width: '100%',
  height: '62px',
  maxWidth: '290px',
  backgroundColor: 'white',
  borderRadius: '8px',
  textAlign: 'left',
  position: 'relative',
}));

export const CompanyNameBadge = styled((props) => <Badge radius="4px" {...props} />)(({ theme, ...props }) => ({
  backgroundColor: `${alpha(props.bgColor, 0.15)} !important`,
  color: `${props.bgColor} !important`,
  border: `1px solid ${props.bgColor} !important`,
  height: '20px',
  padding: '2px',
  marginLeft: '8px',
  fontWeight: `100!important`,
}));

export const BoxCountContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  marginTop: '110px',
  marginBottom: '110px',
}));

export const BoxCount = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

export const Count = styled('div')(() => ({
  backgroundColor: color.grey,
  borderRadius: '8px',
  width: '48px',
  height: '64px',
  fontSize: '24px',
  fontWeight: 700,
  padding: '15px 8px',
}));
