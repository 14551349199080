export const headCells = [
  {
    id: 'rank', // Unique ID for the column... im using this as a placeholder until rank is in hasura
    label: 'Rank', // Column header label... literally just the title
    width: 1.3, // Keeping this the same as camille's columns...
  },
  {
    id: 'action',
    label: '',
    width: 1,
  },
  {
    id: 'name',
    label: 'Name',
    width: 3,
  },
  {
    id: 'featuresIn',
    label: 'Features',
    width: 2,
  },
  {
    id: 'initiative_state',
    label: 'State',
    width: 2,
  },
  {
    id: 'progress',
    label: 'Progress',
    width: 2.5,
  },
  {
    id: 'plannedRealisationDate',
    label: 'Target Realisation',
    width: 3,
  },
  {
    id: 'estimatedCompletionDate',
    label: 'Estimated Completion',
    width: 2.5,
  },
  {
    id: 'ragStatus',
    label: 'RAG Status',
    width: 2,
  },
];
