import React from 'react';
import {
  Dialog,
  Group,
  Paper,
  Text,
  useMantineTheme,
  Alert,
  rgba,
  Stack,
  Badge,
  Divider,
  ThemeIcon,
} from '@mantine/core';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { getStatusColorMantine } from '../../../utils/piColors';
import { values } from 'lodash';
import SvgWarning from '../../../assets/images/Warning';
import SvgHexagone from '../../../assets/images/Hexagon';

export const DialogFeaturePopup = ({ feature, setIsFeatureHovered, teams }) => {
  const theme = useMantineTheme();
  const color = getStatusColorMantine(feature || {}, theme);

  return (
    <Dialog
      opened={true}
      title="Detailed Information"
      style={{ width: '276px', border: '1px solid #EEE7EA', position: 'relative' }}
      withBorder
      size="lg"
      onClose={() => setIsFeatureHovered(false)}
      onMouseEnter={() => setIsFeatureHovered(feature.id)}
      onMouseLeave={() => setIsFeatureHovered(null)}
      radius="md">
      <Text size="sm" mb="xs" fw={500} pr={8} pl={8}>
        {feature.name}
      </Text>
      <Text size="sm" mb="xs" fw={500} pr={8} pl={8}>
        Status: <span style={{ fontWeight: '100' }}>{feature.status}</span>
      </Text>
      {feature.epic_id && (
        <Group justify="space-between" grow mb="xs">
          <Text size="sm" fw={600} pr={8} pl={8}>
            {feature.epic_id}
          </Text>
          <LinearWithValueLabel color={color} value={feature?.metrics?.percentageDoneStories || 0} />
        </Group>
      )}

      {feature.errors &&
        values(feature.errors).map((error, index, array) => (
          <Alert
            icon={<SvgWarning />}
            color={theme.other.colours.red}
            p={4}
            mb={index === array.length - 1 ? 'xs' : 4}
            title={error}
            key={index}
          />
        ))}

      {feature.metrics?.teams?.length > 1 && (
        <Paper p="xs" mb={4} withBorder shadow="none">
          <Group m={4}>
            <Badge radius="5px" p={4} color="grey">
              {feature?.metrics?.teams?.length}
            </Badge>
            <Text>Teams</Text>
          </Group>
          <Divider ml={4} mr={4} mt={8} mb={8} />
          {feature?.metrics?.teams.map((teamData, index) => (
            <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
              <ThemeIcon
                variant="outline"
                style={{ backgroundColor: rgba(theme.other.colours.purplePink, 0.15) }}
                color={theme.other.colours.purplePink}>
                <SvgHexagone />
              </ThemeIcon>
              <Stack gap={0}>
                <Text lineClamp={1}>{(teams.find((team) => team.id == teamData.teamId) || {}).name}</Text>
                <Text c="dimmed">{teamData.issuesCount} Stories Planned</Text>
              </Stack>
            </Group>
          ))}
        </Paper>
      )}
    </Dialog>
  );
};
