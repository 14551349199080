import * as React from 'react';

const SvgComments = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1193_1280)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77818 0.875C1.53134 0.875 0.583008 1.83517 0.583008 3.08085V10.2083C0.583008 10.4282 0.706674 10.6294 0.902873 10.7288C1.09907 10.8281 1.33445 10.8086 1.51168 10.6784L3.6391 9.11575C3.9569 8.88784 4.29981 8.76353 4.67786 8.76353H8.30448C9.48018 8.76353 10.4997 7.8169 10.4997 6.55766V3.08085C10.4997 1.90682 9.56477 0.875 8.30448 0.875H2.77818ZM2.95572 8.17011L1.74967 9.05602V3.08085C1.74967 2.47222 2.18292 2.04167 2.77818 2.04167H8.30448C8.88629 2.04167 9.33301 2.51647 9.33301 3.08085V6.55766C9.33301 7.15277 8.85584 7.59687 8.30448 7.59687H4.67786C4.02062 7.59687 3.4432 7.81964 2.95572 8.17011ZM12.1534 4.41841C11.8553 4.29628 11.5146 4.43894 11.3925 4.73706C11.2703 5.03518 11.413 5.37586 11.7111 5.49799C12.057 5.63967 12.2497 5.93997 12.2497 6.23134V11.466L11.1271 10.7355C10.6337 10.3657 10.0204 10.2423 9.47027 10.2423H6.04744C5.65538 10.2423 5.32812 10.0248 5.19178 9.74196C5.05186 9.45175 4.70319 9.32995 4.41299 9.46983C4.12279 9.60977 4.00097 9.95843 4.14089 10.2486C4.48494 10.9622 5.23856 11.4089 6.04744 11.4089H9.47027C9.87353 11.4089 10.212 11.5036 10.436 11.6756C10.4481 11.6849 10.4605 11.6937 10.4732 11.702L12.5149 13.0305C12.6942 13.1472 12.923 13.1563 13.1111 13.0543C13.2992 12.9523 13.4163 12.7555 13.4163 12.5415V6.23134C13.4163 5.41562 12.8884 4.71954 12.1534 4.41841Z"
        fill="#272727"
      />
    </g>
    <defs>
      <clipPath id="clip0_1193_1280">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComments;
