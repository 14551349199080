import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Chip, Grid, Paper, SvgIcon } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Attention } from '../../../assets/images';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.color.accordion,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .Mui-expanded': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.color.accordion,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.color.accordion,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
}));

export default function FieldsGroup({ defaultExpanded, index, title, showFocusedFields, children, firstRender }) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Chip size="small" label={index} sx={{ fontWeight: 'bold' }} style={{ marginRight: '10px' }} />
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
        {showFocusedFields && (
          <SvgIcon style={{ fontSize: 25, position: 'relative', top: 2, left: 10 }} component={Attention} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Paper style={{ padding: '10px' }} variant="outlined">
          <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
            {!firstRender && children}
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}
