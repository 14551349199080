export const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  piWrapper: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.color.selectorBorder}`,
    borderRadius: 5,
  },
  piSelect: {
    minWidth: 190,
    maxWidth: 250,
    height: 44,
    padding: '7px 17px 13px 14px',
    borderRadius: 5,
    backgroundColor: theme.palette.color.selector,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingTop: 5,
      '&:focus': {
        background: 0,
      },
    },
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& label': {
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.color.greyishPurple,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.color.greyishPurple,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.color.greyishPurple,
      },
    },
  },
  dot: {
    height: '10px',
    width: '10px',
    backgroundColor: 'green',
    borderRadius: 50,
    display: 'inline-block',
    marginRight: '5px',
  },
  input: {
    color: theme.palette.text.primary,
  },
  selectedItem: {
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: (props) => props.backgroundColor,
    },
  },
  piName: {
    paddingRight: 6,
  },
  tabsWrapper: {
    width: '100%',
  },
  tabRoot: {
    minWidth: 120,
  },
  tabs: {
    color: theme.palette.color.blueGrey,
    fontSize: 15,
    fontWeight: theme.typography.fontWeight.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTabs-indicator': {
      height: 3,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 90,
      width: '100%',
      backgroundColor: theme.palette.color.darkishPink,
    },
  },
  tabText: {
    color: theme.palette.text.primary,
  },
  tab: {
    textTransform: 'none',
    fontSize: 14,
    padding: 0,
    marginRight: '5% !important',
    minWidth: '0px',
    color: theme.palette.text.primary,
    alignItems: 'flex-start',
    '&.Mui-selected': {
      color: theme.palette.color.darkishPink,
    },
  },
});
