import {
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  rgba,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import SvgHexagone from '../../../../assets/images/Hexagon';
import {
  Field,
  FormContext,
  renderCombobox,
  renderDate,
  renderInput,
} from '../../../../components/Common/FormFieldsHooks/MantineField';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import { IconArrowRight } from '@tabler/icons-react';
import {
  DELETE_PORTFOLIO_OBJECTIVE,
  GET_PORTFOLIO_OBJECTIVES,
  INSERT_PORTFOLIO_OBJECTIVE,
  UPDATE_PORTFOLIO_OBJECTIVE,
} from '../graphql';
import { mapValues, omitBy } from 'lodash';
import { showNotification } from '@mantine/notifications';
import { resetDrawerDetails } from '../../../../reactiveVariables';

const CreateObjectiveModal = ({ open, color, close, valueStream }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const [insertObjective] = useMutation(INSERT_PORTFOLIO_OBJECTIVE, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });
  const [updateObjective] = useMutation(UPDATE_PORTFOLIO_OBJECTIVE);
  const [deleteObjective] = useMutation(DELETE_PORTFOLIO_OBJECTIVE);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    const portfolioObjective = mapValues(
      omitBy(values, (property, key) => key === 'id'),
      (property) => (property === '' ? null : property),
    );

    insertObjective({
      variables: {
        portfolioObjective: { ...portfolioObjective, valueStreamId: valueStream == '0' ? null : valueStream },
      },
    }).then(() => {
      showNotification({
        title: 'Objective Created',
        message: `Objective was successfully created`,
      });
      close();
    });
  };

  return (
    <Modal.Root
      style={{
        borderRadius: '12px',
        backgroundColor: theme.other.colours.background,
        boxShadow: '0px 2px 2px 0px #0000000D',
      }}
      opened={open}
      onClose={close}
      size="600px"
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header
          style={{ borderBottom: `1px solid ${theme.other.colours.borderPaper}`, alignItems: 'flex-start' }}>
          <Modal.Title>
            <Group wrap="nowrap" align="flex-start">
              <ThemeIcon
                variant="outline"
                radius="xl"
                style={{ marginTop: '4px', backgroundColor: rgba(color, 0.15) }}
                color={color}>
                <SvgHexagone width={18} height={18} />
              </ThemeIcon>
              <div>
                <Title order={4}>Create objective</Title>
                <Text size="sm">
                  An objective is a qualitative statement outlining a high level goal you want to achieve in a defined
                  period.
                </Text>
              </div>
            </Group>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body p={16} style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext.Provider value={{ control, errors }}>
              <Grid>
                <Field name="name" data-autofocus required renderField={renderInput} label="Objective" />
                <Field name="description" renderField={renderInput} label="Description" />
                <Field
                  name="owner"
                  required
                  renderField={renderCombobox}
                  label="Owner"
                  data={users.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))}
                />
              </Grid>
              <Divider mt={16} mb={12} />
              <Grid>
                <Field name="targetDate" required renderField={renderDate} label="Target Date" size={6} />
                <Field
                  name="reviewFrequency"
                  required
                  renderField={renderCombobox}
                  label="Review Frequency"
                  data={[
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'monthly', label: 'Monthly' },
                    { value: 'quarterly', label: 'Quarterly' },
                    { value: '6months', label: 'Every 6 months' },
                  ]}
                  size={6}
                />
                <Field
                  name="visibility"
                  required
                  renderField={renderCombobox}
                  label="Visibility"
                  data={['Public', 'Private']}
                  size={6}
                />
              </Grid>
            </FormContext.Provider>
          </form>
          <Divider m="12px -12px" />
          <Group justify="flex-end">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="light"
              radius={8}
              color={theme.other.colours.buttonIcon}
              rightSection={<IconArrowRight size={16} />}>
              Create Objective
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateObjectiveModal;
