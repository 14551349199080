import * as React from 'react';

function SvgMilestones(props) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35633 1.51726C9.62295 1.71404 9.67955 2.0897 9.48277 2.35631L4.90724 8.55542C4.80569 8.69301 4.80283 8.87994 4.90014 9.02056L9.49342 15.6586C9.68197 15.9311 9.61393 16.3048 9.34144 16.4934C9.06894 16.682 8.69519 16.6139 8.50663 16.3414L3.91335 9.70339C3.52412 9.14088 3.53553 8.39316 3.94175 7.84279L8.51728 1.64369C8.71406 1.37708 9.08972 1.32047 9.35633 1.51726ZM7.41157 8.42078L11.9112 2.08798C12.2976 1.5442 13.0979 1.52398 13.5113 2.04754L18.5108 8.38034C18.7977 8.74365 18.7977 9.25632 18.5108 9.61963L13.5113 15.9524C13.0979 16.476 12.2976 16.4558 11.9112 15.912L7.41157 9.5792C7.16514 9.23237 7.16514 8.7676 7.41157 8.42078ZM5.32888 3.22772C5.45464 3.04609 5.40936 2.79691 5.22774 2.67115C5.04611 2.54538 4.79693 2.59067 4.67117 2.77229L1.05105 8.00034C0.729048 8.46537 0.718509 9.07848 1.02434 9.55431L4.66353 15.2163C4.78298 15.4021 5.03046 15.4559 5.2163 15.3365C5.40214 15.217 5.45596 14.9696 5.33651 14.7837L1.69732 9.12175C1.56625 8.91783 1.57076 8.65507 1.70877 8.45577L5.32888 3.22772Z"
      />
    </svg>
  );
}

export default SvgMilestones;
