import React, { memo, useState, forwardRef, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Text, Tooltip } from '@mantine/core';
import { useInput } from '@mui/base';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { CircularProgress, TableCell, TableRow, Avatar } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/system';
import { useForm } from 'react-hook-form';

import SvgComments from '../../../assets/images/Comments';
import { NotesComponent } from '../../../components/Notes';
import { openDrawer } from '../../../reactiveVariables';
import { color, lightColors } from '../../../shared/styles/color';
import { UPDATE_FEATURE_STAGE } from '../Features/graphql';

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledInputElement = styled('input')(({ theme }) => ({
  width: '100%',
  fontSize: '0.875rem',
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 400,
  lineHeight: 1.5,
  color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]}`,
  borderRadius: '8px',
  padding: '12px 12px',

  '&:hover': {
    background: theme.palette.mode === 'dark' ? null : grey[100],
    borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
  },
  '&:focus': {
    outline: `3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]}`,
  },
  '&:disabled': {
    backgroundColor: theme.palette.color.disabled,
  },
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    margin: 0,
  },
  '&[type=number]': {
    WebkitAppearance: 'textfield',
    MozAppearance: 'textfield',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
  padding: '8px',
  ...(props.clickable && { cursor: 'pointer' }),
}));

const CustomInput = React.forwardRef(function CustomInput({ register, ...props }, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return (
    <div {...getRootProps()}>
      <StyledInputElement type="number" {...getInputProps()} {...register(props.name)} {...props} />
    </div>
  );
});

const useStyles = makeStyles({
  parent: {
    position: 'relative',
    zIndex: 0,
  },
  backdrop: {
    position: 'absolute',
  },
});

const Feature = forwardRef(
  (
    {
      feature,
      onLockFeature,
      isSticky,
      lockedFeatureId,
      onNotesCancelClick,
      onNotesTextChange,
      onNotesAddClick,
      openNotesId,
      setOpenNotesId,
      disabledNotesSubmit,
    },
    ref,
  ) => {
    const { businessValue, riskReduction, timeCriticality, size, costOfDelay, wsjf } = feature;
    const {
      register,
      setValue,
      reset,
      watch,
      handleSubmit,
      formState: { isDirty },
    } = useForm({
      defaultValues: { businessValue, riskReduction, timeCriticality, size, costOfDelay, wsjf },
    });
    const classes = useStyles();
    const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);
    const [loading, setLoading] = useState(false);

    const renderField = (name, disabled) => (
      <CustomInput
        placeholder={!disabled && 'Add Value'}
        name={name}
        register={register}
        disabled={disabled}
        onChange={(event) => handleChange(parseInt(event.target.value), name)}
        onBlur={handleSubmit(onSubmit)}
      />
    );

    useEffect(() => {
      const handleEscape = (event) => {
        if (openNotesId && event.key === 'Escape') {
          onNotesCancelClick();
        }
      };

      document.addEventListener('keydown', handleEscape);

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }, [onNotesCancelClick, openNotesId]);

    const onSubmit = (data) => {
      if (isDirty) {
        setLoading(true);
        updateFeature({
          variables: {
            feature: data,
            featureId: feature.id,
          },
          optimisticResponse: {
            feature: { ...feature, ...data },
          },
        }).then(() => reset(data));
        setTimeout(() => setLoading(false), 500);
      }
    };

    const data = watch();

    const handleChange = (newValue, field) => {
      const { businessValue, riskReduction, timeCriticality, size } = {
        ...data,
        [field]: newValue,
      };
      const cod = (businessValue || 0) + (riskReduction || 0) + (timeCriticality || 0);
      const wsjf = Math.round(((cod && size ? cod / size : 0) + Number.EPSILON) * 100) / 100;

      setValue(field, newValue, { shouldDirty: true });
      setValue('costOfDelay', cod);
      setValue('wsjf', wsjf);
    };

    const openFeatureDrawer = () => {
      openDrawer(feature, 'feature');
      onNotesCancelClick();
    };

    return (
      <>
        <TableRow
          ref={ref}
          className={classes.parent}
          style={{
            backgroundColor: feature.isLocked ? 'black' : 'color.background',
            background: feature.isLocked ? 'black' : 'color.background',
            position: isSticky ? 'sticky' : 'static',
            top: isSticky ? '-23px' : 'auto',
            zIndex: isSticky ? 10 : 'auto',
            boxShadow: '0px 1px 0px 0px rgba(224, 224, 224, 1)',
          }}
          sx={{
            backgroundColor: feature.isLocked ? 'black' : 'color.background',
            padding: '5px',
          }}>
          <StyledTableCell clickable onClick={() => onLockFeature(feature.id)}>
            <div style={{ display: 'inline-flex' }}>
              {lockedFeatureId === feature.id ? (
                <LocalOfferIcon style={{ width: '20px', color: color.purpulishPink }} />
              ) : (
                <LocalOfferOutlinedIcon style={{ width: '20px' }} />
              )}

              <Text size="xs" ml={3} mt={4}>{`ID-${feature.id}`}</Text>
            </div>
            {loading && (
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </StyledTableCell>
          <StyledTableCell clickable onClick={openFeatureDrawer}>
            <Text size="xs">{feature.name}</Text>
          </StyledTableCell>
          <StyledTableCell clickable onClick={openFeatureDrawer}>
            {feature.assignedTo && (
              <Tooltip color="dark" label={feature.assignedTo}>
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: 10,
                    fontWeight: 'bold',
                    backgroundColor: color.purpulishPink,
                    marginLeft: '10px',
                    marginRight: '5px',
                  }}>
                  {feature.assignedTo
                    ?.split(' ')
                    .map((n) => n[0])
                    .join('')}
                </Avatar>
              </Tooltip>
            )}
          </StyledTableCell>
          <StyledTableCell style={{ position: 'relative' }}>
            <div
              onClick={() => setOpenNotesId(feature.id)}
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: '20px',
                left: '-34px',
                cursor: 'pointer',
                backgroundColor: openNotesId === feature.id ? 'white' : lightColors.tileBorder,
                borderRadius: '4px',
                padding: '2px 4px',
                border: openNotesId === feature.id ? `1px solid ${color.purpulishPink}` : 'none',
              }}>
              <SvgComments style={{ marginRight: '5px' }} />
              <span>{feature.notes?.length || 0}</span>
            </div>

            {openNotesId === feature.id && (
              <NotesComponent
                style={{
                  position: 'absolute',
                  top: lockedFeatureId === openNotesId ? '0px' : '-115px',
                  left: '6px',
                  zIndex: lockedFeatureId === openNotesId ? 100 : 1,
                }}
                onNotesCancelClick={onNotesCancelClick}
                onNotesTextChange={onNotesTextChange}
                onNotesAddClick={onNotesAddClick}
                notes={feature.notes}
                disabled={disabledNotesSubmit}
              />
            )}
            {renderField('businessValue')}
          </StyledTableCell>
          <StyledTableCell align={'center'}>+</StyledTableCell>
          <StyledTableCell>{renderField('riskReduction')}</StyledTableCell>
          <StyledTableCell align={'center'}>+</StyledTableCell>
          <StyledTableCell>{renderField('timeCriticality')}</StyledTableCell>
          <StyledTableCell align={'center'}>=</StyledTableCell>
          <StyledTableCell>{renderField('costOfDelay', true)}</StyledTableCell>
          <StyledTableCell align={'center'}>/</StyledTableCell>
          <StyledTableCell>{renderField('size')}</StyledTableCell>
          <StyledTableCell align={'center'}>=</StyledTableCell>
          <StyledTableCell>{renderField('wsjf', true)}</StyledTableCell>
        </TableRow>
      </>
    );
  },
);

Feature.displayName = 'Feature';

export default memo(Feature);
