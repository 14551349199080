import * as React from 'react';

const SvgBigAgileLogo = (props) => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1109.95 687.41">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill="#000000"
          d="m585.05,200.87c7.64,12.95,14.87,25.05,21.96,37.24,27.49,47.27,54.77,94.67,82.5,141.81,9.18,15.61,19.41,30.62,29.31,45.8,7.17,10.99,18.24,16.55,30.41,19.38,17.65,4.1,32.56-2.97,45.48-14.22,13.13-11.43,21.34-25.78,19.24-43.77-.74-6.32-4.15-12.54-7.21-18.34-48.81-92.48-100.96-183.07-154.44-272.91-6.12-10.28-12.62-20.34-19.08-30.41-11.87-18.48-29.63-28.49-50.43-32.79-24.08-4.98-47.64-2.56-67.79,12.7-8.69,6.59-16.01,15.48-22.52,24.38-63.19,86.44-126.14,173.06-189.01,259.74-21.97,30.29-43.53,60.87-65.28,91.31-.67.94-1.46,1.8-2.54,3.13-8.43-5.66-16.77-11.27-25.63-17.21,1.11-1.68,2.03-3.17,3.04-4.59,45.81-64.14,91.29-128.51,137.54-192.33,42.36-58.44,85.25-116.51,128.53-174.27,14.99-20.01,36.11-30.91,61.14-33.58,8.4-.89,16.84-1.67,25.27-1.87,32.81-.79,60.1,12.6,83,34.79,8.16,7.91,13.92,18.56,19.8,28.53,36.84,62.42,73.71,124.82,109.93,187.59,19.1,33.1,36.89,66.95,55.27,100.46,7.41,13.51,11.24,27.72,10.93,43.38-.69,35.06-27.14,69.36-61.24,77.47-9.25,2.2-18.92,4.18-28.33,3.9-22.91-.69-43.51-8.62-58.78-26.16-7.66-8.8-13.2-19.53-19.15-29.71-34.56-59.12-68.97-118.33-103.38-177.54-3.76-6.47-7.1-13.18-10.82-19.66-2.48-4.31-1.62-7.72,2.39-10.24,6.31-3.97,12.77-7.7,19.89-11.97Z"
        />
        <path
          fill="#000000"
          d="m98.64,591.34c9.26,2.92,17.75,6.53,23.84,14.16,5.35,6.7,8.53,14.16,8.16,22.95-.27,6.55.61,13.25-.51,19.63-3.23,18.45-15.27,29.01-32.87,33.45-6.4,1.61-13.12,2.61-19.71,2.7-24.64.32-49.29.15-73.94.15-1.12,0-2.25-.16-3.62-.26v-175.56c1.67-.1,3.12-.27,4.57-.27,22.32-.01,44.63-.18,66.95.06,13.99.15,27.65,2.15,38.99,11.41,8.46,6.9,12.8,16.02,13.07,26.93.21,8.63.59,17.28-3.44,25.43-4.59,9.27-11.92,15.29-21.49,19.23Zm-74,71.76c19.55,0,38.51.65,57.39-.23,12.68-.59,21.2-8.98,23.14-20.49.74-4.38.42-8.95.42-13.43,0-12.07-6.27-20.63-17.83-24.04-3.29-.97-6.78-1.71-10.18-1.77-17.28-.31-34.57-.37-51.85-.49-.28,0-.56.31-1.09.62v59.82Zm0-79.02c8.64,0,16.77.11,24.9-.03,9.48-.16,19-.05,28.41-.97,9.81-.96,17.29-5.86,20.15-15.97.62-2.2.64-4.56.99-6.84,2.38-15.19-4.39-29.42-22.57-29.9-11.78-.31-23.56-.71-35.34-.98-5.44-.12-10.88-.02-16.55-.02v54.71Z"
        />
        <path
          fill="#000000"
          d="m573.87,684.37c-9.45,0-18.21.14-26.96-.17-1.11-.04-2.58-2.28-3.13-3.78-3.95-10.76-7.84-21.55-11.46-32.43-1.32-3.99-3.16-5.67-7.71-5.6-24.13.38-48.26.41-72.39.39-3.13,0-4.7.83-5.75,3.91-3.86,11.32-8.16,22.49-11.99,33.82-1.05,3.1-2.54,4.05-5.71,3.96-7.59-.22-15.2-.07-23.41-.07,5.63-14.43,11-28.19,16.37-41.94,16.64-42.56,33.36-85.08,49.83-127.71,1.86-4.83,4.05-7.1,9.56-6.61,6.61.58,13.31.3,19.96.06,3.04-.11,4.33,1.05,5.39,3.78,15.84,40.55,31.79,81.06,47.7,121.58,5.83,14.85,11.65,29.7,17.46,44.55.71,1.81,1.31,3.67,2.23,6.27Zm-84.27-158.45c-11.48,32.25-22.73,63.85-34.01,95.52h67.23c-11.12-31.97-22.04-63.37-33.22-95.52Z"
        />
        <path
          fill="#000000"
          d="m269.43,584.31c.04,10.15.07,20.3.11,30.46.1,24.49,14.3,43.26,36.31,47.98,11.08,2.37,22.19,3.76,33.48,1.22,4.7-1.06,9.45-1.92,14.2-2.69,3.99-.65,5.25-2.61,5.19-6.77-.26-18.98-.11-37.96-.12-56.94,0-1.81,0-3.63,0-5.84h22.46c.09,1.27.28,2.7.28,4.13.02,25.64-.03,51.28.07,76.93.01,3-.53,4.86-3.74,5.95-28.81,9.72-58.01,11.81-87.41,3.58-23.6-6.61-37.26-23.15-43.69-46.36-5.47-19.72-4.84-39.7-3.23-59.77,1.32-16.37,5.82-31.64,15.94-44.87,10.75-14.06,25.81-20.68,42.62-23.68,23.49-4.18,46.66-2.09,69.56,4.42,4.35,1.24,5.63,3.58,5.01,7.58-.8,5.17-1.68,10.33-2.56,15.69-5.35-1.48-10.72-3.15-16.17-4.44-16.89-4.02-33.94-4.38-50.96-1.16-19.38,3.66-31.49,17.05-35.24,38.47-.82,4.69-1.33,9.43-1.98,14.15-.04.66-.09,1.33-.13,1.99Z"
        />
        <path
          fill="#000000"
          d="m720.64,534.91c-9.45-2.07-18.57-5.07-27.87-5.85-11.71-.98-23.67-1.13-35.34.1-20.63,2.18-33.66,14.07-38.34,34.4-5.46,23.72-5.37,47.47,1.39,70.92,4.32,14.99,14.7,23.89,29.79,27.93,16.87,4.52,33.41,2.92,50.01-1.17,3.6-.89,5.03-2.46,4.98-6.44-.24-19.14-.11-38.28-.11-57.42v-5.73h22.13c.1,1.56.28,3.14.28,4.73.02,25.46-.07,50.93.11,76.39.03,3.7-1.27,5.24-4.6,6.37-24.96,8.5-50.48,10.36-76.3,5.71-32.71-5.89-52.33-26.92-56.03-61.07-1.75-16.1-2.52-32.62-.99-48.69,2.07-21.7,9.05-41.98,28.24-55.49,11.19-7.89,23.93-11.44,37.29-12.98,21.56-2.49,42.72-.38,63.56,5.73,4.09,1.2,5.29,2.86,4.41,6.92-1.14,5.25-1.79,10.61-2.6,15.65Z"
        />
        <path
          fill="#000000"
          d="m1109.95,662.66v21.37h-118.6v-175.42h114.58v21.56h-89.32v52.72h76.13v20.32h-76.19v59.45h93.41Z"
        />
        <path fill="#000000" d="m848.41,508.46h25.41v153.58h86.56v22.01h-111.97v-175.6Z" />
        <path fill="#000000" d="m199.27,684.17h-25.47v-175.52h25.47v175.52Z" />
        <path fill="#000000" d="m770.51,508.49h25.51v175.53h-25.51v-175.53Z" />
      </g>
    </g>
  </svg>
);

export default SvgBigAgileLogo;
