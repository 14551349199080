import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from 'containers/Reports/Roadmap/styles';
import { useTheme } from '@emotion/react';
import { find, get } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { Metric } from '../../../../../../components/Dashboard/ChartElements';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_TEAMS } from '../../../../../Settings/GraphQL/teams';
import { GET_FEATURES_FOR_PI, GET_FEATURES_FOR_PROGRAMME } from '../../../../../Product/Features/graphql';
import { selectedInitiativeVar } from '../../../../../../reactiveVariables';
import BugReportIcon from '@mui/icons-material/BugReport';
import SvgFeatures from '../../../../../../assets/images/Features';
import SvgStories from '../../../../../../assets/images/Stories';
import { GET_SELECTED_PI_FULL } from '../../../../../Settings/ProgramIncrement/graphql';

const useStyles = makeStyles(styles);

const FeatureProgression = ({ backlog, selectedTeams, scope }) => {
  const classes = useStyles();
  const { data: { teams = [] } = {}, loading: teamLoading } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {}, loading: featureLoading } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { selectedPiFull = {} || {} } = {} } = useQuery(GET_SELECTED_PI_FULL);

  const theme = useTheme();

  const loading = teamLoading || featureLoading;
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  function hasStoriesInPI(feature) {
    return !!feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id];
  }

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (feature.programIncrement === selectedPiFull?.id || hasStoriesInPI(feature)) &&
          (!backlog || feature.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, backlog, selectedTeams, selectedInitiative, selectedPiFull],
  );

  const updatedFeatures = useMemo(
    () =>
      filteredFeatures
        .filter((feature) => feature.teamId && feature.metrics)
        .sort((a, b) => a.teamId > b.teamId)
        .map((feature) => {
          return {
            id: feature.id,
            teamName: find(teams, { id: feature.teamId }).name,
            status: feature.status,
            name: feature.name,
            inProgressCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.inProgress`, 0),
            toDoCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.toDo`, 0),
            doneCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.done`, 0),
          };
        }),
    [teams, backlog, selectedTeams, filteredFeatures, scope],
  );

  const featuresDone = filteredFeatures.filter((feature) => feature.status === 'Done');
  const storiesToDo = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.toDo', 0),
    0,
  );
  const storiesInProgress = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.inProgress', 0),
    0,
  );
  const storiesDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.done', 0),
    0,
  );
  const storyPointsToDo = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.toDo', 0),
    0,
  );
  const storyPointsInProgress = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.inProgress', 0),
    0,
  );
  const storyPointsDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.done', 0),
    0,
  );
  const defects = filteredFeatures.reduce((total, feature) => total + get(feature, 'metrics.defectsCount.total', 0), 0);
  const defectsDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.defectsCount.done', 0),
    0,
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Metric
          title="Features"
          icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}
          classes={classes}
          done={featuresDone.length}
          total={filteredFeatures.length}
        />
        <Metric
          title="Bugs"
          icon={<BugReportIcon color="primary" />}
          classes={classes}
          done={defectsDone}
          total={defects}
        />
        <Metric
          title="Stories"
          classes={classes}
          done={storiesDone}
          total={storiesToDo + storiesInProgress + storiesDone}
          icon={<SvgStories color={theme.palette.color.buttonIcon} />}
        />
        <Metric
          title="Story Points"
          classes={classes}
          done={storyPointsDone}
          total={storyPointsInProgress + storyPointsToDo + storyPointsDone}
          icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}
        />
        <Grid item xs={12}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <CircularProgress className={classes.progress} size={50} />
            </div>
          ) : (
            <div style={{ height: `${updatedFeatures.length * 40 + 100}px` }}>
              <Progression features={updatedFeatures} scope={scope} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Progression = ({ features, scope }) => {
  const theme = useTheme();

  const toDo = features.map((feature) => feature.toDoCount);
  const inProgress = features.map((feature) => feature.inProgressCount);
  const done = features.map((feature) => feature.doneCount);

  const config = {
    series: [
      {
        name: scope === 'storyCount' ? 'To Do Story Points' : 'To Do Story Count',
        data: toDo,
        color: theme.palette.color.toDo,
      },
      {
        name: scope === 'storyCount' ? 'In Progress Story Points' : 'In Progress Story Count',
        data: inProgress,
        color: theme.palette.color.inProgress,
      },
      {
        name: scope === 'storyCount' ? 'Done Story Points' : 'Done Story Count',
        data: done,
        color: theme.palette.color.done,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        background: theme.palette.color.background,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: theme.palette.text.secondary,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        background: theme.palette.color.background,
      },
      xaxis: {
        axisBorder: { show: false },
        categories: features.map((feature) => feature.name),
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      grid: {
        yaxis: { lines: { show: false } },
        xaxis: { lines: { show: true } },
      },
      tooltip: {
        theme: theme.palette.mode,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        labels: { colors: theme.palette.text.secondary },
      },
    },
  };

  return <ReactApexChart options={config.options} series={config.series} type="bar" height="100%" />;
};

export default FeatureProgression;
