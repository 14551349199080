import Keycloak from 'keycloak-js';
import { accessTokenVar, loggedInUserVar, saveUserInfo } from './reactiveVariables';
import config from 'Config';

const envConfig = config[window.location.hostname];

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  realm: envConfig.realm,
  url: 'https://login.bigagile.io/auth',
  clientId: 'big-agile',
});

const saveToken = () => {
  accessTokenVar(keycloak.token);
  window.localStorage.setItem('accessToken', keycloak.token);
  window.localStorage.setItem('refreshToken', keycloak.refreshToken);
  window.localStorage.setItem('idToken', keycloak.idToken);
  saveUserInfo(keycloak.tokenParsed);

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    alignment: 'left',
    app_id: 'imq97uik',
    email: keycloak.tokenParsed.email,
  });
};

keycloak.onTokenExpired = () => {
  keycloak
    .updateToken(5)
    .then((refreshed) => {
      if (refreshed) {
        saveToken();
      }
    })
    .catch((error) => {
      console.log('Failed to refresh the token, or the session has expired ' + error);
      keycloak.login({
        idpHint: window.location.search.includes('support=true') ? '' : 'microsoft',
        redirectUri: window.location,
      });
    });
};

keycloak.onAuthSuccess = () => saveToken();

keycloak
  .init({
    enableLogging: true,
    redirectUri: window.location,
    token: window.localStorage.getItem('accessToken'),
    refreshToken: window.localStorage.getItem('refreshToken'),
    idToken: window.localStorage.getItem('idToken'),
    checkLoginIframe: false,
    timeSkew: 0,
  })
  .then((authenticated) => {
    if (!authenticated) {
      keycloak.login({
        idpHint: window.location.search.includes('support=true') ? '' : 'microsoft',
        redirectUri: window.location,
      });
    }
  })
  .catch((error) => {
    console.log('Failed to initialize ' + error);
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('idToken');
    keycloak.login({
      idpHint: window.location.search.includes('support=true') ? '' : 'microsoft',
      redirectUri: window.location,
    });
  });

export default keycloak;
