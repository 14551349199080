import * as React from 'react';
import { memo, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardContent, Chip, Grid, SvgIcon } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Attention } from '../../../assets/images';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_FEATURES, GET_STORIES_FOR_FEATURE } from '../../../containers/Product/Features/graphql';
import { find, isEmpty, isEqual, sortBy, orderBy, last } from 'lodash';
import { GET_CONNECTORS } from '../../../containers/Settings/GraphQL/connectors';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { GET_PROGRAM_INCREMENTS } from '../../../containers/Settings/ProgramIncrement/graphql';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import Tag from '../../Common/Tag';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@mantine/core';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.color.accordion,
  },
}));

export const getSprint = (issue) => {
  if (issue.sprint) {
    return issue.sprint;
  }
  if (issue.fields.sprint) {
    return issue.fields.sprint.id;
  }
  if (!isEmpty(issue.fields.closedSprints)) {
    const closedSprints = sortBy(issue.fields.closedSprints, 'completeDate');
    const sprint = last(closedSprints);
    return sprint.id;
  }
  return '';
};

export function mapSprint(jiraSprintId, sprints) {
  if (!jiraSprintId) return null;

  for (const sprint of sprints) {
    const teamSprint = Array.isArray(sprint.jiraIds)
      ? Object.entries(Object.assign({}, sprint.jiraIds)).find(([key, value]) => value === jiraSprintId)
      : Object.entries(sprint.jiraIds || {}).find(([key, value]) => value === jiraSprintId);

    if (teamSprint) {
      return { sprint, teamId: teamSprint[0] };
    }
  }
}

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .Mui-expanded': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.color.accordion,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.color.accordion,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
}));

const StoriesFieldGroup = ({
  index,
  title,
  feature,
  team,
  showFocusedFields,
  connectorId,
  classes,
  initialStoryCount,
  firstRender,
}) => {
  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const theme = useTheme();

  const allSprints = programIncrements.map((pi) => pi.sprints).flat();

  const [getStories, { loading, data: { stories = [] } = {} }] = useLazyQuery(GET_STORIES_FOR_FEATURE, {
    variables: { connectorId: connectorId, epicId: feature.epicId, project: team?.projectId },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'rest' },
  });

  const sortedStories = useMemo(() => orderBy(stories, ['id'], ['desc']), [stories]);

  const openJira = (story) => {
    const connector = find(connectors, { id: connectorId }) || {};
    const url =
      connector.type === 'azureDevops'
        ? `${connector.apiUrl}/${team?.projectId}/_workitems/edit/${story.key}`
        : connector.apiUrl + '/browse/' + story.key;

    window.open(url, '_blank');
  };

  return (
    <Accordion
      onClick={() => {
        if (isEmpty(stories) && connectorId && feature.epicId)
          getStories({ variables: { connectorId: connectorId, epicId: feature.epicId, project: team?.projectId } });
      }}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Chip size="small" label={index} sx={{ fontWeight: 'bold' }} style={{ marginRight: '10px' }} />
        <Typography sx={{ fontWeight: 'bold' }}>{`${title} (${stories?.length || initialStoryCount || 0})`}</Typography>
        {showFocusedFields && (
          <SvgIcon style={{ fontSize: 25, position: 'relative', top: 2, left: 10 }} component={Attention} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
          {!firstRender &&
            stories &&
            sortedStories.map((story) => {
              const jiraSprintId = getSprint(story);
              const sprintAndTeam = mapSprint(jiraSprintId, allSprints);
              const isTeamSprint = !sprintAndTeam || sprintAndTeam?.teamId == team?.id;
              const issueTeam = (!isTeamSprint && find(teams, { id: Number(sprintAndTeam?.teamId) })) || {};

              return (
                <Grid key={story.id} item xs={12}>
                  <Card variant="outlined" onClick={() => openJira(story)}>
                    <CardActionArea>
                      <CardContent style={{ padding: '10px' }}>
                        <Grid container justifyContent="flex-start" alignItems="center">
                          <Grid item xs={2}>
                            <Typography color="textSecondary">{story.key}</Typography>
                          </Grid>
                          <Grid item xs={isTeamSprint ? 6 : 4}>
                            <Typography color="textSecondary">{story.name || story.fields?.summary}</Typography>
                          </Grid>
                          {!isTeamSprint && issueTeam && (
                            <Grid item xs={2}>
                              <Tooltip label={issueTeam.name} zIndex={2000} transition="scale-y">
                                <Tag color={theme.palette.color.purpulishPink} label={issueTeam.name} />
                              </Tooltip>
                            </Grid>
                          )}
                          <Grid item xs={2}>
                            <Typography color="textSecondary" align="center">
                              {jiraSprintId ? (sprintAndTeam ? sprintAndTeam.sprint.name : 'Unknown') : 'Backlog'}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography color="textSecondary">{story.status || story.fields?.status.name}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(StoriesFieldGroup, (prevProps, nextProps) => isEqual(prevProps, nextProps));
