import { Card, Paper, TextField } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import DropDownIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Text, UnstyledButton } from '@mantine/core';
import { CardContent, CardHeader, Typography } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import { styled as muiStyled } from '@mui/system';

export const FlexDiv = styled('div')(({ theme }) => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const PaperCard = styled((props) => <Paper elevation={0} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.color.card,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
}));

export const GrowingText = styled((props) => <TextField variant="outlined" autoComplete="off" {...props} />)(
  ({ theme }) => ({
    paddingTop: '10px',
    paddingLeft: 0,
    '& label': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      padding: '8px',
      '& fieldset': {
        borderColor: 'red',
        display: 'none',
      },
      '& input': {
        padding: '5px 5px 5px 0px',
        paddingLeft: '8px',
        maxWidth: '100%',
        width: `100px`,
        fontSize: 14,
        fontWeight: 400,
      },
      '&:hover fieldset': {
        borderColor: '#93a0b5',
        display: 'inline-flex',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#93a0b5',
        display: 'inline-flex',
      },
    },
  }),
);

export const ListCard = styled(Card)(({ theme }) => ({
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0), 0 1px 4px 0 rgba(0, 0, 0, 0.05)',
  border: theme.palette.mode === 'light' ? 'solid 1px #f2f2f3' : 'none',
  borderLeft: '0.3em solid #191919',
  cursor: 'pointer',
  overflow: 'auto',
  marginBottom: '5px',
  backgroundColor: theme.palette.background.default,
}));

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.color.paper,
    '&:before': {
      display: 'none',
    },
  }),
);

export const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<DropDownIcon />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.color.paper,
    padding: 0,
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
  }),
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  backgroundColor: theme.palette.color.paper,
}));

export const ClickableCard = styled(UnstyledButton)`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => `${theme.palette.color.panel} !important`};
  transition: box-shadow 250ms ease, transform 200ms ease;
  border-radius: 4px;
  margin: 12px 0;

  :hover {
    box-shadow: ${({ theme }) => theme.shadows[2]} !important;
    transform: scale(1.03);
  }
`;

export const IDWrapper = styled(Text)(({ theme }) => ({
  height: 'calc(100% - 6px)',
  minWidth: '100px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px 0 0 4px',
  margin: '3px !important',
  backgroundColor: theme.palette.color.header,
}));

export const DisplayID = styled(Text)(({ theme }) => ({
  paddingTop: 'px',
}));

export const CardButton = styled(UnstyledButton)`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.palette.color.header};
  border-radius: 4px;
  margin: 12px 0;
`;

export const StyledCardHeader = muiStyled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
  padding: 0,
  paddingTop: '16px',
  paddingBottom: '16px',
  marginLeft: '16px',
  marginRight: '16px',
}));

export const StyledCard = muiStyled(Card)(
  ({ theme }) => `
  box-shadow: 0 1px 1px 0 rgba(61, 71, 86, 0.05);
  border: ${theme.palette.mode === 'light' ? 'solid 1px #f2f2f3' : 'none'};
`,
);

export const StyledPaper = muiStyled(Paper)(
  ({ theme }) => `
  box-shadow: 0 1px 1px 0 rgba(61, 71, 86, 0.05);
  border: ${theme.palette.mode === 'light' ? 'solid 1px #f2f2f3' : 'none'};
  background-color: ${theme.palette.color.paper};
`,
);

export const Grid = muiStyled(MuiGrid)(({ theme }) => ({
  backgroundColor: theme.palette.color.background,
}));

export const Dot = muiStyled('span')(({ theme }) => ({
  height: '8px',
  width: '8px',
  borderRadius: 50,
  display: 'inline-block',
  marginRight: '6px',
  backgroundColor: theme.palette.text.primary,
}));

export const MetricPaper = ({ icon, title, xs = 3, md, children }) => {
  return (
    <Grid item xs={xs} md={md}>
      <StyledPaper sx={{ padding: '10px' }}>
        <MuiGrid container>
          <MuiGrid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {icon}
              <Text style={{ fontWeight: 600, marginLeft: '10px' }} size="sm">
                {title}
              </Text>
            </div>
          </MuiGrid>
          {children}
        </MuiGrid>
      </StyledPaper>
    </Grid>
  );
};

export const Metric = ({ value, name, dotColor, xs = 6 }) => {
  return (
    <MuiGrid item xs={xs}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {dotColor && <Dot sx={{ backgroundColor: dotColor }} />}
        <Text size={'lg'} style={{ fontWeight: 600 }}>
          {value}
        </Text>
      </div>
      <Text size={'xs'} c="#93a0b5">
        {name}
      </Text>
    </MuiGrid>
  );
};

export const DashboardCard = ({ title, cardStyle, headerStyle, gridStyle, contentStyle, children, action, xs = 6 }) => {
  return (
    <Grid item xs={xs} sx={gridStyle}>
      <StyledCard sx={{ height: '60vh', maxHeight: '400px', ...cardStyle }}>
        <StyledCardHeader
          sx={headerStyle}
          titleTypographyProps={{
            variant: 'body1',
            style: { fontWeight: 600 },
          }}
          title={title}
          action={action}
        />
        <CardContent style={{ height: 'calc(100% - 54px)', ...contentStyle }}>{children}</CardContent>
      </StyledCard>
    </Grid>
  );
};

export const nivoTheme = () => {
  const muiTheme = useTheme();

  return {
    tooltip: {
      container: {
        background: muiTheme.palette.background.default,
      },
    },
    axis: {
      legend: {
        text: {
          fontSize: 14,
          fill: '#93a0b5',
        },
      },
      ticks: {
        text: {
          fontSize: 11,
          fill: '#93a0b5',
        },
      },
      domain: {
        line: {
          stroke: '#eaeaeb',
          strokeWidth: 2,
        },
      },
    },
  };
};
