import * as React from 'react';

const SvgPriorityMedium = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.42117 4.46696C4.42117 3.84098 4.90195 3.3335 5.49483 3.3335H6.50542C7.09829 3.3335 7.57907 3.84098 7.57907 4.46696V10.8667C7.57907 11.4927 7.09829 12.0002 6.50542 12.0002H5.49483C4.90195 12.0002 4.42117 11.4927 4.42117 10.8667V4.46696ZM0.00012207 7.13363C0.00012207 6.50765 0.480899 6.00016 1.07378 6.00016H2.08436C2.67724 6.00016 3.15802 6.50765 3.15802 7.13363V10.8667C3.15802 11.4927 2.67724 12.0002 2.08436 12.0002H1.07378C0.480899 12.0002 0.00012207 11.4927 0.00012207 10.8667V7.13363Z"
      fill="#FF4189"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91582 0C9.32294 0 8.84216 0.507487 8.84216 1.13346V10.8665C8.84216 11.4925 9.32294 12 9.91582 12H10.9264C11.5193 12 12.0001 11.4925 12.0001 10.8665V1.13346C12.0001 0.507487 11.5193 0 10.9264 0H9.91582Z"
      fill="#808080"
      fillOpacity="0.3"
    />
  </svg>
);

export default SvgPriorityMedium;
