import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { mean, uniq } from 'lodash';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';
import { useTheme } from '@emotion/react';

const TeamBreakdown = ({ initiative, teams }) => {
  const theme = useTheme();
  const data =
    initiative?.features?.nodes.reduce((acc, feature) => {
      if (feature.teamId === null) return acc;
      const team = teams.find((team) => team.id === feature.teamId);
      if (!acc[team.id]) {
        acc[team.id] = { name: team.name, features: 0, progress: [] };
      }
      acc[team.id].features += 1;
      acc[team.id].progress.push(feature.percentageDone);

      return acc;
    }, {}) || {};

  return (
    <TableContainer sx={{ maxHeight: 300, borderTop: `1px solid ${theme.palette.color.tileBorder}` }}>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow sx={{ height: '40px' }}>
            <TableCell
              width="40%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Team
            </TableCell>
            <TableCell
              width="20%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Features
            </TableCell>
            <TableCell
              width="40%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Progress
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map(([key, value]) => (
            <TableRow>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>{value.name}</TableCell>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>{value.features}</TableCell>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>
                <LinearWithValueLabel value={mean(value.progress).toFixed(1)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamBreakdown;
