import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import Grow from '@mui/material/Grow';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { exportCSVData, getFieldValue } from 'utils/export';
import { useQuery } from '@apollo/client';
import { GET_OKRS } from './graphql';
import { openDrawer } from '../../../reactiveVariables';
import EntityTable, { InnerRow } from '../../../components/Common/EntityTable';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { FlexDiv } from '../../../components/Common/Elements';
import { Avatar, Chip, Typography } from '@mui/material';
import OKRExpand from './OKRExpand';
import Table from '../../../components/Common/Table';

const OKRs = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { okrs = [] } = {} } = useQuery(GET_OKRS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);

  useImperativeHandle(ref, () => ({
    export: () => exportOKRs(),
  }));

  const exportOKRs = useCallback(() => {
    const data = okrs.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'OKRs');
  }, [okrs]);

  const onRowClick = (event, row) => {
    const okr = okrs.find((okr) => okr?.id === row.id);
    openDrawer(okr, 'okr');
  };

  const getInitiativesAndFeatures = (okr, setLoading) => {
    return <OKRExpand okr={okr} setLoading={setLoading} />;
  };

  return (
    <Grow in={true}>
      <div>
        <Table
          onRowClick={onRowClick}
          fields={headCells}
          searchQuery={searchQuery}
          rows={getRowsData(okrs, features, initiatives)}
          expandFunc={getInitiativesAndFeatures}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(OKRs);
