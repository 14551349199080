import * as React from 'react';

function SvgTotal(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1044_103161)">
        <path d="M8.4375 7.03125C8.4375 7.8079 7.8079 8.4375 7.03125 8.4375C6.2546 8.4375 5.625 7.8079 5.625 7.03125C5.625 6.2546 6.2546 5.625 7.03125 5.625C7.8079 5.625 8.4375 6.2546 8.4375 7.03125Z" />
        <path
          opacity="0.6"
          d="M8.15625 3.375C8.15625 3.99632 7.65257 4.5 7.03125 4.5C6.40993 4.5 5.90625 3.99632 5.90625 3.375C5.90625 2.75368 6.40993 2.25 7.03125 2.25C7.65257 2.25 8.15625 2.75368 8.15625 3.375Z"
          fillOpacity="0.6"
        />
        <path
          opacity="0.6"
          d="M12.0938 3.375C12.0938 3.99632 11.5901 4.5 10.9688 4.5C10.3474 4.5 9.84375 3.99632 9.84375 3.375C9.84375 2.75368 10.3474 2.25 10.9688 2.25C11.5901 2.25 12.0938 2.75368 12.0938 3.375Z"
          fillOpacity="0.6"
        />
        <path
          opacity="0.3"
          d="M7.59375 0.5625C7.59375 0.87316 7.34191 1.125 7.03125 1.125C6.72059 1.125 6.46875 0.87316 6.46875 0.5625C6.46875 0.25184 6.72059 0 7.03125 0C7.34191 0 7.59375 0.25184 7.59375 0.5625Z"
        />
        <path
          opacity="0.3"
          d="M1.125 7.03125C1.125 7.34191 0.87316 7.59375 0.5625 7.59375C0.25184 7.59375 0 7.34191 0 7.03125C0 6.72059 0.25184 6.46875 0.5625 6.46875C0.87316 6.46875 1.125 6.72059 1.125 7.03125Z"
        />
        <path
          opacity="0.3"
          d="M1.125 10.9688C1.125 11.2794 0.87316 11.5312 0.5625 11.5312C0.25184 11.5312 0 11.2794 0 10.9688C0 10.6581 0.25184 10.4062 0.5625 10.4062C0.87316 10.4062 1.125 10.6581 1.125 10.9688Z"
        />
        <path
          opacity="0.3"
          d="M18 7.03125C18 7.34191 17.7482 7.59375 17.4375 7.59375C17.1268 7.59375 16.875 7.34191 16.875 7.03125C16.875 6.72059 17.1268 6.46875 17.4375 6.46875C17.7482 6.46875 18 6.72059 18 7.03125Z"
        />
        <path
          opacity="0.3"
          d="M18 10.9688C18 11.2794 17.7482 11.5312 17.4375 11.5312C17.1268 11.5312 16.875 11.2794 16.875 10.9688C16.875 10.6581 17.1268 10.4062 17.4375 10.4062C17.7482 10.4062 18 10.6581 18 10.9688Z"
        />
        <path
          opacity="0.3"
          d="M11.5312 0.5625C11.5312 0.87316 11.2794 1.125 10.9688 1.125C10.6581 1.125 10.4062 0.87316 10.4062 0.5625C10.4062 0.25184 10.6581 0 10.9688 0C11.2794 0 11.5312 0.25184 11.5312 0.5625Z"
        />
        <path
          opacity="0.3"
          d="M7.59375 17.4375C7.59375 17.7482 7.34191 18 7.03125 18C6.72059 18 6.46875 17.7482 6.46875 17.4375C6.46875 17.1268 6.72059 16.875 7.03125 16.875C7.34191 16.875 7.59375 17.1268 7.59375 17.4375Z"
        />
        <path
          opacity="0.3"
          d="M11.5312 17.4375C11.5312 17.7482 11.2794 18 10.9688 18C10.6581 18 10.4062 17.7482 10.4062 17.4375C10.4062 17.1268 10.6581 16.875 10.9688 16.875C11.2794 16.875 11.5312 17.1268 11.5312 17.4375Z"
        />
        <path
          opacity="0.6"
          d="M8.15625 14.625C8.15625 15.2463 7.65257 15.75 7.03125 15.75C6.40993 15.75 5.90625 15.2463 5.90625 14.625C5.90625 14.0037 6.40993 13.5 7.03125 13.5C7.65257 13.5 8.15625 14.0037 8.15625 14.625Z"
        />
        <path
          opacity="0.6"
          d="M4.5 3.375C4.5 3.99632 3.99632 4.5 3.375 4.5C2.75368 4.5 2.25 3.99632 2.25 3.375C2.25 2.75368 2.75368 2.25 3.375 2.25C3.99632 2.25 4.5 2.75368 4.5 3.375Z"
          fillOpacity="0.6"
        />
        <path
          opacity="0.6"
          d="M4.5 14.625C4.5 15.2463 3.99632 15.75 3.375 15.75C2.75368 15.75 2.25 15.2463 2.25 14.625C2.25 14.0037 2.75368 13.5 3.375 13.5C3.99632 13.5 4.5 14.0037 4.5 14.625Z"
        />
        <path
          opacity="0.4"
          d="M4.5 7.03125C4.5 7.65257 3.99632 8.15625 3.375 8.15625C2.75368 8.15625 2.25 7.65257 2.25 7.03125C2.25 6.40993 2.75368 5.90625 3.375 5.90625C3.99632 5.90625 4.5 6.40993 4.5 7.03125Z"
        />
        <path
          opacity="0.4"
          d="M4.5 10.9688C4.5 11.5901 3.99632 12.0938 3.375 12.0938C2.75368 12.0938 2.25 11.5901 2.25 10.9688C2.25 10.3474 2.75368 9.84375 3.375 9.84375C3.99632 9.84375 4.5 10.3474 4.5 10.9688Z"
        />
        <path
          opacity="0.6"
          d="M15.75 3.375C15.75 3.99632 15.2463 4.5 14.625 4.5C14.0037 4.5 13.5 3.99632 13.5 3.375C13.5 2.75368 14.0037 2.25 14.625 2.25C15.2463 2.25 15.75 2.75368 15.75 3.375Z"
          fillOpacity="0.6"
        />
        <path
          opacity="0.6"
          d="M15.75 14.625C15.75 15.2463 15.2463 15.75 14.625 15.75C14.0037 15.75 13.5 15.2463 13.5 14.625C13.5 14.0037 14.0037 13.5 14.625 13.5C15.2463 13.5 15.75 14.0037 15.75 14.625Z"
        />
        <path
          opacity="0.4"
          d="M15.75 7.03125C15.75 7.65257 15.2463 8.15625 14.625 8.15625C14.0037 8.15625 13.5 7.65257 13.5 7.03125C13.5 6.40993 14.0037 5.90625 14.625 5.90625C15.2463 5.90625 15.75 6.40993 15.75 7.03125Z"
        />
        <path
          opacity="0.4"
          d="M15.75 10.9688C15.75 11.5901 15.2463 12.0938 14.625 12.0938C14.0037 12.0938 13.5 11.5901 13.5 10.9688C13.5 10.3474 14.0037 9.84375 14.625 9.84375C15.2463 9.84375 15.75 10.3474 15.75 10.9688Z"
        />
        <path
          opacity="0.6"
          d="M12.0938 14.625C12.0938 15.2463 11.5901 15.75 10.9688 15.75C10.3474 15.75 9.84375 15.2463 9.84375 14.625C9.84375 14.0037 10.3474 13.5 10.9688 13.5C11.5901 13.5 12.0938 14.0037 12.0938 14.625Z"
        />
        <path d="M8.4375 10.9688C8.4375 11.7454 7.8079 12.375 7.03125 12.375C6.2546 12.375 5.625 11.7454 5.625 10.9688C5.625 10.1921 6.2546 9.5625 7.03125 9.5625C7.8079 9.5625 8.4375 10.1921 8.4375 10.9688Z" />
        <path d="M12.375 7.03125C12.375 7.8079 11.7454 8.4375 10.9688 8.4375C10.1921 8.4375 9.5625 7.8079 9.5625 7.03125C9.5625 6.2546 10.1921 5.625 10.9688 5.625C11.7454 5.625 12.375 6.2546 12.375 7.03125Z" />
        <path d="M12.375 10.9688C12.375 11.7454 11.7454 12.375 10.9688 12.375C10.1921 12.375 9.5625 11.7454 9.5625 10.9688C9.5625 10.1921 10.1921 9.5625 10.9688 9.5625C11.7454 9.5625 12.375 10.1921 12.375 10.9688Z" />
      </g>
      <defs>
        <clipPath id="clip0_1044_103161">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTotal;
