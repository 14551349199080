import { IconButton, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { styles } from './styles';
import { useDroppable } from '@dnd-kit/core';
import Risk from './risk';
import Draggable from './draggable';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { openDrawer } from '../../../../reactiveVariables';
import { StyledPaper } from '../../../ValueStream/components/Common';

const useStyles = makeStyles(styles);

export function RiskArea(props) {
  const classes = useStyles();

  const { setNodeRef } = useDroppable({ id: props.id });

  const handleClick = (risk) => openDrawer(risk, 'risk');

  return (
    <StyledPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'color.column',
        height: '100%',
        padding: '8px',
        overflow: 'auto',
      }}>
      <div style={{ flexShrink: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={classes.title} variant="h6">
          {props.title}
        </Typography>
        <IconButton aria-label="sync" onClick={() => handleClick()} className={classes.button} size="large">
          <SvgIcon component={AddIcon} />
        </IconButton>
      </div>
      <div ref={setNodeRef} style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}>
        {props.risks.map((item) => (
          <Draggable key={item.id} element={item} data={item}>
            <Risk overlay={false} risk={item} classes={classes} handleClick={handleClick} />
          </Draggable>
        ))}
      </div>
    </StyledPaper>
  );
}
