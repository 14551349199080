import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import Grow from '@mui/material/Grow';
import makeStyles from '@mui/styles/makeStyles';
import { find, get, sortBy } from 'lodash';
import { exportCSVData, getFieldValue } from 'utils/export';

import EntityTable from '../../../components/Common/EntityTable';
import { openDrawer, selectedProductThemeVar } from '../../../reactiveVariables';
import { STAGE_SELECTS } from '../../../utils/formConstants';
import { filterProductTheme } from '../../../utils/helpers';
import { GET_BACKLOGS } from '../../Settings/GraphQL/backlogs';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { csvColumns, headCells } from './constants';
import { GET_FEATURES_FOR_PI, GET_FEATURES_FOR_PROGRAMME } from './graphql';
import { getRowsData } from './helper';
import Stories from './Stories';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const Features = (props, ref) => {
  const { searchQuery, filterByPi } = props;
  const classes = useStyles();
  const theme = useTheme();

  const featuresQuery = filterByPi ? GET_FEATURES_FOR_PI : GET_FEATURES_FOR_PROGRAMME;

  const { data: { features = [] } = {} } = useQuery(featuresQuery);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const filteredFeatures = useMemo(
    () => features.filter((feature) => filterProductTheme(selectedProductTheme, feature)),
    [features, selectedProductTheme],
  );

  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  useImperativeHandle(ref, () => ({
    export: () => exportFeatures(),
  }));

  const onRowClick = (event, row) => {
    const feature = filteredFeatures.find((feature) => feature?.id === row.id);
    openDrawer(feature, 'feature');
  };

  const removeHtmlTag = (str) => str?.replace(/<[^>]*>/g, '') || '';

  const exportFeatures = useCallback(() => {
    const sortedColumns = [...headCells, ...csvColumns];

    const data = sortBy(features, ['team', 'programIncrement', 'status'])
      .map((feature) => {
        const pi = find(programIncrements, { id: feature.programIncrement }) || {};
        const committedSprint = find(pi.sprints, { id: feature.committedSprint }) || {};
        const backlog = find(backlogs, { id: feature.backlogId }) || {};
        const initiative = find(initiatives, { id: feature.initiativeId }) || {};

        const estimatedSprint = find(pi.sprints, { id: feature.estimatedSprint });

        return {
          id: feature.id,
          name: feature.name,
          initiative: initiative?.name,
          assignedTo: feature.assignedTo,
          stage: find(STAGE_SELECTS, { value: feature.stage })?.label,
          programIncrement: pi.name,
          team: feature?.team?.name,
          estimatedSprint: estimatedSprint?.name
            ? estimatedSprint?.name?.includes('Sprint')
              ? estimatedSprint?.name
              : `Sprint ${estimatedSprint?.name}`
            : '',
          epicId: feature.epicId,
          percentageDone: get(feature, 'metrics.percentageDone', 0) + '%',
          committedSprint: committedSprint.name,
          committedSprintDate: committedSprint.endDate,
          storyPointsToDo: get(feature, 'metrics.storyPoints.total', 0),
          storyPointsInProgress: get(feature, 'metrics.storyPoints.inProgress', 0),
          storyPointsDone: get(feature, 'metrics.storyPoints.done', 0),
          progress: feature.metrics?.percentageDone || 0,
          plannedReleaseDate: feature?.plannedReleaseDate,
          releaseDate: feature?.releaseDate,
          description: removeHtmlTag(feature.description),
          wsjf: feature.wsjf,
          businessValue: feature.businessValue,
          timeCriticality: feature.timeCriticality,
          size: feature.size,
          riskReduction: feature.riskReduction,
          backlog: backlog.name,
        };
      })
      .map((rowData) => sortedColumns.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(sortedColumns, data, 'Features');
  }, [programIncrements, features]);

  const getStories = (row, setLoading) => {
    const feature = filteredFeatures.find((feature) => feature?.id === row.id);
    return <Stories feature={feature} setLoading={setLoading} />;
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          headCells={headCells}
          expandFunction={getStories}
          rows={getRowsData(filteredFeatures, teams, backlogs, programIncrements, theme, initiatives)}
          searchQuery={searchQueryLowerCase}
          expand={true}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Features);
