import { grey } from '@mui/material/colors';

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  paper: {
    textAlign: 'center',
  },
  table: {
    borderBottom: '0',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableCell: {
    borderBottom: '0',
    textAlign: 'center',
    border: `1px solid ${theme.palette.color.tableBorder}`,
    borderRight: 0,
    padding: 4,
    minWidth: 200,
  },
  currentSprint: {
    backgroundColor: 'rgba(255,73,158, 0.08)',
  },
  tableCellNoBorder: {
    borderBottom: '0',
    textAlign: 'center',
    padding: '4px',
  },
  tableHeadRow: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    borderBottom: '0',
    borderRight: '1px solid ' + grey[200],
    padding: '4px 18px',
    '& h6': {
      textAlign: 'left',
      color: 'rgba(30, 35, 42, 0.51)',
    },
  },
  tableHeadCol: {
    fontSize: '12px',
    border: 'none',
    borderTop: `1px solid ${theme.palette.color.tableBorder}`,
    padding: '8px',
  },
  teamName: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadObj: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '0',
    borderRight: '1px solid ' + grey[200],
  },
  tableCellMilestones: {
    border: 'none',
  },
  chip: {
    margin: 2,
    color: 'white',
  },
  piChip: {
    color: 'white',
    background: grey[700],
  },
  featureSwitch: {
    color: 'white',
  },
  icon: {
    marginRight: 8,
    marginLeft: 8,
  },
  objective: {
    backgroundColor: '#426CB4',
    margin: 2,
    color: 'white',
  },
  dependency: {
    margin: 2,
    backgroundColor: theme.palette.color.dependency,
    borderColor: 'purple',
    color: 'purple',
  },
  piStatus: {
    backgroundColor: 'purple',
    border: 'none',
    color: 'white',
    height: '30px',
    width: '60px',
    fontSize: 10,
    marginLeft: 5,
  },
  badgeEnabled: {
    top: 0,
    color: 'white',
    backgroundColor: '#4286f4',
    fontSize: 10,
    fontWeight: 300,
    opacity: 0.8,
  },
  badgeRoot: {
    marginRight: 8,
  },
  sprintDates: {
    fontSize: 11,
  },
  sprintItems: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeight.values.medium,
  },
});
