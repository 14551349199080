import { CsvBuilder } from 'filefy';
export const byString = (o, s) => {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i];
    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }
  return o;
};

export const getFieldValue = (rowData, columnDef, lookup = true) => {
  let value =
    typeof rowData[columnDef.field] !== 'undefined' ? rowData[columnDef.field] : byString(rowData, columnDef.field);

  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value];
  }

  return value;
};

export function exportCSVData(columns, data, type) {
  const builder = new CsvBuilder(`${type}.csv`);
  builder
    .setDelimeter(',')
    .setColumns(columns.map((columnDef) => columnDef.label))
    .addRows(data)
    .exportFile();
}
