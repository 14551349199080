import * as React from 'react';

function SvgChevronRight(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="arrows chevs">
        <path
          id="Icon"
          d="M5.33301 13.3333L10.6663 7.99999L5.33301 2.66666"
          stroke="#808080"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgChevronRight;
