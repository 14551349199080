export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  paper: {
    overflow: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: {
    borderBottom: '0',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableCell: {
    borderBottom: '0',
    textAlign: 'center',
    border: `1px solid ${theme.palette.color.tableBorder}`,
    borderRight: 0,
    padding: 4,
    backgroundColor: theme.palette.color.background,
    // minWidth: 200,
  },
  currentSprint: {
    backgroundColor: 'rgba(255,73,158, 0.08)',
  },
  tableCellNoBorder: {
    borderBottom: '0',
    textAlign: 'center',
    padding: '4px',
  },
  tableHeadRow: {
    color: theme.palette.text.primary,
    transition: 'background-color 0.5s',
    borderRight: `1px solid  ${theme.palette.color.borderPaper}`,
    backgroundColor: (props) => (props.stickyHeader ? theme.palette.color.darkPaper : theme.palette.color.background),
    textAlign: 'center',
    borderBottom: (props) => (props.stickyHeader ? `1px solid ${theme.palette.color.tableBorder}` : '0'),
    padding: '4px 18px',
    '& h6': {
      textAlign: 'left',
      color: 'rgba(30, 35, 42, 0.51)',
    },
  },
  tableHeadCol: {
    fontSize: '12px',
    backgroundColor: theme.palette.color.background,
    border: 'none',
    borderTop: `1px solid ${theme.palette.color.tableBorder}`,
  },
  teamName: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadObj: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '0',
    borderRight: `1px solid  ${theme.palette.color.borderPaper}`,
    backgroundColor: '#191919',
  },
  row: {
    backgroundColor: theme.palette.background.default,
  },
  rowMilestones: {
    backgroundColor: theme.palette.color.background,
  },
  tableCellMilestones: {
    border: 'none',
    backgroundColor: theme.palette.color.background,
  },
  scroll: {
    height: '100%',
    width: '100%',
  },
  tableHeadCellRoot: {
    borderBottom: 'none',
    padding: 0,
  },
  tableTeamCell: {
    fontSize: '12px',
    backgroundColor: 'rgba(201, 172, 172, 0.04)',
    border: `1px solid ${theme.palette.color.borderPaper}`,
    borderBottom: '0',
    width: '12.5%',
  },
  typo: {
    lineHeight: '1.375em',
    letterSpacing: 0,
    width: 'auto',
  },
  metricsProgress: {
    paddingTop: 8,
  },
  linearProgressBox: {
    width: '100%',
    padding: '8px 6px',
  },
  linearProgress: {
    height: 7,
    borderRadius: 100,
  },
  colorPrimary: {
    backgroundColor: theme.palette.color.silver,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.color.butterscotch,
  },
  barColorPrimaryGreen: {
    backgroundColor: theme.palette.color.algaeGreen,
  },
  card: {
    borderLeft: '0.3em solid #426CB4',
    margin: '10px',
  },
  cardContent: {
    padding: 10,
  },
  featureSwitch: {
    color: '#C82C64',
  },
  sprintDates: {
    fontSize: 11,
  },
  sprintItems: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeight.values.medium,
  },
});
