import { mean, find, get, orderBy, sum, sumBy, takeWhile, last, groupBy, uniq, takeRight } from 'lodash';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PROGRAM_INCREMENTS } from '../../../Settings/ProgramIncrement/graphql';
import { GET_FEATURES_FOR_PROGRAMME } from '../../Features/graphql';
import { selectedProgrammeVar } from '../../../../reactiveVariables';
import { useMemo } from 'react';
import { date, getPiDates } from '../../../../utils/helpers';
import { DateTime } from 'luxon';

const defaultValue = {
  pis: [],
  cycleTime: 0,
  incrementVelocity: 0,
  plannedFeatures: [],
  doneFeatures: [],
  filteredFeatures: [],
};

const getSelectedTeams = (teams, projectionFilters) => {
  if (!projectionFilters?.velocityBasedOn?.length) return teams.filter((team) => team.board);
  return teams.filter((team) => team.board && projectionFilters.velocityBasedOn.includes(team.id));
};

const filterFeaturesForPIs = (features, pis) => {
  const piIds = pis.map(({ id }) => id);
  return features.filter(({ programIncrement }) => piIds.includes(programIncrement));
};

export const usePiRoadmap = (filteredFeatures, forecastValue, teams, projectionFilters, filteredPIs) => {
  const last4CompletedPis = takeRight(filteredPIs.filter((pi) => pi.status === 'complete'));
  const doneFeatures = useMemo(
    () => filteredFeatures.filter((feature) => feature.status === 'Done'),
    [filteredFeatures],
  );
  const averagePiLength = Math.round(mean(filteredPIs.map((pi) => getPiDates(pi).length)));

  const doneFeaturesByTeam = useMemo(
    () => groupBy(filterFeaturesForPIs(doneFeatures, last4CompletedPis), 'teamId'),
    [doneFeatures, filteredPIs],
  );

  const avgVelocityPerTeam =
    Math.round(
      mean(
        getSelectedTeams(teams, projectionFilters).map((team) =>
          forecastValue === 'feature'
            ? doneFeaturesByTeam[team.id]?.length || 0
            : sum(doneFeaturesByTeam[team.id]?.map((feature) => feature.size)) || 0,
        ),
      ),
    ) || 0 / (last4CompletedPis.length || 1);

  const incrementVelocity = useMemo(
    () =>
      Math.round(
        filteredPIs
          .filter((pi) => pi.status === 'complete')
          .map((pi) => {
            const piFeatures = doneFeatures.filter((feature) => feature.programIncrement === pi.id);
            return forecastValue === 'feature' ? piFeatures.length : sum(piFeatures.map((feature) => feature.size));
          })
          .reduce((velocity, piVelocity) => (velocity > 0 ? (velocity + piVelocity) / 2 : piVelocity), 0),
      ) || 1,
    [filteredPIs, doneFeatures, forecastValue],
  );

  let projectedVelocity =
    projectionFilters?.nbOfTeams && projectionFilters?.nbOfTeams !== teams.length
      ? incrementVelocity + (projectionFilters?.nbOfTeams - teams.length) * avgVelocityPerTeam
      : null;

  projectedVelocity = projectedVelocity < 0 ? 0 : projectedVelocity;

  const velocity = projectedVelocity ?? incrementVelocity;

  const spVelocity = useMemo(
    () =>
      Math.round(
        filteredPIs
          .filter((pi) => pi.status === 'complete')
          .map((pi) => {
            const piFeatures = doneFeatures.filter((feature) => feature.programIncrement === pi.id);
            return sum(piFeatures.map((feature) => feature.storyPointCount));
          })
          .reduce((velocity, piVelocity) => (velocity > 0 ? (velocity + piVelocity) / 2 : piVelocity), 0),
      ) || 1,
    [filteredPIs, doneFeatures],
  );

  const cycleTime = useMemo(
    () => mean(doneFeatures.filter((feature) => feature.cycleTime).map((feature) => feature.cycleTime)) || 0,
    [doneFeatures],
  );

  const plannedFeatures = useMemo(
    () =>
      orderBy(
        filteredFeatures.filter((feature) => {
          const pi = find(filteredPIs, { id: feature.programIncrement });
          return !pi || pi.status !== 'complete';
        }),
        ['programIncrement', (item) => get(item, 'wsjf', 0) || -1, (item) => get(item, 'priority')],
        ['asc', 'desc', 'asc'],
      ).map((feature, index) => ({ ...feature, order: index + 1 })),
    [filteredFeatures, filteredPIs],
  );

  const notAssignedFeatures = useMemo(
    () => plannedFeatures.filter((feature) => !feature.programIncrement),
    [plannedFeatures],
  );
  const unassignedFeatures = [...notAssignedFeatures];

  const getForecastFeatures = (features, piFeatures) => {
    if (forecastValue === 'feature') {
      return unassignedFeatures.splice(0, velocity - (piFeatures?.length || 0));
    } else {
      let totalSize = piFeatures ? sumBy(piFeatures, (feature) => feature.size) : 0;
      const addedFeatures = takeWhile(features, (feature) => {
        const initialSize = totalSize;
        if (totalSize < velocity) totalSize += feature.size;
        return initialSize === 0 || totalSize < velocity;
      });

      unassignedFeatures.splice(0, addedFeatures.length);

      return addedFeatures;
    }
  };

  let pis = useMemo(
    () =>
      filteredPIs
        .filter((pi) => pi.status !== 'complete')
        .map((pi) => {
          const { startDate, endDate } = getPiDates(pi);
          const piFeatures = plannedFeatures
            .filter((feature) => feature.programIncrement === pi.id)
            .map((feature) => ({ ...feature, planned: true }));

          if (pi.status === 'planning' && piFeatures.length < velocity) {
            const addedFeatures = getForecastFeatures(unassignedFeatures, piFeatures);
            const fullPiFeatures = orderBy(
              [...piFeatures, ...addedFeatures],
              ['programIncrement', (item) => get(item, 'wsjf', 0), (item) => get(item, 'priority')],
              ['asc', 'desc', 'asc'],
            );
            return { ...pi, startDate, endDate, features: fullPiFeatures };
          } else {
            return { ...pi, startDate, endDate, features: piFeatures };
          }
        }),
    [filteredPIs, plannedFeatures, velocity, unassignedFeatures],
  );

  if (!filteredPIs.length || !filteredFeatures.length) {
    return defaultValue;
  }

  const extraPis = [];
  let previousPiEndDate = last(pis)?.sprints.length ? date(getPiDates(last(pis)).endDate) : null;

  while (unassignedFeatures.length && velocity > 0) {
    extraPis.push({
      name: `FI-${extraPis.length + 1}`,
      status: 'projected',
      startDate: previousPiEndDate && previousPiEndDate.plus({ days: 1 }),
      endDate: previousPiEndDate && previousPiEndDate.plus({ days: averagePiLength + 1 }),
      features: getForecastFeatures(unassignedFeatures),
    });
    previousPiEndDate = previousPiEndDate && previousPiEndDate.plus({ days: averagePiLength + 1 });
  }

  pis = [...pis, ...extraPis];

  return {
    pis,
    cycleTime,
    spVelocity,
    projectedVelocity,
    incrementVelocity,
    plannedFeatures,
    doneFeatures,
  };
};
