const drawerWidth = 240;

export const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRight: '1px solid #ebebeb',
  },
  appBar: {
    zIndex: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.color.background,
    height: 65,
    border: 'none',
    borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 4,
    marginRight: 4,
  },
  logo: {
    height: 44,
    width: 44,
  },
  logoText: {
    letterSpacing: '-0.14px',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.color.purpulishPink,
    fontSize: 20,
    fontWeight: 600,
  },
  accountIconDelimiter: {
    width: 1,
    height: 30,
    margin: '13px 30px 17px',
    opacity: 0.31,
    backgroundColor: theme.palette.color.slateGrey,
    verticalAlign: 'middle',
  },
  accountIconButton: {
    height: '50px',
    width: '50px',
  },
  accountIcon: {
    fontSize: 40,
  },
  icon: {
    textDecoration: 'none',
    paddingRight: 0,
    cursor: 'pointer',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '75px',
  },
  toolbarRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 40,
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'inherit',
    },
  },
});
