import { Fieldset, TextInput, Text, Title, useMantineTheme, Select, Group, Button, SimpleGrid } from '@mantine/core';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from '../../../../Settings/GraphQL/users';
import { DateInput } from '@mantine/dates';
import {
  Field,
  FormContext,
  renderCombobox,
  renderDate,
  renderInput,
} from '../../../../../components/Common/FormFieldsHooks/MantineField';
import { useForm } from 'react-hook-form';
import React from 'react';
import { UPDATE_PORTFOLIO_OBJECTIVE } from '../../graphql';
import { showNotification } from '@mantine/notifications';

const Details = ({ objective }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const [updateObjective, { data, loading, error }] = useMutation(UPDATE_PORTFOLIO_OBJECTIVE);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: objective,
  });

  const onSubmit = (values) => {
    updateObjective({
      variables: { portfolioObjectiveId: objective.id, portfolioObjective: values },
    }).then(() => {
      reset(values);
      showNotification({
        title: 'Objective Updated',
        message: `Objective was successfully updated`,
      });
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContext.Provider value={{ control, errors }}>
          <Fieldset>
            <Group justify="space-between" align="center">
              <div>
                <Title order={6}>General</Title>
                <Text c="dimmed" mb={12}>
                  Keep your objective statement high level
                </Text>
              </div>
              {isDirty && (
                <Button
                  variant="light"
                  radius={8}
                  color={theme.other.colours.buttonIcon}
                  onClick={() => handleSubmit(onSubmit)()}
                  loading={loading}
                  size="xs">
                  Save
                </Button>
              )}
            </Group>
            <Field name="name" renderField={renderInput} label="Name/objective goal" />
            <Field name="description" renderField={renderInput} label="Description" mt="md" />
            <Field
              name="owner"
              renderField={renderCombobox}
              label="Owner"
              mt="md"
              data={users.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))}
              styles={{
                wrapper: { width: '30%', minWidth: '200px' },
              }}
            />
          </Fieldset>
          <Fieldset m="12px 0" bg={theme.other.colours.paper}>
            <Title order={6}>Objective Settings</Title>
            <Text c="dimmed" mb={12}>
              Set specifics for your objective, add content here
            </Text>
            <Group grow>
              <SimpleGrid cols={3}>
                <Field name="targetDate" clearable={false} renderField={renderDate} label="Target Date" />
                <Field
                  name="reviewFrequency"
                  renderField={renderCombobox}
                  label="Review Frequency"
                  data={[
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'monthly', label: 'Monthly' },
                    { value: 'quarterly', label: 'Quarterly' },
                    { value: '6months', label: 'Every 6 months' },
                  ]}
                />
                <Field name="visibility" renderField={renderCombobox} label="Visibility" data={['Public', 'Private']} />
              </SimpleGrid>
            </Group>
          </Fieldset>
        </FormContext.Provider>
      </form>
    </>
  );
};

export default Details;
