export const deleteUpdate = (element, updatedElement) => (cache, mutationResult) =>
  cache.modify({
    fields: {
      [`${element}s`]: (previous, { readField }) =>
        previous.filter((ref) => readField('id', ref) !== mutationResult.data[updatedElement || element].id),
    },
  });

export const deleteUserUpdate = (cache, mutationResult) =>
  cache.modify({
    fields: {
      users: (previous, { readField }) =>
        previous.filter((ref) => readField('id', ref) !== mutationResult.data?.users.id),
    },
  });

export const insertUpdate = (element, updatedElement) => (cache, mutationResult) =>
  cache.modify({
    fields: {
      [`${element}s`]: (previous, { toReference }) => [
        ...previous,
        toReference(mutationResult.data[updatedElement || element]),
      ],
    },
  });

export const insertUserUpdate = (cache, mutationResult) =>
  cache.modify({
    fields: {
      users: (previous, { toReference }) => [...previous, toReference(mutationResult.data?.users)],
    },
  });
