import { Container, ScrollArea, Group, Modal, Text, Title, useMantineTheme, Button } from '@mantine/core';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import SvgKeyResult from '../../../../../assets/images/KeyResult';
import KrDetails from './Details';
import Chart from './Chart';
import Trajectory from './Trajectory';
import Output from './Output';
import { useFieldArray, useForm } from 'react-hook-form';
import { mapValues, omitBy } from 'lodash';
import { showNotification } from '@mantine/notifications';
import { FormContext } from '../../../../../components/Common/FormFieldsHooks/MantineField';
import classes from './KeyResultDetails.module.css';
import { useMutation } from '@apollo/client';
import { GET_PORTFOLIO_OBJECTIVES, UPDATE_PORTFOLIO_KEY_RESULT } from '../../graphql';
import cn from 'classnames';
import { colorForStatus } from '../../utils';

const KeyResultDetails = ({ objective, vsColor, keyResult, open, setSelectedKeyResult }) => {
  const theme = useMantineTheme();
  const [page, setPage] = useState(0);

  const [updateKeyResult, { loading }] = useMutation(UPDATE_PORTFOLIO_KEY_RESULT, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: keyResult,
  });

  const fieldArray = useFieldArray({
    control,
    name: 'trajectory',
    keyName: 'formId',
  });

  const onSubmit = (values) => {
    const keyResultToSave = mapValues(
      omitBy(values, (property, key) =>
        ['id', 'updates', 'status', 'output', 'outputFeatures', 'outputInitiatives', 'objectiveId'].includes(key),
      ),
      (property) => (property === '' ? null : property),
    );

    updateKeyResult({
      variables: { keyResult: keyResultToSave, keyResultId: values.id },
    }).then(() => {
      setSelectedKeyResult(values);
      reset(values);
      showNotification({
        title: 'Key Result Updated',
        message: `Key Result ${values?.id} was successfully updated`,
      });
    });
  };

  const currentKeyResult = watch();

  return (
    <Modal.Root
      opened={open}
      onClose={() => setSelectedKeyResult(null)}
      size="50%"
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}
      styles={{
        content: {
          borderRadius: '12px',
          border: `1px solid ${theme.other.colours.tableBorder}`,
          boxShadow: '0px 2px 2px 0px #0000000D',
        },
      }}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header p={'16px 16px 0 16px'}>
          <Group gap={0}>
            <Modal.Title>
              <Group gap={0}>
                <Title order={4} mr={16}>
                  {keyResult.name}
                </Title>
                <Display2
                  size="md"
                  h={null}
                  bgColor={colorForStatus(keyResult.status)}
                  style={{ borderRadius: '100px' }}>
                  {keyResult.status}
                </Display2>
              </Group>
            </Modal.Title>
            <Modal.CloseButton />
            <Group w="100%" p={0} gap={0} style={{ backgroundColor: theme.other.colours.paper }}>
              <Box
                sx={{ borderBottom: 1, margin: '0 -16px 0 -16px', borderColor: 'divider', width: 'calc(100% + 32px)' }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="false"
                  sx={{ margin: '0 16px', height: '60px' }}
                  value={page}
                  onChange={(event, value) => setPage(value)}
                  indicatorColor="secondary"
                  textColor="inherit">
                  <Tab label="Details" />
                  <Tab
                    icon={<SvgKeyResult color={theme.other.colours.buttonIcon} />}
                    iconPosition="start"
                    label="Chart"
                  />
                  <Tab label="Trajectory" />
                  {/*<Tab label="Timeline" />*/}
                  <Tab label="Deliverables" />
                </Tabs>
              </Box>
            </Group>
          </Group>
        </Modal.Header>
        <Modal.Body
          p={0}
          style={{ backgroundColor: theme.other.colours.background, boxShadow: '0px 2px 2px 0px #0000000D' }}>
          <ScrollArea.Autosize mh={500} type="scroll">
            <Container
              w="100%"
              h="calc(100% - 124px)"
              pt={16}
              pb={16}
              style={{ maxWidth: '100%', backgroundColor: theme.other.colours.background }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormContext.Provider value={{ control, errors }}>
                  {page === 0 && <KrDetails keyResult={keyResult} />}
                  {page === 1 && <Chart keyResult={keyResult} />}
                  {page === 2 && <Trajectory keyResult={keyResult} fieldArray={fieldArray} />}
                  {page === 3 && <Output objective={objective} keyResult={currentKeyResult} setValue={setValue} />}
                </FormContext.Provider>
              </form>
            </Container>
          </ScrollArea.Autosize>
        </Modal.Body>
        <div className={cn(classes.footer, isDirty && classes.footerDisplay)}>
          <Button
            onClick={() => reset(keyResult)}
            loading={loading}
            variant="light"
            radius={8}
            color={theme.other.colours.buttonIcon}
            size="sm"
            {...(!isDirty && { display: 'none' })}>
            Discard
          </Button>
          <Button
            onClick={() => handleSubmit(onSubmit)()}
            variant="light"
            radius={8}
            color={theme.other.colours.buttonIcon}
            size="sm"
            {...(!isDirty && { display: 'none' })}>
            Save
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

export default KeyResultDetails;
