export const buildPieData = (toDoFeatures, doneFeatures, inProgressFeatures) => {
  return [
    {
      id: 'To Do',
      label: 'To Do',
      value: toDoFeatures,
      color: '#808080',
    },
    {
      id: 'In Progress',
      label: 'In Progress',
      value: inProgressFeatures,
      color: '#82A7ED',
    },
    {
      id: 'Done',
      label: 'Done',
      value: doneFeatures,
      color: '#2F6DE1',
    },
  ];
};
