import { useQuery } from '@apollo/client';
import { GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { ClickableCard, DisplayID, IDWrapper } from '../../../components/Common/Elements';
import { openDrawer } from '../../../reactiveVariables';
import { Divider, Group, Text } from '@mantine/core';
import React from 'react';
import { uniq } from 'lodash';
import { useTheme } from '@emotion/react';
import SvgInitiatives from '../../../assets/images/Initiatives';
import SvgFeatures from '../../../assets/images/Features';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';

const MilestoneExpand = ({ milestone, features, setLoading }) => {
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);

  const milestoneFeatures = features.filter((feature) => feature.milestones?.some((fOkr) => fOkr.id === milestone.id));
  const milestoneInitiatives = initiatives.filter((initiative) =>
    initiative.milestones?.some((iOkr) => iOkr.id === milestone.id),
  );
  const theme = useTheme();

  return (
    <>
      {!!milestoneFeatures.length && <Text size="sm">Features: {milestoneFeatures.length || 0}</Text>}
      {milestoneFeatures.map((feature) => (
        <ClickableCard key={feature.id} onClick={() => openDrawer(feature, 'feature')}>
          <IDWrapper>
            <SvgFeatures color={theme.palette.color.buttonIcon} />
            <DisplayID pt="8px" span={true} size="xs">
              F-{feature.id}
            </DisplayID>
          </IDWrapper>
          <div style={{ padding: '10px' }}>
            <Text lineClamp={1} fw={450} size="sm">
              {feature.name}
            </Text>
            <Group>
              <Text span={true} size="xs">
                Stories: {feature.metrics?.storyCount?.done || 0} / {feature.metrics?.storyCount?.total || 0}
              </Text>
              <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
              <Text span={true} size="xs">
                Sprint: {sprints.find((sprint) => sprint.id === feature.estimatedSprint)?.name}
              </Text>
              <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
              <Text span={true} size="xs">
                Status: {feature.status}
              </Text>
            </Group>
          </div>
        </ClickableCard>
      ))}
      {!!milestoneInitiatives.length && <Text size="sm">Initiatives: {milestoneInitiatives.length || 0}</Text>}
      {milestoneInitiatives.map((initiative) => (
        <ClickableCard key={initiative.id} onClick={() => openDrawer(initiative, 'initiative')}>
          <IDWrapper>
            <SvgInitiatives color={theme.palette.color.buttonIcon} />
            <DisplayID span={true} size="xs">
              I-{initiative.id}
            </DisplayID>
          </IDWrapper>
          <div style={{ padding: '10px' }}>
            <Text lineClamp={1} fw={450} size="sm">
              {initiative.name}
            </Text>
            <Group>
              <Text span={true} size="xs">
                Programmes:{' '}
                {uniq(initiative.features?.nodes?.filter((node) => node.pi).map((node) => node.pi.programme))
                  .sort()
                  ?.join(', ')}
              </Text>
              <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
              <Text span={true} size="xs">
                Features: {initiative.features?.aggregate?.count || 0}
              </Text>
              <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
              <Text span={true} size="xs">
                Status: {initiative.status}
              </Text>
            </Group>
          </div>
        </ClickableCard>
      ))}
    </>
  );
};

export default MilestoneExpand;
