import * as React from 'react';

function SvgMoreAction(props) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="... horizontal">
        <rect width="28" height="28" transform="translate(0 28) rotate(-90)" />
        <circle id="Ellipse 1" cx="14" cy="21" r="2" transform="rotate(-90 14 21)" fill="#808080" />
        <circle id="Ellipse 3" cx="14" cy="7" r="2" transform="rotate(-90 14 7)" fill="#808080" />
        <circle id="Ellipse 2" cx="14" cy="14" r="2" transform="rotate(-90 14 14)" fill="#808080" />
      </g>
    </svg>
  );
}

export default SvgMoreAction;
