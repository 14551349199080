import { CircularProgress, Dialog, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CONNECTORS } from '../../../containers/Settings/GraphQL/connectors';
import { GET_EPIC, GET_EPICS } from '../../../containers/Product/Features/graphql';
import { useDebouncedCallback } from '../../../utils/hooks';
import Toolbar from '@mui/material/Toolbar';
import RoundButton from '../../Common/Button/RoundButton';
import { drawerLoadingVar, openDrawer } from '../../../reactiveVariables';
import { getToolingName } from '../../../utils/helpers';
import { get } from 'lodash';

export default function ImportDialog(props) {
  const { handleCancel, openDialog, title } = props;
  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);
  const [searchEpics, { loading, data: { epics = [] } = {} }] = useLazyQuery(GET_EPICS);
  const [getEpic, { loadingEpic, data: { epic = [] } = {} }] = useLazyQuery(GET_EPIC);

  const [connector, setConnector] = useState(connectors[0]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fetchEpics = useDebouncedCallback(() => {
    searchEpics({ variables: { connectorId: connector.id, query: inputValue } });
  }, 500);

  useEffect(() => {
    if (inputValue && open) {
      fetchEpics();
    }
  }, [inputValue, connector]);

  return (
    <Dialog open={openDialog} style={{ borderRadius: 30 }} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          select
          value={connector}
          onChange={(event) => setConnector(event.target.value)}
          label="Connector"
          placeholder="Connector"
          margin="normal"
          variant="outlined">
          {connectors.map((connector) => (
            <MenuItem key={connector.id} value={connector}>
              {connector.name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          id="jira-epics"
          style={{ width: 300 }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) => `${option.key} - ${option.summaryText}`}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          onChange={(_e, option) => setSelectedEpic(option)}
          options={epics}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={getToolingName(true)}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <Toolbar>
        <RoundButton disableElevation autoSize buttonText="Cancel" handleClick={handleCancel} />
        <div style={{ textAlign: 'right', flex: 1 }}>
          <RoundButton
            disableElevation
            autoSize
            buttonText="Import Feature"
            handleClick={() => {
              drawerLoadingVar(true);
              getEpic({ variables: { connectorId: connector.id, epicId: selectedEpic.key } }).then((epic) => {
                drawerLoadingVar(false);
                openDrawer(
                  {
                    name: epic.data?.epic.fields.summary,
                    description: epic.data?.epic.fields.description,
                    status: get(epic.data?.epic, 'fields.status.statusCategory.name', 'To Do'),
                    epicId: selectedEpic.key,
                  },
                  'feature',
                );
              });
            }}
          />
        </div>
      </Toolbar>
    </Dialog>
  );
}
