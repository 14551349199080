import { selectedProgrammeVar, selectedValueStreamVar } from '../../../reactiveVariables';
import React from 'react';
import { BigAgileSelect } from '../Select/CustomSelect';
import { useTheme } from '@emotion/react';

const ValueStreamSelector = ({ valueStreams, value, handleChange }) => {
  const theme = useTheme();

  if (!value && valueStreams[0]) {
    selectedProgrammeVar(valueStreams[0]?.programmes[0]?.id);
    selectedValueStreamVar(valueStreams[0]?.id);
    window.localStorage.setItem('selectedProgramme', valueStreams[0]?.programmes[0]?.id);
    window.localStorage.setItem('selectedValueStream', valueStreams[0]?.id);
  }

  const renderValue = (option) => (
    <span style={{ color: theme.palette.text.secondary }}>
      VS: <span style={{ color: theme.palette.text.primary }}>{option?.label}</span>
    </span>
  );

  return <BigAgileSelect options={valueStreams} value={value} onChange={handleChange} renderValue={renderValue} />;
};

export default ValueStreamSelector;
