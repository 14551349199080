export const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  titleWrapper: {
    padding: '15px 45px 10px 0px',
  },
  title: {
    color: theme.palette.text.primary,
    width: 81,
    height: 25,
    fontSize: 22,
    fontWeight: theme.typography.fontWeight.bold,
    fontStretch: 'normal',
    lineHeight: 'normal',
  },
});
