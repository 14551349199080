import * as React from 'react';

function Close(props) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.999 4.641a.929.929 0 1 1 1.857 0v16.715a.929.929 0 1 1-1.857 0V4.64zM11.32 5.763a1 1 0 0 1 1.413.056l6 6.5.626.679-.626.678-6 6.5a1 1 0 0 1-1.47-1.356l4.451-4.822h-12a1 1 0 1 1 0-2h12l-4.45-4.822a1 1 0 0 1 .056-1.413z"
        fill="#3D4756"
      />
    </svg>
  );
}

export default Close;
