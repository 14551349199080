import { Button, Divider, Group, Modal, Paper, rgba, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import SvgHexagone from '../../../../assets/images/Hexagon';
import { FormContext } from '../../../../components/Common/FormFieldsHooks/MantineField';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import { IconArrowRight } from '@tabler/icons-react';
import {
  DELETE_PORTFOLIO_KEY_RESULT,
  GET_PORTFOLIO_OBJECTIVES,
  INSERT_PORTFOLIO_KEY_RESULT,
  UPDATE_PORTFOLIO_KEY_RESULT,
} from '../graphql';
import { mapValues, omitBy } from 'lodash';
import { showNotification } from '@mantine/notifications';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import { date } from '../../../../utils/helpers';

const getTitle = (page) => {
  if (page === 0) {
    return (
      <div>
        <Title order={4}>Create a Key Result</Title>
        <Text size="sm">
          Key Results are specific, measurable outcomes used to track progress towards achieving your objective. They
          should be quantitative and time-bound.
        </Text>
      </div>
    );
  } else if (page === 1) {
    return (
      <div>
        <Title order={4}>Define expected trajectory</Title>
        <Text size="sm">Add expected trajectory points for achieving your key result</Text>
      </div>
    );
  } else if (page === 2) {
    return (
      <div>
        <Title order={4}>Align Initiatives and Features</Title>
        <Text size="sm">Link your deliverables from the backlog to this Key Result</Text>
      </div>
    );
  }
};

const CreateKeyResultModal = ({ open, close, objective }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const [insertKeyResult] = useMutation(INSERT_PORTFOLIO_KEY_RESULT, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });
  const [updateKeyResult] = useMutation(UPDATE_PORTFOLIO_KEY_RESULT);
  const [deleteKeyResult] = useMutation(DELETE_PORTFOLIO_KEY_RESULT);
  const [page, setPage] = useState(0);

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      targetDate: new Date(objective.targetDate),
      reviewFrequency: objective.reviewFrequency,
      initiatives: [],
      features: [],
      updates: [],
    },
  });

  const fieldArray = useFieldArray({
    control,
    name: 'trajectory',
    keyName: 'formId',
  });

  const onSubmit = (values) => {
    const keyResultToSave = mapValues(
      omitBy(values, (property, key) => ['id', 'status'].includes(key)),
      (property) => (property === '' ? null : property),
    );

    values.id
      ? updateKeyResult({
          variables: { keyResult: keyResultToSave, keyResultId: values.id },
        }).then(() => {
          showNotification({
            title: 'Key Result Updated',
            message: `Key Result ${values?.id} was successfully updated`,
          });
          close();
        })
      : insertKeyResult({
          variables: { keyResult: { ...keyResultToSave, objectiveId: objective.id } },
        }).then(() => {
          showNotification({
            title: 'Key Result Created',
            message: `Objective was successfully created`,
          });
          close();
        });
  };

  const keyResult = watch();

  return (
    <Modal.Root
      style={{
        borderRadius: '12px',
        backgroundColor: theme.other.colours.background,
        boxShadow: '0px 2px 2px 0px #0000000D',
      }}
      opened={open}
      onClose={close}
      size="600px"
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header style={{ alignItems: 'flex-start' }}>
          <Modal.Title>
            <Group wrap="nowrap" align="flex-start">
              <ThemeIcon
                variant="outline"
                radius="xl"
                style={{ marginTop: '4px', backgroundColor: rgba(theme.other.colours.done, 0.15) }}
                color={theme.other.colours.done}>
                <SvgHexagone width={18} height={18} />
              </ThemeIcon>
              {getTitle(page)}
            </Group>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Paper bg={theme.other.colours.background} p={8} m="0 16px 12px 16px">
          <Text size="sm" c="dimmed">
            Objective:{' '}
            <Text span c={theme.other.colours.primary}>
              {objective.name}
            </Text>
          </Text>
        </Paper>
        {page !== 0 && (
          <Paper bg={theme.other.colours.innerRow} p={8} m="0 16px 12px 16px">
            <Text size="sm" c="dimmed">
              Key Result:{' '}
              <Text span c={theme.other.colours.primary}>
                {keyResult.name}
              </Text>
            </Text>
          </Paper>
        )}
        <Modal.Body
          p={16}
          style={{
            boxShadow: '0px 2px 2px 0px #0000000D',
            borderTop: `1px solid ${theme.other.colours.borderPaper}`,
          }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext.Provider value={{ control, errors }}>
              {page === 0 && <FirstPage keyResult={keyResult} objective={objective} />}
              {page === 1 && <SecondPage keyResult={keyResult} fieldArray={fieldArray} />}
              {page === 2 && <ThirdPage keyResult={keyResult} setValue={setValue} />}
            </FormContext.Provider>
          </form>
          <Divider m="12px -12px" />
          <Group justify={page !== 0 ? 'space-between' : 'flex-end'}>
            {page !== 0 && (
              <Button onClick={() => setPage(page - 1)} variant="light" radius={8} color="#808080">
                Back
              </Button>
            )}
            <Button
              type="submit"
              onClick={() => {
                if (page !== 2) {
                  trigger().then((isValid) => isValid && setPage(page + 1));
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
              variant="light"
              radius={8}
              color={theme.other.colours.buttonIcon}
              rightSection={<IconArrowRight size={16} />}>
              {page === 2 ? 'Add Key Result' : `Next ${page + 1}/3`}
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateKeyResultModal;
