import * as React from 'react';

function SvgValueStream(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M2.76451 13C2.40393 13 2.1619 12.6299 2.30643 12.2996L3.66232 9.20041C3.71822 9.07265 3.71822 8.92735 3.66232 8.79959L2.30643 5.70041C2.1619 5.37007 2.40393 5 2.76451 5L6.92299 5C7.12164 5 7.30145 5.1176 7.38107 5.29959L8.91232 8.79959C8.96822 8.92735 8.96822 9.07265 8.91232 9.20041L7.38107 12.7004C7.30145 12.8824 7.12164 13 6.92299 13L2.76451 13Z" />
        <path
          d="M9.76451 13C9.40393 13 9.1619 12.6299 9.30643 12.2996L10.6623 9.20041C10.7182 9.07265 10.7182 8.92735 10.6623 8.79959L9.30643 5.70041C9.1619 5.37007 9.40393 5 9.76451 5L13.923 5C14.1216 5 14.3014 5.1176 14.3811 5.29959L15.9123 8.79959C15.9682 8.92735 15.9682 9.07265 15.9123 9.20041L14.3811 12.7004C14.3014 12.8824 14.1216 13 13.923 13L9.76451 13Z"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
}

export default SvgValueStream;
