import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const ButtonIcon = ({ handleClick, icon }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.button} onClick={handleClick} size="large">
      <SvgIcon component={icon} />
    </IconButton>
  );
};

ButtonIcon.propTypes = {
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ButtonIcon;
