import { Grid } from '@mantine/core';
import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Collapse } from '@mui/material';
import InitiativeTitle from './InitiativeTitle';
import Line from './InitiativeLine';
import FeatureRow from './FeatureRow';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { useQuery } from '@apollo/client';
import { getInitiativeBgColor } from '../utils';
import classes from './InitiativeDashboard.module.css';

const Initiative = ({
  features,
  initiative,
  width,
  height,
  lastChild,
  columnDates,
  projectedFeatures,
  firstChild,
  expandedByDefault,
  sprints,
  programmes,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(expandedByDefault);
  const initiativeFeatures = initiative.features?.nodes || [];
  const color = getInitiativeBgColor(initiative, theme);

  return (
    <>
      <Grid m={0} gutter={0}>
        <Grid.Col
          span={4}
          p={0}
          data-noBorderBottom={!lastChild || expanded}
          data-noBorderTop={firstChild}
          className={classes.titleColumn}>
          <InitiativeTitle initiative={initiative} expanded={expanded} onExpand={setExpanded} color={color} />
        </Grid.Col>
        <Grid.Col span={8} p={0} style={{ position: 'relative' }}>
          <Line
            initiative={initiative}
            width={width}
            height={height}
            lastChild={lastChild}
            firstChild={firstChild}
            expanded={expanded}
            color={color}
            endDate={initiative.endDate?.isValid && initiative.endDate.toISO()}
            startDate={initiative.startDate?.isValid && initiative.startDate.toISO()}
            columnDates={columnDates}
          />
        </Grid.Col>
      </Grid>
      <Collapse
        sx={{
          alignItems: 'center',
          backgroundColor: theme.palette.color.backgroundColor,
          ...(lastChild && { borderBottom: `1px solid ${theme.palette.color.tableColumn}` }),
        }}
        in={expanded}
        timeout="auto"
        unmountOnExit>
        {initiativeFeatures.map((feature, index) => (
          <FeatureRow
            feature={feature}
            lastChild={index === features.length - 1}
            width={width}
            height={height}
            columnDates={columnDates}
            projectedFeatures={projectedFeatures}
            features={features}
            sprints={sprints}
            programmes={programmes}
          />
        ))}
      </Collapse>
    </>
  );
};

export default Initiative;
