import * as React from 'react';

function OpenCollapse(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#DEDDDF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.4 11.467a.5.5 0 0 1-.8 0L4.1 6.8a.5.5 0 0 1 .4-.8h7a.5.5 0 0 1 .4.8l-3.5 4.667z"
        fill="#3D4756"
        {...props}
      />
    </svg>
  );
}

export default OpenCollapse;
