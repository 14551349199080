export const csvColumns = [
  { label: 'ID', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Owner', field: 'assignedTo' },
  { label: 'Business Value', field: 'businessValue' },
  { label: 'Risk Reduction', field: 'riskReduction' },
  { label: 'Time Criticality', field: 'timeCriticality' },
  { label: 'COD', field: 'costOfDelay' },
  { label: 'Job Size', field: 'size' },
  { label: 'WSJF', field: 'wsjf' },
];
