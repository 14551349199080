import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Anchor, Breadcrumbs, Group, Title, useMantineTheme } from '@mantine/core';
import { Box, Tab, Tabs } from '@mui/material';
import ObjectiveView from './ObjectiveView';
import Dashboard from './Dashboard';
import { GET_VALUE_STREAMS } from '../../../Settings/Streams/graphql';
import { useQuery } from '@apollo/client';
import { GET_PORTFOLIO_OBJECTIVES } from '../graphql';
import { vsColors } from '../Summary/Objectives';

const Index = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const theme = useMantineTheme();

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { portfolioObjectives = [] } = {} } = useQuery(GET_PORTFOLIO_OBJECTIVES);

  const selectedValueStream = valueStreams.find((valueStream) => valueStream.id == params.valueStreamId);
  const color = vsColors[valueStreams.indexOf(selectedValueStream)] || theme.other.colours.buttonIcon;

  const objectives = portfolioObjectives.filter(
    (objective) =>
      (!objective.valueStreamId && params.valueStreamId == '0') || objective.valueStreamId == params.valueStreamId,
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Group>
        <Title order={3} style={{ marginLeft: '16px' }}>
          {selectedValueStream?.name || 'Global'}
        </Title>
        <Breadcrumbs>
          <Anchor onClick={() => navigate('/organisation/okrs')} key={1}>
            OKRs
          </Anchor>
          <Anchor key={2}>{selectedValueStream?.name || 'Global'}</Anchor>
        </Breadcrumbs>
      </Group>
      <Box sx={{ borderBottom: 1, margin: '0 -24px', borderColor: 'divider' }}>
        <Tabs
          sx={{ margin: '0 24px' }}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="icon label tabs">
          <Tab label="Objectives" />
          {/*<Tab icon={<SvgKeyResult color={theme.other.colours.buttonIcon} />} iconPosition="start" label="Dashboard" />*/}
        </Tabs>
      </Box>
      {value === 0 && <ObjectiveView objectives={objectives} vsColor={color} valueStreamId={params.valueStreamId} />}
      {value === 1 && <Dashboard objectives={objectives} />}
    </>
  );
};

export default Index;
