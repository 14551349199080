import React from 'react';

import styled from '@emotion/styled';
import { Tooltip } from '@mantine/core';
import { Chip, Typography } from '@mui/material';
import { find } from 'lodash';

import CircularProgressWithLabel from '../../../components/Common/Elements/CircularProgress';
import { getFeatureBackgroundColor } from '../../../utils/piColors';
import { getMappedValues } from '../../../utils/tableUtils';
import { Dot } from '../../ValueStream/components/Common';
import { STAGE_MAPPING } from './constants';

const SprintChip = styled(Chip)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: '11px',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.color.background,
  ':hover': {
    backgroundColor: '#EEE7EA',
  },
}));

const getTooltipMessage = (item, sprints) => {
  if (item.status === 'Done') return 'Feature is done';
  if (item.ragStatus === 'Green') return 'On Track';
  if (item.estimatedSprint !== item.committedSprint) {
    const sprint = find(sprints, { id: item.committedSprint }) || {};
    const sprintLabel = sprint?.name?.includes('Sprint') ? sprint?.name : `Sprint ${sprint?.name}`;
    return `Committed to ${sprintLabel}`;
  }
  if (item.hasUnassignedStories && item.team?.type !== 'kanban') return `Has unassigned stories`;
  if (['Red', 'Amber'].includes(item.ragStatus)) return `RAG Status is ${item.ragStatus}`;
};

const createData = (item, teams, backlogs, programIncrements, theme, initiatives) => {
  const {
    id,
    name,
    teamId,
    ragStatus,
    status,
    backlogId,
    stage,
    programIncrement,
    epicId,
    metrics,
    assignedTo,
    initiativeId,
  } = item;
  const teamNames = getMappedValues(teams)[teamId];
  const backlogNames = getMappedValues(backlogs)[backlogId];
  const pi = find(programIncrements, { id: programIncrement }) || {};
  const stageName = getMappedValues(STAGE_MAPPING)[stage];
  const estimatedSprint = find(pi.sprints, { id: item.estimatedSprint });
  const sprintLabel = estimatedSprint?.name?.includes('Sprint')
    ? estimatedSprint?.name
    : `Sprint ${estimatedSprint?.name}`;

  const initiative = find(initiatives, { id: initiativeId }) || {};

  return {
    id,
    name,
    team: teamNames,
    programIncrement: pi?.name,
    status,
    stageName,
    assignedTo,
    initiative: initiative.name || '',
    backlog: backlogNames,
    estimatedSprint: !!estimatedSprint && (
      <Tooltip data-value={estimatedSprint.name} transition={'scale'} label={getTooltipMessage(item, pi.sprints)}>
        <SprintChip
          size="small"
          data-value={estimatedSprint.name}
          label={
            <>
              <Dot sx={{ marginRight: '8px', backgroundColor: getFeatureBackgroundColor(item, theme) }} />
              <span style={{ color: getFeatureBackgroundColor(item, theme) }}>{sprintLabel}</span>
            </>
          }
        />
      </Tooltip>
    ),
    epicId,
    progress: (
      <CircularProgressWithLabel
        data-value={item.metrics?.percentageDone || 0}
        value={item.metrics?.percentageDone || 0}
      />
    ),
    expand: !!metrics?.storyCount?.full,
    ragStatus: (
      <Typography data-value={ragStatus} color={getFeatureBackgroundColor({ ragStatus }, theme)}>
        {ragStatus}
      </Typography>
    ),
  };
};

export const getRowsData = (data, teams, backlogs, programIncrements, theme, initiatives) => {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, teams, backlogs, programIncrements, theme, initiatives));
  }
  return rows;
};
