import * as React from 'react';

function SvgPrioritisation(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="4.7" y="4.7" width="12.6" height="12.6" rx="2.3" strokeWidth="1.4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.4L10.5 1.4L10.5 -4.5897e-07L11 4.72849e-07C12.6569 4.00426e-07 14 1.34315 14 3L14 5.4L6.38837 5.48851C5.84066 5.49488 5.4 5.94069 5.4 6.48844L5.4 12.6L5.4 14L4 14L3 14C1.34315 14 -5.87107e-08 12.6569 -1.31134e-07 11L-1.63917e-07 10.25L1.4 10.25L1.4 11C1.4 11.8837 2.11634 12.6 3 12.6L4 12.6L4 6.48844C4 5.17384 5.05758 4.10389 6.37209 4.0886L12.6 4.01619L12.6 3C12.6 2.11634 11.8837 1.4 11 1.4ZM1.4 8.75L1.4 5.25L-3.82474e-07 5.25L-2.29484e-07 8.75L1.4 8.75ZM1.4 3.75L-4.48042e-07 3.75L-4.80825e-07 3C-5.53249e-07 1.34315 1.34315 -5.87108e-08 3 -1.31134e-07L4 -1.74846e-07L4 1.4L3 1.4C2.11634 1.4 1.4 2.11634 1.4 3L1.4 3.75ZM5.5 1.4L9 1.4L9 -3.93402e-07L5.5 -2.40413e-07L5.5 1.4Z"
      />
    </svg>
  );
}

export default SvgPrioritisation;
