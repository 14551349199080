import { DateTime } from 'luxon';
import { last } from 'lodash';
import { getPiDates } from '../../../utils/helpers';

export const getColumnDates = (dateType) => {
  if (dateType === 'quarter') {
    // const startOfYear = DateTime.now().startOf('quarter').minus({ months: 3 });
    const startOfYear = DateTime.now().startOf('quarter');
    return [...Array(4).keys()].map((index) => ({
      start: startOfYear.plus({ months: 3 * index }),
      end: startOfYear.plus({ months: 3 * (index + 1) }).minus({ days: 1 }),
    }));
  }
  if (dateType === 'month') {
    const startOfYear = DateTime.now().startOf('month');
    // const startOfYear = DateTime.now().startOf('month').minus({ months: 1 });
    return [...Array(4).keys()].map((index) => ({
      start: startOfYear.plus({ months: index }),
      end: startOfYear.plus({ months: index + 1 }).minus({ days: 1 }),
    }));
  }
  if (dateType === 'year') {
    const startOfYear = DateTime.now().startOf('year');
    // const startOfYear = DateTime.now().startOf('year').minus({ year: 1 });
    return [...Array(4).keys()].map((index) => ({
      start: startOfYear.plus({ year: index }),
      end: startOfYear.plus({ year: index + 1 }).minus({ days: 1 }),
    }));
  }
};

export const getColumTitle = (dateType, dates) => {
  if (dateType === 'quarter') return `Q${getQuarterNumber(dates.start)} - ${dates.start.toFormat('yyyy')}`;
  if (dateType === 'month') return dates.start.toFormat('LLLL - yyyy');
  if (dateType === 'year') return dates.start.toFormat('yyyy');
};

export const getQuarterNumber = (date) => {
  switch (date.toFormat('LLLL')) {
    case 'January':
    case 'February':
    case 'March':
      return '1';
    case 'April':
    case 'May':
    case 'June':
      return '2';
    case 'July':
    case 'August':
    case 'September':
      return '3';
    case 'October':
    case 'November':
    case 'December':
      return '4';
  }
};

export const moveDatesLeft = (dateType, columDates) => {
  if (dateType === 'quarter')
    return columDates.map((dates) => ({
      start: dates.start.minus({ months: 3 }),
      end: dates.end.minus({ months: 3 }),
    }));
  if (dateType === 'month')
    return columDates.map((dates) => ({
      start: dates.start.minus({ months: 1 }),
      end: dates.end.minus({ months: 1 }),
    }));
  if (dateType === 'year')
    return columDates.map((dates) => ({
      start: dates.start.minus({ years: 1 }),
      end: dates.end.minus({ years: 1 }),
    }));
};

export const getPreviousPeriod = (dateType, columDates) => {
  if (dateType === 'quarter')
    return columDates.map((dates) => ({
      start: dates.start.minus({ months: 12 }),
      end: dates.end.minus({ months: 12 }),
    }));
  if (dateType === 'month')
    return columDates.map((dates) => ({
      start: dates.start.minus({ months: 4 }),
      end: dates.end.minus({ months: 4 }),
    }));
  if (dateType === 'year')
    return columDates.map((dates) => ({
      start: dates.start.minus({ years: 4 }),
      end: dates.end.minus({ years: 4 }),
    }));
};

export const moveDatesRight = (dateType, columDates) => {
  if (dateType === 'quarter')
    return columDates.map((dates) => ({
      start: dates.start.plus({ months: 3 }),
      end: dates.end.plus({ months: 3 }),
    }));
  if (dateType === 'month')
    return columDates.map((dates) => ({
      start: dates.start.plus({ months: 1 }),
      end: dates.end.plus({ months: 1 }),
    }));
  if (dateType === 'year')
    return columDates.map((dates) => ({ start: dates.start.plus({ years: 1 }), end: dates.end.plus({ years: 1 }) }));
};

export const getPredictabilityForPeriod = (features, columnDates, programIncrements) => {
  if (!features.length || !columnDates.length || !programIncrements.length) return 0;

  const start = columnDates[0].start;
  const end = last(columnDates).end;

  const pis = programIncrements
    .filter((pi) => {
      const { startDate, endDate } = getPiDates(pi);
      return (startDate > start && startDate < end) || (endDate > start && endDate < end);
    })
    .map((pi) => pi.id);

  const featuresInPeriod = features.filter((feature) => pis.includes(feature.programIncrement));
  const doneStories = featuresInPeriod.filter((feature) => feature.status === 'Done');

  if (!featuresInPeriod.length) return 'N/A';

  return (doneStories.length / (featuresInPeriod.length || 1)) * 100;
};
