export const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    height: '100%',
  },
  title: {
    padding: '10px',
  },
  card: {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0), 0 1px 4px 0 rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #f1f1f1',
    borderLeft: '0.3em solid #191919',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    whiteSppace: 'nowrap',
    overflow: 'auto',
    marginBottom: '5px',
    backgroundColor: theme.palette.background.default,
  },
  flexSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  flexColScroll: {
    flexGrow: 1,
    overflow: 'auto',
    height: '98.5%',
    // marginTop: '10px'
  },
  flexNoShrink: {
    flexShrink: 0,
  },
  column: {
    height: '100%',
    width: '16%',
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortableGrid: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 150px))',
    gridTemplateRows: '25% 25% 25% 25%',
    gap: '0.5rem',
  },
  gridBox: {
    height: '50%',
  },
  sortableGhost: {
    opacity: 0,
  },
  cardContent: {
    boderLefr: '1px',
    padding: '10px',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.default,
  },
});
