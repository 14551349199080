import React, { useMemo } from 'react';
import FeatureRoadmap from 'components/Dashboard/FeatureRoadmap';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_FEATURES_FOR_PI } from '../../Product/Features/graphql';
import { GET_SELECTED_PI_FULL } from '../../Settings/ProgramIncrement/graphql';
import { selectedInitiativeVar } from '../../../reactiveVariables';

const Roadmap = ({ backlog, selectedTeams }) => {
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);

  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (!backlog || feature.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, backlog, selectedInitiative, selectedInitiative, selectedTeams],
  );

  return <FeatureRoadmap features={filteredFeatures} pi={selectedPiFull || {}} />;
};

export default Roadmap;
