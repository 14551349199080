import React from 'react';
import { DateTime } from 'luxon';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';

function createData(item, features, initiatives) {
  const { id, name, plannedRealisationDate } = item;
  const okrFeatures = features.filter((feature) => feature.okrs?.some((okr) => okr.id === id));
  const okrInitiatives = initiatives.filter((initiative) => initiative.okrs?.some((okr) => okr.id === id));

  const weightSum =
    okrInitiatives.reduce((total, initiative) => total + (initiative.features?.aggregate?.count || 0), 0) +
    okrFeatures.length;

  const featureProgress = okrFeatures.reduce((total, feature) => total + (feature.percentageDone * 1) / weightSum, 0);
  const totalProgress = okrInitiatives.reduce(
    (total, initiative) =>
      total + (initiative.features?.aggregate?.avg?.percentageDone * initiative.features?.aggregate?.count) / weightSum,
    featureProgress,
  );

  return {
    id,
    name,
    featuresIn: <span data-value={okrFeatures.length}>{`${okrFeatures.length} Features`}</span>,
    initiativesIn: <span data-value={okrInitiatives.length}>{`${okrInitiatives.length} Initiatives`}</span>,
    progress: <LinearWithValueLabel data-value={totalProgress} value={totalProgress.toFixed()} />,
    expand: !!okrFeatures.length || !!okrInitiatives.length,
    plannedRealisationDate:
      plannedRealisationDate && DateTime.fromISO(plannedRealisationDate).toLocaleString(DateTime.DATE_SHORT),
  };
}

export function getRowsData(data, features, initiatives) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, features, initiatives));
  }
  return rows;
}
