import React from 'react';
import { filter, size } from 'lodash';
import { Text } from '@mantine/core';
import { FormHeader, TopSection, Button, Center, BoxCount, Count, BoxCountContainer } from '../styles';
import { useNavigate } from 'react-router-dom';
import SvgBigAgileLogo from '../../../assets/images/BigAgileLogo';
import { GET_IDEAS_BY_USER_ID } from '../graphql';
import { useQuery } from '@apollo/client';

const IdeaSubmitted = ({ user }) => {
  const navigate = useNavigate();

  const { data: ideasByUserId = {} } = useQuery(GET_IDEAS_BY_USER_ID, {
    variables: { userId: user.id },
  });

  return (
    <Center>
      <TopSection style={{ maxWidth: '600px' }}>
        <div style={{ width: '125px', margin: '0 auto 50px', cursor: 'pointer' }} onClick={() => navigate(`/`)}>
          <SvgBigAgileLogo />
        </div>

        <FormHeader>
          Idea submitted. <span style={{ color: 'black' }}>The form.</span>
        </FormHeader>

        <Text size="16px" lh={'20px'} mb={40}>
          You have successfully sent your idea. Add another one?
        </Text>

        <Button onClick={() => navigate(`/submit-idea/new`)}>Submit an idea</Button>

        <BoxCountContainer style={{ width: '600px' }}>
          <BoxCount>
            <Count>{ideasByUserId?.ideas?.length}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Your submissions
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{size(filter(ideasByUserId?.ideas, { state: 'New' }))}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              New
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{size(filter(ideasByUserId?.ideas, { state: 'inAnalysis' }))}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              In Analysis
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{size(filter(ideasByUserId?.ideas, { state: 'Accepted' }))}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Accepted
            </Text>
          </BoxCount>

          <BoxCount>
            <Count>{size(filter(ideasByUserId?.ideas, { state: 'Rejected' }))}</Count>
            <Text c="dimmed" size="14px" mt={18}>
              Rejected
            </Text>
          </BoxCount>
        </BoxCountContainer>
      </TopSection>
    </Center>
  );
};

export default IdeaSubmitted;
