import * as React from 'react';

function SvgRocket(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_799_38896)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.37826 3.49134C8.88488 2.77697 9.67836 2.31965 10.5504 2.23939L12.9778 2.01602C13.9601 1.92562 14.8617 2.56496 15.1012 3.52189L15.6932 5.88655C15.9059 6.7361 15.7367 7.63618 15.2301 8.35055L11.4005 13.7506C10.2302 15.4009 8.03768 15.8961 6.2927 14.9863C6.38347 14.8867 6.4481 14.7592 6.47109 14.6103C6.48824 14.4993 6.53099 14.3938 6.59598 14.3021L7.53842 12.9732C7.73011 12.7029 7.66639 12.3284 7.39609 12.1367C7.12579 11.945 6.75127 12.0088 6.55958 12.2791L5.61714 13.608C5.55215 13.6996 5.46673 13.7749 5.36763 13.8277C5.23472 13.8987 5.13574 14.0019 5.07174 14.1205C3.63605 12.7746 3.37832 10.5417 4.54863 8.89142L8.37826 3.49134ZM9.82569 8.71079C10.6366 9.28586 11.7601 9.09469 12.3352 8.28379C12.9103 7.4729 12.7191 6.34935 11.9082 5.77428C11.0973 5.19921 9.97377 5.39038 9.39869 6.20128C8.82362 7.01217 9.0148 8.13572 9.82569 8.71079ZM6.47898 4.78552L3.07664 5.02802C2.77826 5.04929 2.50508 5.20301 2.33204 5.44701L0.812075 7.59028C0.652333 7.81553 0.705437 8.12762 0.930686 8.28736L2.15423 9.15508C2.60473 9.47456 3.22892 9.36836 3.54841 8.91786L6.47898 4.78552ZM15.5324 13.8614L14.636 10.5703L11.7054 14.7026C11.3859 15.1531 11.4921 15.7773 11.9426 16.0968L13.1662 16.9645C13.3914 17.1243 13.7035 17.0712 13.8632 16.8459L15.3832 14.7027C15.5563 14.4586 15.611 14.15 15.5324 13.8614Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_799_38896">
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRocket;
