import * as React from 'react';

function SvgAdd(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="x">
        <path
          id="Icon"
          d="M7.99963 2.34315V13.6569M2.34277 8H13.6565"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgAdd;
