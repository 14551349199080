import * as React from 'react';

function SvgStories(props) {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60236 5.40449V14.8886C8.60236 15.0458 8.4294 15.1415 8.2962 15.0581L7.76896 14.7279C6.68932 14.0517 5.47842 13.6124 4.21634 13.439L3.23855 13.3047C2.7435 13.2367 2.37463 12.8137 2.37463 12.314V2.62493C2.37463 2.29756 2.63048 2.02726 2.95736 2.0093C4.94863 1.8999 6.86984 2.76041 8.11403 4.31897L8.30677 4.56041C8.49813 4.80013 8.60236 5.09776 8.60236 5.40449ZM1.68215 12.9691V4.41502C1.3988 4.23755 1.02799 4.29615 0.813178 4.55235L0.61807 4.78506C0.539172 4.87916 0.496924 4.99859 0.499104 5.12137L0.685288 15.6064C0.68966 15.8526 0.913304 16.0364 1.15569 15.993L1.51634 15.9285C3.26661 15.6152 5.05084 15.5368 6.82184 15.6953L7.41202 15.7481C7.48178 15.7543 7.52196 15.6707 7.47351 15.6202C6.61563 14.7246 5.42928 14.2182 4.18914 14.2182H2.93131C2.24142 14.2182 1.68215 13.659 1.68215 12.9691ZM9.39795 5.40449V14.8886C9.39795 15.0458 9.57091 15.1415 9.70411 15.0581L10.2314 14.7279C11.311 14.0517 12.5219 13.6124 13.784 13.439L14.7618 13.3047C15.2568 13.2367 15.6257 12.8137 15.6257 12.314V2.62493C15.6257 2.29756 15.3698 2.02726 15.043 2.0093C13.0517 1.8999 11.1305 2.76041 9.88628 4.31897L9.69355 4.56041C9.50218 4.80013 9.39795 5.09776 9.39795 5.40449ZM16.3182 12.9691V4.41502C16.6015 4.23755 16.9723 4.29615 17.1871 4.55235L17.3822 4.78506C17.4611 4.87916 17.5034 4.99859 17.5012 5.12137L17.315 15.6064C17.3107 15.8526 17.087 16.0364 16.8446 15.993L16.484 15.9285C14.7337 15.6152 12.9495 15.5368 11.1785 15.6953L10.5883 15.7481C10.5185 15.7543 10.4784 15.6707 10.5268 15.6202C11.3847 14.7246 12.571 14.2182 13.8112 14.2182H15.069C15.7589 14.2182 16.3182 13.659 16.3182 12.9691Z"
      />
    </svg>
  );
}

export default SvgStories;
