import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@mui/styles';
import { trim } from 'lodash';

const styleById = {
  Forecast: {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  default: {
    strokeWidth: 2,
  },
};

const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));
};

const IncrementBurnUp = ({ lineData, sprints = [], type }) => {
  const muiTheme = useTheme();

  const commonProps = {
    margin: { top: 50, right: 40, bottom: 50, left: 70 },
    animate: true,
    enableSlices: 'x',
  };

  const theme = {
    axis: {
      legend: {
        text: {
          fontSize: 14,
          fill: '#93a0b5',
        },
      },
      ticks: {
        text: {
          fontSize: 11,
          fill: '#93a0b5',
        },
      },
      domain: {
        line: {
          stroke: '#eaeaeb',
          strokeWidth: 2,
        },
      },
    },
    tooltip: {
      container: {
        background: muiTheme.palette.background.default,
      },
    },
  };

  return (
    <ResponsiveLine
      {...commonProps}
      theme={theme}
      data={lineData}
      enableArea={true}
      areaOpacity={0.5}
      colors={{ datum: 'color' }}
      xScale={{ type: 'point' }}
      pointSize={10}
      pointBorderWidth={2}
      useMesh={true}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      pointBorderColor="white"
      layers={['grid', 'markers', 'axes', 'areas', 'crosshair', DashedSolidLine, 'points', 'slices', 'mesh', 'legends']}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickValues: 4,
        legend: type === 'storyPoints' ? 'Story Points' : 'Stories',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        tickValues: sprints.map((sprint) => sprint.name),
        format: (value) => trim(value.replace('Sprint', '')),
        legend: 'Sprints',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: -100,
          translateY: -50,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default IncrementBurnUp;
