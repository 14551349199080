import { getFeatureBackgroundColor } from '../../../utils/piColors';
import { openDrawer } from '../../../reactiveVariables';
import React, { memo } from 'react';
import { Card, CardActionArea, CardContent, Grid, Typography, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { Text } from '@mantine/core';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles(styles);

const ObjectiveCard = ({ objective }) => {
  const { id } = objective;
  const theme = useTheme();
  const backgroundColor = getFeatureBackgroundColor(objective, theme);
  const classes = useStyles();

  const handleClick = () => {
    openDrawer(objective, 'objective');
  };

  return (
    <Zoom in={true}>
      <Card className={classes.card} onClick={handleClick} style={{ borderLeftColor: backgroundColor }}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid container item xs={12} sx={{ marginBottom: '2px' }} justifyContent={'space-between'}>
                <Text span={true} size={11} fw={550}>
                  O-{id}
                </Text>
                <Text span={true} size={11} fw={550}>
                  PV {objective.plannedValue}
                </Text>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.typo} variant="caption" display="block" align="left" color="textSecondary">
                {objective.name}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default memo(ObjectiveCard);
