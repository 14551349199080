import { gql } from '@apollo/client';
import { INITIATIVE_FIELDS } from '../Initiatives/graphql';

export const OKR_FIELDS = gql`
  fragment OKRFields on okrs {
    description
    id
    name
    plannedRealisationDate
  }
`;

export const INSERT_OKR = gql`
  ${OKR_FIELDS}
  mutation InsertOKR($okr: okrs_insert_input! = {}) {
    okr: insert_okrs_one(object: $okr) {
      ...OKRFields
    }
  }
`;

export const UPDATE_OKR = gql`
  ${OKR_FIELDS}
  mutation UpdateOKR($okrId: Int!, $okr: okrs_set_input = {}) {
    update_okrs_by_pk(pk_columns: { id: $okrId }, _set: $okr) {
      ...OKRFields
    }
  }
`;

export const DELETE_OKR = gql`
  mutation DeleteOKR($id: Int!) {
    okr: delete_okrs_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_OKRS = gql`
  ${OKR_FIELDS}
  query GetOKRs($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    okrs: okrs(where: { valueStream: { _eq: $valueStream } }, order_by: { id: asc }) {
      ...OKRFields
    }
  }
`;
