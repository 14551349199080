import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';

import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const sortValues = (data) => [...data].sort((a, b) => a?.name?.localeCompare(b?.name));

const Selector = (props) => {
  const classes = useStyles(props);
  const { label, placeholder, value, values, onChange } = props;
  return (
    <span className={classes.selector}>
      <TextField
        select
        margin="dense"
        id="outlined-basic"
        size="small"
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        }}
        InputProps={{
          className: classes.input,
          focused: classes.cssFocused,
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.inputLabel,
            focused: classes.cssFocused,
          },
        }}>
        <MenuItem
          classes={{
            root: classes.selectedRoot,
            selected: classes.selectedItem,
          }}
          value={0}>
          All
        </MenuItem>
        {sortValues(values).map((item) => (
          <MenuItem
            classes={{
              root: classes.selectedRoot,
              selected: classes.selectedItem,
            }}
            key={item.id}
            value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </span>
  );
};

Selector.propTypes = {
  value: PropTypes.number,
  values: PropTypes.array,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Selector;
