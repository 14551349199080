import * as React from 'react';

function SvgFeatureIcon(props) {
  return (
    <svg width="16" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.89839 1.66634C2.43084 1.66634 1.99984 2.06779 1.99984 2.65274V9.24269C2.27666 9.12587 2.58658 9.0685 2.91975 9.08811H9.04331C9.51086 9.08811 9.94186 8.68666 9.94186 8.10172V2.65274C9.94186 2.05096 9.49513 1.66634 9.10128 1.66634H2.89839ZM0.666504 11.3466V2.65274C0.666504 1.40095 1.6268 0.333008 2.89839 0.333008H9.10128C10.3306 0.333008 11.2752 1.41778 11.2752 2.65274V8.10172C11.2752 9.35351 10.3149 10.4214 9.04331 10.4214H2.89839C2.88277 10.4214 2.86715 10.4209 2.85156 10.4198C2.43048 10.3902 1.99984 10.7489 1.99984 11.3466C1.99984 11.9316 2.43084 12.333 2.89839 12.333H9.10128C9.56883 12.333 9.99984 11.9316 9.99984 11.3466C9.99984 10.9784 10.2983 10.6799 10.6665 10.6799C11.0347 10.6799 11.3332 10.9784 11.3332 11.3466C11.3332 12.5984 10.3729 13.6663 9.10128 13.6663H2.89839C1.6268 13.6663 0.666504 12.5984 0.666504 11.3466Z"
        fill="#808080"
      />
    </svg>
  );
}

export default SvgFeatureIcon;
