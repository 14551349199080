import { CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery } from '@apollo/client';
import { useDebouncedCallback } from '../../../utils/hooks';
import { SEARCH_STORIES } from '../../../containers/Delivery/components/Dependencies/graphql';

export default function SelectStories({ label, connectorId, setValue, value }) {
  const [searchStories, { loading, data: { stories = [] } = {} }] = useLazyQuery(SEARCH_STORIES);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fetchStories = useDebouncedCallback(() => {
    searchStories({ variables: { connectorId: connectorId, query: inputValue } });
  }, 500);

  useEffect(() => {
    if (inputValue && open) {
      fetchStories();
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id="jira-epics"
      style={{ width: '100%', marginTop: '10px' }}
      open={open}
      multiple
      value={value || []}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.key || option.id} - ${option.summaryText || option?.fields?.summary}`}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(_e, option) => {
        setValue('issuesNeeded', option);
      }}
      options={stories}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
