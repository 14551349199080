import { gql } from '@apollo/client';

export const PROGRAMME_FIELDS = gql`
  fragment ProgrammeFields on programmes {
    id
    name
    description
    valueStream: value_stream {
      id
      name
      isActive
    }
    portfolioTag
  }
`;

export const UPSERT_PROGRAMME = gql`
  ${PROGRAMME_FIELDS}
  mutation UpsertProgramme($programme: programmes_insert_input! = {}) {
    insert_programmes_one(object: $programme) {
      ...ProgrammeFields
    }
  }
`;

export const INSERT_PROGRAMME = gql`
  ${PROGRAMME_FIELDS}
  mutation InsertFeature($programme: programmes_insert_input! = {}) {
    programme: insert_programmes_one(object: $programme) {
      ...ProgrammeFields
    }
  }
`;

export const UPDATE_PROGRAMME = gql`
  ${PROGRAMME_FIELDS}
  mutation UpdateFeature($programmeId: Int!, $programme: programmes_set_input = {}) {
    update_programmes_by_pk(pk_columns: { id: $programmeId }, _set: $programme) {
      ...ProgrammeFields
    }
  }
`;

export const DELETE_PROGRAMME = gql`
  mutation DeleteProgramme($id: Int!) {
    programme: delete_programmes_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PROGRAMMES = gql`
  ${PROGRAMME_FIELDS}
  query GetProgrammes {
    programmes: programmes {
      ...ProgrammeFields
    }
  }
`;
