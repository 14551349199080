import * as React from 'react';

function SvgDate(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.2788 3.06667H11.2838V2.66667C11.2838 2.26667 11.0184 2 10.6204 2C10.2224 2 9.95703 2.26667 9.95703 2.66667V3.06667H7.4363C7.0383 3.06667 6.77296 3.33333 6.77296 3.73333C6.77296 4.13333 7.0383 4.4 7.4363 4.4H10.0234V4.8C10.0234 5.2 10.2887 5.46667 10.6868 5.46667C11.0848 5.46667 11.3501 5.2 11.3501 4.8V4.4H12.3451C12.8094 4.4 13.2738 4.8 13.2738 5.33333V6.26667H2.6602V5.33333C2.6602 4.8 3.12454 4.4 3.65522 4.4H4.65024V4.8C4.65024 5.2 4.91558 5.46667 5.3136 5.46667C5.7116 5.46667 5.97694 5.2 5.97694 4.8V3.73333V2.66667C5.97694 2.26667 5.7116 2 5.3136 2C4.91558 2 4.65024 2.26667 4.65024 2.66667V3.06667H3.65522C2.32852 3.06667 1.3335 4.06667 1.3335 5.33333V11.7333C1.3335 13 2.32852 14 3.65522 14H12.3451C13.6055 14 14.6668 13 14.6668 11.7333V5.33333C14.6005 4.06667 13.6055 3.06667 12.2788 3.06667ZM12.2788 12.6667H3.65522C3.12454 12.6667 2.6602 12.2667 2.6602 11.7333V7.6H13.2738V11.7333C13.2738 12.2667 12.8094 12.6667 12.2788 12.6667Z"
        fill="#808080"
      />
    </svg>
  );
}

export default SvgDate;
