import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { mean, uniq } from 'lodash';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';
import { useTheme } from '@emotion/react';

const DistributionTable = ({ initiative, programmes }) => {
  const theme = useTheme();
  const data =
    initiative?.features?.nodes.reduce((acc, feature) => {
      const programme = programmes.find((programme) => programme.id === feature.programmeId) || {};
      if (!acc[feature.programmeId]) {
        acc[feature.programmeId] = { name: programme.name, features: 0, teams: [], progress: [] };
      }
      acc[feature.programmeId].teams = uniq([...acc[feature.programmeId].teams, feature.teamId]);
      acc[feature.programmeId].features += 1;
      acc[feature.programmeId].progress.push(feature.percentageDone);

      return acc;
    }, {}) || {};

  return (
    <TableContainer sx={{ borderTop: `1px solid ${theme.palette.color.tileBorder}` }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              width="30%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Programmes
            </TableCell>
            <TableCell
              width="15%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Teams
            </TableCell>
            <TableCell
              width="15%"
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Features
            </TableCell>
            <TableCell
              width="40% "
              sx={{ height: '40px', padding: '0 16px', backgroundColor: theme.palette.color.header }}>
              Progress
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map(([key, value]) => (
            <TableRow>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>{value.name}</TableCell>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>{value.teams.length}</TableCell>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>{value.features}</TableCell>
              <TableCell sx={{ height: '44px', padding: '0 16px' }}>
                <LinearWithValueLabel value={mean(value.progress).toFixed(1)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DistributionTable;
