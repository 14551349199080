export function lowerCase(str) {
  return str?.toLowerCase();
}

export function upperCase(str) {
  return str?.toUpperCase();
}

export function truncate(str, max) {
  return str.length > max ? `${str.substring(0, max)}...` : str;
}
