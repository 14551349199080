import * as React from 'react';

function SvgForecast(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_799_38971)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.28268 0.859467C1.13749 1.15729 0.385876 2.24407 0.514238 3.41308L1.62696 13.4976C1.78393 14.9207 2.54095 16.2111 3.71012 17.0477C4.5754 17.6668 5.61467 18 6.68099 18H11.319C11.8928 18 12.4588 17.9035 12.9939 17.7179C13.4532 17.5588 13.8901 17.3336 14.2899 17.0477C15.459 16.2111 16.2161 14.9207 16.373 13.4976L17.4858 3.41308C17.6141 2.24407 16.8625 1.15729 15.7173 0.859467C11.3136 -0.286489 6.6864 -0.286489 2.28268 0.859467ZM9 3.8335C12.9359 3.8335 16.1264 3.12871 16.1264 2.25944C16.1264 1.39017 12.9359 0.685383 9 0.685383C5.0641 0.685383 1.87363 1.39017 1.87363 2.25944C1.87363 3.12871 5.0641 3.8335 9 3.8335Z"
          fill="#F46EAE"
        />
      </g>
      <defs>
        <clipPath id="clip0_799_38971">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgForecast;
