import * as React from 'react';

function HeartSvg(props) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#F46EAE" fillOpacity="0.15" stroke="#F46EAE" />
      <path
        d="M15.8572 7.66699C14.7143 7.66699 13.6985 8.17351 13 8.9966C12.3016 8.17351 11.2223 7.66699 10.0794 7.66699C8.04766 7.66699 6.33337 9.37649 6.33337 11.4026C6.33337 11.5292 6.33337 11.6558 6.33337 11.7824C6.58734 14.8849 9.88893 17.4808 11.8572 18.6838C12.1746 18.8737 12.5556 19.0003 13 19.0003C13.381 19.0003 13.7619 18.8737 14.1429 18.6838C16.1112 17.4175 19.4127 14.8849 19.6667 11.7824C19.6667 11.6558 19.6667 11.5292 19.6667 11.4026C19.6667 9.37649 17.9524 7.66699 15.8572 7.66699Z"
        fill="#F46EAE"
      />
    </svg>
  );
}

export default HeartSvg;
