import React, { forwardRef, useEffect, useMemo, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import {
  Grow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  Alert,
} from '@mui/material';
import { RangeSlider } from '@mantine/core';
import { GET_HL_ESTIMATE_CONFIG, INSERT_CONFIGURATION, UPDATE_CONFIGURATION } from '../../GraphQL/configuration';
import { GET_VALUE_STREAMS } from '../../Streams/graphql';
import RoundButton from '../../../../components/Common/Button/RoundButton';
import { BigAgileSelect } from '../../../../components/Common/Select/CustomSelect';
import { useTheme } from '@emotion/react';

const Index = () => {
  const [insertEstimation] = useMutation(INSERT_CONFIGURATION);
  const [updateEstimation] = useMutation(UPDATE_CONFIGURATION);

  const theme = useTheme();

  const { data: { hlEstimates = [] } = {} } = useQuery(GET_HL_ESTIMATE_CONFIG);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const defaultShirtState = useMemo(
    () => [
      { name: 'XS', points: [1, 10], count: [1, 5], isActive: true },
      { name: 'S', points: [11, 20], count: [6, 10], isActive: true },
      { name: 'M', points: [21, 40], count: [11, 20], isActive: true },
      { name: 'L', points: [41, 80], count: [21, 30], isActive: true },
      { name: 'XL', points: [81, 160], count: [31, 50], isActive: true },
      { name: 'XXL', points: [161, 250], count: [51, 100], isActive: true },
    ],
    [],
  );

  const [sliderValues, setSliderValues] = useState(defaultShirtState);
  const [isValid, setIsValid] = useState(true);
  const [validationMessages, setValidationMessages] = useState([]);
  const [selectedValueStream, setSelectedValueStream] = useState({ name: '', id: '' });

  const valueStreamEstimate = hlEstimates.find((stream) => stream.value_stream === selectedValueStream.id);

  useEffect(() => {
    if (valueStreamEstimate) {
      setSliderValues(valueStreamEstimate.estimates);
    } else {
      setSliderValues(defaultShirtState);
    }
  }, [hlEstimates, selectedValueStream.id, defaultShirtState, valueStreamEstimate]);

  useEffect(() => {
    setSelectedValueStream({ name: valueStreams[0].name, id: valueStreams[0].id });
  }, [valueStreams]);

  const handleRangeSliderChange = (index, type) => (value) => {
    setSliderValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [type]: value,
      };
      return newValues;
    });
    validateSliderValues();
  };

  const handleSave = () => {
    if (!isValid) return;

    const data = { estimates: sliderValues };

    if (valueStreamEstimate) {
      updateEstimation({
        variables: { configuration: { value: data }, configurationId: valueStreamEstimate.id },
        refetchQueries: [GET_HL_ESTIMATE_CONFIG, 'GetEstimates'],
      });
    } else {
      insertEstimation({
        variables: { configuration: { type: 'hlEstimate', value: data, value_stream: selectedValueStream.id } },
        refetchQueries: [GET_HL_ESTIMATE_CONFIG, 'GetEstimates'],
      });
    }
  };

  const handleCancel = () => {
    if (valueStreamEstimate?.estimates) {
      setSliderValues(valueStreamEstimate?.estimates);
    } else {
      setSliderValues(defaultShirtState);
    }
  };

  const isNotDirty = isEqual(sliderValues, valueStreamEstimate?.estimates);

  const toggleCheckbox = (index) => (e) => {
    setSliderValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        isActive: e.target.checked,
      };
      return newValues;
    });
    validateSliderValues();
  };

  const validateSliderValues = useCallback(() => {
    const activeSizes = sliderValues.filter((size) => size.isActive);
    const messages = [];
    for (let i = 0; i < activeSizes.length - 1; i++) {
      if (activeSizes[i].points[1] >= activeSizes[i + 1].points[0]) {
        messages.push(`Story Points for ${activeSizes[i].name} should not overlap with ${activeSizes[i + 1].name}`);
      }
      if (activeSizes[i].count[1] >= activeSizes[i + 1].count[0]) {
        messages.push(`Story Count for ${activeSizes[i].name} should not overlap with ${activeSizes[i + 1].name}`);
      }
    }
    setValidationMessages(messages);
    setIsValid(messages.length === 0);
  }, [sliderValues]);

  useEffect(() => {
    validateSliderValues();
  }, [sliderValues, validateSliderValues]);

  const renderValue = () => {
    const truncateName = (name) => {
      return name.length > 23 ? name.substring(0, 23) + '...' : name;
    };

    return (
      <span
        style={{
          color: theme.palette.text.secondary,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        VS: <span style={{ color: theme.palette.text.primary }}>{truncateName(selectedValueStream.name)}</span>
      </span>
    );
  };

  const handleValueStreamChange = (e, id) => {
    const selectedStream = valueStreams.find((stream) => stream.id === id);
    setSelectedValueStream({ name: selectedStream.name, id: selectedStream.id });
  };

  return (
    <Grow>
      <>
        <div
          style={{
            display: 'inline-block',
            marginBottom: '15px',
            minWidth: '150px',
            maxWidth: '300px',
          }}>
          <BigAgileSelect
            options={activeValueStreams}
            renderValue={renderValue}
            onChange={handleValueStreamChange}
            style={{
              width: '100%',
              '& .MuiSelect-select': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <TableContainer component={Paper}>
            <Table sx={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>
              <TableHead>
                <TableRow>
                  <TableCell>In use</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Story Points</TableCell>
                  <TableCell>Story Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sliderValues.map((size, index) => (
                  <TableRow key={size.name}>
                    <TableCell style={{ padding: '20px 14px', width: '115px' }}>
                      <Checkbox name={size.name} checked={size.isActive} onChange={toggleCheckbox(index)} />
                    </TableCell>
                    <TableCell style={{ padding: '20px 14px', width: '115px' }}>{size.name}</TableCell>
                    <TableCell>
                      <RangeSlider
                        value={size.points}
                        onChange={handleRangeSliderChange(index, 'points')}
                        min={0}
                        max={250}
                        step={1}
                        minRange={0}
                        disabled={!size.isActive}
                        marks={[
                          { value: 0, label: 0 },
                          { value: 250, label: 250 },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <RangeSlider
                        value={size.count}
                        onChange={handleRangeSliderChange(index, 'count')}
                        min={0}
                        max={100}
                        step={1}
                        minRange={0}
                        disabled={!size.isActive}
                        marks={[
                          { value: 0, label: 0 },
                          { value: 100, label: 100 },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {validationMessages.length > 0 && (
              <Alert severity="warning" style={{ marginTop: '10px' }}>
                {validationMessages.map((message, index) => (
                  <Typography key={index}>{message}</Typography>
                ))}
              </Alert>
            )}

            <div style={{ textAlign: 'right', flex: 1, margin: '20px 0px' }}>
              <RoundButton
                disabled={isNotDirty || !isValid}
                disableElevation
                buttonText="Cancel"
                handleClick={handleCancel}
                width="80px"
              />
              <RoundButton
                disabled={isNotDirty || !isValid}
                disableElevation
                buttonText="Save"
                handleClick={handleSave}
                width="80px"
              />
            </div>
          </TableContainer>
        </div>
      </>
    </Grow>
  );
};

export default forwardRef(Index);
