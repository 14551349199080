import React from 'react';
import { Route } from 'react-router-dom';
import IdeaFormIndex from './IdeaForm';
import IdeaFormPage from './IdeaForm/NewIdeaForm';
import IdeaSubmitted from './IdeaForm/FormSubmit';

const getFormRoutes = (user) => (
  <>
    <Route user={user} path="/submit-idea" element={<IdeaFormIndex />} />
    <Route user={user} path="/submit-idea/new" element={<IdeaFormPage user={user} />} />
    <Route user={user} path="/submit-idea/submitted" element={<IdeaSubmitted user={user} />} />
  </>
);

export default getFormRoutes;
