import Tag from '../../../components/Common/Tag';
import React from 'react';

function createData(item, theme) {
  const { id, name, description, boardName, connector, type } = item;

  return {
    id,
    name,
    description,
    board: boardName,
    connector: connector?.name,
    type: (
      <Tag
        data-value={type}
        color={type === 'kanban' ? theme.palette.color.done : theme.palette.color.buttonIcon}
        label={type === 'kanban' ? 'Kanban' : 'Scrum'}
      />
    ),
  };
}

export function getRowsData(data, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, theme));
  }
  return rows;
}
