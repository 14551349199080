import * as React from 'react';

const SvgPriorityLow = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91588 0C9.323 0 8.84223 0.507487 8.84223 1.13346V10.8665C8.84223 11.4925 9.323 12 9.91588 12H10.9265C11.5193 12 12.0001 11.4925 12.0001 10.8665V1.13346C12.0001 0.507487 11.5193 0 10.9265 0H9.91588ZM4.42117 4.4668C4.42117 3.84082 4.90195 3.33333 5.49483 3.33333H6.50542C7.09829 3.33333 7.57907 3.84082 7.57907 4.4668V10.8665C7.57907 11.4925 7.09829 12 6.50542 12H5.49483C4.90195 12 4.42117 11.4925 4.42117 10.8665V4.4668ZM0.00012207 7.13346C0.00012207 6.50749 0.480899 6 1.07378 6H2.08436C2.67724 6 3.15802 6.50749 3.15802 7.13346V10.8665C3.15802 11.4925 2.67724 12 2.08436 12H1.07378C0.480899 12 0.00012207 11.4925 0.00012207 10.8665V7.13346Z"
      fill="#808080"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00012207 7.13346C0.00012207 6.50749 0.480899 6 1.07378 6H2.08436C2.67724 6 3.15802 6.50749 3.15802 7.13346V10.8665C3.15802 11.4925 2.67724 12 2.08436 12H1.07378C0.480899 12 0.00012207 11.4925 0.00012207 10.8665V7.13346Z"
      fill="#FF4189"
    />
  </svg>
);

export default SvgPriorityLow;
