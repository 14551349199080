import { Text, Timeline } from '@mantine/core';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import SvgDiamondDate from '../../../assets/images/DiamondDate';
import SvgStar from '../../../assets/images/Star';
import { date } from '../../../utils/helpers';
import { getMantineColor } from '../utils';

const Dates = ({ initiative }) => {
  const startItem = { title: 'Started', date: initiative?.startDate };
  const secondItem =
    initiative?.plannedRealisationDate && date(initiative?.plannedRealisationDate) < initiative?.endDate
      ? { title: 'Target Realisation', date: date(initiative?.plannedRealisationDate), icon: SvgDiamondDate }
      : { title: 'Estimated Completion', date: initiative?.endDate, icon: SvgStar };
  const lastItem =
    initiative?.plannedRealisationDate && date(initiative?.plannedRealisationDate) < initiative?.endDate
      ? { title: 'Estimated Completion', date: initiative?.endDate, icon: SvgStar }
      : { title: 'Target Realisation', date: date(initiative?.plannedRealisationDate), icon: SvgDiamondDate };

  const daysOverrun = initiative.endDate.diff(date(initiative.plannedRealisationDate), 'days').toObject().days;

  const timeLineItems = [startItem, secondItem];

  if (initiative?.plannedRealisationDate) timeLineItems.push(lastItem);

  return (
    <Timeline active={timeLineItems.length} bulletSize={24} lineWidth={2}>
      {timeLineItems.map((item, index) => (
        <Timeline.Item
          lineVariant={item.date > DateTime.now() ? 'dashed' : 'solid'}
          key={index}
          bullet={item.icon ? <item.icon width={16} height={16} /> : <SvgStar width={0} height={0} />}
          color={getMantineColor(initiative)}
          title={item.title}>
          <Text c="dimmed" size="sm">
            {item.date?.toFormat(DATE_FORMAT.date)}
          </Text>
          {daysOverrun && item.title === 'Estimated Completion' && (
            <Text c={daysOverrun > 0 ? 'baRed.0' : 'baGreen.6'} size="sm">
              {daysOverrun > 0 ? 'Overrun' : 'Early'} by {Math.abs(daysOverrun)} days
            </Text>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default Dates;
