import React from 'react';

function createData(item) {
  const { id, name, isDefault } = item;

  return {
    id,
    name,
    isDefault: isDefault ? 'Enabled' : 'Disabled',
  };
}

export function getRowsData(data) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item));
  }
  return rows;
}
