import React from 'react';
import { createTheme } from '@mui/material/styles';
import { color, darkColors, lightColors } from '../../../shared/styles/color';
import classes from './Global.module.css';
import { Grid } from '@mantine/core';

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
const fontWeightsValues = {
  normal: 400,
  medium: 600,
  semiBold: 700,
  bold: 900,
};

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const buildTheme = (mode) =>
  createTheme({
    breakpoints: { values: breakpointValues },
    components: {
      // Name of the component
      MuiCard: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            overflow: 'initial',
            backgroundImage: 'none',
            backgroundColor: mode === 'light' ? lightColors.paper : darkColors.paper,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
    },
    palette: {
      mode: mode,
      error: {
        main: '#df6262',
      },
      primary: {
        // main: '#191919'
        main: '#d156a1',
      },
      secondary: {
        main: '#191919',
      },
      chip: {
        main: '#797980',
        contrastText: '#fff',
      },
      selectedChip: {
        main: '#1E1E29',
        contrastText: '#fff',
      },
      color: {
        ...color,
        ...(mode === 'light' ? lightColors : darkColors),
      },
    },
    typography: {
      fontFamily: 'Inter, Raleway, Arial',
      fontWeight: { values: fontWeightsValues },
    },
  });

export const buildMantineTheme = (mode) => ({
  colorScheme: mode,
  cursorType: 'pointer',
  colors: {
    darkGrey: [
      '#919197',
      '#868689',
      '#79797E',
      '#6D6D73',
      '#636369',
      '#505058',
      '#424248',
      '#363639',
      '#2C2C2E',
      '#191B1F',
    ],
    bigagilePink: [
      '#F9DDF4',
      '#D8B8D2',
      '#F9DDF4',
      '#CD7DC0',
      '#D15CBD',
      '#DB36C0',
      '#BF36A9',
      '#A03D90',
      '#87407B',
      '#73406B',
    ],
    baRed: [
      '#cb3439',
      '#b72f33',
      '#a22a2e',
      '#8e2428',
      '#7a1f22',
      '#d0484d',
      '#d55d61',
      '#db7174',
      '#e08588',
      '#e59a9c',
    ],
    dark: [
      '#C9C9C9',
      '#B8B8B8',
      '#828282',
      '#696969',
      '#424242',
      '#3B3B3B',
      '#191B1F',
      '#191B1F',
      '#1F1F1F',
      '#191B1F',
    ],
    amber: [
      '#fff0e4',
      '#fee0d2',
      '#f4bea6',
      '#ec9c77',
      '#e57e4f',
      '#e26b35',
      '#e16028',
      '#c7501b',
      '#b34615',
      '#9c3a0c',
    ],
    done: [
      '#e8f2ff',
      '#d3e2fe',
      '#a6c2f5',
      '#77a0ed',
      '#4f83e6',
      '#3571e2',
      '#2568e1',
      '#1658c8',
      '#074eb5',
      '#0043a1',
    ],
    baGreen: [
      '#e9fbf0',
      '#dcf1e3',
      '#bbdfc8',
      '#97cdab',
      '#78be93',
      '#64b483',
      '#59af7a',
      '#489967',
      '#3c895a',
      '#2d764b',
    ],
  },
  primaryShade: { light: 6, dark: 9 },
  fontFamily: 'Inter, Raleway, Arial',
  other: {
    colours: {
      ...color,
      ...(mode === 'light' ? lightColors : darkColors),
    },
  },
  components: {
    Text: {
      defaultProps: {
        size: 'sm',
      },
    },
    Paper: {
      bg: mode === 'light' ? lightColors.paper : darkColors.paper,
    },
    Grid: Grid.extend({
      // defaultProps: {
      //   gutter: 0
      // },
      classNames: {
        root: classes.root,
      },
    }),
  },
});

export const resolver = (theme) => ({
  variables: Object.entries(color).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`--mantine-color-${key}`]: value,
    }),
    {},
  ),
  light: Object.entries(lightColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`--mantine-color-${key}`]: value,
    }),
    {},
  ),
  dark: Object.entries(darkColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`--mantine-color-${key}`]: value,
    }),
    {},
  ),
});
