import { Button, Card, CardContent } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Field, FormContext, renderTextField } from '../../Common/FormFieldsHooks';
import { BUTTONS } from '../../../utils/formConstants';
import { useMutation } from '@apollo/client';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField } from '../../../utils/helpers';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { DELETE_BACKLOG, INSERT_BACKLOG, UPDATE_BACKLOG } from '../../../containers/Settings/GraphQL/backlogs';

const BacklogDetails = (props, ref) => {
  const [insertBacklog] = useMutation(INSERT_BACKLOG);
  const [updateBacklog] = useMutation(UPDATE_BACKLOG);
  const [deleteBacklog] = useMutation(DELETE_BACKLOG);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...props.element,
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id');
      setValue('id', props.element.id);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteBacklog({
      variables: { id: backlog.id },
      update: deleteUpdate('backlog', 'backlog'),
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const backlogToSave = removeIdField(values);

    values.id
      ? updateBacklog({
          variables: { backlog: backlogToSave, backlogId: values.id },
        }).then(() => resetDrawerDetails())
      : insertBacklog({
          variables: { backlog: backlogToSave },
          update: insertUpdate('backlog', 'backlog'),
        }).then(() => resetDrawerDetails());
  };

  const backlog = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete Project / Product: ${backlog.name}`}
        text="Are you sure you want to delete this Project / Product ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(BacklogDetails);
