import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export default function PISelector({ options, value, onChange, color }) {
  const classes = useStyles({ backgroundColor: color });

  return (
    <div className={classes.piWrapper}>
      <TextField
        id="pi-selector"
        select
        label=""
        value={value}
        onChange={onChange}
        variant="standard"
        color="secondary"
        className={classes.piSelect}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        }}
        InputProps={{
          className: classes.input,
          disableUnderline: true,
        }}>
        {options?.map(({ value, label, color }) => (
          <MenuItem
            classes={{
              root: classes.selectedRoot,
              selected: classes.selectedItem,
            }}
            key={value}
            value={value}>
            <span className={classes.dot} style={{ backgroundColor: color }} />
            <span className={classes.piName}>{label}</span>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
