export const color = {
  dark: '#1e232a',
  white: '#ffffff',
  whiteBackground: '#fafafa',
  black: '#212529',
  blush: '#ed8270',
  silver: '#e7eaea',
  slateGrey: '#5f6367',
  greyishPurple: '#87868F',
  paleGrey: '#eeeff6',
  paleGrey2: '#dfe2e8',
  paleGrey3: '#edeff3',
  pinkishGrey: '#c9acac',
  darkishPink: '#d5469b',
  barbiePink: '#ff30c2',
  purpulishPink: '#d156a1',
  mediumPink: '#f04d99',
  warmGrey: '#868686',
  menuIconColor: '#818186',
  steel: '#8e8e8f',
  blueGrey: '#9aa8bd',
  purplePink: '#db36c0',
  gunMetal: '#3d4756',
  butterscotch: '#ffbf55',
  lightPurple: '#f9fafe',
  algaeGreen: '#17bb4b',
  brightBlue: '#0062ff',
  burntYellow: '#e3b505',
  shamrockGreen: '#00d153',
  // done: '#4498F8',
  amber: '#E16832',
  red: '#CB3439',
  green: '#50A771',
  forecasted: '#F6C544',
  greyIcon: '#757575',
  predicted: '#e095e1',
  toDo: '#F6C544',
  inProgress: '#E16832',
  done: '#2F6DE1',
  cancel: '#df6262',
  subtitle: '#93a0b5',
  secondary: '#808080',
  purple: '#9D81EF',
  lightBlue: '#82A7ED',
  grey: '#EFEFEF',
  companyColor: '#FF4189',
};

export const lightColors = {
  primary: '#000000',
  paper: '#ffffff',
  background: '#F7F7F8',
  darkPaper: '#f7f7f8',
  border: '#dfe2e8',
  borderPaper: '#dfe2e8',
  tableBorder: '#dfe2e8',
  dependency: 'white',
  accordion: '#f2f2f2',
  buttonIcon: '#F46EAE',
  disabled: '#f2f2f3',
  table: '#f7f7f7',
  row: '#ffffff',
  innerRow: '#f2f2f3',
  button: '#EAEAEB',
  icon: '#EAEAEB',
  column: '#f2f2f3',
  selector: '#ffffff',
  selectorBorder: '#464646',
  header: '#F4F5F5',
  divider: '#EEE7EA',
  cardBorder: '#EEE7EA',
  oddColumn: '#F4F5F5',
  tableColumn: '#E8E8E8',
  panel: '#ffffff',
  control: '#E8E8E8',
  shadow: '#D8C8D866',
  tileBorder: '#E8E8E8',
  graphLine: '#eaeaeb',
};

export const darkColors = {
  primary: '#C1C2C5',
  card: '#191B1F',
  table: '#292933',
  row: '#2F2F39',
  cardBorder: '#272727',
  innerRow: '#44444D',
  background: '#100F14',
  paper: '#191B1F',
  darkPaper: '#2D2D38',
  border: '#43434C',
  borderPaper: '#121212',
  tableBorder: '#2E2E38',
  dependency: '#1E1E1E',
  accordion: '#121212',
  buttonIcon: '#F46EAE',
  disabled: '#3D3D46',
  button: '#6E6E75',
  icon: '#22222d',
  column: '#292933',
  selector: '#2C2C2C',
  selectorBorder: '#464646',
  menu: '#191B1F',
  header: '#272727',
  divider: '#D9D9D9',
  oddColumn: '#191B1F',
  tableColumn: '#4C4A4C',
  panel: '#191B1F',
  control: '#272727',
  shadow: '#4C4A4C66',
  tileBorder: '#4C4A4C',
  graphLine: '#93a0b5',
};
