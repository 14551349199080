import { gql } from '@apollo/client';

export const VALUE_STREAM_STEP_FIELDS = gql`
  fragment ValueStreamStepFields on value_stream_steps {
    id
    name
    order
    description
    valueStream
  }
`;

export const INSERT_VALUE_STREAM_STEP = gql`
  ${VALUE_STREAM_STEP_FIELDS}
  mutation InsertFeature($valueStreamStep: [value_stream_steps_insert_input!] = {}) {
    valueStreamStep: insert_value_stream_steps(objects: $valueStreamStep) {
      returning {
        ...ValueStreamStepFields
      }
    }
  }
`;

export const UPDATE_VALUE_STREAM_STEP = gql`
  ${VALUE_STREAM_STEP_FIELDS}
  mutation UpdateFeature($valueStreamStepId: Int!, $valueStreamStep: value_stream_steps_set_input = {}) {
    update_value_stream_steps_by_pk(pk_columns: { id: $valueStreamStepId }, _set: $valueStreamStep) {
      ...ValueStreamStepFields
    }
  }
`;

export const DELETE_VALUE_STREAM_STEP = gql`
  ${VALUE_STREAM_STEP_FIELDS}
  mutation DeleteValueStreamStep($id: Int!) {
    valueStreamStep: delete_value_stream_steps_by_pk(id: $id) {
      ...ValueStreamStepFields
    }
  }
`;

export const GET_VALUE_STREAM_STEPS = gql`
  ${VALUE_STREAM_STEP_FIELDS}
  query GetValueStreamSteps($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    valueStreamSteps: value_stream_steps(where: { valueStream: { _eq: $valueStream } }, order_by: { id: asc }) {
      ...ValueStreamStepFields
    }
  }
`;
