import React from 'react';
import { Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Selector from 'components/Common/Selector';
import Toggle from 'components/Common/Toggle';

import { TOGGLE_VALUES } from './constants';
import PropTypes from 'prop-types';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const ToolBarSummary = (props) => {
  const {
    backlogs = [],
    backlog,
    team,
    teams,
    features,
    selectedPI,
    selectedItem,
    onBacklogChange,
    onTeamChange,
    handleSwitch,
    isAdditionalStories,
  } = props;
  const classes = useStyles(props);

  const selectTeamValues =
    Array.isArray(teams) && teams.length
      ? teams.filter((team) =>
          features.some(
            (feature) => feature.teamId === team.id && feature.programIncrement === selectedPI && feature.metrics,
          ),
        )
      : [];

  return (
    <Toolbar className={classes.appBar}>
      <div className={classes.appBarItems}>
        <Selector
          value={backlog}
          values={backlogs}
          label={'Project/Product'}
          placeholder={'Project/Product'}
          onChange={onBacklogChange}
        />
      </div>
      <div>
        {Array.isArray(teams) && teams.length && (
          <Selector
            value={team}
            values={selectTeamValues}
            label={'Team'}
            placeholder={'Team'}
            onChange={onTeamChange}
          />
        )}
      </div>
      <div>
        {isAdditionalStories && (
          <Toggle
            classes={{ buttonsWrapper: classes.buttonsWrapper }}
            className={classes.toogleWidth}
            values={TOGGLE_VALUES}
            selectedItem={selectedItem}
            handleSwitch={handleSwitch}
          />
        )}
      </div>
    </Toolbar>
  );
};

ToolBarSummary.propTypes = {
  backlogs: PropTypes.array,
  backlog: PropTypes.number,
  team: PropTypes.number,
  teams: PropTypes.array,
  features: PropTypes.array,
  selectedPI: PropTypes.number,
  selectedItem: PropTypes.string,
  onBacklogChange: PropTypes.func,
  handleSwitch: PropTypes.func,
  isAdditionalStories: PropTypes.bool,
};

export default ToolBarSummary;
