import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import Grow from '@mui/material/Grow';
import makeStyles from '@mui/styles/makeStyles';
import { orderBy } from 'lodash';

import EntityTable from '../../../components/Common/EntityTable';
import { openDrawer } from '../../../reactiveVariables';
import {
  selectedStateVar,
  selectedSubmitterVar,
  selectedUsersVar,
  selectedThinkTankValueStreamVar,
} from '../../../reactiveVariables';
import { exportCSVData, getFieldValue } from '../../../utils/export';
import { GET_ALL_IDEAS } from '../../Form/graphql';
import { headCells } from './constant';
import { getRowsData } from './helper';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const ThinkTank = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const classes = useStyles();

  const { data: { ideas = [] } = {} } = useQuery(GET_ALL_IDEAS);

  const selectedStates = useReactiveVar(selectedStateVar);
  const selectedSubmitters = useReactiveVar(selectedSubmitterVar);
  const selectedValueStream = useReactiveVar(selectedThinkTankValueStreamVar);
  const selectedUsers = useReactiveVar(selectedUsersVar);

  const filterIdeas = (ideas, users, states, submitter, valueStreams) => {
    return ideas.filter((idea) => {
      const ownerMatches = users.length === 0 || users.includes(idea.owner);
      const stateMatches = states.length === 0 || states.includes(idea.state);
      const submitterMatches = submitter.length === 0 || submitter.includes(idea.idea.creator);
      const valueStreamMatches =
        valueStreams === 'All' ||
        valueStreams === null ||
        valueStreams === undefined ||
        idea.idea.valueStreams.some((vs) => vs.id === valueStreams);

      return ownerMatches && stateMatches && submitterMatches && valueStreamMatches;
    });
  };

  const filteredIdeas = orderBy(
    filterIdeas(ideas, selectedUsers, selectedStates, selectedSubmitters, selectedValueStream) || [],
    'created_at',
    'desc',
  );

  useImperativeHandle(ref, () => ({
    export: () => exportThinkTank(),
  }));

  const exportThinkTank = useCallback(() => {
    const data = ideas.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'thinkTank');
  }, [ideas]);

  const onRowClick = (event, row) => {
    const idea = ideas.find((idea) => idea.id === row.id);
    openDrawer(
      { ...idea, priority: idea.idea.priority, valueStreams: idea.idea.valueStreams, createdAt: idea.created_at },
      'thinkTank',
    );
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          searchQuery={searchQueryLowerCase}
          headCells={headCells}
          rows={getRowsData(filteredIdeas)}
          expand={false}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(ThinkTank);
