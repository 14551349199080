import React, { useEffect, useRef, useState } from 'react';
import DrawerView from 'components/DrawerView';
import Menu from 'components/Menu';
import { useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import { GET_OBJECTIVES, SUBSCRIBE_OBJECTIVES } from '../Delivery/components/Objectives/graphql';
import { GET_FEATURES, SUBSCRIBE_FEATURES } from '../Product/Features/graphql';
import { GET_DEPENDENCIES, SUBSCRIBE_DEPENDENCIES } from '../Delivery/components/Dependencies/graphql';
import { GET_PROGRAM_INCREMENTS } from '../Settings/ProgramIncrement/graphql';
import { debounce } from 'lodash';
import { subscribeTo } from '../../apollo';
import { useLocation } from 'react-router';
import { accessTokenVar, globalLoadingVar, loggedInUserVar, resetDrawerDetails } from '../../reactiveVariables';
import AppRoutes from './components/Routes';
import keycloak from '../../keycloak';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import getFormRoutes from '../../containers/Form/routes';
import { Routes } from 'react-router';

const Subscriptions = () => {
  const objectivesSubscription = useQuery(GET_OBJECTIVES);
  const dependenciesSubscription = useQuery(GET_DEPENDENCIES);
  // eslint-disable-next-line no-unused-vars
  const featuresQuery = useQuery(GET_FEATURES);
  // eslint-disable-next-line no-unused-vars
  const programIncrementsQuery = useQuery(GET_PROGRAM_INCREMENTS);

  useSubscription(SUBSCRIBE_FEATURES, {
    onData: ({ client }) => refetchFeatures(client),
    onError: (error) => console.warn(`Subscription error: ${error}`),
  });

  const refetchFeatures = debounce((client) => client.query({ query: GET_FEATURES, fetchPolicy: 'network-only' }), 500);

  useEffect(() => {
    subscribeTo(objectivesSubscription.subscribeToMore, SUBSCRIBE_OBJECTIVES, 'objectives');
    subscribeTo(dependenciesSubscription.subscribeToMore, SUBSCRIBE_DEPENDENCIES, 'dependencies');
  }, []);

  return null;
};

const App = () => {
  const location = useLocation();
  const user = useReactiveVar(loggedInUserVar);
  const accessToken = useReactiveVar(accessTokenVar);
  const ref = useRef();
  const path = location.pathname.substring(1);
  const theme = useTheme();
  const globalLoading = useReactiveVar(globalLoadingVar);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    resetDrawerDetails();
  }, [location]);

  if (!keycloak.authenticated || !accessToken || keycloak.isTokenExpired(5)) {
    if (keycloak.authenticated && keycloak.isTokenExpired(5)) keycloak.onTokenExpired();
    return null;
  }

  const isFormRoute = path.includes('submit-idea');

  return (
    <>
      {isFormRoute ? (
        <Routes>{getFormRoutes(user, ref)}</Routes>
      ) : (
        <>
          <Menu handleOpen={setOpen} open={open} user={user} styles={{ height: '-webkit-fill-available' }} />
          <AppRoutes open={open} user={user} mainRef={ref} />
          <DrawerView location={path} user={user} styles={{ height: '-webkit-fill-available' }} />
          <ErrorBoundary FallbackComponent={null}>
            <Subscriptions />
          </ErrorBoundary>
          <Backdrop sx={{ position: 'absolute', zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={globalLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default App;
