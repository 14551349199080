import { gql } from '@apollo/client';

export const TEAM_FIELDS = gql`
  fragment TeamFields on teams {
    board
    boardName
    connectorId
    description
    id
    key
    name
    owner
    projectId
    programmes
    throughput
    throughputType
    type
    connector {
      type
      name
      id
    }
  }
`;

export const INSERT_TEAM = gql`
  ${TEAM_FIELDS}
  mutation InsertFeature($team: [teams_insert_input!] = {}) {
    team: insert_teams(objects: $team) {
      returning {
        ...TeamFields
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  ${TEAM_FIELDS}
  mutation UpdateFeature($teamId: Int!, $team: teams_set_input = {}) {
    update_teams_by_pk(pk_columns: { id: $teamId }, _set: $team) {
      ...TeamFields
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($id: Int!) {
    team: delete_teams_by_pk(id: $id) {
      id
      connectorId
    }
  }
`;

export const GET_TEAMS = gql`
  ${TEAM_FIELDS}
  query GetTeams {
    teams: teams(order_by: { id: asc }) {
      ...TeamFields
    }
  }
`;
