/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import 'sanitize.css/sanitize.css';
import { StyledEngineProvider } from '@mui/material/styles';
import config from 'Config';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

// Import root app
// Load the favicon, the manifest.json file and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';
import '@codehardt/leader-line/leader-line.min.css';

// Import i18n messages
import { translationMessages } from './i18n';
import { createRoot } from 'react-dom/client';

// Import Apollo
import AppWrapper from './containers/App/components/AppWrapper';
import { BrowserRouter } from 'react-router-dom';
import { api } from './utils/api';
import '@mantine/dates/styles.css';

// Create redux store with history
const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE); // createRoot(container!) if you use TypeScript

export const envConfig = config[window.location.hostname];

api.setup({ uri: config.apiUrl });

const render = (messages) => {
  root.render(
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <AppWrapper />
      </StyledEngineProvider>
    </BrowserRouter>,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    root.unmount();
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
