import { useEffect, useState } from 'react';
import { Combobox, InputBase, useCombobox } from '@mantine/core';
import { uniq } from 'lodash';

export function SelectCreatable({ options, onOptionSubmit, value: formValue, ...props }) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [data, setData] = useState(uniq([...options, formValue].filter(Boolean)));
  const [value, setValue] = useState(formValue);
  const [search, setSearch] = useState(formValue);

  useEffect(() => {
    setValue(formValue);
    setSearch(formValue);
  }, [formValue]);

  const exactOptionMatch = data.some((item) => item === search);
  const filteredOptions = exactOptionMatch
    ? data
    : data.filter((item) => item.toLowerCase().includes(search.toLowerCase().trim()));

  const values = filteredOptions.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          setData((current) => [...current, search]);
          onOptionSubmit(search);
          setValue(search);
        } else {
          setValue(val);
          setSearch(val);
          onOptionSubmit(val);
        }

        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value || '');
          }}
          placeholder="Search value"
          rightSectionPointerEvents="none"
          {...props}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {values}
          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">Add {search}</Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
