import { Container, Group, Title, useMantineTheme } from '@mantine/core';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import SvgKeyResult from '../../../../../assets/images/KeyResult';
import Dashboard from './Dashboard';
import Details from './Details';
import KeyResults from './KeyResults';
import Timeline from './Timeline';
import KeyResultDetails from '../KeyResultDetails';
import { useQuery } from '@apollo/client';
import { GET_ALL_INITIATIVES, GET_INITIATIVES } from '../../../../ValueStream/Initiatives/graphql';
import { GET_FEATURES, GET_FEATURES_FOR_VS } from '../../../../Product/Features/graphql';
import { calculateOutput, colorForStatus } from '../../utils';
import { every, some } from 'lodash';

const ObjectiveDetails = ({ objective, vsColor }) => {
  const theme = useMantineTheme();
  const [value, setValue] = useState(0);
  const [selectedKeyResult, setSelectedKeyResult] = useState(null);
  const { data: { initiatives = [] } = {} } = useQuery(objective.valueStream ? GET_INITIATIVES : GET_ALL_INITIATIVES, {
    variables: { valueStream: objective.valueStream },
  });
  const { data: { features = [] } = {} } = useQuery(objective.valueStream ? GET_FEATURES_FOR_VS : GET_FEATURES, {
    variables: { valueStream: objective.valueStream },
  });

  const keyResults =
    objective.keyResults?.map((keyResult) => ({
      ...keyResult,
      ...calculateOutput(keyResult, features, initiatives),
    })) || [];

  const status = every(keyResults, (keyResult) => keyResult.status === 'Done')
    ? 'Done'
    : some(keyResults, (keyResult) => keyResult.status === 'In Progress')
    ? 'In Progress'
    : 'Not Started';

  return (
    <>
      <Group w="100%" p="12px 16px 0 16px" gap={0} style={{ backgroundColor: theme.other.colours.paper }}>
        <Title order={3} mr={16}>
          {objective.name}
        </Title>
        <Display2 size="md" h={null} bgColor={colorForStatus(status)} style={{ borderRadius: '100px' }}>
          {status}
        </Display2>
        <Box sx={{ borderBottom: 1, margin: '0 -16px', borderColor: 'divider', width: 'calc(100% + 32px)' }}>
          <Tabs
            sx={{ margin: '0 16px', height: '60px' }}
            value={value}
            onChange={(event, value) => setValue(value)}
            indicatorColor="secondary"
            textColor="inherit"
            aria-label="icon label tabs">
            <Tab label="Details" />
            <Tab
              icon={<SvgKeyResult color={theme.other.colours.buttonIcon} />}
              iconPosition="start"
              label="Dashboard"
            />
            <Tab label="Key Results" />
          </Tabs>
        </Box>
      </Group>
      <Container
        w="100%"
        h="calc(100% - 124px)"
        style={{ maxWidth: '100%', backgroundColor: theme.other.colours.background }}>
        {value === 0 && <Details key={objective.id} objective={objective} />}
        {value === 1 && <Dashboard keyResults={keyResults} />}
        {value === 2 && (
          <KeyResults
            vsColor={vsColor}
            objective={objective}
            keyResults={keyResults}
            selectKeyResult={setSelectedKeyResult}
          />
        )}
      </Container>
      {selectedKeyResult && (
        <KeyResultDetails
          vsColor={vsColor}
          objective={objective}
          open={!!selectedKeyResult}
          keyResult={selectedKeyResult}
          setSelectedKeyResult={setSelectedKeyResult}
        />
      )}
    </>
  );
};

export default ObjectiveDetails;
