import React, { useState } from 'react';
import { Button, Group, Loader, Stepper } from '@mantine/core';
import FirstStep from './FirstStep';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_FEATURES_FOR_PROGRAMME,
  GET_FEATURES_FOR_TAGS,
  INSERT_FEATURE,
} from '../../../../Product/Features/graphql';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { selectedPiVar, selectedProgrammeVar } from '../../../../../reactiveVariables';
import { showNotification } from '@mantine/notifications';
import { insertUpdate } from '../../../../../utils/graphQLUtils';
import { getIssueTypeName, getToolingName, hasJiraBE } from '../../../../../utils/helpers';

const FeatureImportModal = ({ onClose }) => {
  const [active, setActive] = useState(0);
  const [team, setTeam] = useState(null);
  const [tags, setTags] = useState([]);
  const [importing, setImporting] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [getFeaturesForTags, { loading, data: { toolingFeatures = [] } = {} }] = useLazyQuery(GET_FEATURES_FOR_TAGS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const [insertFeature] = useMutation(INSERT_FEATURE);

  const selectedPi = useReactiveVar(selectedPiVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const name = getToolingName();

  const searchFeatures = () => {
    getFeaturesForTags({
      variables: { connectorId: team.connectorId, projectId: team.projectId, tags: tags.join(',') },
    }).then(nextStep);
  };

  const addSelectedFeature = (id) => {
    if (selectedFeatures.includes(id)) {
      setSelectedFeatures(selectedFeatures.filter((featureId) => featureId !== id));
    } else {
      setSelectedFeatures([...selectedFeatures, id]);
    }
  };

  const submit = () => {
    const insertPromises = toolingFeatures
      .filter((feature) => selectedFeatures.includes(feature.id))
      .map((feature) =>
        insertFeature({
          update: insertUpdate('feature'),
          variables: {
            feature: {
              name: feature.name,
              description: feature.description,
              teamId: team.id,
              ragStatus: 'Green',
              status: 'To Do',
              businessOwner: [],
              stage: 'planned',
              type: hasJiraBE ? 'jira' : 'azure',
              epicId: feature.id.toString(),
              programIncrement: selectedPi,
              programmeId: selectedProgramme,
            },
          },
        })
          .then(() =>
            showNotification({
              title: `Feature Created`,
              message: `Feature was successfully created for ID ${feature.id}`,
            }),
          )
          .catch((error) =>
            showNotification({
              title: `Error when creating Feature for ${name} ID ${feature.id}`,
              message: error,
              color: 'red',
            }),
          ),
      );

    onClose(false);

    Promise.all(insertPromises)
      .then(() => setImporting(false))
      .catch(() => setImporting(false));
  };

  return (
    <>
      <Stepper active={active} onStepClick={setActive} breakpoint="sm">
        <Stepper.Step label="First step" description="Select Connector & Team">
          <FirstStep onTeamSelect={setTeam} onTagsChange={setTags} />
        </Stepper.Step>
        <Stepper.Step label="Second step" description="Select Features">
          <SecondStep
            team={team}
            existingFeatures={features}
            selectedFeatures={selectedFeatures}
            addSelectedFeature={addSelectedFeature}
            features={toolingFeatures}
          />
        </Stepper.Step>
        <Stepper.Step label="Final step" description="Review & Import">
          <ThirdStep
            removeSelectedFeature={addSelectedFeature}
            selectedFeatures={selectedFeatures}
            features={toolingFeatures}
          />
        </Stepper.Step>
        <Stepper.Completed>Importing Features {importing && <Loader sx={{ marginLeft: '12px' }} />}</Stepper.Completed>
      </Stepper>

      <Group position="center" mt="xl">
        {active !== 0 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {active === 0 && (
          <Button loading={loading} onClick={searchFeatures}>
            Search Features
          </Button>
        )}
        {active === 1 && <Button onClick={nextStep}>Review</Button>}
        {active === 2 && <Button onClick={submit}>Import</Button>}
      </Group>
    </>
  );
};

export default FeatureImportModal;
