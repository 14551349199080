import * as React from 'react';

function SvgIdeation(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 16C1.94772 16 1.5 15.5523 1.5 15V3C1.5 2.44772 1.94771 2 2.5 2H5.5C6.05228 2 6.5 2.44772 6.5 3L6.5 15C6.5 15.5523 6.05228 16 5.5 16H2.5ZM9 16C8.44772 16 8 15.5523 8 15L8 3C8 2.44772 8.44771 2 9 2H11C11.5523 2 12 2.44772 12 3L12 15C12 15.5523 11.5523 16 11 16H9ZM13.5 15C13.5 15.5523 13.9477 16 14.5 16H15.5C16.0523 16 16.5 15.5523 16.5 15V6C16.5 5.44772 16.0523 5 15.5 5H14.5C13.9477 5 13.5 5.44772 13.5 6V15Z"
      />
    </svg>
  );
}

export default SvgIdeation;
