import { useLocation, useOutlet } from 'react-router';
import React, { useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { mainRef, stickyHeaderVar } from '../../../reactiveVariables';
import { Toolbar } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { styles } from '../styles';
import styled from '@emotion/styled';

const useStyles = makeStyles(styles);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  width: '100%',
}));

const ProtectedLayout = ({ open }) => {
  const outlet = useOutlet();
  const classes = useStyles();
  const location = useLocation();
  const ref = useRef();
  const stickyHeader = useReactiveVar(stickyHeaderVar);

  mainRef(ref);

  return (
    <div style={{ display: 'block', width: '100%', overflow: 'auto' }}>
      <Toolbar />
      <Main
        open={open && location.pathname === '/product/ideation'}
        id="main"
        ref={ref}
        className={classNames(
          classes.content,
          stickyHeader && classes.stickyHeader,
          ['programBoard', ''].includes(location.pathname) && classes.contentProgramBoard,
          'product' === location.pathname && classes.contentIdeation,
        )}>
        {outlet}
        <div id="wrapper" />
      </Main>
    </div>
  );
};

export default ProtectedLayout;
