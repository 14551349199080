import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const RoundButton = (props) => {
  const { buttonText, handleClick, autoSize, disableElevation = false, width = 'auto', disabled = false } = props;
  const classes = useStyles(props);

  return (
    <Button
      style={{
        width: width,
      }}
      disableElevation={disableElevation}
      className={autoSize ? classes.noWidth : classes.button}
      variant="contained"
      disabled={disabled}
      onClick={handleClick}>
      <span className={classes.buttonText}>{buttonText}</span>
    </Button>
  );
};

RoundButton.propTypes = {
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
};

export default RoundButton;
