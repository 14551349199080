import * as React from 'react';

function SvgIncrease(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9906 4.80592L15.0011 4.06668L14.2406 4.05592L10.2952 4.00008C9.8752 3.99414 9.53004 4.32029 9.52427 4.72856C9.51849 5.13683 9.85427 5.47261 10.2743 5.47856L12.3773 5.50832L8.7452 9.30547L6.28924 6.73787C6.14775 6.58995 5.95195 6.50629 5.74726 6.50629C5.54256 6.50629 5.34676 6.58995 5.20528 6.73787L1.20802 10.9168C0.921706 11.2161 0.932256 11.6909 1.23158 11.9772C1.53091 12.2635 2.00567 12.253 2.29198 11.9537L5.74726 8.34132L8.20322 10.9089C8.34471 11.0568 8.5405 11.1405 8.7452 11.1405C8.94989 11.1405 9.14569 11.0568 9.28718 10.9089L13.4446 6.56257L13.4154 8.61973C13.4097 9.028 13.7455 9.36379 14.1654 9.36973C14.5854 9.37568 14.9306 9.04953 14.9364 8.64126L14.9906 4.80592Z"
        fill="#50A771"
      />
    </svg>
  );
}

export default SvgIncrease;
