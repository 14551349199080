import { ColumnHeader, PIDates, PIName } from './styles';
import { piStatusColor } from '../../../utils/piColors';
import { FlexDiv } from '../../../components/Common/Elements';
import { Divider, Text } from '@mantine/core';
import React from 'react';
import { useTheme } from '@emotion/react';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { Dot } from '../../ValueStream/components/Common';

const Header = ({ pi, incrementVelocity, forecastValue }) => {
  const theme = useTheme();

  const getLoad = (features) => {
    if (forecastValue === 'feature') return features?.length || 0;
    else return sumBy(features, 'size');
  };

  const load = getLoad(pi.features);

  return (
    <ColumnHeader>
      <PIDates
        color={piStatusColor(pi.status, theme, false, true)}
        backgroundColor={piStatusColor(pi.status, theme, true)}>
        {`${pi.startDate?.toLocaleString(DateTime.DATE_SHORT) ?? 'Unknown'} - ${
          pi.endDate?.toLocaleString(DateTime.DATE_SHORT) ?? 'Unknown'
        }`}
      </PIDates>
      <PIName>
        <Dot style={{ height: '8px', width: '8px', backgroundColor: piStatusColor(pi.status, theme) }} />
        <Text span={true} size="md" fw={600}>{`${pi.name} (${pi.status})`}</Text>
      </PIName>
      <FlexDiv style={{ padding: '10px 0 0 0' }}>
        <Text size="sm" c={'dimmed'}>
          Capacity:{' '}
        </Text>
        &nbsp;
        <Text size="xs">{incrementVelocity}</Text>
        <Divider size={1} orientation="vertical" style={{ margin: '3px 8px' }} />
        <Text size="sm" c={load > incrementVelocity ? 'baRed.0' : 'dimmed'}>
          Load:
        </Text>
        &nbsp;
        <Text size="sm" c={load > incrementVelocity ? 'baRed.0' : ''}>
          {load}
        </Text>
      </FlexDiv>
    </ColumnHeader>
  );
};

export default Header;
