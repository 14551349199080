import {
  ActionIcon,
  Divider,
  Grid,
  Group,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { InputText, StyledAvatar } from '../../../../components/Common/Fields';
import { DateInput } from '@mantine/dates';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SvgAdd from '../../../../assets/images/Add';
import { useFieldArray } from 'react-hook-form';
import {
  Field,
  renderCombobox,
  renderCreatableCombobox,
  renderDate,
  renderInput,
  renderNumberInput,
} from '../../../../components/Common/FormFieldsHooks/MantineField';
import { date } from '../../../../utils/helpers';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import * as d3 from 'd3';
import { DATE_FORMAT } from '../../../../utils/timeUtils';

const SecondPage = ({ keyResult, fieldArray: { fields, append, remove } }) => {
  const theme = useMantineTheme();

  useEffect(() => {
    if (keyResult && isEmpty(fields)) {
      const expected = d3
        .scaleTime()
        .range([keyResult.startingValue, keyResult.targetValue])
        .domain([new Date(keyResult.startingDate), new Date(keyResult.targetDate)]);

      switch (keyResult.reviewFrequency) {
        case 'weekly': {
          const nbOfWeeks = Math.floor(
            DateTime.fromJSDate(new Date(keyResult.targetDate)).diff(
              DateTime.fromJSDate(new Date(keyResult.startingDate)),
              'weeks',
            ).weeks,
          );

          [...Array(nbOfWeeks).keys()].map((week) => {
            const inflectionDate = DateTime.fromJSDate(new Date(keyResult.startingDate)).plus({ weeks: week + 1 });
            append({
              date: inflectionDate.toFormat('yyyy-MM-dd'),
              expectedValue: Math.round(expected(inflectionDate.toJSDate())),
            });
          });
          break;
        }
        case 'monthly': {
          const nbOfMonths = Math.floor(
            DateTime.fromJSDate(new Date(keyResult.targetDate)).diff(
              DateTime.fromJSDate(new Date(keyResult.startingDate)),
              'months',
            ).months,
          );

          [...Array(nbOfMonths).keys()].map((month) => {
            const inflectionDate = DateTime.fromJSDate(new Date(keyResult.startingDate)).plus({ months: month + 1 });
            append({
              date: inflectionDate.toFormat('yyyy-MM-dd'),
              expectedValue: Math.round(expected(inflectionDate.toJSDate())),
            });
          });
          break;
        }
        case 'quarterly': {
          const nbOfQuarters = Math.floor(
            DateTime.fromJSDate(new Date(keyResult.targetDate)).diff(
              DateTime.fromJSDate(new Date(keyResult.startingDate)),
              'quarters',
            ).quarters,
          );

          [...Array(nbOfQuarters).keys()].map((quarter) => {
            const inflectionDate = DateTime.fromJSDate(new Date(keyResult.startingDate)).plus({
              quarters: quarter + 1,
            });
            append({
              date: inflectionDate.toFormat('yyyy-MM-dd'),
              expectedValue: Math.round(expected(inflectionDate.toJSDate())),
            });
          });
          break;
        }
        case '6months': {
          const nbOfMonths = Math.floor(
            DateTime.fromJSDate(new Date(keyResult.targetDate)).diff(
              DateTime.fromJSDate(new Date(keyResult.startingDate)),
              'months',
            ).months,
          );

          [...Array(nbOfMonths >= 6 ? nbOfMonths / 6 : 0).keys()].map((months) => {
            const inflectionDate = DateTime.fromJSDate(new Date(eyResult.startingDate)).plus({
              months: 6 * (months + 1),
            });
            append({
              date: inflectionDate.toFormat('yyyy-MM-dd'),
              expectedValue: Math.round(expected(inflectionDate.toJSDate())),
            });
          });
          break;
        }
      }
    }
  }, []);

  return (
    <>
      <Group mb={12}>
        <Stack gap={0}>
          <Title order={6}>Starting Position</Title>
          <Text c="dimmed">Define expected trajectory</Text>
        </Stack>
      </Group>
      <Grid>
        <Field name="startingValue" disabled renderField={renderNumberInput} label="Starting Value" size={4} />
        <Field name="startingDate" disabled clearable={false} renderField={renderDate} label="Starting Date" size={4} />
        <Field
          name="unit"
          disabled
          renderField={renderCreatableCombobox}
          label="Unit"
          options={['£', '$', '€', '%', 'Points']}
          size={3}
        />
      </Grid>
      <Divider mt={12} mb={12} />
      <Group mb={12}>
        <Stack gap={0}>
          <Title order={6}>Expected Trajectory</Title>
        </Stack>
      </Group>
      {fields.map((field, index) => (
        <Grid align="center">
          <Field
            name={`trajectory[${index}].expectedValue`}
            required
            renderField={renderNumberInput}
            label="Expected Value"
            size={4}
          />
          <Field name={`trajectory[${index}].date`} required renderField={renderDate} label="Date" size={4} />
          <Field
            name="unit"
            disabled
            renderField={renderCreatableCombobox}
            label="Unit"
            options={['£', '$', '€', '%', 'Points']}
            size={3}
          />
          <Grid.Col span={1}>
            <ActionIcon
              mt={24}
              variant="transparent"
              color="primary"
              aria-label="Settings"
              onClick={() => remove(index)}>
              <CloseIcon stroke={theme.other.colours.secondary} />
            </ActionIcon>
          </Grid.Col>
        </Grid>
      ))}
      <Group gap={1} mt={12}>
        <Text c="dimmed">Add inflection point</Text>
        <ActionIcon variant="transparent" color="primary" aria-label="Settings" onClick={() => append({})}>
          <SvgAdd stroke={theme.other.colours.secondary} />
        </ActionIcon>
      </Group>
      <Divider mt={12} mb={12} />
      <Group mb={12}>
        <Stack gap={0}>
          <Title order={6}>Target Position</Title>
        </Stack>
      </Group>
      <Grid>
        <Field name="targetValue" disabled renderField={renderInput} label="Target Value" size={4} />
        <Field name="targetDate" disabled clearable={false} renderField={renderDate} label="Target Date" size={4} />
        <Field
          name="unit"
          disabled
          renderField={renderCreatableCombobox}
          label="Unit"
          options={['£', '$', '€', '%', 'Points']}
          size={3}
        />
      </Grid>
    </>
  );
};

export default SecondPage;
