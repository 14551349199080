import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Avatar, Checkbox, Chip, Collapse, Grid, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { TransitionGroup } from 'react-transition-group';
import { DndContext } from '@dnd-kit/core';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import { GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { GET_VALUE_STREAM_STEPS } from './graphql';
import { nEq } from '../../../utils/helpers';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../components/Common/Elements';
import { StyledPaper } from '../components/Common';

export const Header = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.borderPaper}`,
  padding: '14px',
  height: '52px',
}));

const Body = styled('div')(({ theme }) => ({
  height: '100%',
  paddingTop: '8px',
  paddingBottom: '4px',
}));

const BoardColumn = styled((props) => (
  <Grid item container xs={2} flexDirection="column" textAlign="center" {...props} />
))(({ theme }) => ({
  height: '100%',
  minWidth: '300px',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: '0px 5px 5px 5px',
  '&:not(:last-child)': {
    borderRight: '2px dashed #63636B',
  },
}));

const StoryCard = ({ story, ...rest }) => {
  return (
    <div style={{ padding: '4px', width: '100%' }} {...rest}>
      <Paper elevation={0} sx={{ width: '100%', padding: '8px', backgroundColor: 'color.card' }}>
        <span style={{ fontWeight: 500, fontSize: '12px' }}>{story.name}</span>
      </Paper>
    </div>
  );
};
const DashboardCard = ({
  title,
  cardStyle,
  gridStyle,
  contentStyle,
  onScroll,
  headerStyle,
  children,
  bodyRef,
  action,
  xs = 6,
}) => {
  return (
    <Grid item xs={xs} sx={gridStyle}>
      <StyledPaper sx={{ height: '60vh', maxHeight: '400px', display: 'flex', flexDirection: 'column', ...cardStyle }}>
        <Header
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            backgroundColor: 'color.darkPaper',
            borderBottom: `1px solid color.borderPaper`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            ...headerStyle,
          }}>
          <span style={{ fontWeight: 600, fontSize: '16px' }}>{title}</span>
          {action}
        </Header>
        <Body ref={bodyRef} onScroll={onScroll} sx={contentStyle}>
          {children}
        </Body>
      </StyledPaper>
    </Grid>
  );
};

const GridItem = forwardRef((props, ref) => <Grid item width="100%" xs={6} {...props} ref={ref} />);
export const GridContainer = forwardRef((props, ref) => (
  <Grid container width="100%" xs={12} ref={ref} spacing={1} rowSpacing={1} {...props} />
));

const Elements = ({ elements, title, ...rest }) => {
  return (
    <div style={{ padding: '4px 8px 4px 8px', width: '100%' }} {...rest}>
      <Paper elevation={0} sx={{ width: '100%', padding: 0, backgroundColor: 'color.paper' }}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Chip
              sx={{
                marginLeft: '8px',
                height: '25px',
                width: '25px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: 0 },
              }}
              label={elements.length}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Body style={{ height: '100%' }}>
              <TransitionGroup component={GridContainer}>
                {elements.map((element) => (
                  <Collapse component={GridItem} key={element.id}>
                    <StoryCard story={element} />
                  </Collapse>
                ))}
              </TransitionGroup>
            </Body>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
};

const Steps = (props) => {
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { valueStreamSteps = [] } = {} } = useQuery(GET_VALUE_STREAM_STEPS);

  const [selectedSteps, setSelectedSteps] = useState([]);

  useEffect(() => setSelectedSteps([]), [valueStreamSteps]);

  const selectedFeatures = useMemo(
    () => features.filter((feature) => feature.valueStreamSteps?.some((step) => selectedSteps.includes(step.id))),
    [features, selectedSteps],
  );

  const selectedInitiatives = useMemo(
    () =>
      initiatives.filter((initiative) => initiative.valueStreamSteps?.some((step) => selectedSteps.includes(step.id))),
    [initiatives, selectedSteps],
  );

  const updateStep = (id, checked) => {
    if (checked) {
      setSelectedSteps([...selectedSteps, id]);
    } else {
      setSelectedSteps(selectedSteps.filter(nEq(id)));
    }
  };

  return (
    <Grid container height="100%" direction="column" rowSpacing={3}>
      <span style={{ fontWeight: 600, height: '40px', paddingTop: '10px', fontSize: '20px' }}>SportsBooks</span>
      <Grid item container xs={12} spacing={1} style={{ flex: 1 }} padding={0}>
        <DashboardCard
          title={`Steps: ${valueStreamSteps.length}`}
          cardStyle={{
            height: '100%',
            flexFlow: 1,
            transition: 'width 300ms',
            maxHeight: null,
            padding: '8px',
          }}
          contentStyle={{ overflowX: 'auto', height: '100%', marginTop: '8px' }}
          xs={3}>
          <TransitionGroup>
            {valueStreamSteps.map((step) => (
              <Collapse key={step.id}>
                <Paper
                  sx={{
                    marginBottom: '8px',
                    backgroundColor: 'color.innerRow',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px',
                    display: 'flex',
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ color: 'white', height: '28px', width: '28px', marginRight: '16px' }}>
                      {step.order}
                    </Avatar>
                    <div>
                      <Typography fontWeight={400} fontSize={14}>
                        {step.name}
                      </Typography>
                      <Typography>Features 0 Initiatives 0</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Checkbox
                      checked={selectedSteps.includes(step.id)}
                      onChange={(event) => updateStep(step.id, event.target.checked)}
                    />
                  </div>
                </Paper>
              </Collapse>
            ))}
          </TransitionGroup>
        </DashboardCard>
        <DashboardCard
          title={selectedSteps.length ? `Viewing: Step ${selectedSteps.join(', ')}` : 'Viewing:'}
          contentStyle={{ overflow: 'auto' }}
          cardStyle={{ padding: '8px', width: '100%', maxHeight: null, height: '100%', transition: 'width 300ms' }}
          xs={9}>
          <DndContext>
            <Grid container sx={{ height: '100%' }}>
              <BoardColumn xs={12}>
                <Paper
                  elevation={0}
                  sx={{ flexShrink: 0, marginBottom: '5px', padding: '8px', backgroundColor: 'color.darkPaper' }}>
                  <Typography textAlign="left">{`Features: ${selectedFeatures.length} Initiatives: ${selectedInitiatives.length}`}</Typography>
                </Paper>
                <div style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto' }}>
                  <Elements elements={selectedFeatures} title="Features" />
                  <Elements elements={selectedInitiatives} title="Initiatives" />
                </div>
              </BoardColumn>
            </Grid>
          </DndContext>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default forwardRef(Steps);
