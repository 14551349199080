import * as React from 'react';

function TodoSvg(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99996 4.66667C5.99996 6.13943 4.80605 7.33333 3.33329 7.33333C1.86053 7.33333 0.666626 6.13943 0.666626 4.66667C0.666626 3.19391 1.86053 2 3.33329 2C4.80605 2 5.99996 3.19391 5.99996 4.66667ZM4.67926 3.45195C4.9083 3.6062 4.96893 3.91692 4.81468 4.14597L3.55753 6.01263L3.23374 6.49341L2.80568 6.10258L1.92949 5.30258C1.72556 5.11638 1.71119 4.80013 1.89738 4.5962C2.08358 4.39227 2.39983 4.3779 2.60376 4.56409L3.05189 4.97325L3.98524 3.58737C4.13949 3.35832 4.45022 3.29769 4.67926 3.45195ZM15.3333 4H7.33329V5.33333H15.3333V4ZM7.33329 10.6667H15.3333V12H7.33329V10.6667ZM4.66663 11.3333C4.66663 12.0697 4.06967 12.6667 3.33329 12.6667C2.59691 12.6667 1.99996 12.0697 1.99996 11.3333C1.99996 10.597 2.59691 10 3.33329 10C4.06967 10 4.66663 10.597 4.66663 11.3333ZM5.99996 11.3333C5.99996 12.8061 4.80605 14 3.33329 14C1.86053 14 0.666626 12.8061 0.666626 11.3333C0.666626 9.86057 1.86053 8.66667 3.33329 8.66667C4.80605 8.66667 5.99996 9.86057 5.99996 11.3333Z"
        fill="black"
      />
    </svg>
  );
}

export default TodoSvg;
