import {
  ActionIcon,
  Avatar,
  Divider,
  Group,
  Menu,
  Paper,
  rem,
  rgba,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import SvgHexagone from '../../../../assets/images/Hexagon';
import React, { useState } from 'react';
import SvgChevronRight from '../../../../assets/images/ChevronRight';
import SvgAdd from '../../../../assets/images/Add';
import UpArrow from '../../../../assets/images/UpArrow';
import { Display2 } from '../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import { Dot } from '../../../../components/Common/Elements';
import SvgKeyResult from '../../../../assets/images/KeyResult';
import SvgMoreAction from '../../../../assets/images/MoreAction';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_VALUE_STREAMS } from '../../../Settings/Streams/graphql';
import CreateObjectiveModal from '../CreateObjectiveModal';
import { DELETE_PORTFOLIO_OBJECTIVE, GET_PORTFOLIO_OBJECTIVES } from '../graphql';
import { DATE_FORMAT } from '../../../../utils/timeUtils';
import { date } from '../../../../utils/helpers';
import { mean, some, sum } from 'lodash';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import SvgHorizontalMore from '../../../../assets/images/HorizontalMoreAction';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  calculateOutcome,
  getRagColor,
  getRagForKeyResult,
  getRagPercentage,
  getTrend,
  getUserName,
  outcomeColor,
} from '../utils';
import DownArrow from '../../../../assets/images/DownArrow';
import SvgRightArrow from '../../../../assets/images/RightArrow';
import ActionMenu from '../Components/ActionMenu';

export const vsColors = [
  '#0062ff',
  '#CB3439',
  '#9D81EF',
  '#FFC107',
  '#50A771',
  '#f3722c',
  '#277da1',
  '#774936',
  '#F94144',
];

const getVsColor = (index) => {
  return undefined;
};

const Objectives = () => {
  const theme = useMantineTheme();
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { portfolioObjectives = [] } = {} } = useQuery(GET_PORTFOLIO_OBJECTIVES);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  return (
    <SimpleGrid cols={2} pt={24} pb={8}>
      <ValueStreamOKRs
        id={0}
        objectives={portfolioObjectives.filter((objective) => objective.valueStreamId === null)}
        title="Global"
        color={theme.other.colours.buttonIcon}
        users={users}
      />
      {activeValueStreams.map((valueStream, index) => (
        <ValueStreamOKRs
          key={valueStream.id}
          id={valueStream.id}
          objectives={portfolioObjectives.filter((objective) => objective.valueStreamId === valueStream.id)}
          title={valueStream.name}
          color={vsColors[index]}
          users={users}
        />
      ))}
    </SimpleGrid>
  );
};

const ValueStreamOKRs = ({ id, title, objectives, color, users }) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [openCreate, setOpenCreate] = useState(false);
  const keyResults = objectives.flatMap((objective) => objective.keyResults);
  const objectiveOutcomes = objectives.map(
    (objective) => mean(objective.keyResults.map((keyResult) => calculateOutcome(keyResult))) || 0,
  );
  const objectiveRagPercentages = objectives.map(
    (objective) => mean(objective.keyResults.map((keyResult) => getRagPercentage(keyResult))) || 0,
  );

  const outcome = Math.round(mean(objectiveOutcomes)) || 0;
  const ragPercentage = Math.round(mean(objectiveRagPercentages)) || 0;

  return (
    <Paper bg={theme.other.colours.paper} withBorder style={{ boxShadow: '0px 2px 2px 0px #0000000D' }} p="12px 16px">
      <Stack gap="12px">
        <Group gap={4}>
          <ThemeIcon
            variant="outline"
            style={{ backgroundColor: rgba(color, 0.15), cursor: 'pointer' }}
            onClick={() => navigate(`/organisation/okrs/${id}`)}
            color={color}
            mr={8}>
            <SvgHexagone />
          </ThemeIcon>
          <Title order={4} style={{ cursor: 'pointer' }} onClick={() => navigate(`/organisation/okrs/${id}`)}>
            {title}
          </Title>
          <ActionIcon
            variant={'transparent'}
            size="xs"
            color={theme.other.colours.paper}
            onClick={() => navigate(`/organisation/okrs/${id}`)}
            ml={4}>
            <SvgChevronRight />
          </ActionIcon>
        </Group>
        <Group gap={4}>
          <ThemeIcon variant="transparent" color={theme.other.colours.secondary} size="1rem">
            <SvgHexagone />
          </ThemeIcon>
          <Text size="xs">Key Results: {keyResults.length}</Text>
          <ThemeIcon variant="transparent" color={getRagColor(ragPercentage, theme)} size="1rem">
            <SvgHexagone />
          </ThemeIcon>
          <Text size="xs">
            Progress:{' '}
            <Text span c={getRagColor(ragPercentage, theme)} inherit>
              {outcome}%
            </Text>
          </Text>
        </Group>
        <Divider />
        <Group gap={4}>
          <Text size="sm" c="dimmed">
            Objectives: {objectives.length}
          </Text>
          <Tooltip transitionProps={{ transition: 'scale' }} label="Add new objective">
            <ActionIcon variant={'transparent'} size="xs" color="white" onClick={() => setOpenCreate(true)} ml={4}>
              <SvgAdd stroke={theme.other.colours.primary} />
            </ActionIcon>
          </Tooltip>
        </Group>
        {objectives.map((objective, index) => (
          <Objective key={index} index={index} objective={objective} users={users} vsId={id} />
        ))}
        {openCreate && (
          <CreateObjectiveModal open={openCreate} color={color} valueStream={id} close={() => setOpenCreate(false)} />
        )}
      </Stack>
    </Paper>
  );
};

const Objective = ({ objective, users, vsId, index }) => {
  const theme = useMantineTheme();
  const owner = users.find((user) => user.id === objective.owner) || {};
  const [deleteObjective] = useMutation(DELETE_PORTFOLIO_OBJECTIVE, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const navigate = useNavigate();
  const outcome = Math.round(mean(objective.keyResults.map((keyResult) => calculateOutcome(keyResult))) || 0);
  const ragPercentage = Math.round(mean(objective.keyResults.map((keyResult) => getRagPercentage(keyResult))) || 0);

  return (
    <Paper
      bg={theme.other.colours.paper}
      withBorder
      style={{ cursor: 'pointer', boxShadow: '0px 2px 2px 0px #0000000D' }}
      p="8px 12px"
      onClick={() => navigate(`/organisation/okrs/${vsId}`, { state: { objectiveIndex: index } })}>
      <Group gap={4} justify="space-between">
        <Group gap={4}>
          <Text size="sm">{objective.name}</Text>
        </Group>
        <Group gap={12}>
          {/*<Display2 variant="light" bgColor={theme.other.colours.purple}>Likely</Display2>*/}
          <Tooltip label={getUserName(owner)}>
            <Avatar size="sm" color="red">
              {owner.firstName?.charAt(0)}
            </Avatar>
          </Tooltip>
          <ActionMenu action={deleteObjective} element={objective} />
        </Group>
      </Group>
      <Divider m="8px 0" c={theme.other.colours.tileBorder} />
      <Group gap={8}>
        <Text size="xs" c="dimmed">
          {date(objective.targetDate).toFormat(DATE_FORMAT.date)}
        </Text>
        <Dot style={{ width: '6px', height: '6px', backgroundColor: theme.other.colours.secondary, marginRight: 0 }} />
        <Group gap={4}>
          <Text size="xs" c="dimmed">
            Key Results: {objective.keyResults.length}
          </Text>
          <ThemeIcon variant="transparent" color={theme.other.colours.secondary} size="1rem">
            <SvgKeyResult />
          </ThemeIcon>
        </Group>
        <Dot style={{ width: '6px', height: '6px', backgroundColor: theme.other.colours.secondary, marginRight: 0 }} />
        <Group gap={4}>
          <Text size="xs" c="dimmed">
            Progress:
          </Text>
          <Dot
            style={{ width: '6px', height: '6px', backgroundColor: getRagColor(ragPercentage, theme), marginRight: 0 }}
          />
          <Text size="xs" c={getRagColor(ragPercentage, theme)}>
            {outcome}%
          </Text>
        </Group>
      </Group>
    </Paper>
  );
};
export default Objectives;
