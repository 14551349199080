import { alpha } from '@mui/material/styles';
import { DEV_STATUS, RAG_STATUS } from 'utils/formConstants';
import { darken } from '@mui/system/colorManipulator';

export const piStatusColor = (status, theme, opacity = false, dark = false) => {
  let color;

  if (status === 'active') {
    color = theme.palette.color.inProgress;
  } else if (status === 'complete') {
    color = theme.palette.color.done;
  } else if (status === 'planning') {
    color = theme.palette.color.toDo;
  } else {
    color = theme.palette.color.predicted;
  }

  return opacity ? alpha(color, 0.25) : dark && theme.palette.mode === 'light' ? darken(color, 0.25) : color;
};

export const valueStreamStatusColor = (isActive, theme) => {
  // Default colors in case theme is not available (can remove later)
  const defaultActiveColor = '#1976d2'; // A default blue color
  const defaultInactiveColor = '#808080'; // A default grey color

  if (!theme || !theme.palette || !theme.palette.color) {
    return isActive ? defaultActiveColor : defaultInactiveColor;
  }

  return isActive
    ? theme.palette.color.inProgress || defaultActiveColor
    : theme.palette.color.red || defaultInactiveColor;
};

export const getFeatureBackgroundColor = (element, theme) => {
  const { status, ragStatus, isDescoped } = element;

  if (isDescoped) return theme.palette.color.greyIcon;

  return status === DEV_STATUS.DONE
    ? theme.palette.color.done
    : ragStatus === RAG_STATUS.AMBER
    ? theme.palette.color.amber
    : ragStatus === RAG_STATUS.RED
    ? theme.palette.color.red
    : theme.palette.color.green;
};

export const getStatusColorMantine = (element, theme) => {
  const { status, ragStatus, isDescoped } = element;

  if (isDescoped) return theme.other.colours.greyIcon;

  return status === DEV_STATUS.DONE
    ? theme.other.colours.done
    : ragStatus === RAG_STATUS.AMBER
    ? theme.other.colours.amber
    : ragStatus === RAG_STATUS.RED
    ? theme.other.colours.red
    : theme.other.colours.green;
};
