import { FlexDiv } from '../../../components/Common/Elements';
import { ActionIcon, Grid, Group, Slider, Text, Tooltip } from '@mantine/core';
import InfoIcon from '@mui/icons-material/Info';
import Select from '../../../components/Common/Select';
import SvgForecast from '../../../assets/images/Forecast';
import React, { useEffect, useReducer } from 'react';
import { useTheme } from '@mui/styles';
import { range } from 'lodash';
import { styled } from '@mui/system';
import SvgRocket from '../../../assets/images/Rocket';

const TeamChange = styled('span')(({ theme }) => ({
  textAlign: 'right',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: '#d147c3',
  },
}));

const getTeamsOptions = (teams) => {
  return range(teams.length - 5, teams.length + 6, 1).map((nbTeams) => ({
    label: (
      <div key={nbTeams} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{`${nbTeams} Teams `} &nbsp;&nbsp;</span>
        <TeamChange>
          {nbTeams < teams.length
            ? ` - ${Math.abs(nbTeams - teams.length)}`
            : nbTeams === teams.length
            ? ' Current '
            : ` + ${Math.abs(nbTeams - teams.length)}`}
        </TeamChange>
      </div>
    ),
    value: nbTeams,
  }));
};

const ForecastToolbar = ({ teams, backlog, stages, runProjection }) => {
  const theme = useTheme();
  const [forecastFilter, updateForecastFilter] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { nbOfTeams: teams.length, velocityBasedOn: [], nbOfPIs: 0 },
  );

  const resetForecast = () => {
    updateForecastFilter({ nbOfTeams: teams.length, velocityBasedOn: [], nbOfPIs: 0 });
    runProjection({ nbOfTeams: teams.length, velocityBasedOn: [], nbOfPIs: 0 });
  };

  useEffect(() => {
    if (forecastFilter.nbOfTeams !== teams.length || forecastFilter.velocityBasedOn.length || forecastFilter.nbOfPIs) {
      updateForecastFilter({ nbOfTeams: teams.length, velocityBasedOn: [], nbOfPIs: 0 });
      runProjection({ nbOfTeams: teams.length, velocityBasedOn: [], nbOfPIs: 0 });
    }
  }, [teams, backlog, stages]);

  return (
    <Grid
      p={8}
      style={{
        minWidth: '800px',
        width: 'fit-content',
        maxWidth: '1200px',
        height: '55px',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.color.paper : '#272727',
        borderRadius: '100px',
        marginBottom: '18px',
        marginTop: '6px',
      }}>
      <Grid.Col
        span="content"
        display={'flex'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: `2px solid ${theme.palette.color.background}`,
        }}>
        <Text size="sm" ml={6} mr={10}>
          Adjust Projection
        </Text>
        <Tooltip
          color="dark"
          withinPortal
          zIndex={10000}
          label="Visualise Roadmap changes when adding or removing teams">
          <InfoIcon />
        </Tooltip>
      </Grid.Col>
      <Grid.Col
        span="auto"
        miw={'450px'}
        display={'flex'}
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          borderRight: `2px solid ${theme.palette.color.background}`,
        }}>
        <Select
          title="Teams"
          placeholder="All"
          border={false}
          rootStyle={{ width: '50%' }}
          defaultValue={teams.length}
          onChange={(event, value) => updateForecastFilter({ nbOfTeams: value })}
          options={getTeamsOptions(teams)}
          value={forecastFilter.nbOfTeams}
        />
        <Slider
          style={{ width: '50%', marginLeft: '8px' }}
          labelAlwaysOn
          value={forecastFilter.nbOfTeams}
          onChange={(value) => updateForecastFilter({ nbOfTeams: value })}
          min={teams.length - 5}
          max={teams.length + 5}
        />
      </Grid.Col>
      <Grid.Col
        span="content"
        display={'flex'}
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          borderRight: `2px solid ${theme.palette.color.background}`,
        }}>
        <Select
          title="Base Velocity on Team"
          placeholder="All"
          border={false}
          multiple={true}
          onChange={(event, value) => updateForecastFilter({ velocityBasedOn: value })}
          options={[{ label: 'All', value: 0 }, ...teams.map((team) => ({ label: team.name, value: team.id }))]}
        />
      </Grid.Col>
      <Grid.Col span="content" display={'flex'} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Tooltip label="Reset">
          <ActionIcon onClick={resetForecast} variant="subtle">
            <SvgForecast />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Run">
          <ActionIcon onClick={() => runProjection(forecastFilter)} variant="subtle">
            <SvgRocket color={theme.palette.color.buttonIcon} />
          </ActionIcon>
        </Tooltip>
      </Grid.Col>
    </Grid>
  );
};

export default ForecastToolbar;
