import * as React from 'react';

function Calendar(props) {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.2783 1.06667H10.2833V0.666667C10.2833 0.266667 10.0179 0 9.61987 0C9.22187 0 8.95654 0.266667 8.95654 0.666667V1.06667H6.43581C6.03781 1.06667 5.77247 1.33333 5.77247 1.73333C5.77247 2.13333 6.03781 2.4 6.43581 2.4H9.02287V2.8C9.02287 3.2 9.28821 3.46667 9.68627 3.46667C10.0843 3.46667 10.3496 3.2 10.3496 2.8V2.4H11.3446C11.8089 2.4 12.2733 2.8 12.2733 3.33333V4.26667H1.65971V3.33333C1.65971 2.8 2.12405 2.4 2.65473 2.4H3.64975V2.8C3.64975 3.2 3.91509 3.46667 4.31311 3.46667C4.71111 3.46667 4.97645 3.2 4.97645 2.8V1.73333V0.666667C4.97645 0.266667 4.71111 0 4.31311 0C3.91509 0 3.64975 0.266667 3.64975 0.666667V1.06667H2.65473C1.32803 1.06667 0.333008 2.06667 0.333008 3.33333V9.73333C0.333008 11 1.32803 12 2.65473 12H11.3446C12.605 12 13.6663 11 13.6663 9.73333V3.33333C13.6 2.06667 12.605 1.06667 11.2783 1.06667ZM11.2783 10.6667H2.65473C2.12405 10.6667 1.65971 10.2667 1.65971 9.73333V5.6H12.2733V9.73333C12.2733 10.2667 11.8089 10.6667 11.2783 10.6667Z"
        fill="#808080"
      />
    </svg>
  );
}

export default Calendar;
