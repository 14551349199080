import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const SectionMenu = (props) => {
  const { value, tabs, handleChange } = props;
  const classes = useStyles(props);

  return (
    <Tabs
      indicatorColor="primary"
      textColor="inherit"
      value={value}
      onChange={handleChange}
      aria-label="icon label tabs">
      {tabs.map((item) => (
        <Tab key={item.index} label={item.name} classes={{ root: classes.tab }} />
      ))}
    </Tabs>
  );
};

SectionMenu.propTypes = {
  tabs: PropTypes.array,
};

export default SectionMenu;
