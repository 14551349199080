import { gql } from '@apollo/client';

export const VALUE_STREAM_FIELDS = gql`
  fragment ValueStreamFields on value_streams {
    id
    name
    isActive
    description
    programmes {
      id
      name
    }
    steps: value_stream_steps {
      id
    }
    createdAt
    updatedAt
  }
`;

export const INSERT_VALUE_STREAM = gql`
  ${VALUE_STREAM_FIELDS}
  mutation InsertValueStream($valueStream: value_streams_insert_input! = {}) {
    valueStream: insert_value_streams_one(object: $valueStream) {
      ...ValueStreamFields
    }
  }
`;

export const UPDATE_VALUE_STREAM = gql`
  ${VALUE_STREAM_FIELDS}
  mutation UpdateValueStream($valueStreamId: Int!, $valueStream: value_streams_set_input = {}) {
    update_value_streams_by_pk(pk_columns: { id: $valueStreamId }, _set: $valueStream) {
      ...ValueStreamFields
    }
  }
`;

export const DELETE_VALUE_STREAM = gql`
  ${VALUE_STREAM_FIELDS}
  mutation DeleteValueStream($id: Int!) {
    valueStream: delete_value_streams_by_pk(id: $id) {
      ...ValueStreamFields
    }
  }
`;

export const GET_VALUE_STREAMS = gql`
  ${VALUE_STREAM_FIELDS}
  query GetValueStreams {
    valueStreams: value_streams(order_by: { id: asc }) {
      ...ValueStreamFields
    }
  }
`;
