import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import KanbanItem from '../../ValueStream/Kanban/kanbanItem';
import KanbanColumn from '../../ValueStream/Kanban/kanbanColumn';
import { canScroll } from '../../Delivery/utils';
import { UPDATE_INITIATIVE_MANY } from '../../ValueStream/Initiatives/graphql';
import { GET_INITIATIVE_STATE_CONFIG } from '../../Settings/GraphQL/configuration';
import { styles } from '../../ValueStream/Kanban/styles';
import { selectedProductThemeVar } from '../../../reactiveVariables';

const useStyles = makeStyles(styles);

export default function PortfolioKanban({
  refetchListOfInitiatives,
  initiatives,
  portfolioStatesConfig,
  initiativeStates,
  valueStreams,
}) {
  const filteredInitiatives = useMemo(() => {
    return initiatives.filter((initiative) => initiative.isPortfolioState === true);
  }, [initiatives]);

  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const classes = useStyles();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 0 },
  });
  const sensors = useSensors(mouseSensor);

  const [activeId, setActiveId] = useState(null);
  const [columns, setColumns] = useState([]);

  const valueStreamInitiativeStates = useMemo(() => {
    return portfolioStatesConfig
      .filter((state) => state?.value_stream === filteredInitiatives[0]?.valueStream)
      .filter((state) => state.inUse === true);
  }, [portfolioStatesConfig, filteredInitiatives]);

  const groupByPortfolioState = useCallback(
    (filteredInitiatives) => {
      const columns = portfolioStatesConfig.map((state) => ({
        title: state.name,
        value: state.name,
        data: [],
        rank: state.rank,
      }));

      const columnsMap = columns.reduce((acc, col) => {
        acc[col.value] = col;
        return acc;
      }, {});

      filteredInitiatives.forEach((initiative) => {
        const title = initiative.portfolio_state || 'Unmapped status';
        if (!columnsMap[title]) {
          columnsMap[title] = {
            title,
            value: title,
            data: [],
          };
          columns.push(columnsMap[title]);
        }
        columnsMap[title].data.push(initiative);
      });

      return columns;
    },
    [portfolioStatesConfig],
  );

  useEffect(() => {
    const filteredColumns = groupByPortfolioState(filteredInitiatives, valueStreamInitiativeStates);

    setColumns(filteredColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductTheme, valueStreamInitiativeStates]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;
    const activeFromColumn = active.data.current.fromColumn;
    const fromColumnIndex = columns.findIndex((column) => column.value === activeFromColumn);
    const overColumn = over.data.current.column;

    if (overColumn && activeFromColumn !== overColumn.value) {
      handleMoveToDifferentColumn(overColumn, fromColumnIndex, activeId, overId);
    } else {
      return;
    }
  };

  const handleMoveToDifferentColumn = (overColumn, fromColumnIndex, activeId, overId) => {
    const toColumnIndex = columns.findIndex((column) => column.value === overColumn.value);
    const fromColumn = columns[fromColumnIndex];
    const toColumn = columns[toColumnIndex];
    const movingItem = fromColumn.data.find((feature) => feature.id === activeId);

    const updatedFromColumn = {
      ...fromColumn,
      data: fromColumn.data.filter((feature) => feature.id !== activeId),
    };

    const updatedToColumn = {
      ...toColumn,
      data: [...toColumn.data, movingItem],
    };

    const newItems = [...columns];
    newItems[fromColumnIndex] = updatedFromColumn;
    newItems[toColumnIndex] = updatedToColumn;

    const updates = [
      {
        where: { id: { _eq: activeId } },
        _set: { portfolio_state: overId },
      },
    ];

    updateInitiativeMany({
      variables: { updates },
    }).then(() => {
      refetchListOfInitiatives();
    });

    setColumns(newItems);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active?.data?.current);
  };

  const renderDragOverlay = (data) => (
    <KanbanItem
      isOverlay={true}
      key="overlay"
      column={data.column}
      feature={data.feature}
      initiativeLength={initiatives.length}
      showRank={false}
    />
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          measuring={measuringConfig}
          autoScroll={{ canScroll }}
          collisionDetection={closestCenter}>
          <div
            style={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'nowrap',
            }}>
            <Grid
              key="container"
              container
              className={classes.flexSection}
              spacing={0}
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
              {columns.map((column) => (
                <KanbanColumn
                  classes={classes}
                  key={column.value}
                  title={column.title}
                  column={column}
                  // onSortChange={(columnName, selectedOption) => sortByOption(columnName, selectedOption)}
                  initiativeLength={initiatives.length}
                  showRank={false}
                  allowingSorting={false}
                  valueStreams={valueStreams}
                />
              ))}
            </Grid>
          </div>
          <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}
