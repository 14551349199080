import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers {
    users @rest(type: "User", path: "/api/users") {
      id
      firstName
      lastName
      username
      email
      role
    }
  }
`;
export const GET_ROLES = gql`
  query GetRoles {
    roles @rest(type: "Role", path: "/api/users/roles") {
      id
      name
      description
    }
  }
`;

export const USER_INPUT = gql`
  fragment UserInput on REST {
    name: String
    email: String
    password: String
    username: String
    roleId: String
    roleName: String
    id: String
  }
`;

export const CREATE_USER = gql`
  ${USER_INPUT}
  mutation CreateUser($input: UserInput) {
    users(input: $input) @rest(method: "POST", type: "User", path: "/api/users/create") {
      id
      name
      email
      role
    }
  }
`;

export const UPDATE_USER = gql`
  ${USER_INPUT}
  mutation UpdateUser($input: UserInput) {
    users(input: $input) @rest(method: "POST", type: "User", path: "/api/users/update") {
      id
      name
      email
      role
      username
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    users(userId: $userId) @rest(method: "DELETE", type: "User", path: "/api/users/delete/{args.userId}") {
      id
    }
  }
`;
