export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '10%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    width: '40%',
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'programmes',
    field: 'programmes',
    label: 'Programmes',
    width: '20%',
  },
  {
    id: 'isActive',
    field: 'isActive',
    label: 'Status',
    width: '10%',
  },
];
