import * as React from 'react';

function SvgFeatures(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0.034668C2.39543 0.034668 1.5 0.930096 1.5 2.03467V16C1.5 17.1045 2.39543 18 3.5 18H14.5C15.6046 18 16.5 17.1045 16.5 16V8C16.5 7.44772 16.0523 7 15.5 7H9.5C9.22386 7 9 6.77614 9 6.5V1.03467C9 0.482383 8.55228 0.034668 8 0.034668H3.5ZM10 0.221307V5.69999C10 5.97613 10.2239 6.19999 10.5 6.19999H16.246C16.3662 6.19999 16.4471 6.07706 16.3997 5.96669L16.2931 5.71888C15.1511 3.06369 12.9421 1.01336 10.2093 0.0720667C10.1068 0.0367639 10 0.112906 10 0.221307Z"
      />
    </svg>
  );
}

export default SvgFeatures;
