import React from 'react';
import { Dialog, Group, Paper, Text } from '@mantine/core';
import Copy from '../../../assets/images/Copy';
import Tick from '../../../assets/images/Tick';
import Todo from '../../../assets/images/Todo';
import RightArrow from '../../../assets/images/RightArrow';
import Heart from '../../../assets/images/Heart';
import { countStatuses } from '../../ValueStream/utils';

export const DialogPopup = ({ initiative, index, setIsHovered, valueStreams = [] }) => {
  const statusCount = countStatuses(initiative);

  return (
    <Dialog
      opened={true}
      onClose={() => setIsHovered(false)}
      title="Detailed Information"
      style={{ width: '276px', border: '1px solid #EEE7EA', position: 'relative' }}
      radius={8}>
      <Text mb={10} pr={30}>
        {initiative.name}
      </Text>

      {initiative.isPortfolioState === true && valueStreams.length > 0 && (
        <div style={{ marginBottom: '15px' }}>
          <Text c="dimmed" size="12px" mb={10}>
            {initiative.portfolioValueStreams.length > 1 ? 'Value streams' : 'Value stream'}
          </Text>

          {initiative.isPortfolioState === true &&
            valueStreams.length > 0 &&
            initiative.portfolioValueStreams.map((valueStreamId) => {
              const valueStream = valueStreams.find((stream) => stream.id === valueStreamId.id);

              return (
                <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'} key={valueStream?.id}>
                  <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
                    <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                      <Group>
                        <Text size="sm">{valueStream.name}</Text>
                      </Group>
                    </Group>
                  </Group>
                </Paper>
              );
            })}

          {initiative.isPortfolioState === true &&
            initiative.portfolioValueStreams.length === 0 &&
            (() => {
              const valueStream = valueStreams.find((stream) => stream.id === initiative.valueStream);
              return valueStream ? (
                <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'} key={valueStream.id}>
                  <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
                    <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                      <Group>
                        <Text size="sm">{valueStream.name}</Text>
                      </Group>
                    </Group>
                  </Group>
                </Paper>
              ) : null;
            })()}
        </div>
      )}

      <Heart style={{ position: 'absolute', right: '15px', top: '15px' }} />
      <Text c="dimmed" size="12px" mb={10}>
        Features progression
      </Text>

      <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
        <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
          <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
            <Group>
              <Copy style={{ marginRight: '-10px' }} />

              <Text size="sm">Total</Text>
            </Group>
            <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
              {initiative?.features?.nodes?.length}
            </Text>
          </Group>
        </Group>
      </Paper>

      <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
        <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
          <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
            <Group>
              <Todo style={{ marginRight: '-11px' }} />

              <Text size="sm">To do</Text>
            </Group>
            <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
              {statusCount['To Do']}
            </Text>
          </Group>
        </Group>
      </Paper>

      <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
        <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
          <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
            <Group>
              <RightArrow style={{ marginRight: '-11px' }} />

              <Text size="sm">In progress</Text>
            </Group>
            <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
              {statusCount['In Progress']}
            </Text>
          </Group>
        </Group>
      </Paper>

      <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
        <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
          <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
            <Group>
              <Tick style={{ marginRight: '-7px' }} />

              <Text size="sm">Done</Text>
            </Group>
            <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
              {statusCount['Done']}
            </Text>
          </Group>
        </Group>
      </Paper>
    </Dialog>
  );
};
