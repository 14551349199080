import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce, keyBy } from 'lodash';
import keycloak from '../keycloak';
import { useMantineColorScheme } from '@mantine/core';
import { getRoadmapProjection } from '../containers/ValueStream/InitiativesDashboard/get-roadmap-projection';
import { useQuery } from '@apollo/client';
import { GET_PROGRAMMES } from '../containers/Settings/GraphQL/programmes';
import { GET_FEATURES } from '../containers/Product/Features/graphql';
import { GET_PROGRAM_INCREMENTS } from '../containers/Settings/ProgramIncrement/graphql';

export const useDebouncedCallback = (callback, delay, opts) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay, opts),
    [],
  );
};

export const useInfiniteScroll = (nbRows, hasMore, callback, mainRef) => {
  const [loading, setLoading] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);
  // hasMore should come from the place where you do the data fetching
  // for example, it could be a prop passed from the parent component
  // or come from some store

  const scrollListener = useCallback(() => {
    const bottom = mainRef.current.scrollHeight - mainRef.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }
    if (mainRef.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      callback();
    }
  }, [hasMore, callback, loading, distanceBottom]);

  useEffect(() => {
    const tableRef = mainRef.current;
    tableRef.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    const hasScroll = mainRef?.current?.scrollHeight > mainRef?.current?.clientHeight;
    if (!hasScroll && hasMore) {
      callback();
    }
  }, [nbRows, hasMore]);

  return [loading, setLoading, distanceBottom, hasMore];
};

export const useColorScheme = () => {
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';
  const isLight = colorScheme === 'light';

  return { isDark, isLight };
};

export const useIsFirstRender = () => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
};

export const useProjectedFeatures = () => {
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);

  const [projectedFeatures, setProjectedFeatures] = useState({});

  useEffect(() => {
    if (features.length && programmes.length && programIncrements.length) {
      setProjectedFeatures(
        keyBy(
          programmes
            .map((programme) =>
              getRoadmapProjection(
                features.filter((feature) => feature.programmeId === programme.id),
                programIncrements.filter((pi) => pi.programme === programme.id),
              ),
            )
            .flat(),
          'id',
        ),
      );
    }
  }, [programmes, features]);

  return projectedFeatures;
};
