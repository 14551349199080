import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PISelector from './components/PISelector';
import SectionMenu from './components/SectionMenu';

import PropTypes, { object } from 'prop-types';
import { styles } from './styles';
import Select from '../Select';

const useStyles = makeStyles(styles);

function SectionHeader(props) {
  const classes = useStyles(props);
  const {
    value,
    selectedTeam,
    teams,
    title,
    programIncrements,
    selectedPi,
    tabs,
    handleChangePi,
    handleChange,
    handleChangeTeam,
    isVisiblePi = true,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>{title}</span>
        </div>
        {value === 0 && teams && (
          <Select
            sx={{ marginTop: '5px' }}
            toggleStyle={{ borderRadius: '22px' }}
            rootStyle={{ padding: 0, marginLeft: '15px' }}
            title="Team"
            defaultValue={selectedTeam || 0}
            options={[{ label: 'All Teams', value: 0 }, ...teams.map((team) => ({ label: team.name, value: team.id }))]}
            onChange={(event, value) => handleChangeTeam(value)}
          />
        )}
      </div>
      <SectionMenu value={value} tabs={tabs} handleChange={handleChange} />
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  programIncrements: PropTypes.array,
  selectedPi: PropTypes.number,
  tabs: PropTypes.arrayOf(object),
  handleChangePi: PropTypes.func.isRequired,
  isVisiblePi: PropTypes.bool,
};

export default SectionHeader;
