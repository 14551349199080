import React from 'react';
import { Loader, MultiSelect } from '@mantine/core';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_TEAMS } from '../../../../Settings/GraphQL/teams';
import { BigAgileSelect } from '../../../../../components/Common/Select/CustomSelect';
import styled from '@emotion/styled';
import { GET_TAGS } from '../../../../Product/Features/graphql';
import { find } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { selectedProgrammeVar } from '../../../../../reactiveVariables';
import { MultiSelectCreatable } from './CreatableMultiSelect';

const Wrapper = styled('div')(({ theme, ...props }) => ({
  width: 'auto',
}));

const FirstStep = ({ onTeamSelect, onTagsChange }) => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const [getTags, { loading, data: { tags = [] } = {} }] = useLazyQuery(GET_TAGS);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const onChange = (event, value) => {
    const team = find(teams, { id: value });
    getTags({ variables: { connectorId: team.connectorId, projectId: team.projectId } });
    onTeamSelect(team);
  };

  return (
    <Wrapper>
      <BigAgileSelect
        options={teams.filter((team) => !!team.connectorId && team.programmes?.includes(selectedProgramme))}
        onChange={onChange}
        componentProps={{ root: { style: { width: '100%' } }, listbox: { style: { width: '100%' } } }}
      />
      <MultiSelectCreatable loading={loading} onChange={onTagsChange} options={tags.map((tag) => tag.tag)} />
    </Wrapper>
  );
};

export default FirstStep;
