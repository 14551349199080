import React, { useState } from 'react';
import { Button, Drawer, Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import MenuItems from './MenuItems';
import HeaderBar from 'components/HeaderBar/HeaderBar';
import { styles } from './styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import ToggleIcon from '../Common/ToggleIcon';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import keycloak from '../../keycloak';
import { globalLoadingVar } from '../../reactiveVariables';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = makeStyles(styles);

const Version = ({ classes }) => (
  <a
    href="https://docs.bigagile.io/en/articles/7958926-bigagile-release-notes"
    className={classes.version}
    target="_blank"
    rel="noreferrer">
    <Tooltip title="Click for release notes">
      <Button style={{ fontWeight: 600, fontSize: 13, paddingBottom: 0 }}>5.4.0</Button>
    </Tooltip>
  </a>
);

const OpenIconContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '20px',
}));

function Menu(props) {
  const { open, user, handleOpen } = props;
  const handleDrawerClick = () => handleOpen(!open);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery('(min-width: 960px)');

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    setAnchorEl(null);
    globalLoadingVar(true);

    keycloak
      .logout({ redirectUri: window.location })
      .then(() => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('idToken');
        globalLoadingVar(false);
      })
      .catch(() => globalLoadingVar(false));
  };

  return (
    <div className={classes.root}>
      <HeaderBar
        open={open}
        handleDrawerClick={handleDrawerClick}
        handleLogout={handleLogout}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      <Drawer
        variant={matches ? 'persistent' : ''}
        classes={{
          paper: classNames(open && classes.drawerPaper),
        }}
        sx={{ height: '100%' }}
        open={open}>
        <div className={classes.drawerInner}>
          <OpenIconContainer>
            <IconButton
              aria-label="open drawer"
              classes={{
                label: 'buttonLabel',
              }}
              color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}
              className={classNames(classes.menuButton, open)}
              size="large"
              onClick={handleDrawerClick}>
              <ToggleIcon
                offIcon={<MenuIcon sx={{ fontSize: 28 }} />}
                onIcon={<MenuOpenIcon sx={{ fontSize: 28 }} />}
                on={open}
              />
            </IconButton>
            <span className={classes.logoText}>BigAgile </span>
            <Version classes={classes} />
          </OpenIconContainer>
          <MenuItems open={open} {...user} />
        </div>
      </Drawer>
    </div>
  );
}

export default Menu;
