import React, { useState } from 'react';
import { Checkbox, Text } from '@mantine/core';
import styled from '@emotion/styled';
import { some } from 'lodash';
import Search from '../../../../../components/Common/BigAgileTable/TableToolBar/Search';
import { CardButton, DisplayID, IDWrapper } from '../../../../../components/Common/Elements';
import { useTheme } from '@emotion/react';
import SvgFeatures from '../../../../../assets/images/Features';
import { Chip } from '@mui/material';

const Wrapper = styled('div')(({ theme, ...props }) => ({
  width: 'auto',
}));

const SecondStep = ({ team, existingFeatures, addSelectedFeature, selectedFeatures = [], features }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const theme = useTheme();
  const onSearchChange = (event) => {
    setSearchQuery(event.target?.value);
  };

  return (
    <Wrapper>
      <Search handleChange={onSearchChange} fullWidth={true} />
      <div style={{ paddingTop: '10px', overflow: 'auto', height: '500px' }}>
        {features
          .filter((feature) => !searchQuery || feature.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
          .map((feature) => (
            <CardButton key={feature.id} onClick={() => addSelectedFeature(feature.id)}>
              <IDWrapper style={{ backgroundColor: theme.palette.color.row }}>
                <SvgFeatures color={theme.palette.color.buttonIcon} />
                <DisplayID span={true} size="xs">
                  {feature.id}
                </DisplayID>
              </IDWrapper>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  height: '100%',
                  alignItems: 'center',
                }}>
                <div style={{ padding: '10px' }}>
                  <Text lineClamp={1} fw={450} size="sm">
                    {feature.name}
                  </Text>
                </div>
                {some(existingFeatures, { epicId: feature.id }) ? (
                  <Chip
                    size="small"
                    sx={{
                      borderRadius: '4px',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      color: 'text.secondary',
                      marginRight: '10px',
                    }}
                    label="Already Exists"
                  />
                ) : (
                  <Checkbox
                    onClick={() => addSelectedFeature(feature.id)}
                    checked={selectedFeatures.includes(feature.id)}
                    sx={{ padding: '8px' }}
                  />
                )}
              </div>
            </CardButton>
          ))}
      </div>
    </Wrapper>
  );
};

export default SecondStep;
