import React from 'react';
import { Route } from 'react-router-dom';
import { TeamBoard } from './TeamBoard';

const getPlannerRouter = (user) => (
  <>
    <Route user={user} path="/planning/team" element={<TeamBoard />} />
  </>
);

export default getPlannerRouter;
