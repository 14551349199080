import * as React from 'react';

function SvgInitiatives(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9 18C8.50889 18 8.09361 17.8274 7.75417 17.4823C7.5332 17.2576 7.38416 17.0002 7.30703 16.7102C7.23607 16.4433 7.46886 16.2154 7.745 16.2154H10.255C10.5311 16.2154 10.7639 16.4433 10.693 16.7102C10.6158 17.0002 10.4668 17.2576 10.2458 17.4823C9.90639 17.8274 9.49111 18 9 18ZM6.15095 14.8494C5.78592 14.8494 5.49 14.5535 5.49 14.1885V14.1885C5.49 13.8235 5.78592 13.5275 6.15095 13.5275H11.849C12.2141 13.5275 12.51 13.8235 12.51 14.1885V14.1885C12.51 14.5535 12.2141 14.8494 11.849 14.8494H6.15095ZM6.20073 12.1836C5.80785 12.1836 5.41915 12.0736 5.10921 11.8322C4.3832 11.2666 3.79152 10.5943 3.33417 9.81518C2.77806 8.86781 2.5 7.79927 2.5 6.60955C2.5 4.81763 3.14278 3.26805 4.42833 1.96083C5.71389 0.653611 7.23778 0 9 0C10.7622 0 12.2861 0.653611 13.5717 1.96083C14.8572 3.26805 15.5 4.81763 15.5 6.60955C15.5 7.79927 15.2256 8.86781 14.6767 9.81518C14.2232 10.5979 13.6267 11.2729 12.8872 11.8401C12.5792 12.0763 12.195 12.1836 11.8068 12.1836H6.20073Z" />
    </svg>
  );
}

export default SvgInitiatives;
