export const styles = (theme) => ({
  appBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 20,
    paddingLeft: 0,
    minHeight: 50,
    fontSize: 20,
  },
  appBarItems: {
    paddingRight: 24,
  },
  buttonText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeight.medium,
  },
  buttonsWrapper: {
    minWidth: 150,
  },
});
