import * as React from 'react';

function SvgButtonSide(props) {
  return (
    <svg width="21" height="33" viewBox="0 0 21 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group 3454">
        <path
          id="Vector 10"
          d="M8 1V7C8 12.5228 12.4772 17 18 17H20"
          fill="none"
          stroke="#E8E8E8"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgButtonSide;
