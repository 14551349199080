export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '7%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    width: '30%',
    filtering: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'valueStream',
    field: 'valueStream',
    label: 'Value Stream',
    width: '15%',
  },
  {
    id: 'teams',
    field: 'teams',
    label: 'Teams',
    width: '28%',
  },
  {
    id: 'portfolioTag',
    field: 'portfolioTag',
    label: 'Portfolio Tag',
    width: '20%',
  },
];
