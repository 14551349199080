import { Button, Card, CardContent, MenuItem } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { BUTTONS } from '../../../utils/formConstants';
import { useForm } from 'react-hook-form';

import { Field, FormContext, renderSelectField, renderTextField } from '../../Common/FormFieldsHooks';
import { drawerLoadingVar, resetDrawerDetails } from '../../../reactiveVariables';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_USER,
  DELETE_USER,
  GET_ROLES,
  GET_USERS,
  UPDATE_USER,
} from '../../../containers/Settings/GraphQL/users';
import { some } from 'lodash';
import config from 'Config';

const envConfig = config[window.location.hostname];

const UserDetails = (props, ref) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { roles = [] } = {} } = useQuery(GET_ROLES);

  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: props.element || {},
  });

  const useIdentityProvider = envConfig.identityProvider;

  const email = {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address',
    },
    validate: (value) =>
      !!user.id || !some(users, (existingUser) => existingUser.email === value) || 'User already exists',
  };

  useEffect(() => {
    if (props.element) {
      register('id', { value: props.element.id });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props?.element?.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteUser({ refetchQueries: [GET_USERS], variables: { userId: user.id } }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    drawerLoadingVar(true);
    const newRole = roles.find((role) => role.id === values.role);
    const options = {
      variables: { input: { ...values, roleId: newRole?.id, roleName: newRole?.name } },
      refetchQueries: [GET_USERS],
    };

    const saveUser = values.id ? updateUser(options) : createUser(options);

    saveUser
      .then(() => {
        drawerLoadingVar(false);
        resetDrawerDetails();
      })
      .catch(() => drawerLoadingVar(false));
  };

  const user = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field
              name="firstName"
              disabled={useIdentityProvider}
              renderField={renderTextField}
              label="First Name"
              required
              size={6}
            />
            <Field
              name="lastName"
              disabled={useIdentityProvider}
              renderField={renderTextField}
              label="Last Name"
              required
              size={6}
            />
            <Field
              name="username"
              disabled={useIdentityProvider || props.element}
              renderField={renderTextField}
              label="Username"
              required
              size={12}
            />
            <Field
              name="email"
              disabled={useIdentityProvider || props.element}
              renderField={renderTextField}
              label="Email"
              autoComplete="new-email"
              size={12}
              rules={email}
              required
            />
            <Field name="role" renderField={renderSelectField} label="Role" size={12} required>
              {Array.isArray(roles) &&
                roles.length &&
                roles?.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.description}
                  </MenuItem>
                ))}
            </Field>
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete User: ${user?.name}`}
        text="Are you sure you want to delete this user ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(UserDetails);
