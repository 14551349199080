import React from 'react';

import { Typography } from '@mui/material';

import { getFeatureBackgroundColor } from '../../../../utils/piColors';
import { getMappedValues } from '../../../../utils/tableUtils';

function createData(item, teams, programIncrements, theme) {
  const { id, name, teamId, sprintId = '', status, ragStatus, plannedValue, deliveredValue } = item;
  const teamNames = getMappedValues(teams)[teamId];

  const sprintValue =
    (
      Array.isArray(programIncrements) &&
      programIncrements.length &&
      programIncrements
        .filter((pi) => pi.id === item.programIncrement)
        .map((pi) => pi?.sprints.find((item) => item.id === sprintId))
        .find((item) => item?.name)
    )?.name || '';

  return {
    id,
    name,
    team: teamNames,
    sprint: sprintValue,
    status,
    plannedValue,
    deliveredValue,
    ragStatus: (
      <Typography data-value={ragStatus} color={getFeatureBackgroundColor({ ragStatus }, theme)}>
        {ragStatus}
      </Typography>
    ),
  };
}

export function getRowsData(data, teams, programIncrements, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, teams, programIncrements, theme));
  }
  return rows;
}
