import React, { useEffect } from 'react';
import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseCollapse from '../../../assets/images/CloseCollapse';
import OpenCollapse from '../../../assets/images/OpenCollapse';
import Select from '../../Common/Select';
import { useLazyQuery } from '@apollo/client';
import { GET_ITERATIONS_FOR_BOARD, GET_SPRINTS_FOR_BOARD } from '../../../containers/Settings/ProgramIncrement/graphql';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { hasJiraBE } from '../../../utils/helpers';
import { BodyTableCell, InnerTableCell } from '../../../containers/ValueStream/components/OKRTable';

const Sprint = ({ sprint, jiraSprints, team, pi, loading, setValue, index }) => {
  const name = sprint.name.includes('Sprint') ? sprint.name : `Sprint - ${sprint.name}`;

  return (
    <Paper elevation={0} sx={{ display: 'flex', backgroundColor: 'color.innerRow', margin: '10px', height: '40px' }}>
      <Grid container alignItems={'center'} sx={{ paddingLeft: '12px', paddingRight: '12px' }}>
        <Grid item xs={6}>
          <Typography align="left" color="textSecondary">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right" sx={{ paddingLeft: '20px' }}>
          {!loading && (
            <Select
              onChange={(event, value) => setValue(`sprints[${index}].jiraIds.${team.id}`, value)}
              defaultValue={sprint.jiraIds?.[team.id]}
              placeholder="Select a sprint"
              border={false}
              options={jiraSprints.map((jiraSprint) => ({
                value: jiraSprint.path || jiraSprint.id,
                label: jiraSprint.name,
              }))}
              align="right"
              title={`${hasJiraBE() ? 'Jira' : 'Azure Devops'} Sprint`}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const Row = ({ team, pi, sprints, setValue }) => {
  const [open, setOpen] = React.useState(false);
  const [getSprints, { loading, data: { jiraSprints = [] } = {} }] = useLazyQuery(
    team.connector?.type === 'azureDevops' ? GET_ITERATIONS_FOR_BOARD : GET_SPRINTS_FOR_BOARD,
    {
      variables: { projectId: team.projectId, connectorId: team.connectorId, boardId: team.board },
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      context: { clientName: 'rest' },
    },
  );

  const sprintsMapped = sprints.filter((sprint) => sprint.jiraIds?.[team.id]).length;

  return (
    <React.Fragment>
      <TableRow key={team.id} sx={{ backgroundColor: 'color.row', '& > *': { borderBottom: 'unset' } }}>
        <BodyTableCell sx={{ paddingLeft: !team.board && '30px' }}>
          {team.board && (
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ borderRadius: '3px', marginRight: '8px' }}
              onClick={() => {
                if (!jiraSprints.length) getSprints();
                setOpen(!open);
              }}>
              {open ? <CloseCollapse /> : <OpenCollapse />}
            </IconButton>
          )}
          {team.id}
        </BodyTableCell>
        <BodyTableCell>{team.name}</BodyTableCell>
        <BodyTableCell>
          {sprintsMapped} / {sprints.length}
        </BodyTableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: 'color.row', width: '100%' }}>
        <TableCell align="center" style={{ padding: 0, backgroundColor: 'color.row', width: '100%' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ position: 'relative', zIndex: 0 }}>
            {sprints.map((sprint, index) => (
              <Sprint
                key={sprint.name}
                jiraSprints={jiraSprints}
                loading={loading}
                sprint={sprint}
                team={team}
                setValue={setValue}
                index={index}
              />
            ))}
            <Backdrop sx={{ position: 'absolute', color: '#fff' }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function TeamSelector({ teams, sprints, pi, setValue }) {
  return (
    <Table sx={{ tableLayout: 'fixed', backgroundColor: 'color.table' }}>
      <TableHead>
        <TableRow sx={{ backgroundColor: 'color.table' }}>
          <InnerTableCell sx={{ backgroundColor: 'color.table', width: '15%' }}>ID</InnerTableCell>
          <InnerTableCell sx={{ backgroundColor: 'color.table', width: '60%' }}>Name</InnerTableCell>
          <InnerTableCell sx={{ backgroundColor: 'color.table', width: '30%' }}>Sprints Mapped</InnerTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {teams.map((team) => (
          <Row team={team} sprints={sprints} pi={pi} key={team.id} setValue={setValue} />
        ))}
      </TableBody>
    </Table>
  );
}
