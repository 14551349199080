import * as React from 'react';
import { styled } from '@mui/system';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Checkbox, Grow } from '@mui/material';
import { find } from 'lodash';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { SelectProvider, useSelect } from '@mui/base/useSelect';
import { Dot } from '../../../containers/ValueStream/components/Common';
import { useOption } from '@mui/base';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import Close from '../../../assets/images/Close';
import CloseCollapse from '../../../assets/images/CloseCollapse';
import ClosePlanner from '../../../assets/images/ClosePlanner';
import { IconX } from '@tabler/icons-react';

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const Root = styled('div')(
  ({ theme, ...props }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  padding: 0.5em;
`,
);

const Toggle = styled('div')(
  ({ theme, ...props }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  background-color: ${props.backgroundColor};
  min-height: ${props.border ? ('calc(1.5em + ' + props.dense ? '22px)' : '30px)') : undefined};
  min-width: 100px;
  background: null;
  border: ${props.border ? `1px solid ${props.open ? '#d147c3' : '#d2d2dc'}` : 'none'};
  border-radius: 3px;
  padding: ${props.border ? '10px' : '0'};
  text-align: left;
  line-height: 1.5;
  color:  ${props.open ? '#d147c3' : theme.palette.text.primary} !important;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    color: #d147c3;
    border:  ${props.border ? '1px solid #d147c3' : 'none'};
  }

  & .placeholder {
    opacity: 0.8;
  }
  `,
);

const ListBox = styled('ul')(
  ({ theme, multiple, alignBox }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  padding-right: ${multiple ? '5px' : '10px'};
  margin: 0 0.5em 0.5em 0.5em;
  list-style: none;
  position: absolute;
  height: auto;
  max-height: 300px;
  transition: opacity 0.1s ease;
  margin-top: 5px;
  width: max-content;
  box-shadow: ${theme.palette.mode === 'dark' ? `0 5px 13px -3px rgba(0,0,0,0.4)` : `0 5px 13px -3px ${grey[200]}`};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  z-index: 100000;
  outline: 0px;
  ${alignBox === 'left' ? 'left:0;' : 'right:0;'}

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  & > li {
    padding: 8px;
    border-radius: 0.45em;

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    }
  }
  `,
);

const Option = ({ multiple, padding, option, metadata, style }) => {
  const { selected, getRootProps } = useOption({ ...option, disabled: false });

  return (
    <li {...getRootProps()} style={{ padding: multiple ? 0 : padding, fontSize: '13px', ...style }} key={option.value}>
      {multiple && <Checkbox checked={selected} />} {option.label}
    </li>
  );
};

export default function Select({
  border = true,
  options,
  placeholder,
  title,
  onChange,
  defaultValue,
  multiple = false,
  sx,
  backgroundColor,
  hasDot,
  spacing,
  dense = true,
  padding = null,
  optionsStyle,
  displayStyle,
  rootStyle,
  toggleStyle,
  addAllOption = false,
  value: controlledValue,
  align = 'left',
  showClearIcon = false,
}) {
  const data = addAllOption
    ? [
        { label: 'All', value: 0 },
        ...options.map((option) => ({ label: option.label || option.name, value: option.value || option.id })),
      ]
    : options.map((option) => ({ label: option.label || option.name, value: option.value || option.id }));

  const listboxRef = React.useRef(null);
  const [listboxVisible, setListboxVisible] = React.useState(false);

  const { getButtonProps, getListboxProps, getOptionMetadata, value, contextValue } = useSelect({
    multiple: multiple,
    defaultValue: multiple ? defaultValue || [] : defaultValue,
    onChange: onChange,
    listboxRef,
    options: data,
    value: controlledValue,
  });

  React.useEffect(() => {
    if (listboxVisible) {
      listboxRef.current?.focus();
    }
  }, [listboxVisible]);

  const getMultipleValue = () => {
    if (!value.length) return <span className="placeholder">{placeholder ?? ' '}</span>;
    if (value.length === data.length) return <span>All</span>;
    const firstOption = find(data, { value: value[0] }) || {};
    const display = value.length > 1 ? ` + ${value.length - 1}` : '';

    return <span>{firstOption.label + display}</span>;
  };

  const getSingleValue = () => {
    if (!value && value !== 0) return <span className="placeholder">{placeholder ?? ' '}</span>;
    const selectedOption = find(data, { value: value }) || {};

    return <span>{selectedOption.label || selectedOption.name}</span>;
  };

  return (
    <ClickAwayListener onClickAway={() => setListboxVisible(false)}>
      <Root sx={rootStyle}>
        <Toggle
          {...getButtonProps()}
          sx={{ fontSize: '14px', ...toggleStyle }}
          open={listboxVisible}
          border={border}
          dense={dense}
          onClick={() => setListboxVisible(!listboxVisible)}>
          {title && <span style={{ width: spacing || 'none', marginRight: '8px', color: '#93a0b5' }}>{title}: </span>}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: backgroundColor,
              padding: !dense && '5px 10px 5px 10px',
              backgroundColor: backgroundColor && alpha(backgroundColor, 0.15),
              borderRadius: '22px',
              ...displayStyle,
            }}>
            {hasDot && <Dot style={{ height: '14px', width: '14px', backgroundColor: backgroundColor }} />}
            {multiple ? getMultipleValue() : getSingleValue()}
            {showClearIcon && ((multiple && value.length) || (!multiple && value)) ? (
              <IconX
                size={18}
                style={{ marginLeft: '8px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onChange(event, []);
                }}
              />
            ) : listboxVisible ? (
              <ArrowDropUp />
            ) : (
              <ArrowDropDown />
            )}
          </div>
        </Toggle>
        <Grow in={listboxVisible} style={{ transformOrigin: '0 0 0' }}>
          <ListBox
            {...getListboxProps()}
            sx={sx}
            multiple={multiple}
            alignBox={align}
            className={listboxVisible ? '' : 'hidden'}
            onClick={() => !multiple && setListboxVisible(false)}>
            <SelectProvider value={contextValue}>
              {data.map((option) => (
                <Option
                  padding={padding}
                  multiple={multiple}
                  style={optionsStyle}
                  metadata={getOptionMetadata(option)}
                  option={option}
                />
              ))}
            </SelectProvider>
          </ListBox>
        </Grow>
      </Root>
    </ClickAwayListener>
  );
}
