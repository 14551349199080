import {
  ActionIcon,
  Avatar,
  Button,
  Collapse,
  Divider,
  Group,
  Menu,
  Paper,
  rem,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import SvgAdd from '../../../../../assets/images/Add';
import React, { useState } from 'react';
import SvgChevronRight from '../../../../../assets/images/ChevronRight';
import { Display2 } from '../../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import LinearWithValueLabel from '../../../../ValueStream/components/OKRTable/LinearWithValueLabel';
import classes from './Dashboard.module.css';
import { date } from '../../../../../utils/helpers';
import { DATE_FORMAT } from '../../../../../utils/timeUtils';
import SvgHexagone from '../../../../../assets/images/Hexagon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SvgButtonSide from '../../../../../assets/images/ButtonSide';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from '../../../../Settings/GraphQL/users';
import CreateUpdateModal from '../../CreateUpdateModal';
import { orderBy } from 'lodash';
import { calculateOutcome, getProgressColor, getRagColorForKeyResult, getUserName } from '../../utils';
import CreateKeyResultModal from '../../CreateKeyResultModal';
import SvgMoreAction from '../../../../../assets/images/MoreAction';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  DELETE_PORTFOLIO_KEY_RESULT,
  DELETE_PORTFOLIO_OBJECTIVE,
  GET_PORTFOLIO_OBJECTIVES,
  UPDATE_PORTFOLIO_KEY_RESULT,
} from '../../graphql';
import ActionMenu from '../../Components/ActionMenu';

const Update = ({ keyResult, update, users, openUpdate }) => {
  const theme = useMantineTheme();
  const owner = users.find((user) => user.id === update.owner) || {};
  const [updateKeyResult] = useMutation(UPDATE_PORTFOLIO_KEY_RESULT, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const deleteUpdate = () => {
    const updates = [...keyResult.updates.filter((u) => u !== update)];
    updateKeyResult({
      variables: { keyResult: { updates }, keyResultId: keyResult.id },
    });
  };

  return (
    <Paper withBorder p={8} radius={8} mt={12}>
      <Group justify="space-between">
        <Group gap={0}>
          <Tooltip label={getUserName(owner)}>
            <Avatar variant="filled" size="sm" mr={12} color={theme.other.colours.purple} className={classes.avatar}>
              {owner.firstName?.charAt(0)}
            </Avatar>
          </Tooltip>
          <Text fw={500} mr={12}>
            {['£', '$'].includes(keyResult.unit) ? update.unit + update.value : `${update.value}  ${update.unit}`}
          </Text>
          <Text>{update.description}</Text>
        </Group>
        <Group gap={0}>
          <Text mr={12} c="dimmed">
            {date(update.date).toFormat(DATE_FORMAT.date)}
          </Text>
          <Menu>
            <Menu.Target>
              <ActionIcon variant="transparent" radius={8} color={theme.other.colours.secondary} aria-label="Settings">
                <SvgMoreAction color={theme.other.colours.paper} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown zIndez={10000} style={{ zIndex: 100000 }}>
              <Menu.Item
                onClick={() => deleteUpdate()}
                leftSection={<DeleteIcon style={{ width: rem(14), height: rem(14) }} />}>
                Remove
              </Menu.Item>
              <Menu.Item
                onClick={() => openUpdate(update)}
                leftSection={<EditIcon style={{ width: rem(14), height: rem(14) }} />}>
                Edit
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </Paper>
  );
};

export const KeyResult = ({ vsColor, keyResult, selectKeyResult, openKr, users }) => {
  const theme = useMantineTheme();
  const [expanded, setExpanded] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(null);

  const [deleteKeyResult] = useMutation(DELETE_PORTFOLIO_KEY_RESULT, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const owner = users.find((user) => user.id === keyResult.owner) || {};
  const outcome = calculateOutcome(keyResult);

  const updates = orderBy(keyResult.updates || [], 'date', 'desc').map((update) => (
    <Update keyResult={keyResult} update={update} openUpdate={setOpenUpdate} users={users} />
  ));

  return (
    <Paper withBorder p={12} radius={8} mt={12}>
      <Group justify="space-between" mb={12}>
        <Group style={{ cursor: 'pointer' }} onClick={() => selectKeyResult(keyResult)}>
          <Title order={5}>{keyResult.name}</Title>
          <ActionIcon
            variant={'transparent'}
            size="xs"
            color={theme.other.colours.paper}
            onClick={() => {
              selectKeyResult(keyResult);
            }}
            ml={4}>
            <SvgChevronRight />
          </ActionIcon>
        </Group>
        <Group>
          {/*<Display2 bgColor={theme.other.colours.purple}>Likely</Display2>*/}
          <Display2 bgColor={getRagColorForKeyResult(keyResult, theme)}>{outcome}%</Display2>
          <ActionMenu action={deleteKeyResult} element={keyResult} />
        </Group>
      </Group>
      <LinearWithValueLabel
        tooltip={`Deliverables ${keyResult.output.toFixed()}% complete`}
        hideLabel={true}
        value={keyResult.output}
        color={getProgressColor(keyResult.output, theme)}
      />
      <Group mt={12} gap={0}>
        <Tooltip label={getUserName(owner)}>
          <Avatar variant="filled" size="sm" mr={12} color={theme.other.colours.purple} className={classes.avatar}>
            {owner.firstName?.charAt(0)}
          </Avatar>
        </Tooltip>
        <Text mr={12}>{date(keyResult.targetDate).toFormat(DATE_FORMAT.dateShort)}</Text>
        <ThemeIcon mr={4} variant="transparent" color={theme.other.colours.secondary} size="1rem">
          <SvgHexagone />
        </ThemeIcon>
        <Text size="xs" mr={12}>
          Features: {keyResult.features.length}
        </Text>
        <ThemeIcon mr={4} variant="transparent" color={theme.other.colours.secondary} size="1rem">
          <SvgHexagone />
        </ThemeIcon>
        <Text size="xs">Initiatives: {keyResult.initiatives.length}</Text>
      </Group>
      <Divider mt={12} mb={12} />
      <Group>
        <ActionIcon
          variant={'transparent'}
          color={theme.other.colours.secondary}
          size="xs"
          onClick={() => setExpanded(!expanded)}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ActionIcon>
        <Text>Updates: {keyResult.updates?.length || 0}</Text>
      </Group>
      {updates.shift()}
      <Collapse in={expanded}>{updates}</Collapse>
      <Group mt={12} gap={0}>
        <SvgButtonSide />
        <Button
          bg={theme.other.colours.paper}
          onClick={() => setOpenUpdate({})}
          leftSection={<SvgAdd stroke={theme.other.colours.secondary} />}
          variant="default"
          size="sm">
          Update progress
        </Button>
      </Group>
      {!!openUpdate && (
        <CreateUpdateModal
          vsColor={vsColor}
          update={openUpdate}
          open={!!openUpdate}
          close={() => setOpenUpdate(null)}
          keyResult={keyResult}
        />
      )}
    </Paper>
  );
};

const KeyResults = ({ vsColor, objective, keyResults, openKr, selectKeyResult }) => {
  const theme = useMantineTheme();
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const [openCreate, setOpenCreate] = useState(false);

  return (
    <>
      <Group>
        <Title order={5}>Key Results</Title>
        <ActionIcon
          onClick={() => setOpenCreate(true)}
          variant="outline"
          style={{ backgroundColor: theme.other.colours.paper }}
          color={theme.other.colours.tileBorder}>
          <SvgAdd stroke={theme.other.colours.primary} />
        </ActionIcon>
      </Group>
      {keyResults?.map((keyResult, index) => (
        <KeyResult
          vsColor={vsColor}
          users={users}
          keyResult={keyResult}
          openKr={openKr}
          selectKeyResult={selectKeyResult}
        />
      ))}
      {openCreate && (
        <CreateKeyResultModal open={openCreate} close={() => setOpenCreate(false)} objective={objective} />
      )}
    </>
  );
};

export default KeyResults;
