import { Button, Card, CardContent, MenuItem } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Field, FormContext, renderDatePicker, renderSelectField, renderTextField } from '../../Common/FormFieldsHooks';
import { BUTTONS } from '../../../utils/formConstants';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_PROGRAM_INCREMENTS_FOR_PROGRAMME,
  GET_SELECTED_PI,
} from '../../../containers/Settings/ProgramIncrement/graphql';
import {
  DELETE_MILESTONE,
  INSERT_MILESTONE,
  UPDATE_MILESTONE,
} from '../../../containers/ValueStream/Milestones/graphql';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField } from '../../../utils/helpers';
import { resetDrawerDetails, selectedValueStreamVar } from '../../../reactiveVariables';

const MilestoneDetails = (props, ref) => {
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);

  const [insertMilestone] = useMutation(INSERT_MILESTONE);
  const [updateMilestone] = useMutation(UPDATE_MILESTONE);
  const [deleteMilestone] = useMutation(DELETE_MILESTONE);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      programIncrement: selectedPI,
      ...props.element,
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id', { value: props.element.id });
    }
  }, [props.element]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteMilestone({
      variables: { id: milestone.id },
      update: deleteUpdate('milestone'),
    });
    resetDrawerDetails();
  };

  const onSubmit = (values) => {
    const milestoneToSave = removeIdField(values);

    values.id
      ? updateMilestone({
          variables: { milestone: milestoneToSave, milestoneId: values.id },
        })
      : insertMilestone({
          variables: { milestone: { ...milestoneToSave, valueStream: selectedValueStream } },
          update: insertUpdate('milestone'),
        });

    resetDrawerDetails();
  };

  const milestone = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field required name="date" renderField={renderDatePicker} label="Milestone Date" size={12} />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete milestone: ${milestone.name}`}
        text="Are you sure you want to delete this milestone ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(MilestoneDetails);
