import { ActionIcon, Divider, Grid, Group, Paper, ScrollArea, Text, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import CloseCollapse from '../../../../assets/images/CloseCollapse';
import OpenCollapse from '../../../../assets/images/OpenCollapse';
import ObjectiveList from './ObjectiveList';
import ObjectiveDetails from './ObjectiveDetails';
import { useLocation } from 'react-router';

const ObjectiveView = ({ objectives, valueStreamId, vsColor }) => {
  const theme = useMantineTheme();
  const location = useLocation();

  const [openObjective, setOpenObjective] = useState(objectives[location.state?.objectiveIndex || 0] || {});
  const objectiveIndex = objectives.findIndex((objective) => objective.id === openObjective.id);

  useEffect(() => {
    if (!openObjective?.id) {
      setOpenObjective(objectives[0] || {});
    } else {
      const objectiveIndex = objectives.findIndex((objective) => objective.id === openObjective.id);
      setOpenObjective(objectives[objectiveIndex] || {});
    }
  }, [objectives]);

  return (
    <Grid gutter={0}>
      <Grid.Col span={3} mt={24}>
        <ObjectiveList
          objectives={objectives}
          vsColor={vsColor}
          onObjectiveClick={setOpenObjective}
          openObjective={openObjective}
          valueStreamId={valueStreamId}
        />
      </Grid.Col>
      <Grid.Col span={9} mt={24}>
        {!!objectives.length && (
          <ScrollArea style={{ height: 'calc(100vh - 204px)' }} pb={0} type="scroll">
            <Paper
              withBorder
              p="0"
              ml={32}
              h="calc(100% - 12px)"
              mb={8}
              pb={8}
              style={{
                borderRadius: '12px',
                backgroundColor: theme.other.colours.background,
                boxShadow: '0px 2px 2px 0px #0000000D',
              }}
              radius={8}>
              <Group
                gap={8}
                p="8px 16px 12px 16px"
                style={{ borderRadius: '12px 12px 0 0', backgroundColor: theme.other.colours.paper }}>
                <ActionIcon
                  disabled={objectiveIndex === 0}
                  variant={'default'}
                  size={24}
                  radius={8}
                  ml={4}
                  onClick={() => setOpenObjective(objectives[objectiveIndex - 1])}>
                  <CloseCollapse />
                </ActionIcon>
                <ActionIcon
                  disabled={objectiveIndex === objectives.length - 1}
                  variant={'default'}
                  size={24}
                  radius={8}
                  ml={4}
                  onClick={() => setOpenObjective(objectives[objectiveIndex + 1])}>
                  <OpenCollapse />
                </ActionIcon>
                <Text c="dimmed">
                  {objectiveIndex + 1} of {objectives.length} objectives
                </Text>
              </Group>
              <Divider />
              <Group gap={16} h="calc(100% - 57px)">
                <ObjectiveDetails objective={openObjective} vsColor={vsColor} />
              </Group>
            </Paper>
          </ScrollArea>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default ObjectiveView;
