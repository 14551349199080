import { ActionIcon, Button, Card, Flex, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import React from 'react';
import SvgShowDependencies from '../../../../assets/images/ShowDependencies';
import { useTheme } from '@emotion/react';
import { Dot } from '../../../ValueStream/components/Common';
import SvgLinkArrow from '../../../../assets/images/LinkArrow';
import Tag from '../../../../components/Common/Tag';
import SvgWarning from '../../../../assets/images/Warning';

const TeamCard = ({
  team,
  featureCount,
  outboundDeps,
  inboundDeps,
  showTeamDependencies,
  piToRedirect,
  changeShowTeamDependencies,
  external,
  programmes,
  onProgramRedirect,
  increment,
}) => {
  const theme = useTheme();
  const buttonProps = showTeamDependencies === team.id ? { color: theme.palette.color.buttonIcon } : {};
  const mappedSprints = increment?.sprints?.filter((sprint) => !!sprint.jiraIds?.[team.id]);
  const hasMissingSprints =
    team.board && team.type !== 'kanban' && mappedSprints?.length !== increment?.sprints?.length;

  return (
    <Card p="lg" style={{ backgroundColor: theme.palette.color.background }}>
      <Card.Section>
        <Group justify="space-between" wrap="nowrap" gap={0} style={{ padding: '0 !important' }}>
          <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={true} size="md" fw={700}>
            {team.name}
          </Text>
          <Group justify="flex-end" wrap="nowrap" gap={0} style={{ padding: '0 !important' }}>
            {hasMissingSprints && (
              <Tooltip
                zIndex={3000}
                label={`Not all sprints mapped for this team ${mappedSprints?.length}/${increment?.sprints?.length}`}
                withinPortal
                transition="scale-y">
                <ThemeIcon variant="transparent" color={theme.palette.color.red}>
                  <SvgWarning />
                </ThemeIcon>
              </Tooltip>
            )}
            <Tooltip label="Show Team Dependencies" withinPortal transition="scale-y">
              <ActionIcon
                variant="transparent"
                color="default"
                onClick={() => changeShowTeamDependencies(showTeamDependencies === team.id ? null : team.id)}>
                <SvgShowDependencies {...buttonProps} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
      </Card.Section>
      <Card.Section>
        {external ? (
          programmes.map((programme) => (
            <Flex justify={'space-between'} wrap={'nowrap'} align={'center'}>
              <Tooltip label={programme.name} withinPortal zIndex={10000} transitionProps={{ transition: 'scale' }}>
                <Button
                  onClick={() => onProgramRedirect(programme, piToRedirect)}
                  key={programme.name}
                  size="compact-md"
                  color={theme.palette.primary.main}
                  rightSection={<SvgLinkArrow color={'white'} />}>
                  {programme.name}
                </Button>
              </Tooltip>
            </Flex>
          ))
        ) : (
          <Group justify="space-between" noWrap={true}>
            <Group style={{ gap: '8px', padding: '0' }}>
              <Tooltip
                label={`${featureCount} Features`}
                withinPortal
                zIndex={10000}
                transitionProps={{ transition: 'scale' }}>
                <Text span={true} size="xs" fw={700}>
                  F: {featureCount}
                </Text>
              </Tooltip>
              <Dot sx={{ height: '4px', width: '4px', margin: 0 }} />
              <Tooltip
                label={
                  <>
                    {outboundDeps !== 0 && <Text size="sm">{`${outboundDeps} Outbound Dependencies`}</Text>}
                    {inboundDeps !== 0 && <Text size="sm">{`${inboundDeps} Inbound Dependencies`}</Text>}
                  </>
                }
                withinPortal
                zIndex={10000}
                transition="scale-y">
                <Text span={true} size="xs" fw={700}>
                  D: {outboundDeps + inboundDeps}
                </Text>
              </Tooltip>
            </Group>
            {team.board && (
              <Tag
                color={team.type === 'kanban' ? theme.palette.color.done : theme.palette.color.buttonIcon}
                label={team.type === 'kanban' ? 'Kanban' : 'Scrum'}
              />
            )}
          </Group>
        )}
      </Card.Section>
    </Card>
  );
};

export default TeamCard;
