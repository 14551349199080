import React from 'react';
import { BigAgileSelect } from '../Select/CustomSelect';
import { useTheme } from '@emotion/react';
import Select from '../Select';
import { STAGE_SELECTS, STAGE_VALUE } from '../../../utils/formConstants';

const TeamSelector = ({ options, value, handleChange }) => {
  const values = options.map((team) => ({ value: team.id, label: team.name }));
  const theme = useTheme();

  return (
    <Select
      title="Team"
      placeholder="Select a team..."
      value={value || []}
      onChange={(event, values) => handleChange(values)}
      rootStyle={{ marginLeft: '-6px', marginRight: '-22px' }}
      toggleStyle={{ borderRadius: '5px', backgroundColor: theme.palette.color.paper }}
      defaultValue={[0]}
      multiple={true}
      options={values}
    />
  );
};

export default TeamSelector;
