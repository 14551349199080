import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import Grow from '@mui/material/Grow';
import makeStyles from '@mui/styles/makeStyles';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { exportCSVData, getFieldValue } from 'utils/export';
import { styles } from './styles';
import { useQuery } from '@apollo/client';
import { GET_MILESTONES } from './graphql';
import { openDrawer } from '../../../reactiveVariables';
import EntityTable from '../../../components/Common/EntityTable';
import { GET_OKRS } from '../OKRs/graphql';
import { GET_FEATURES, GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import OKRExpand from '../OKRs/OKRExpand';
import Table from '../../../components/Common/Table';
import MilestoneExpand from './MilestoneExpand';

const useStyles = makeStyles(styles);

const Milestones = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);

  useImperativeHandle(ref, () => ({
    export: () => exportOKRs(),
  }));

  const exportOKRs = useCallback(() => {
    const data = milestones.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Milestones');
  }, [milestones]);

  const onRowClick = (event, row) => {
    const milestone = milestones.find((okr) => okr?.id === row.id);
    openDrawer(milestone, 'milestone');
  };

  const getInitiativesAndFeatures = (milestone, setLoading) => {
    return <MilestoneExpand features={features} milestone={milestone} setLoading={setLoading} />;
  };

  return (
    <Grow in={true}>
      <div>
        <Table
          onRowClick={onRowClick}
          fields={headCells}
          searchQuery={searchQueryLowerCase}
          rows={getRowsData(milestones, features, initiatives)}
          expandFunc={getInitiativesAndFeatures}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Milestones);
