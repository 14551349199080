export const styles = (theme) => ({
  paper: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 70,
  },
  avatarCircle: {
    backgroundColor: theme.palette.color.background,
    opacity: 0.75,
    borderRadius: '50%',
    width: 44,
    height: 44,
    margin: '0 0 8px',
    padding: 11,
    '& svg': {
      opacity: 0.5,
    },
  },
  typographyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  typography: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: theme.typography.fontWeight.bold,
  },
  typographyTitle: {
    color: theme.palette.text.secondary,
    marginTop: -5,
  },
});
