import { gql } from '@apollo/client';

export const CONFIGURATION_FIELDS = gql`
  fragment ConfigurationFields on configurations {
    id
    type
    value
  }
`;

export const SUBTASK_FIELDS = gql`
  fragment SubtaskFields on configurations {
    id
    type
    name: value(path: "name")
    isDefault: value(path: "isDefault")
  }
`;

export const PORTFOLIO_STATE_FIELDS = gql`
  fragment SubtaskFields on configurations {
    id
    type
    value
    value_stream
  }
`;

export const HL_ESTIMATES_FIELDS = gql`
  fragment SubtaskFields on configurations {
    id
    type
    name: value(path: "name")
    storyPointRange: value(path: "storyPointRange")
    storyCountRange: value(path: "storyCountRange")
    value_stream
    estimates: value(path: "estimates")
  }
`;

export const INITIATIVE_STATE_FIELDS = gql`
  fragment SubtaskFields on configurations {
    id
    type
    rank
    name: value(path: "name")
    inUse: value(path: "inUse")
    portfolioState: value(path: "portfolioState")
    stateType: value(path: "stateType")
    value_stream
  }
`;

export const INSERT_CONFIGURATION = gql`
  ${CONFIGURATION_FIELDS}
  mutation InsertConfiguration($configuration: configurations_insert_input! = {}) {
    configuration: insert_configurations_one(object: $configuration) {
      ...ConfigurationFields
    }
  }
`;

export const UPDATE_CONFIGURATION = gql`
  ${CONFIGURATION_FIELDS}
  mutation UpdateConfiguration($configurationId: Int!, $configuration: configurations_set_input = {}) {
    update_configurations_by_pk(pk_columns: { id: $configurationId }, _set: $configuration) {
      ...ConfigurationFields
    }
  }
`;

export const DELETE_CONFIGURATION = gql`
  mutation DeleteConfiguration($id: Int!) {
    configuration: delete_configurations_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_SUBTASKS = gql`
  ${SUBTASK_FIELDS}
  query GetConfigurations {
    subtasks: configurations(where: { type: { _eq: "subtask" } }) {
      ...SubtaskFields
    }
  }
`;

export const GET_HL_ESTIMATE_CONFIG = gql`
  ${HL_ESTIMATES_FIELDS}
  query GetConfigurations {
    hlEstimates: configurations(where: { type: { _eq: "hlEstimate" } }) {
      ...SubtaskFields
    }
  }
`;

export const GET_PORTFOLIO_STATES_CONFIG = gql`
  ${PORTFOLIO_STATE_FIELDS}
  query GetConfigurations {
    portfolioStates: configurations(where: { type: { _eq: "portfolioState" } }) {
      ...SubtaskFields
    }
  }
`;

export const GET_INITIATIVE_STATE_CONFIG = gql`
  ${INITIATIVE_STATE_FIELDS}
  query GetConfigurations {
    initiativeStates: configurations(where: { type: { _eq: "initiativeState" } }) {
      ...SubtaskFields
    }
  }
`;

export const UPDATE_CONFIGURATION_MANY = gql`
  mutation MyMutation($updates: [configurations_updates!] = { where: {} }) {
    update_configurations_many(updates: $updates) {
      affected_rows
    }
  }
`;
