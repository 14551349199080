import { ActionIcon, Divider, Fieldset, Group, Select, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import { InputText, StyledAvatar } from '../../../../../components/Common/Fields';
import { DateInput } from '@mantine/dates';
import SvgAdd from '../../../../../assets/images/Add';
import Close from '../../../../../assets/images/Close';
import CloseIcon from '@mui/icons-material/Close';
import SecondPage from '../../CreateKeyResultModal/SecondPage';

const Trajectory = ({ keyResult, fieldArray }) => {
  return (
    <>
      <Fieldset m="12px 0" radius={8}>
        <SecondPage fieldArray={fieldArray} />
      </Fieldset>
    </>
  );
};

export default Trajectory;
