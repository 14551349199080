export const TABS = [
  {
    index: 0,
    name: 'Ideation',
  },
  {
    index: 1,
    name: 'Roadmap',
  },
  {
    index: 2,
    name: 'Prioritisation',
  },
  {
    index: 3,
    name: 'Features',
  },
  {
    index: 4,
    name: 'ProductTheme',
  },
];

export const IdeationPopupMessage = 'You have not completed key fields for this feature';

export const IdeationDonePopupMessage = 'This feature has been completed. Are you sure you wish to continue ?';

export const IdeationSortOptions = {
  funnel: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'createdAt', name: 'Date Created' },
  ],
  analysis: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'size', name: 'Size' },
    { id: 'assignedTo', name: 'Owner' },
  ],
  backlog: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'size', name: 'Size' },
    { id: 'assignedTo', name: 'Owner' },
  ],
  candidate: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'assignedTo', name: 'Owner' },
  ],
  planned: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'assignedTo', name: 'Owner' },
  ],
  inProgress: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'team.name', name: 'Team' },
    { id: 'assignedTo', name: 'Owner' },
  ],
  done: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'team.name', name: 'Team' },
  ],
};

export const FeatureKanbanSortOptions = {
  ...IdeationSortOptions,
  candidate: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'assignedTo', name: 'Owner' },
    { id: 'programme.name', name: 'Programme' },
    { id: 'program_increment.name', name: 'Increment' },
  ],
  planned: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'assignedTo', name: 'Owner' },
    { id: 'programme.name', name: 'Programme' },
    { id: 'program_increment.name', name: 'Increment' },
  ],
  inProgress: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'team.name', name: 'Team' },
    { id: 'assignedTo', name: 'Owner' },
    { id: 'programme.name', name: 'Programme' },
    { id: 'program_increment.name', name: 'Increment' },
  ],
  done: [
    { id: 'priority', name: 'Priority' },
    { id: 'name', name: 'Name' },
    { id: 'wsjf', name: 'WSJF' },
    { id: 'team.name', name: 'Team' },
    { id: 'programme.name', name: 'Programme' },
    { id: 'program_increment.name', name: 'Increment' },
  ],
};
