import * as React from 'react';

function SvgDecrease(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.01033 11.3796L0.99987 12.1189L1.76033 12.1296L5.70579 12.1855C6.12578 12.1914 6.47093 11.8653 6.47671 11.457C6.48249 11.0487 6.1467 10.7129 5.72671 10.707L3.62372 10.6772L7.25578 6.88008L9.71174 9.44768C9.85323 9.5956 10.049 9.67926 10.2537 9.67926C10.4584 9.67926 10.6542 9.5956 10.7957 9.44768L14.793 5.26873C15.0793 4.9694 15.0687 4.49464 14.7694 4.20833C14.4701 3.92202 13.9953 3.93257 13.709 4.23189L10.2537 7.84423L7.79776 5.27663C7.65627 5.12871 7.46047 5.04505 7.25578 5.04505C7.05109 5.04505 6.85529 5.12871 6.7138 5.27663L2.55642 9.62298L2.58553 7.56581C2.59131 7.15754 2.25553 6.82176 1.83553 6.81581C1.41554 6.80987 1.07039 7.13602 1.06461 7.54429L1.01033 11.3796Z"
        fill="#CB3439"
      />
    </svg>
  );
}

export default SvgDecrease;
