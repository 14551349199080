import Toolbar from '@mui/material/Toolbar';
import Select from '../../../../../components/Common/Select';
import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_BACKLOGS } from '../../../../Settings/GraphQL/backlogs';
import Roadmap from '../../../../Reports/Roadmap';
import AdditionalStoriesCharts from './AdditionalStoriesCharts';
import { SegmentedControl, useMantineColorScheme } from '@mantine/core';
import FeatureProgression from './Progression';
import { selectedTeamVar } from '../../../../../reactiveVariables';

export const TOGGLE_VALUES = [
  { value: 'storyCount', label: 'Stories' },
  { value: 'storyPoints', label: 'Points' },
];

const Reporting = () => {
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const [backlog, setBacklog] = useState(0);
  const [selectedReport, setSelectedReport] = useState(1);
  const [scope, setScope] = useState('storyCount');
  const { colorScheme } = useMantineColorScheme();

  const selectedTeams = useReactiveVar(selectedTeamVar);

  const onBacklogChange = (event, value) => {
    setBacklog(value);
  };

  const onReportChange = (event, value) => {
    setSelectedReport(value);
  };

  return (
    <>
      <Toolbar style={{ minHeight: 0, marginBottom: '16px', paddingLeft: 0 }}>
        <Select
          addAllOption={true}
          onChange={onBacklogChange}
          defaultValue={backlog || 0}
          placeholder="Project"
          border={false}
          options={backlogs}
          title="Project"
        />
        <Select
          onChange={onReportChange}
          defaultValue={selectedReport}
          placeholder="Report"
          border={false}
          options={[
            { value: 1, label: 'Roadmap' },
            { value: 2, label: 'Feature Progression' },
            { value: 3, label: 'Scope Change' },
          ]}
          title="Report"
        />
        {(selectedReport === 3 || selectedReport == 2) && (
          <SegmentedControl
            sx={{
              label: { color: colorScheme === 'dark' ? '#ffffff' : '#db36c0' },
              backgroundColor: colorScheme === 'dark' ? '#484851' : '#FFFFFF',
            }}
            radius="xl"
            value={scope}
            color={colorScheme === 'dark' ? 'darkGrey.0' : 'bigagilePink.0'}
            onChange={(value) => setScope(value)}
            data={TOGGLE_VALUES}
          />
        )}
      </Toolbar>
      {selectedReport === 1 && <Roadmap backlog={backlog} selectedTeams={selectedTeams} />}
      {selectedReport === 2 && <FeatureProgression backlog={backlog} scope={scope} selectedTeams={selectedTeams} />}
      {selectedReport === 3 && (
        <AdditionalStoriesCharts backlog={backlog} scope={scope} selectedTeams={selectedTeams} />
      )}
    </>
  );
};

export default Reporting;
