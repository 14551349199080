import * as React from 'react';

function SvgRightArrow(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33445 2.94266C7.702 2.5751 8.29792 2.5751 8.66547 2.94266L13.0576 7.33481C13.4252 7.70236 13.4252 8.29829 13.0576 8.66584L8.66547 13.058C8.29792 13.4255 7.702 13.4255 7.33445 13.058C6.96689 12.6904 6.96689 12.0945 7.33445 11.727L10.1199 8.9415H3.6078C3.088 8.9415 2.66663 8.52012 2.66663 8.00033C2.66663 7.48053 3.088 7.05915 3.6078 7.05915L10.1199 7.05915L7.33445 4.27368C6.96689 3.90613 6.96689 3.31021 7.33445 2.94266Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgRightArrow;
