import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Text } from '@mantine/core';

const CircularProgressWithLabel = ({ value }) => {
  const [data, setData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => setData(value), 200);
  }, [value]);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={38} color="info" variant="determinate" value={data} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {data != null && (
          <Text size="xs" color={theme.palette.text.secondary}>
            {`${Math.round(data)}`}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
