import * as React from 'react';

function SvgPortfolio(props) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m1 1 5 4.348V21l-5-4.348V1z" className="dimmed" />
      <path d="M11 1 6 5.348V21l5-4.348V1z" className="normal" />
      <path d="m11 1 5 4.348V21l-5-4.348V1z" className="dimmed" />
      <path d="m21 1-5 4.348V21l5-4.348V1z" className="normal" />
    </svg>
  );
}

export default SvgPortfolio;
