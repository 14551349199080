import * as React from 'react';

function SvgStar(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.53167 1.25247C6.69364 0.819298 7.30636 0.819299 7.46833 1.25247L8.85906 4.97175C8.92933 5.15967 9.10512 5.28739 9.30556 5.29615L13.2726 5.46949C13.7346 5.48968 13.9239 6.0724 13.562 6.36031L10.4545 8.83229C10.2975 8.95719 10.2304 9.16385 10.284 9.35718L11.345 13.1836C11.4686 13.6292 10.9729 13.9894 10.5872 13.7341L7.27595 11.5426C7.10865 11.4319 6.89135 11.4319 6.72405 11.5426L3.41279 13.7341C3.02714 13.9894 2.53144 13.6292 2.65502 13.1836L3.71603 9.35718C3.76964 9.16385 3.70249 8.95719 3.54548 8.83229L0.437997 6.36031C0.0760776 6.0724 0.265416 5.48968 0.727441 5.46949L4.69444 5.29615C4.89488 5.28739 5.07067 5.15967 5.14094 4.97175L6.53167 1.25247Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgStar;
