import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_BACKLOGS } from '../../Settings/GraphQL/backlogs';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { useTheme } from '@mui/styles';
import SvgProduct from '../../../assets/images/Product';
import { openDrawer, selectedProductThemeVar, selectedProgrammeVar, selectedTeamVar } from '../../../reactiveVariables';
import { SegmentedControl, Text, useMantineColorScheme } from '@mantine/core';
import { FlexDiv } from '../../../components/Common/Elements';
import SvgFeatures from '../../../assets/images/Features';
import { Metric, MetricPaper } from '../../ValueStream/components/Common';
import SvgStories from '../../../assets/images/Stories';
import SvgCycle from '../../../assets/images/Cycle';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { usePiRoadmap } from './hooks/use-pi-roadmap';
import { TOGGLE_VALUES } from './utils';
import ForecastToolbar from './ForecastToolbar';
import FeatureCard from './FeatureCard';
import Header from './Header';
import Select from '../../../components/Common/Select';
import { STAGE_SELECTS } from '../../../utils/formConstants';
import { GET_FEATURES_FOR_PROGRAMME } from '../Features/graphql';
import { some, uniq } from 'lodash';
import { GET_PROGRAM_INCREMENTS, GET_PROGRAM_INCREMENTS_FOR_PROGRAMME } from '../../Settings/ProgramIncrement/graphql';
import { filterProductTheme } from '../../../utils/helpers';

const useStyles = makeStyles(styles);

const ProductRoadMap = () => {
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);

  const programmeTeams = useMemo(
    () => teams.filter((team) => team.board && team?.programmes?.includes(selectedProgramme)),
    [teams],
  );

  const [backlog, setBacklog] = useState(0);
  const [stages, setStages] = useState([]);
  const [projectionFilters, setProjectionFilters] = useState({});

  const [forecastValue, setForecastValue] = useState('feature');

  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';

  const classes = useStyles();
  const theme = useTheme();

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          filterProductTheme(selectedProductTheme, feature) &&
          (!backlog || feature.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!stages.length || stages.includes(feature.stage)),
      ),
    [features, backlog, stages, selectedTeams, selectedProductTheme],
  );

  const backlogTeams = useMemo(() => uniq(filteredFeatures.map((feature) => feature.teamId)), [filteredFeatures]);
  const filteredTeams = useMemo(
    () => programmeTeams.filter(({ id }) => backlogTeams.includes(id)),
    [backlogTeams, programmeTeams],
  );

  const { pis, cycleTime, spVelocity, incrementVelocity, plannedFeatures, projectedVelocity, doneFeatures } =
    usePiRoadmap(filteredFeatures, forecastValue, filteredTeams, projectionFilters, programIncrements);

  const onBacklogChange = (event, value) => {
    setBacklog(value);
  };
  const handleClick = useCallback((feature) => openDrawer({ ...feature, isIdeationBoard: true }, 'feature'), []);
  const runProjection = useCallback((forecastFilters) => setProjectionFilters(forecastFilters), []);

  const displayVelocity = projectedVelocity ?? incrementVelocity;

  return (
    <>
      <FlexDiv>
        <Text size="xl" fz={20} fw={600}>
          Roadmap
        </Text>
        <SegmentedControl
          styles={() => ({
            label: { color: isDark ? '#ffffff' : '#db36c0' },
          })}
          style={{
            marginLeft: '18px',
            backgroundColor: isDark ? '#484851' : '#FFFFFF',
          }}
          value={forecastValue}
          color={isDark ? 'darkGrey.0' : 'bigagilePink.0'}
          onChange={setForecastValue}
          data={TOGGLE_VALUES}
        />
        <Select
          rootStyle={{ marginLeft: '10px' }}
          title="Stage"
          onChange={(event, values) => setStages(values)}
          placeholder="Select a stage..."
          defaultValue={STAGE_SELECTS.map((stage) => stage.value)}
          multiple={true}
          options={STAGE_SELECTS}
          border={false}
        />
        <Select
          options={[{ name: 'All', id: 0 }, ...backlogs]}
          defaultValue={backlog}
          title="Project"
          placeholder="Project"
          border={false}
          onChange={onBacklogChange}
        />
      </FlexDiv>
      <ForecastToolbar teams={filteredTeams} runProjection={runProjection} backlog={backlog} stages={stages} />
      <Grid sx={{ height: '130px' }} container item spacing={1}>
        <MetricPaper title="Features" icon={<SvgFeatures color={theme.palette.color.buttonIcon} xs={3} />}>
          <Metric
            value={filteredFeatures.length - doneFeatures.length}
            name="In Progress"
            dotColor={theme.palette.color.inProgress}
          />
          <Metric value={doneFeatures.length} name="Done" dotColor={theme.palette.color.done} />
        </MetricPaper>
        <MetricPaper title="Increment Velocity" xs={3} icon={<SvgProduct fill={theme.palette.color.buttonIcon} />}>
          <Metric
            value={incrementVelocity}
            name={forecastValue === 'feature' ? 'No. of Features done' : 'Size of Features done'}
            xs={projectedVelocity !== null ? 6 : 12}
          />
          {projectedVelocity !== null ? <Metric value={projectedVelocity} name="Projected Velocity" xs={6} /> : null}
        </MetricPaper>
        <MetricPaper title="Feature Cycle Time" xs={3} icon={<SvgCycle fill={theme.palette.color.buttonIcon} />}>
          <Metric value={cycleTime.toFixed(1)} name="Average No. of Sprints to close a feature" xs={12} />
        </MetricPaper>
        <MetricPaper
          title="SPs Velocity per increment"
          xs={3}
          icon={<SvgStories color={theme.palette.color.buttonIcon} />}>
          <Metric value={spVelocity} name="Average No. of Story Points Done" xs={12} />
        </MetricPaper>
      </Grid>
      <Grid sx={{ overflowY: 'auto' }} item xs={12} flex={3}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {pis.map((pi) => (
                <Header key={pi.id} pi={pi} forecastValue={forecastValue} incrementVelocity={displayVelocity} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {pis.map((pi, index) => {
                const piFeatures = pi.features.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    feature={feature}
                    forecastValue={forecastValue}
                    index={index}
                    piStatus={pi.status}
                    handleFeatureClick={handleClick}
                    plannedFeatures={plannedFeatures}
                  />
                ));
                return (
                  <TableCell key={index} className={classes.tableHeadRow} style={{ verticalAlign: 'top' }}>
                    {piFeatures}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

export default ProductRoadMap;
