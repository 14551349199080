import * as React from 'react';

function SvgObjectives(props) {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8452 4.76033L11.3383 7.58579C9.74355 8.27826 7.92685 8.24495 6.35857 7.49448L3.31219 6.03668L3.1387 7.78014C6.71515 10.038 11.282 10.0482 14.875 7.78336L15.0053 7.70126C15.1385 7.61732 15.3119 7.71303 15.3119 7.87046V11.5193C15.3119 11.568 15.2913 11.6144 15.2551 11.6469L15.1157 11.7725C11.5932 14.9443 6.25072 14.9341 2.74151 11.7716L2.40147 15.1887C2.35225 15.6833 1.91139 16.0443 1.41678 15.9951C0.922164 15.9459 0.5611 15.505 0.610319 15.0104L1.13332 9.75471L1.59435 5.12163C1.05636 4.79878 0.534416 4.44922 0.0305264 4.07413C-0.0135446 4.04132 -0.00913696 3.97398 0.0388341 3.9472L0.125724 3.89869C2.78323 2.41503 5.57302 1.18186 8.45901 0.215127L8.86478 0.0792041C9.18005 -0.0264013 9.52114 -0.0264014 9.8364 0.079204L10.55 0.318256C13.2164 1.21141 15.7053 2.56544 17.9037 4.31877C18.0569 4.44096 18.0249 4.68228 17.8452 4.76033Z"
      />
    </svg>
  );
}

export default SvgObjectives;
