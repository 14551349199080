import React, { useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { styles } from '../../Product/IdeaBoard/styles';
import { openDrawer, selectedProductThemeVar, selectedOwnerVar } from '../../../reactiveVariables';
import SectionMenu from 'components/Common/SectionHeader/components/SectionMenu';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { initiativeWithDates } from '../../ValueStream/utils';
import { useProjectedFeatures } from '../../../utils/hooks';
import { some } from 'lodash';
import { GET_VALUE_STREAMS } from '../../Settings/Streams/graphql';
import { GET_INITIATIVE_STATE_CONFIG, GET_PORTFOLIO_STATES_CONFIG } from '../../Settings/GraphQL/configuration';
import { defaultPortfolioStateList } from '../../Settings/Configuration/PortfolioStates/defaultStateList';
import Breakdown from './Breakdown';
import PortfolioKanban from './PortfolioKanban';

const useStyles = makeStyles(styles);

export default function OperationalPortfolio() {
  const classes = useStyles();
  const handleCreate = () => openDrawer(null, 'initiative');

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(GET_ALL_INITIATIVES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const { data: { portfolioStates = [] } = {} } = useQuery(GET_PORTFOLIO_STATES_CONFIG);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const projectedFeatures = useProjectedFeatures();
  const selectedOwners = useReactiveVar(selectedOwnerVar);

  const portfolioStatesConfig = useMemo(
    () => (portfolioStates.length ? portfolioStates[0].value.columns : defaultPortfolioStateList),
    [portfolioStates],
  );

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const TABS = [
    {
      index: 0,
      name: 'Portfolio Kanban',
    },
    {
      index: 1,
      name: 'Breakdown',
    },
  ];

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const refetchListOfInitiatives = () => {
    refetchInitiatives();
  };

  let initiativesWithDatesAndRag = useMemo(() => {
    return initiatives
      .map((initiative) => ({
        ...initiative,
        initiative_state: initiative.initiative_state || 'Unknown state',
      }))
      .filter(
        (initiative) =>
          !selectedProductTheme || some(initiative.productThemes, (theme) => theme.id === selectedProductTheme),
      )
      .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures));
  }, [selectedProductTheme, initiatives, sprints, projectedFeatures]);

  if (selectedOwners.length) {
    initiativesWithDatesAndRag = initiativesWithDatesAndRag.filter((initiative) =>
      selectedOwners.includes(initiative.owner),
    );
  }

  return (
    <div className={classes.root}>
      <Toolbar className={classes.appBar} disableGutters>
        <div className={classes.toolbarLeft}>
          <Typography fontSize={20} fontWeight={600}>
            Operational Portfolio
          </Typography>
          <IconButton
            key="add"
            aria-label="Add"
            color="inherit"
            onClick={handleCreate}
            size="small"
            sx={{ marginLeft: '6px' }}>
            <AddBoxIcon />
          </IconButton>
        </div>
      </Toolbar>

      <SectionMenu handleChange={handleTabChange} value={value} tabs={TABS} />
      <section style={{ marginTop: '20px', overflowY: 'auto' }}>
        {value === 0 && (
          <PortfolioKanban
            portfolioStatesConfig={portfolioStatesConfig}
            initiatives={initiativesWithDatesAndRag}
            valueStreams={activeValueStreams}
            initiativeStates={initiativeStates}
            refetchListOfInitiatives={refetchListOfInitiatives}
          />
        )}
        {value === 1 && (
          <Breakdown
            allInitiatives={initiativesWithDatesAndRag}
            valueStreams={activeValueStreams}
            portfolioStatesConfig={portfolioStatesConfig}
          />
        )}
      </section>
    </div>
  );
}
