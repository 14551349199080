import React, { useMemo } from 'react';
import { sum } from 'lodash';
import { useTheme } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';

const ProgramPredictability = ({ programIncrements, objectives }) => {
  const theme = useTheme();
  const colors = ['#D8A24E', '#EAA4A5', '#4F74CB', '#B4CD67'];

  const data = useMemo(
    () =>
      programIncrements.map((pi) => {
        const piObjectives = objectives.filter((objective) => objective.programIncrement === pi.id);
        const totalPlannedValue = sum(piObjectives.map((objective) => objective.plannedValue));
        const totalDeliveredValue = sum(piObjectives.map((objective) => objective.deliveredValue));

        return {
          x: pi.name,
          y: Math.round((totalDeliveredValue / totalPlannedValue) * 100 || 0),
        };
      }),
    [programIncrements, objectives],
  );

  const config = {
    series: [
      {
        name: 'Predictability (%)',
        data: data,
      },
    ],
    options: {
      chart: {
        height: 100,
        type: 'bar',
        background: theme.palette.color.paper,
      },
      theme: {
        mode: theme.palette.mode,
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '10%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: { colors: theme.palette.text.secondary },
      },

      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
        title: {
          text: 'Predictability (%)',
          style: { color: theme.palette.text.secondary },
        },
      },
      xaxis: {
        categories: programIncrements.map((pi) => pi.name),
        axisTicks: { show: false },
        labels: {
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
    },
  };

  return <ReactApexChart options={config.options} series={config.series} type="bar" height="100%" />;
};

export default ProgramPredictability;
