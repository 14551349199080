export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '5%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    disablePadding: false,
    filtering: false,
    numeric: false,
    align: 'left',
    width: '30%',
  },
];
