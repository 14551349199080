import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { find, isEmpty } from 'lodash';
import { DEPENDENCIES } from './containers/Delivery/components/Dependencies/constants';
import { FEATURES } from './containers/Product/Features/constants';
import { OBJECTIVES } from './containers/Delivery/components/Objectives/constants';
import { RestLink } from 'apollo-link-rest';

import config from 'Config';
import { DateTime } from 'luxon';
import { selectedPiVar, selectedProgrammeVar, selectedValueStreamVar } from './reactiveVariables';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import keycloak from './keycloak';
import { createClient } from 'graphql-ws';
import { showNotification } from '@mantine/notifications';

const envConfig = config[window.location.hostname];

const getAuthHeaders = (headers, token) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  },
});

const authLinkSub = setContext((_, { headers }) => {
  return { connectionParams: getAuthHeaders(headers, keycloak.token) };
});

const restLink = new RestLink({ uri: config.apiUrl });

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => getAuthHeaders(headers, keycloak.token));
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: `https://${envConfig.hasuraHostname}/v1/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${envConfig.hasuraHostname}/v1/graphql`,
    lazy: true,
    retryAttempts: 3,
    shouldRetry: (error) => true,
    connectionParams: () => ({
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    }),
  }),
);

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => {
      if (['__typename', 'updatedAt'].includes(key)) return undefined;
      if (value === '') return null;
      return value;
    };
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => data);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // showNotification({
      //   color: 'red',
      //   title: 'GraphQL Error',
      //   message: message
      // });
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  ApolloLink.from([cleanTypeName, authLinkSub, errorLink, wsLink]),
  ApolloLink.from([cleanTypeName, authLink, errorLink, restLink, httpLink]),
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectedPI: {
          read() {
            return selectedPiVar();
          },
        },
        selectedProgramme: {
          read() {
            return selectedProgrammeVar();
          },
        },
        selectedValueStream: {
          read() {
            return selectedValueStreamVar();
          },
        },
        dependencies: {
          read(existing, context) {
            return readElementsForPI(existing, context, DEPENDENCIES);
          },
        },
        features: {
          read(existing, context) {
            return readElementsForPI(existing, context, FEATURES);
          },
        },
        objectives: {
          read(existing, context) {
            return readElementsForPI(existing, context, OBJECTIVES);
          },
        },
      },
    },
  },
});

const readElementsForPI = (existing, { variables, readField, storeFieldName }, element) => {
  if (variables.pi && storeFieldName.includes('"where":{"programIncrement"')) {
    const elements = readField({
      fieldName: element,
      args: { order_by: { id: 'asc' } },
    });

    if (elements) {
      const elementsForPI = elements.filter((dep) => readField('programIncrement', dep) === variables.pi);
      return !isEmpty(elementsForPI) ? elementsForPI : undefined;
    }
  }

  if (variables.programme && storeFieldName.includes('"where":{"programmeId"')) {
    const elements = readField({
      fieldName: element,
      args: { order_by: { id: 'asc' } },
    });

    if (elements) {
      const elementsForPI = elements.filter((dep) => readField('programmeId', dep) === variables.programme);
      return !isEmpty(elementsForPI) ? elementsForPI : undefined;
    }
  }

  return existing;
};

export const subscribeTo = (subscribeFunction, subscription, type, variables) => {
  try {
    subscribeFunction({
      document: subscription,
      variables: variables,
      onError: (error) => console.warn('Error when subscribing to ' + type + ': ' + error),
      updateQuery: (prev, data) => {
        const newData = data?.subscriptionData?.data;

        const mergedData = newData[type].map((element) => {
          const previousElement = find(prev[type], { id: element.id });
          if (!previousElement || DateTime.fromISO(previousElement.updatedAt) <= DateTime.fromISO(element.updatedAt)) {
            return element;
          }
          return previousElement;
        });
        return { [type]: mergedData };
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const client = new ApolloClient({
  link: splitLink,
  cache: cache,
});
