import React from 'react';

import { Badge } from '@mantine/core';

import SvgPriorityHigh from '../../../assets/images/priorityHigh';
import SvgPriorityLow from '../../../assets/images/priorityLow';
import SvgPriorityMedium from '../../../assets/images/priorityMedium';
import SvgPriorityNo from '../../../assets/images/priorityNo';
import SvgPriorityUrgent from '../../../assets/images/priorityUrgent';
import { color } from '../../../shared/styles/color';
import { fontSize, textTransform } from '@mui/system';

const returnSvg = (priority) => {
  switch (priority) {
    case 'No':
      return { svg: <SvgPriorityNo />, value: 0 };
    case 'Low':
      return { svg: <SvgPriorityLow />, value: 1 };
    case 'Medium':
      return { svg: <SvgPriorityMedium />, value: 2 };
    case 'High':
      return { svg: <SvgPriorityHigh />, value: 3 };
    case 'Urgent':
      return { svg: <SvgPriorityUrgent />, value: 4 };
    default:
      return { svg: <SvgPriorityNo />, value: 0 };
  }
};

function createData(item) {
  const { id, created_at, owner, state, idea, next_step, next_step_feature_or_initiative_id } = item;

  const namesString = idea?.valueStreams?.length ? idea.valueStreams.map((stream) => stream.name).join(', ') : '';

  const priorityData = returnSvg(idea?.priority);

  return {
    id,
    created_at,
    owner,
    state,
    name: idea?.name,
    creator: idea?.creator,
    valueStreams: namesString,
    priority: priorityData.svg,
    priorityValue: priorityData.value,
    next_step: next_step_feature_or_initiative_id ? (
      <Badge
        color={color.purpulishPink}
        w={'100%'}
        size="m"
        style={{ fontSize: '13px', textTransform: 'none' }}
        radius="4px"
        sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C', fontSize: '13px' }}>
        {next_step === 'Convert to feature'
          ? `Feature: ${next_step_feature_or_initiative_id}`
          : `Initiative: ${next_step_feature_or_initiative_id}`}
      </Badge>
    ) : (
      next_step || 'Unassigned'
    ),
  };
}

export function getRowsData(data) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item));
  }
  return rows;
}
