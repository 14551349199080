import { DateTime } from 'luxon';

export const DATE_FORMAT = {
  date: 'dd/MM/yyyy',
  dateShort: 'dd/MM/yy',
  dateTime: 'dd/MM/yyyy HH:mm',
};

export const timeAgo = (timestamp) => {
  const now = DateTime.now();
  const diff = now.diff(DateTime.fromMillis(timestamp), ['minutes', 'hours', 'days']);

  if (diff.as('minutes') < 1) {
    return '1 minute ago';
  } else if (diff.as('minutes') < 60) {
    const minutes = Math.floor(diff.as('minutes'));
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diff.as('hours') < 24) {
    const hours = Math.floor(diff.as('hours'));
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diff.as('days'));
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
};
