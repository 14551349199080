import * as React from 'react';

function SvgRoadmap(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.96281C12 3.77761 12.1024 3.60757 12.2661 3.52092L16.7661 1.13856C17.0991 0.96227 17.5 1.20367 17.5 1.58046V14.0372C17.5 14.2224 17.3976 14.3924 17.2339 14.4791L12.7339 16.8614C12.4009 17.0377 12 16.7963 12 16.4196V3.96281ZM0.5 3.95484C0.5 3.77386 0.597802 3.60701 0.755713 3.51858L4.95571 1.16677C5.289 0.980145 5.7 1.22105 5.7 1.60303V14.0452C5.7 14.2262 5.6022 14.393 5.44429 14.4814L1.24429 16.8332C0.910996 17.0199 0.5 16.779 0.5 16.397V3.95484ZM11.1808 3.51552C11.3294 3.60637 11.4199 3.76794 11.4199 3.94204V16.358C11.4199 16.7486 10.9922 16.9883 10.659 16.7845L6.89901 14.4845C6.75049 14.3936 6.65992 14.2321 6.65992 14.058V1.64199C6.65992 1.25143 7.08766 1.01166 7.42084 1.21547L11.1808 3.51552Z"
      />
    </svg>
  );
}

export default SvgRoadmap;
