import { gql } from '@apollo/client';

export const BACKLOG_FIELDS = gql`
  fragment BacklogFields on backlogs {
    id
    name
  }
`;

export const INSERT_BACKLOG = gql`
  ${BACKLOG_FIELDS}
  mutation InsertBacklog($backlog: backlogs_insert_input! = {}) {
    backlog: insert_backlogs_one(object: $backlog) {
      ...BacklogFields
    }
  }
`;

export const UPDATE_BACKLOG = gql`
  ${BACKLOG_FIELDS}
  mutation UpdateBacklog($backlogId: Int!, $backlog: backlogs_set_input = {}) {
    update_backlogs_by_pk(pk_columns: { id: $backlogId }, _set: $backlog) {
      ...BacklogFields
    }
  }
`;

export const DELETE_BACKLOG = gql`
  mutation DeleteBacklog($id: Int!) {
    backlog: delete_backlogs_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_BACKLOGS = gql`
  ${BACKLOG_FIELDS}
  query GetBacklogs {
    backlogs: backlogs {
      ...BacklogFields
    }
  }
`;

export const GET_BACKLOGS_FOR_PI = gql`
  ${BACKLOG_FIELDS}
  query GetBacklogs($pi: Int) {
    backlogs: backlogs(where: { programIncrement: { _eq: $pi } }) {
      ...BacklogFields
    }
  }
`;

export const SUBSCRIBE_BACKLOGS = gql`
  ${BACKLOG_FIELDS}
  subscription GetBacklogs($pi: Int) {
    backlogs: backlogs(where: { programIncrement: { _eq: $pi } }) {
      ...BacklogFields
    }
  }
`;
