import SectionMenu from '../../../../components/Common/SectionHeader/components/SectionMenu';
import React, { useEffect, useState } from 'react';
import { selectedPiVar } from '../../../../reactiveVariables';
import { TeamBoard } from '../../../Planner/TeamBoard';
import Overview from './Overview';
import SectionWrapper from '../../../../components/Common/SectionWrapper';
import MultiPiDashboard from './MultiPI';
import Reporting from './Reporting';

export const TABS = [
  {
    index: 0,
    name: 'Single Increment',
  },
  {
    index: 1,
    name: 'Multi Increment',
  },
  {
    index: 2,
    name: 'Reporting',
  },
];

const DeliveryDashboard = ({}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    window.Intercom('update');
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <SectionMenu handleChange={handleTabChange} value={value} tabs={TABS} />
      <SectionWrapper>
        {value === 0 && <Overview />}
        {value === 1 && <MultiPiDashboard />}
        {value === 2 && <Reporting />}
      </SectionWrapper>
    </>
  );
};

export default DeliveryDashboard;
