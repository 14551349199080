import withStyles from '@mui/styles/withStyles';

export const GlobalStyle = withStyles({
  '@global': {
    html: {
      fontSize: '100%',
    },
    body: {
      fontSize: '1rem',
    },
    'html, body': {
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      height: '100vh',
      width: '100%',
      fontFamily: 'Inter, Raleway, Arial',
      minWidth: '768px',
    },
    '#app': {
      backgroundColor: '#fafafa',
      minHeight: '100%',
      minWidth: '100%',
      display: 'flex',
      height: '100%',
      width: '100%',
    },
  },
})(() => null);
