import styled from '@emotion/styled';
import { color } from '../../../../shared/styles/color';

export const StateCard = styled('div')(({ colorScheme }) => ({
  width: '100%',
  height: '84px',
  padding: '8px 12px',
  border: colorScheme === 'dark' ? '1px solid #2B2B2B' : '1px solid #E8E8E8',
  borderRadius: '8px',
  backgroundColor: colorScheme === 'dark' ? '#111111' : color.grey,
  color: colorScheme === 'dark' ? 'white' : '#242424',
  marginBottom: '5px',
}));
