export const TOGGLE_VALUES = [
  { value: 'feature', label: 'No. of Features' },
  { value: 'size', label: 'Size of Features' },
];

export const getStatusTitle = (feature, piStatus) => {
  if (piStatus === 'planning' || piStatus === 'future') {
    if (feature.planned) {
      return 'Forecast';
    }
    return 'Predicted';
  }
  if (feature.status === 'Done') {
    return 'Done';
  }

  switch (feature.ragStatus) {
    case 'Red':
      return 'Action required';
    case 'Amber':
      return 'Issues';
    case 'Green':
      return 'On target';
  }
};

export const featureStatusColor = (feature, piStatus, theme) => {
  if (piStatus === 'planning' || piStatus === 'future') {
    if (feature.planned) {
      return theme.palette.color.forecast;
    }
    return theme.palette.color.predicted;
  }
  if (feature.status === 'Done') {
    return theme.palette.color.done;
  }

  return theme.palette.color[feature.ragStatus?.toLowerCase()];
};
