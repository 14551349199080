import * as React from 'react';

function SvgThinkTank(props) {
  return (
    <svg width="25" height="25" viewBox="0 0 18 14" fill="#d156a1" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#d156a1"
        d="M2.6665 0C1.56193 0 0.666504 0.895431 0.666504 2V10C0.666504 11.1046 1.56193 12 2.6665 12H5.33317C6.43774 12 7.33317 11.1046 7.33317 10V2C7.33317 0.895431 6.43774 0 5.33317 0H2.6665ZM1.99984 2C1.99984 1.63181 2.29831 1.33333 2.6665 1.33333H5.33317C5.70136 1.33333 5.99984 1.63181 5.99984 2V10C5.99984 10.3682 5.70136 10.6667 5.33317 10.6667H2.6665C2.29831 10.6667 1.99984 10.3682 1.99984 10V2ZM10.6665 0C9.56193 0 8.6665 0.895431 8.6665 2V5.33333C8.6665 6.4379 9.56193 7.33333 10.6665 7.33333H13.3332C14.4377 7.33333 15.3332 6.4379 15.3332 5.33333V2C15.3332 0.895431 14.4377 0 13.3332 0H10.6665ZM9.99984 2C9.99984 1.63181 10.2983 1.33333 10.6665 1.33333H13.3332C13.7014 1.33333 13.9998 1.63181 13.9998 2V5.33333C13.9998 5.70152 13.7014 6 13.3332 6H10.6665C10.2983 6 9.99984 5.70152 9.99984 5.33333V2Z"
      />
    </svg>
  );
}

export default SvgThinkTank;
