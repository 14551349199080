import * as React from 'react';

function SvgDashboard(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="8" height="8" rx="1.8" />
      <rect y="10" width="8" height="8" rx="1.8" />
      <rect x="10" width="8" height="8" rx="1.8" />
      <rect x="10" y="10" width="8" height="8" rx="1.8" />
    </svg>
  );
}

export default SvgDashboard;
