import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { headCells } from './constants';
import { getRowsData } from './helper';
import EntityTable from '../../../components/Common/EntityTable';
import { useQuery } from '@apollo/client';
import { exportCSVData, getFieldValue } from '../../../utils/export';
import { openDrawer } from '../../../reactiveVariables';
import { GET_TEAMS } from '../GraphQL/teams';
import { useTheme } from '@emotion/react';

const Teams = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();
  const theme = useTheme();

  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  useImperativeHandle(ref, () => ({
    export: () => exportTeams(),
  }));

  const exportTeams = useCallback(() => {
    // mapping throught the teams nad store it in data
    const data = teams
      .map((team) => ({ ...team, connector: team.connector?.name }))
      .map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Teams');
  }, [teams]);

  const onRowClick = (event, row) => {
    const team = teams.find((team) => team?.id === row.id);
    openDrawer(team, 'team');
  };

  return (
    <EntityTable
      headCells={headCells}
      rows={getRowsData(teams, theme)}
      searchQuery={searchQueryLowerCase}
      expand={false}
      handleRowClick={onRowClick}
    />
  );
};

export default forwardRef(Teams);
