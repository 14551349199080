export const TABS = [
  {
    index: 0,
    name: 'Features & Sprints',
  },
  {
    index: 1,
    name: 'Features Progression',
  },
  {
    index: 2,
    name: 'Additional Stories',
  },
];
