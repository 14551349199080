import * as React from 'react';

function TickSvg(props) {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6758 0.622057C12.0912 1.02358 12.1099 1.69372 11.7176 2.11886L4.75333 9.66634L0.323025 5.37043C-0.0917327 4.96825 -0.109429 4.29808 0.283499 3.87356C0.676428 3.44904 1.33119 3.43093 1.74594 3.83311L4.67196 6.67036L10.2134 0.664823C10.6057 0.239683 11.2605 0.220536 11.6758 0.622057Z"
        fill="black"
      />
    </svg>
  );
}

export default TickSvg;
