import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Toggle = (props) => {
  const classes = useStyles(props);
  const { values, selectedItem, isDisabled, handleSwitch } = props;
  return (
    <div className={classes.buttonsWrapper}>
      {values.map(({ key, value }) => {
        const isSelectedItem = key === selectedItem;
        return (
          <Button
            classes={{ root: classes.buttonRoot }}
            disableRipple={isDisabled}
            disabled={isDisabled}
            value={key}
            key={key}
            className={cn(classes.toggleButton, isSelectedItem && classes.toggleButtonActive)}
            variant="contained"
            onClick={(event) => handleSwitch(event, isDisabled)}>
            <span>{value}</span>
          </Button>
        );
      })}
    </div>
  );
};

Toggle.propTypes = {
  values: PropTypes.array,
  isActive: PropTypes.bool,
  handleSwitch: PropTypes.func,
};

export default Toggle;
