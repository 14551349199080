import React from 'react';
import { Box, Card, CardActionArea, CardContent, Zoom } from '@mui/material';
import Dotdotdot from 'react-dotdotdot';
import { ListCard } from '../../../../components/Common/Elements';

const colorForState = {
  accepted: '#F1AF54',
  resolved: '#4498F8',
  owned: '#D14F5E',
  mitigated: '#15BB4B',
};

const Risk = ({ risk, classes, handleClick }) => {
  return (
    <div style={{ display: 'inline-block', height: '100%', width: '100%' }}>
      <Zoom in={true}>
        <ListCard
          onClick={() => handleClick(risk)}
          style={{ marginBottom: 0, borderLeftColor: colorForState[risk.state] }}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <Dotdotdot clamp={4}>
                <Box component="span" display="block">
                  {risk.name}
                </Box>
              </Dotdotdot>
            </CardContent>
          </CardActionArea>
        </ListCard>
      </Zoom>
    </div>
  );
};

export default React.memo(Risk);
