import * as React from 'react';

function SvgWarning(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.8972 16.0374C1.56075 16.0374 1.3053 15.8879 1.13084 15.5888C0.956386 15.2897 0.956386 14.9907 1.13084 14.6916L8.23364 2.4486C8.4081 2.14953 8.66355 2 9 2C9.33645 2 9.5919 2.14953 9.76635 2.4486L16.8692 14.6916C17.0436 14.9907 17.0436 15.2897 16.8692 15.5888C16.6947 15.8879 16.4393 16.0374 16.1028 16.0374H1.8972ZM9.07477 7.49533C8.91277 7.49533 8.77882 7.54829 8.6729 7.65421C8.56698 7.76013 8.51402 7.89408 8.51402 8.05608V10.972C8.51402 11.134 8.56698 11.2679 8.6729 11.3738C8.77882 11.4798 8.91277 11.5327 9.07477 11.5327C9.23676 11.5327 9.37072 11.4798 9.47663 11.3738C9.58255 11.2679 9.63551 11.134 9.63551 10.972V8.05608C9.63551 7.89408 9.58255 7.76013 9.47663 7.65421C9.37072 7.54829 9.23676 7.49533 9.07477 7.49533ZM9.07477 13.5701C9.24922 13.5701 9.39875 13.5047 9.52336 13.3738C9.64797 13.243 9.71028 13.0966 9.71028 12.9346C9.71028 12.7601 9.64486 12.6137 9.51402 12.4953C9.38318 12.3769 9.23676 12.3178 9.07477 12.3178C8.90031 12.3178 8.75078 12.3801 8.62617 12.5047C8.50156 12.6293 8.43925 12.7726 8.43925 12.9346C8.43925 13.109 8.50467 13.2586 8.63551 13.3832C8.76636 13.5078 8.91277 13.5701 9.07477 13.5701Z" />
    </svg>
  );
}

export default SvgWarning;
