import { gql } from '@apollo/client';

export const PROGRAM_INCREMENT_FIELDS = gql`
  fragment ProgramIncrementFields on program_increments {
    defaultConnectorId
    defaultProjectKey
    id
    name
    scopePerSprint
    programme
    metrics
    status
    sprints(order_by: { startDate: asc }) {
      id
      endDate
      name
      startDate
      jiraIds
      metrics
    }
  }
`;

export const INSERT_PROGRAM_INCREMENT = gql`
  ${PROGRAM_INCREMENT_FIELDS}
  mutation InsertProgramIncrement($programIncrement: program_increments_insert_input! = {}) {
    programIncrement: insert_program_increments_one(object: $programIncrement) {
      ...ProgramIncrementFields
    }
  }
`;

export const buildUpdatePI = (updatedSprints) => {
  const updateSprintsMutation = updatedSprints.map(
    (sprint) => `
    update_${sprint.id}: update_sprints_by_pk(pk_columns: { id: ${sprint.id} }, _set: $sprint${sprint.id}) {
        id
    }`,
  );

  const updateSprintsVariables = updatedSprints.map((sprint) => `$sprint${sprint.id}: sprints_set_input `).join(',');

  return gql`
    ${PROGRAM_INCREMENT_FIELDS}
    mutation UpdateProgramIncrement(${updateSprintsVariables}, $id: Int!, $deletedSprintIds: [Int!], $programIncrement: program_increments_set_input = {}, $addedSprints: [sprints_insert_input!] = []) {
      delete_sprints(where: { id: {_in: $deletedSprintIds }}){
        returning {
          id
        }
      }
      insert_sprints(objects: $addedSprints) {
        returning {
          id
        }
      }
      ${updateSprintsMutation}
      update_program_increments_by_pk(pk_columns: {id: $id}, _set: $programIncrement) {
        ...ProgramIncrementFields
      }
    }
  `;
};

export const DELETE_PROGRAM_INCREMENT = gql`
  mutation DeleteProgramIncrement($id: Int!) {
    programIncrement: delete_program_increments_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PROGRAM_INCREMENTS = gql`
  ${PROGRAM_INCREMENT_FIELDS}
  query GetProgramIncrements {
    programIncrements: program_increments(order_by: { id: asc }) {
      ...ProgramIncrementFields
    }
  }
`;

export const GET_PROGRAM_INCREMENTS_FOR_PROGRAMME = gql`
  ${PROGRAM_INCREMENT_FIELDS}
  query GetProgramIncrements($programme: Int) {
    selectedProgramme @client @export(as: "programme")
    programIncrements: program_increments(order_by: { id: asc }, where: { programme: { _eq: $programme } }) {
      ...ProgramIncrementFields
    }
  }
`;

export const GET_SPRINTS = gql`
  query GetSprints {
    sprints: sprints(order_by: { endDate: asc }) {
      id
      name
      endDate
      startDate
      jiraIds
      programIncrement
    }
  }
`;

export const GET_SELECTED_PI = gql`
  query GetSelectedPI {
    selectedPI @client
  }
`;

export const GET_SELECTED_PI_FULL = gql`
  ${PROGRAM_INCREMENT_FIELDS}
  query GetSelectedPiFull($pi: Int!) {
    selectedPI @client @export(as: "pi")
    selectedPiFull: program_increments_by_pk(id: $pi) {
      ...ProgramIncrementFields
    }
  }
`;

export const GET_SPRINTS_FOR_BOARD = gql`
  query GetJiraSprints($connectorId: String!, $boardId: String!) {
    jiraSprints(connectorId: $connectorId, boardId: $boardId)
      @rest(type: "Sprint", path: "/api/jira/{args.connectorId}/{args.boardId}/sprints/0") {
      id
      name
    }
  }
`;

export const GET_ITERATIONS_FOR_BOARD = gql`
  query GetAzureSprints($connectorId: String!, $boardId: String!, $projectId: String!) {
    jiraSprints(connectorId: $connectorId, boardId: $boardId, projectId: $projectId)
      @rest(type: "Sprint", path: "/api/azure/{args.connectorId}/{args.projectId}/{args.boardId}/sprints") {
      id
      name
      path
    }
  }
`;

export const ACTIVATE_PI = gql`
  fragment PiInput on REST {
    pi: String
  }

  mutation ActivePI($input: PiInput!) {
    activatedPi(input: $input, pi: $pi) @rest(method: "POST", type: "PI", path: "/api/pi/activate/{args.pi}") {
      affected_rows
    }
  }
`;
