import * as React from 'react';

const SvgPriorityHigh = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91582 0C9.32295 0 8.84217 0.507484 8.84217 1.13346V10.8665C8.84217 11.4924 9.32295 11.9999 9.91582 11.9999H10.9264C11.5193 11.9999 12 11.4924 12 10.8665V1.13346C12 0.507484 11.5193 0 10.9264 0H9.91582ZM4.42115 4.46677C4.42115 3.8408 4.90192 3.33331 5.49479 3.33331H6.50538C7.09825 3.33331 7.57902 3.8408 7.57902 4.46677V10.8665C7.57902 11.4924 7.09825 11.9999 6.50538 11.9999H5.49479C4.90192 11.9999 4.42115 11.4924 4.42115 10.8665V4.46677ZM0.00012207 7.13342C0.00012207 6.50745 0.480896 5.99996 1.07377 5.99996H2.08435C2.67722 5.99996 3.158 6.50745 3.158 7.13342V10.8665C3.158 11.4924 2.67722 11.9999 2.08435 11.9999H1.07377C0.480896 11.9999 0.00012207 11.4924 0.00012207 10.8665V7.13342Z"
      fill="#FF4189"
    />
  </svg>
);

export default SvgPriorityHigh;
