import * as React from 'react';

function SvgDiamondActive(props) {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy={15} r={15} fill="#DB36C0" fillOpacity={0.18} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 15l-7-8-7 8 7 8 7-8z" fill="#DB36C0" />
    </svg>
  );
}

export default SvgDiamondActive;
