export const getColumnStyle = (style, isOver, theme) => {
  if (isOver)
    return {
      ...style,
      backgroundColor: theme.palette.color.column,
      height: 'calc(100% - 10px)',
      border: '1px dashed #EEE7EA',
      padding: '10px',
    };

  return { ...style, height: 'calc(100% - 10px)' };
};
