import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/system';
import CloseCollapse from '../../../../assets/images/CloseCollapse';
import OpenCollapse from '../../../../assets/images/OpenCollapse';
import { uniq } from 'lodash';

const InnerTableCell = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  fontSize: '14px',
  fontWeight: 'normal',
  width: '16.7%',
  padding: '10px',
  borderBottom: 0,
}));

const BodyTableCell = styled(MuiTableCell)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '8px',
  borderBottom: 0,
}));

const MetricTable = ({ programIncrements }) => {
  const Row = ({ pi }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const piMetrics = pi?.sprints
      ?.filter((sprint) => sprint.metrics)
      .reduce(
        (metrics, sprint) => ({
          totalStoryCount: metrics.totalStoryCount + sprint.metrics.totalStoryCount,
          featureStoryCount: metrics.featureStoryCount + sprint.metrics.featureStoryCount,
          bugStoryCount: metrics.bugStoryCount + sprint.metrics.bugStoryCount,
          unknownStoryCount: metrics.unknownStoryCount + sprint.metrics.unknownStoryCount,
          teamIds: uniq([...metrics.teamIds, ...sprint.metrics.teamIds]),
        }),
        {
          totalStoryCount: 0,
          featureStoryCount: 0,
          bugStoryCount: 0,
          unknownStoryCount: 0,
          teamIds: [],
        },
      );

    return (
      <React.Fragment>
        <TableRow key={pi.id} sx={{ backgroundColor: 'color.paper', '& > *': { borderBottom: 'unset' } }}>
          <BodyTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ borderRadius: '3px', marginRight: '8px', padding: '3px', backgroundColor: 'color.button' }}
              onClick={() => setOpen(!open)}>
              {open ? (
                <CloseCollapse fill={theme.palette.text.primary} />
              ) : (
                <OpenCollapse fill={theme.palette.text.primary} />
              )}
            </IconButton>
            {pi.name}
          </BodyTableCell>
          <BodyTableCell>
            {((piMetrics.featureStoryCount / piMetrics.totalStoryCount) * 100 || 0).toFixed()}%
          </BodyTableCell>
          <BodyTableCell>0%</BodyTableCell>
          <BodyTableCell>{((piMetrics.bugStoryCount / piMetrics.totalStoryCount) * 100 || 0).toFixed()}%</BodyTableCell>
          <BodyTableCell>
            {((piMetrics.unknownStoryCount / piMetrics.totalStoryCount) * 100 || 0).toFixed()}%
          </BodyTableCell>
          <BodyTableCell>{piMetrics.teamIds.length}</BodyTableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: 'color.paper', width: '100%' }}>
          <TableCell style={{ padding: 0, backgroundColor: 'color.paper', width: '100%' }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table sx={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                {pi?.sprints
                  ?.filter((sprint) => sprint.metrics)
                  .map((sprint) => (
                    <TableRow key={sprint.id} sx={{ height: '40px' }}>
                      <InnerTableCell
                        sx={{
                          backgroundColor: 'color.paper',
                          marginLeft: '10px',
                          width: '3%',
                        }}
                      />
                      <InnerTableCell
                        sx={{
                          marginLeft: '10px',
                          width: '13.6%',
                          borderTopLeftRadius: '3px',
                          borderBottomLeftRadius: '3px',
                        }}>
                        {sprint.name}
                      </InnerTableCell>
                      <InnerTableCell>
                        {((sprint.metrics.featureStoryCount / sprint.metrics.totalStoryCount) * 100 || 0).toFixed()}%
                      </InnerTableCell>
                      <InnerTableCell>0%</InnerTableCell>
                      <InnerTableCell>
                        {((sprint.metrics.bugStoryCount / sprint.metrics.totalStoryCount) * 100 || 0).toFixed()}%
                      </InnerTableCell>
                      <InnerTableCell>
                        {((sprint.metrics.unknownStoryCount / sprint.metrics.totalStoryCount) * 100 || 0).toFixed()}%
                      </InnerTableCell>
                      <InnerTableCell
                        sx={{
                          width: '13.6%',
                          borderTopRightRadius: '3px',
                          borderBottomRightRadius: '3px',
                        }}>
                        {sprint?.metrics?.teamIds?.length}
                      </InnerTableCell>
                      <InnerTableCell
                        sx={{
                          backgroundColor: 'color.paper',
                          marginLeft: '10px',
                          width: '3%',
                        }}
                      />
                    </TableRow>
                  ))}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <InnerTableCell>Increment</InnerTableCell>
          <InnerTableCell>Features</InnerTableCell>
          <InnerTableCell>Maintenance</InnerTableCell>
          <InnerTableCell>Bugs</InnerTableCell>
          <InnerTableCell>Unknown</InnerTableCell>
          <InnerTableCell>Teams</InnerTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {programIncrements.map((pi) => (
          <Row pi={pi} key={pi.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default MetricTable;
