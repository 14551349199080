import React from 'react';
import {
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import { styled } from '@mui/system';
import CloseCollapse from '../../../../assets/images/CloseCollapse';
import OpenCollapse from '../../../../assets/images/OpenCollapse';
import LinearWithValueLabel from './LinearWithValueLabel';

export const InnerTableCell = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.color.table,
  fontSize: '14px',
  fontWeight: 'normal',
  width: '20%',
  padding: '10px',
  borderBottom: 0,
}));

export const BodyTableCell = styled(MuiTableCell)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '8px',
  borderBottom: 0,
}));

const Feature = ({ status, name }) => {
  return (
    <Paper elevation={0} sx={{ display: 'flex', backgroundColor: 'color.table', margin: '10px', height: '40px' }}>
      <Grid
        container
        alignItems={'center'}
        justifyContent="flex-start"
        sx={{ paddingLeft: '12px', paddingRight: '12px' }}>
        <Grid item xs={6}>
          <Typography align="left" color="textSecondary">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <LinearWithValueLabel value={(Math.random() * 100).toFixed()} />
        </Grid>
        <Grid item xs={2} align="left" sx={{ paddingLeft: '20px' }}>
          <Chip
            label={status}
            sx={{
              backgroundColor: colorForStatus(status),
              height: '16px',
              fontWeight: 'bold',
              borderRadius: '3px',
              color: 'white',
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const Row = ({ okr }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow key={okr.id} sx={{ backgroundColor: 'color.paper', borderBottom: 'none' }}>
        <BodyTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ borderRadius: '3px', marginRight: '8px', padding: '3px', backgroundColor: 'color.button' }}
            onClick={() => setOpen(!open)}>
            {open ? (
              <CloseCollapse fill={theme.palette.text.primary} />
            ) : (
              <OpenCollapse fill={theme.palette.text.primary} />
            )}
          </IconButton>
          {okr.name}
        </BodyTableCell>
        <BodyTableCell>{okr.id}</BodyTableCell>
        <BodyTableCell>{okr.features}</BodyTableCell>
        <BodyTableCell>{okr.progress}</BodyTableCell>
        <BodyTableCell>{okr.due}</BodyTableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: 'color.paper', width: '100%' }}>
        <TableCell align="center" style={{ padding: 0, backgroundColor: 'color.paper', width: '100%' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Feature status="IN PROGRESS" name="Account Owner File Generation (Investment Asset Migration)" />
            <Feature status="TO DO" name="Document Renderer Extension" />
            <Feature status="DONE" name="Investment Costs and Charges (Ex-Ante)" />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const colorForStatus = (status) => {
  switch (status) {
    case 'IN PROGRESS':
      return '#edaa47';
    case 'TO DO':
      return '#cc5348';
    case 'DONE':
      return '#4f74cc';
  }
};
const OKRTable = ({ programIncrements }) => {
  const piMetrics = [
    {
      id: 1,
      name: 'Improve time to market',
      features: '12/21 Done',
      progress: 60,
      due: 'PI-3',
    },
    {
      id: 2,
      name: 'Increase market share',
      features: '30/30 Done',
      progress: 100,
      due: 'PI-4',
    },
    {
      id: 3,
      name: 'Increase revenue',
      features: '22/34 Done',
      progress: 40,
      due: 'PI-2',
    },
    {
      id: 4,
      name: 'Reduce operating costs',
      features: '4/14 Done',
      progress: 20,
      due: 'PI-4',
    },
  ];

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <InnerTableCell sx={{ width: '45%' }}>Metric</InnerTableCell>
          <InnerTableCell sx={{ width: '10%' }}>ID</InnerTableCell>
          <InnerTableCell sx={{ width: '15%' }}>Features</InnerTableCell>
          <InnerTableCell sx={{ width: '15%' }}>Done (%)</InnerTableCell>
          <InnerTableCell sx={{ width: '15%' }}>Quarter Due</InnerTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {piMetrics.map((okr) => (
          <Row okr={okr} key={okr.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default OKRTable;
