import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const ConfirmationDialog = (props) => {
  const { handleOk, handleCancel, open, title, text, children } = props;

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">{text}</Typography>
      </DialogContent>
      <DialogActions>
        {children ? (
          children
        ) : (
          <div>
            <Button onClick={handleCancel} color="primary">
              CANCEL
            </Button>
            <Button onClick={handleOk} color="primary" data-testid="ConfirmationDialog-OK">
              OK
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
