function createData(item, teams) {
  const { id, name, valueStream, portfolioTag } = item;
  const programmeTeams = teams.filter((team) => team.programmes?.includes(id));

  return {
    id,
    name,
    valueStream: valueStream?.name,
    portfolioTag,
    teams: programmeTeams?.length || 0,
  };
}

export function getRowsData(data, teams) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, teams));
  }
  return rows;
}
