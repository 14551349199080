import * as React from 'react';

function SvgDelivery(props) {
  return (
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd">
        <rect x={1.5} y={1.5} width={7} height={10.5} rx={1.7} fill={props.fill} />
        <rect x={1.5} y={13.3} width={7} height={6.997} rx={1.7} fill={props.fill} />
        <rect x={9.8} y={9.1} width={10.7} height={11.197} rx={1.7} fill={props.fill} />
        <rect x={9.8} y={1.5} width={10.7} height={6.5} rx={1.7} fill={props.fill} />
      </g>
    </svg>
  );
}

export default SvgDelivery;
