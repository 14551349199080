import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from './styles';
import { openDrawer } from '../../../reactiveVariables';
import { useQuery } from '@apollo/client';
import { GET_CONNECTORS } from '../../../containers/Settings/GraphQL/connectors';
import Grow from '@mui/material/Grow';
import EntityTable from '../../Common/EntityTable';
import { headCells } from './constants';
import { getRowsData } from './helper';
import { exportCSVData, getFieldValue } from 'utils/export';

const useStyles = makeStyles(styles);

const Integrations = ({ searchQuery }, ref) => {
  const classes = useStyles();
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);

  useImperativeHandle(ref, () => ({
    export: () => exportIntegration(),
  }));

  const exportIntegration = useCallback(() => {
    const data = connectors?.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Value Streams');
  }, [connectors]);

  const onRowClick = (event, row) => {
    const connector = connectors.find((connector) => connector?.id === row.id);
    openDrawer(connector, 'integration');
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          headCells={headCells}
          rows={getRowsData(connectors)}
          searchQuery={searchQueryLowerCase}
          expand={false}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Integrations);
