export const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    display: 'block',
    height: `calc(100% - 64px)`,
    overflow: 'auto',
    scrollbarGutter: 'stable',
    backgroundColor: theme.palette.color.background,
    padding: '24px',
    paddingBottom: '0px',
  },
  contentProgramBoard: {
    // overflow: 'auto',
  },
  contentIdeation: {
    padding: 0,
    // overflow: 'auto',
    height: '100%',
  },
  stickyHeader: {
    paddingTop: '1px',
  },
});
