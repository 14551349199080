import { DateTime } from 'luxon';
import { showNotification } from '@mantine/notifications';

export const dragFeatureEnd = (event, updateFeature) => {
  if (!event || !event.over) return;

  const feature = event.active?.data?.current;
  const team = event.over?.data?.current?.team;
  const sprint = event.over?.data?.current?.sprint;

  if (feature.teamId === team.id && feature.committedSprint === sprint.id) return;

  const sprintName = sprint.name.toLowerCase().includes('sprint') ? sprint.name : `sprint ${sprint.name}`;
  const message = `${feature.committedSprint !== sprint.id ? sprintName : ''}
    ${
      feature.teamId !== team.id
        ? feature.committedSprint !== sprint.id
          ? `and team ${team.name}`
          : `team ${team.name}`
        : ''
    }`;

  updateFeature({
    variables: {
      feature: { teamId: team.id, committedSprint: sprint.id },
      featureId: feature.id,
    },
    optimisticResponse: {
      feature: { ...feature, teamId: team.id, committedSprint: sprint.id, updatedAt: DateTime.local().toISO() },
    },
  })
    .then(() => {
      showNotification({
        title: 'Feature Updated',
        message: `Feature ${feature.id} moved to ${message}`,
      });
    })
    .catch(() => {
      showNotification({
        title: 'Error',
        message: `Error when moving feature ${feature.id} to ${message}`,
        color: 'red',
      });
    });
};

export const dragDependencyEnd = (event, updateDependency) => {
  if (!event || !event.over) return;

  const dependency = event.active?.data?.current;
  const team = event.over?.data?.current?.team;
  const sprint = event.over?.data?.current?.sprint;

  if (dependency.teamId === team.id && dependency.neededBySprint === sprint.id) return;

  const sprintName = sprint.name.toLowerCase().includes('sprint') ? sprint.name : `sprint ${sprint.name}`;
  const message = `${dependency.neededBySprint !== sprint.id ? sprintName : ''}
    ${
      dependency.teamId !== team.id
        ? dependency.neededBySprint !== sprint.id
          ? `and team ${team.name}`
          : `team ${team.name}`
        : ''
    }`;

  updateDependency({
    variables: {
      dependency: { teamId: team.id, neededBySprint: sprint.id },
      dependencyId: dependency.id,
    },
    optimisticResponse: {
      dependency: { ...dependency, teamId: team.id, neededBySprint: sprint.id, updatedAt: DateTime.local().toISO() },
    },
  })
    .then(() => {
      showNotification({
        title: 'Dependency Updated',
        message: `Dependency ${dependency.id} moved to ${message}`,
      });
    })
    .catch(() => {
      showNotification({
        title: 'Error',
        message: `Error when moving dependency ${dependency.id} to ${message}`,
        color: 'red',
      });
    });
};

const teamOrder = ['Sportsbook', 'Trading', 'Core Compliance', 'Retail Omni', 'Gaming', 'Platform'].reverse();

export const sortTeams = (team) => {
  if (!team.board || team.board === '0') return -1;
  if (teamOrder.indexOf(team.name) > -1) return teamOrder.indexOf(team.name) + 1;
  return 0;
};

export const canScroll = (element) => element.localName !== 'html' && element.localName !== 'main';
