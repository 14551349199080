import * as React from 'react';

function SvgRisks(props) {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.2C0 17.6418 0.358172 18 0.8 18C1.24183 18 1.6 17.6418 1.6 17.2V9.82059H7.86681C8.33445 9.82059 8.73965 10.1447 8.84238 10.6009L8.99762 11.2903C9.10035 11.7465 9.50555 12.0706 9.97319 12.0706H15C15.5523 12.0706 16 11.6229 16 11.0706V3.27647C16 2.72419 15.5523 2.27647 15 2.27647H10.4552C10.2926 2.27647 10.1374 2.23725 10 2.16691V7.5C10 7.77614 9.77614 8 9.5 8C9.22386 8 9 7.77614 9 7.5V0.244572C8.82298 0.0910673 8.59266 0 8.34476 0H1C0.447715 0 0 0.447716 0 1V17.2Z"
      />
    </svg>
  );
}

export default SvgRisks;
