import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Divider,
  Group,
  Paper,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import SvgInfo from '../../../../assets/images/Info';
import SvgAdd from '../../../../assets/images/Add';
import React, { useState } from 'react';
import { Display2 } from '../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import CreateKeyResultModal from '../CreateKeyResultModal';
import { DATE_FORMAT } from '../../../../utils/timeUtils';
import { date } from '../../../../utils/helpers';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../../Settings/GraphQL/users';
import CreateObjectiveModal from '../CreateObjectiveModal';
import { mean } from 'lodash';
import { calculateOutcome, getRagColor, getRagPercentage, getUserName, outcomeColor } from '../utils';

const ObjectiveList = ({ objectives, vsColor, onObjectiveClick, openObjective, valueStreamId }) => {
  const theme = useMantineTheme();
  const [openCreate, setOpenCreate] = useState(false);

  return (
    <ScrollArea style={{ height: 'calc(100vh - 204px)' }} type="scroll">
      <Group justify="space-between">
        <Group gap={8}>
          <Title order={5}>Objectives ({objectives.length})</Title>
        </Group>
        <Button
          onClick={() => setOpenCreate(true)}
          rightSection={<SvgAdd stroke={theme.other.colours.primary} />}
          variant="default"
          size="sm">
          Add new
        </Button>
      </Group>
      <Stack gap={8} mt={16} pb={8}>
        {objectives.map((objective) => (
          <ObjectiveCard
            objective={objective}
            isSelected={objective.id === openObjective.id}
            onSelectObjective={onObjectiveClick}
          />
        ))}
      </Stack>
      {openCreate && (
        <CreateObjectiveModal
          color={vsColor}
          open={openCreate}
          close={() => setOpenCreate(false)}
          valueStream={valueStreamId}
        />
      )}
    </ScrollArea>
  );
};

const ObjectiveCard = ({ objective, isSelected, onSelectObjective }) => {
  const theme = useMantineTheme();
  const [openCreate, setOpenCreate] = useState(false);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const owner = users.find((user) => user.id === objective.owner) || {};
  const color = Math.floor(objective.owner.charCodeAt(0) * 167772).toString(16);
  const outcome = Math.round(mean(objective.keyResults.map((keyResult) => calculateOutcome(keyResult)))) || 0;
  const rags = objective.keyResults.map((keyResult) => getRagPercentage(keyResult));
  const ragPercentage = Math.round(mean(rags)) || 0;

  return (
    <Paper
      bg={theme.other.colours.paper}
      withBorder
      p="8px 12px"
      radius={8}
      onClick={() => onSelectObjective(objective)}
      style={{ cursor: 'pointer', ...(isSelected && { borderColor: theme.other.colours.buttonIcon }) }}>
      <Group preventGrowOverflow justify="space-between" wrap="nowrap">
        <Checkbox
          cursorType="pointer"
          checked={isSelected}
          label={objective.name}
          style={{ cursor: 'pointer', maxWidth: '75%' }}
          onClick={() => onSelectObjective(objective)}
        />
        <Display2 bgColor={getRagColor(ragPercentage, theme)}>{outcome}%</Display2>
      </Group>
      <Group gap={8} mt={12}>
        <Tooltip label={getUserName(owner)}>
          <Avatar size="sm" color={`#${color}`}>
            {owner.firstName?.charAt(0)}
          </Avatar>
        </Tooltip>
        <Text size="xs">{date(objective.targetDate).toFormat(DATE_FORMAT.date)}</Text>
        {/*<Display2 bgColor={theme.other.colours.red}>Unlikely</Display2>*/}
      </Group>
      <Divider mt={12} />
      <Paper bg={theme.other.colours.paper} withBorder mt={12} p="8px 12px">
        <Group justify="space-between">
          <Group>
            <Badge autoContrast radius="5px" p={4} color={theme.other.colours.control}>
              {objective.keyResults.length}
            </Badge>
            <Text>Key Results</Text>
          </Group>
          <ActionIcon variant="transparent" color="primary" aria-label="Settings" onClick={() => setOpenCreate(true)}>
            <SvgAdd stroke={theme.other.colours.secondary} />
          </ActionIcon>
        </Group>
      </Paper>
      {openCreate && (
        <CreateKeyResultModal open={openCreate} close={() => setOpenCreate(false)} objective={objective} />
      )}
    </Paper>
  );
};

export default ObjectiveList;
