import * as React from 'react';

const SvgAttention = (props) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.688 0 0 2.688 0 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6ZM5 3a1 1 0 0 1 2 0v3a1 1 0 0 1-2 0V3Zm1 4.8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
);

export default SvgAttention;
