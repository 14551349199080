import { alpha } from '@mui/material/styles';

const drawerWidth = 240;
const pinkBackgroundColor = 'rgba(255, 48, 194, 0.08)';

export const styles = (theme) => ({
  root: {
    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.color.menu}`,
    zIndex: 1000,
  },
  drawerPaper: {
    zIndex: 1,
    position: 'relative',
    width: drawerWidth,
    backgroundColor: '#fafafa',
    borderRight: '0',
    height: '100%',
    overflow: 'auto',
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    height: '100%',
    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.color.menu}`,
  },
  link: {
    textDecoration: 'none',
    paddingRight: 0,
    paddingLeft: 20,
    color: theme.palette.color.warmGrey,
    '& span': {
      fontSize: 12,
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.color.warmGrey,
    },
  },
  logoText: {
    letterSpacing: '-0.14px',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.color.purpulishPink,
    fontSize: 20,
    fontWeight: 600,
  },
  linkOpen: {
    '& span': {
      fontSize: 14,
    },
  },
  linkItemOpen: {
    padding: '0 15px',
  },
  icon: {
    textDecoration: 'none',
    paddingRight: 0,
    cursor: 'pointer',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '75px',
  },
  toolbarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  toolbarRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  circle: {
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    backgroundColor: 'red',
  },
  version: {
    textDecoration: 'none',
    paddingRight: 0,
    paddingLeft: 10,
    color: 'black',
    bottom: 0,
    textAlign: 'start',
  },
  listItem: {
    padding: '0 10px',
    '& path': {
      '&.dimmed': {
        fill: alpha(theme.palette.color.greyIcon, 0.3),
      },
      '&.normal': {
        fill: theme.palette.color.greyIcon,
      },
    },
    '&.Mui-selected': {
      backgroundColor: `${
        theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.color.background
      }`,
      color: theme.palette.color.purpulishPink,
      '& path': {
        '&.dimmed': {
          fill: '#DC98D5',
        },
        '&.normal': {
          fill: '#C952BD',
        },
      },
      '& svg': {
        opacity: 1,
      },
      '& g': {
        fill: theme.palette.color.purpulishPink,
      },
    },
    '&:hover': {
      color: theme.palette.color.purpulishPink,
      backgroundColor: `${
        theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.color.background
      }`,
      '& svg': {
        opacity: 1,
      },
      '& path': {
        '&.dimmed': {
          fill: '#DC98D5',
        },
        '&.normal': {
          fill: '#C952BD',
        },
      },
      '& g': {
        fill: theme.palette.color.purpulishPink,
      },
    },
  },
  listItemOpen: {
    paddingLeft: 10,
    borderRadius: 6,
    '& path': {
      '&.dimmed': {
        fill: alpha(theme.palette.color.greyIcon, 0.3),
      },
      '&.normal': {
        fill: theme.palette.color.greyIcon,
      },
    },
    '&.Mui-selected': {
      backgroundColor: pinkBackgroundColor,
      color: theme.palette.color.darkishPink,
      '& path': {
        '&.dimmed': {
          fill: alpha(theme.palette.color.purpulishPink, 0.3),
        },
        '&.normal': {
          fill: theme.palette.color.purpulishPink,
        },
      },
    },
    '&:hover': {
      backgroundColor: pinkBackgroundColor,
    },
  },
  avatarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 70,
  },
  avatarCircle: {
    backgroundColor: theme.palette.color.icon,
    opacity: 0.75,
    borderRadius: '50%',
    width: 44,
    height: 44,
    margin: '0 0 8px',
    padding: 11,
    '& svg': {
      opacity: 0.5,
    },
  },
  avatarCircleSelected: {
    backgroundColor: pinkBackgroundColor,
    borderRadius: '50%',
    width: 44,
    height: 44,
    margin: '0 0 8px',
    padding: 11,
    '& svg': {
      opacity: 0.5,
    },
  },
  avatarText: {
    paddingTop: 5,
  },
});
