import * as React from 'react';

function SvgDiamondDate(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.27959 6.9217L5.61666 2.33491C5.81855 2.1214 6.10238 2 6.39969 2H11.6003C11.8976 2 12.1815 2.1214 12.3833 2.33491L16.7168 6.91791C16.8989 7.11045 17 7.36307 17 7.62534C17 7.86752 16.9138 8.10214 16.7561 8.28885L9.57172 16.7966C9.46297 16.9254 9.30113 17 9.13038 17C8.96302 17 8.80405 16.9284 8.69529 16.8039L1.25232 8.28867C1.08948 8.10237 1 7.86531 1 7.62018C1 7.36123 1.09984 7.1118 1.27959 6.9217Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgDiamondDate;
