import * as React from 'react';

function SvgDiamond(props) {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy={15} r={15} fill="#000" fillOpacity={0.04} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 15l-7-8-7 8 7 8 7-8z" fill="#95949C" />
      <title>Diamond</title>
    </svg>
  );
}

export default SvgDiamond;
