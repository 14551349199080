import { getMappedValues } from '../../../utils/tableUtils';
import { Typography } from '@mui/material';
import { getFeatureBackgroundColor } from '../../../utils/piColors';
import React from 'react';

function createData(item, theme) {
  const { id, title, strategicTheme } = item;

  return {
    id,
    title,
    strategicTheme,
  };
}

export function getRowsData(data, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, theme));
  }
  return rows;
}
