import * as React from 'react';

function SvgProgramme(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_678_35314"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_678_35314)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.66667 7.225C5.66667 7.58398 5.96514 7.875 6.33333 7.875H8.66667V13.075H6.5C6.31591 13.075 6.16667 13.2205 6.16667 13.4V15.675C6.16667 15.8545 6.31591 16 6.5 16H11.5C11.6841 16 11.8333 15.8545 11.8333 15.675V13.4C11.8333 13.2205 11.6841 13.075 11.5 13.075H9.33333V7.875H11.6667C12.0349 7.875 12.3333 7.58398 12.3333 7.225V6.575H13.6667V9.175H12.6667C12.4826 9.175 12.3333 9.32051 12.3333 9.5V11.775C12.3333 11.9545 12.4826 12.1 12.6667 12.1H15.6667C15.8508 12.1 16 11.9545 16 11.775V9.5C16 9.32051 15.8508 9.175 15.6667 9.175H14.3333V6.59167C14.3333 6.22348 14.0349 5.925 13.6667 5.925H12.3333V2.65C12.3333 2.29101 12.0349 2 11.6667 2H6.33333C5.96514 2 5.66667 2.29101 5.66667 2.65V5.925L4.33333 5.925C3.96514 5.925 3.66667 6.22348 3.66667 6.59167V9.175H2.33333C2.14924 9.175 2 9.32051 2 9.5V11.775C2 11.9545 2.14924 12.1 2.33333 12.1H5.33333C5.51743 12.1 5.66667 11.9545 5.66667 11.775V9.5C5.66667 9.32051 5.51743 9.175 5.33333 9.175H4.33333V6.575H5.66667V7.225Z"
        />
      </g>
    </svg>
  );
}

export default SvgProgramme;
