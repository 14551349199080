import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FeatureProgression from 'components/Dashboard/FeatureProgression';
import ToolBarSummary from '../shared/ToolBar';
import { styles } from '../Roadmap/styles';
import { useQuery } from '@apollo/client';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_SELECTED_PI_FULL } from '../../Settings/ProgramIncrement/graphql';
import { GET_BACKLOGS } from '../../Settings/GraphQL/backlogs';
import { GET_FEATURES_FOR_PI } from '../../Product/Features/graphql';

const useStyles = makeStyles(styles);

const Progression = () => {
  const classes = useStyles();
  const [backlog, setBacklog] = useState(0);
  const [selectedTeam, setSelectedTeam] = useState(0);

  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);

  const filteredFeatures = backlog ? features.filter((feature) => feature.backlogId === backlog) : features;

  const onBacklogChange = (event) => setBacklog(event.target.value);

  const onTeamChange = (event) => setSelectedTeam(event.target.value);

  return (
    <div className={classes.container}>
      <ToolBarSummary
        backlog={backlog}
        backlogs={backlogs}
        team={selectedTeam}
        features={features}
        selectedPI={selectedPiFull?.id}
        teams={teams}
        onBacklogChange={onBacklogChange}
        onTeamChange={onTeamChange}
        isRoadmap={true}
      />
      <FeatureProgression
        features={filteredFeatures}
        pi={selectedPiFull || {}}
        classes={classes}
        teams={teams}
        team={selectedTeam}
      />
    </div>
  );
};

export default Progression;
