export const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
  },
  appBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 20,
    paddingLeft: 0,
    minHeight: 50,
    fontSize: 20,
  },
});
