import { IdeationDonePopupMessage, IdeationPopupMessage } from '../constants';
import { orderArrayBy } from '../../../utils/helpers';

export const descSortOptions = [
  'wsjf',
  'riskReduction',
  'timeCriticality',
  'size',
  'businessValue',
  'costOfDelay',
  'program_increment.name',
];

export const getSortedDesc = (data, field) =>
  data.reduce((previousValue, currentValue) => {
    currentValue[field] ? previousValue.unshift(currentValue) : previousValue.push(currentValue);

    return previousValue;
  }, []);

export const getFieldsSortedDesc = (field) => descSortOptions.includes(field);

export const sortFeaturesBy = (features, field) => {
  let data = [];
  data = orderArrayBy(features, field, 'asc'); // https://github.com/lodash/lodash/issues/4169
  if (getFieldsSortedDesc(field)) {
    data = getSortedDesc(data, field);
  }

  return data;
};

export const getColumnStyle = (style, isOver, theme) => {
  if (isOver)
    return {
      ...style,
      backgroundColor: theme.palette.color.column,
      height: 'calc(100% - 10px)',
    };

  return { ...style, height: 'calc(100% - 10px)' };
};

export const getColumns = (
  funnelFeatures,
  analysisFeatures,
  backlogFeatures,
  candidateFeatures,
  plannedFeatures,
  implementationFeatures,
  doneFeatures,
) => {
  return [
    { value: 'funnel', title: 'Funnel', data: funnelFeatures, isPerPI: false },
    {
      value: 'analysis',
      title: 'Analysis',
      data: analysisFeatures,
      isPerPI: false,
    },
    {
      value: 'backlog',
      title: 'Backlog',
      data: backlogFeatures,
      isPerPI: false,
    },
    {
      value: 'candidate',
      title: 'Candidate',
      data: candidateFeatures,
      isPerPI: true,
    },
    {
      value: 'planned',
      title: 'Planned',
      data: plannedFeatures,
      isPerPI: true,
    },
    {
      value: 'inProgress',
      title: 'In Progress',
      data: implementationFeatures,
      isPerPI: true,
    },
    { value: 'done', title: 'Done', data: doneFeatures, isPerPI: true },
  ];
};

export const getIdeationMessage = (done) => {
  return done ? IdeationDonePopupMessage : IdeationPopupMessage;
};

export const validateColumns = (stage, { acceptanceCriterias, okr, teamId, committedSprint }) => {
  switch (stage) {
    case 'backlog':
    case 'candidate':
      return !acceptanceCriterias || !okr;
    case 'planned':
    case 'inProgress':
      return !acceptanceCriterias || !okr || !teamId || !committedSprint;
    case 'funnel':
    case 'analysis':
    case 'done':
      return false;
  }
};
