import React, { forwardRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grow } from '@mui/material';
import { useTheme } from '@emotion/react';
import EntityTable from '../../../../components/Common/EntityTable';
import { BigAgileSelect } from '../../../../components/Common/Select/CustomSelect';
import { openDrawer } from '../../../../reactiveVariables';
import { GET_INITIATIVE_STATE_CONFIG } from '../../GraphQL/configuration';
import { GET_VALUE_STREAMS } from '../../Streams/graphql';
import { GET_ALL_INITIATIVES } from '../../../ValueStream/Initiatives/graphql';
import { UPDATE_CONFIGURATION_MANY } from '../../GraphQL/configuration';
import { getRowsData } from './helper';

export const headCells = [
  {
    id: 'rank',
    field: 'rank',
    label: 'Rank',
    disablePadding: false,
    filtering: false,
    numeric: true,
    align: 'left',
    width: '10%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    disablePadding: false,
    filtering: false,
    numeric: false,
    align: 'left',
    width: '30%',
  },
  {
    id: 'stateType',
    field: 'stateType',
    label: 'State type',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'inUse',
    field: 'inUse',
    label: 'In use',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
];

const Index = ({ additionalDataCallback }) => {
  const theme = useTheme();

  const { data: { initiativeStates = [] } = {}, refetch: refetchInitiativesStates } =
    useQuery(GET_INITIATIVE_STATE_CONFIG);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const [updateConfigurationsMany] = useMutation(UPDATE_CONFIGURATION_MANY);
  const [selectedValueStream, setSelectedValueStream] = useState({ name: '', id: '' });

  const valueStreamInitiativeStates = initiativeStates.filter((state) => state.value_stream === selectedValueStream.id);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  useEffect(() => {
    if (valueStreams.length > 0) {
      setSelectedValueStream({ name: valueStreams[0].name, id: valueStreams[0].id });
    }
  }, [valueStreams]);

  useEffect(() => {
    additionalDataCallback({
      valueStream: {
        name: selectedValueStream.name,
        id: selectedValueStream.id,
      },
    });
  }, [selectedValueStream]);

  const onRowClick = (event, row) => {
    const state = initiativeStates.find((state) => state?.id === row.id);
    const initiativeStateInUse = initiatives.find((initiative) => initiative.initiative_state === row.name);

    const additionalData = {
      valueStream: {
        name: selectedValueStream.name,
        id: selectedValueStream.id,
      },
      canDelete: !initiativeStateInUse,
    };

    openDrawer(state, 'initiativeState', additionalData);
  };

  const renderValue = () => {
    const truncateName = (name) => {
      return name.length > 23 ? name.substring(0, 23) + '...' : name;
    };

    return (
      <span
        style={{
          color: theme.palette.text.secondary,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        VS: <span style={{ color: theme.palette.text.primary }}>{truncateName(selectedValueStream.name)}</span>
      </span>
    );
  };

  const handleValueStreamChange = (e, id) => {
    const selectedStream = valueStreams.find((stream) => stream.id === id);
    setSelectedValueStream({ name: selectedStream.name, id: selectedStream.id });
  };

  const updateConfigurationsOrder = () => {
    refetchInitiativesStates();
  };

  const updateRank = (updatedRankOrder) => {
    const updates = updatedRankOrder.map((item, index) => ({
      where: { id: { _eq: item.id } },
      _set: { rank: index + 1 },
    }));

    updateConfigurationsMany({
      variables: { updates },
    }).then(() => {
      updateConfigurationsOrder();
    });
  };

  return (
    <Grow>
      <>
        <div
          style={{
            display: 'inline-block',
            marginBottom: '15px',
            minWidth: '150px',
            maxWidth: '300px',
          }}>
          <BigAgileSelect
            options={activeValueStreams}
            renderValue={renderValue}
            onChange={handleValueStreamChange}
            style={{
              width: '100%',
              '& .MuiSelect-select': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
        <div>
          <EntityTable
            handleRowClick={onRowClick}
            headCells={headCells}
            updateRank={updateRank}
            enableDragAndDrop
            rows={getRowsData(valueStreamInitiativeStates)}
          />
        </div>
      </>
    </Grow>
  );
};

export default forwardRef(Index);
