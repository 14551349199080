import * as React from 'react';

function SvgKeyResult(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="x" clipPath="url(#clip0_405_74231)">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00033 0.666668L9.98099 6.1994L15.3337 8L9.98099 9.80061L8.00033 15.3333L6.01966 9.80061L0.666992 8L6.01966 6.1994L8.00033 0.666668ZM3.33366 1.33333L3.87384 2.79315L5.33366 3.33333L3.87384 3.87352L3.33366 5.33333L2.79348 3.87352L1.33366 3.33333L2.79348 2.79315L3.33366 1.33333ZM13.2072 12.1265L12.667 10.6667L12.1268 12.1265L10.667 12.6667L12.1268 13.2068L12.667 14.6667L13.2072 13.2068L14.667 12.6667L13.2072 12.1265Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_74231">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgKeyResult;
