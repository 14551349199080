import React from 'react';
import { useTheme } from '@emotion/react';
import Select from '../Select';
import { BigAgileSelect } from '../Select/CustomSelect';
import { useQuery } from '@apollo/client';
import { GET_ALL_IDEAS } from '../../../containers/Form/graphql';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';

const SubmitterAndStateSelector = ({
  submittersValue,
  statesValue,
  usersValue,
  thinkTankValueStreamValue,
  thinkTankValueStreamChange,
  handleStateChange,
  handleSubmitterChange,
  handleUsersChange,
  valueStreams,
}) => {
  const { data: { ideas = [] } = {} } = useQuery(GET_ALL_IDEAS);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const creators = ideas
    .map((idea) => idea.idea.creator)
    .filter(Boolean)
    .filter((creator, index, self) => self.indexOf(creator) === index);

  const creatorValues = creators.map((creator) => ({ value: creator, label: creator }));

  const userValues = users.map((user) => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const theme = useTheme();

  const ValueStreamSelector = () => {
    const theme = useTheme();

    valueStreams = [{ id: 'All', name: 'All' }, ...valueStreams];

    const renderValue = (option) => (
      <span style={{ color: theme.palette.text.secondary }}>
        VS: <span style={{ color: theme.palette.text.primary }}>{option?.label}</span>
      </span>
    );

    return (
      <BigAgileSelect
        options={valueStreams}
        value={thinkTankValueStreamValue}
        onChange={(e, value) => {
          thinkTankValueStreamChange(value);
        }}
        renderValue={renderValue}
      />
    );
  };

  return (
    <>
      <div style={{ marginLeft: '70px', width: 'auto', display: 'inline-block' }}>{ValueStreamSelector()}</div>
      <Select
        title="Owner"
        placeholder="All"
        value={usersValue || []}
        onChange={(event, values) => handleUsersChange(values)}
        rootStyle={{ marginLeft: '17px', marginRight: '-22px' }}
        toggleStyle={{ borderRadius: '5px', backgroundColor: theme.palette.color.paper }}
        defaultValue={[0]}
        multiple={true}
        options={userValues}
      />
      <Select
        title="Submitter"
        placeholder="All"
        value={submittersValue || []}
        onChange={(event, values) => handleSubmitterChange(values)}
        rootStyle={{ marginLeft: '17px', marginRight: '-22px' }}
        toggleStyle={{ borderRadius: '5px', backgroundColor: theme.palette.color.paper }}
        defaultValue={[0]}
        multiple={true}
        options={creatorValues}
      />
      <Select
        title="State"
        placeholder="All"
        value={statesValue || []}
        onChange={(event, values) => handleStateChange(values)}
        rootStyle={{ marginLeft: '17px', marginRight: '-22px' }}
        toggleStyle={{ borderRadius: '5px', backgroundColor: theme.palette.color.paper }}
        defaultValue={[0]}
        multiple={true}
        options={[
          {
            label: 'New',
            value: 'New',
          },
          {
            label: 'In Analysis',
            value: 'In Analysis',
          },
          {
            label: 'Rejected',
            value: 'Rejected',
          },
          {
            label: 'Accepted',
            value: 'Accepted',
          },
        ]}
      />
    </>
  );
};

export default SubmitterAndStateSelector;
