import { useMemo } from 'react';
import { get, isEmpty, round } from 'lodash';

export const useDeliveryMetrics = (features, incrementIds) => {
  const getMetric = (feature, field) => {
    if (!incrementIds) {
      return get(feature?.metrics, field, 0);
    }
    return incrementIds.reduce(
      (total, incrementId) => total + get(feature.metrics?.metricsPerIncrement?.[incrementId], field, 0),
      0,
    );
  };

  const deferredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          !!feature.completedIncrements?.[incrementIds?.[0]] && feature.programIncrement !== incrementIds?.[0],
      ),
    [features, incrementIds],
  );

  const doneFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (!incrementIds || incrementIds.includes(feature.programIncrement)) &&
          feature.status === 'Done' &&
          !deferredFeatures.includes(feature),
      ).length,
    [features, incrementIds],
  );
  const toDoFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (!incrementIds || incrementIds.includes(feature.programIncrement)) &&
          feature.status === 'To Do' &&
          !deferredFeatures.includes(feature),
      ).length,
    [features, incrementIds],
  );
  const inProgressFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (!incrementIds || incrementIds.includes(feature.programIncrement)) &&
          feature.status === 'In Progress' &&
          !deferredFeatures.includes(feature),
      ).length,
    [features, incrementIds],
  );

  const doneStories = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyCount.done'), 0),
    [features, incrementIds],
  );

  const toDoStories = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyCount.toDo'), 0),
    [features, incrementIds],
  );

  const inProgressStories = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyCount.inProgress'), 0),
    [features, incrementIds],
  );

  const deferredStories = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyCount.deferred'), 0),
    [features, incrementIds],
  );

  const doneStoryPoints = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyPoints.done'), 0),
    [features, incrementIds],
  );

  const toDoStoryPoints = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyPoints.toDo'), 0),
    [features, incrementIds],
  );

  const inProgressStoryPoints = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyPoints.inProgress'), 0),
    [features, incrementIds],
  );

  const deferredStoryPoints = useMemo(
    () => features.reduce((total, feature) => total + getMetric(feature, 'storyPoints.deferred'), 0),
    [features, incrementIds],
  );

  return {
    doneFeatures,
    toDoFeatures,
    inProgressFeatures,
    deferredFeatures: deferredFeatures.length,
    doneStories,
    toDoStories,
    inProgressStories,
    deferredStories,
    totalStoryPoints: round(doneStoryPoints + toDoStoryPoints + inProgressStoryPoints + deferredStoryPoints, 1),
    doneStoryPoints: round(doneStoryPoints, 1),
    toDoStoryPoints: round(toDoStoryPoints, 1),
    inProgressStoryPoints: round(inProgressStoryPoints, 1),
    deferredStoryPoints: round(deferredStoryPoints, 1),
  };
};
