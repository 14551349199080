import React from 'react';
import BigAgileTable from '../../components/Common/BigAgileTable';
import { Route } from 'react-router-dom';
import Backlogs from '../../components/Settings/Backlogs';
import JiraConfig from '../../components/Settings/Integration';
import Configuration from './Configuration';

const getAdminRoutes = (user) => (
  <>
    <Route user={user} path="/admin/integration" element={<BigAgileTable type="integration" />} />
    <Route user={user} path="/admin/increments" element={<BigAgileTable type="increment" />} />
    <Route user={user} path="/admin/programmes" element={<BigAgileTable type="programme" />} />
    <Route user={user} path="/admin/teams" element={<BigAgileTable type="team" />} />
    <Route user={user} path="/admin/valueStreams" element={<BigAgileTable type="valueStream" />} />
    <Route user={user} path="/admin/projects" element={<BigAgileTable type="backlog" />} />
    <Route user={user} path="/admin/users" element={<BigAgileTable type="user" />} />
    <Route user={user} path="/admin/configuration" element={<Configuration />} />
  </>
);

export default getAdminRoutes;
