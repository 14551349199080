export const BUTTONS = {
  SAVE: 'SAVE',
  DISCARD: 'DISCARD',
  CANCEL: 'CANCEL',
};

export const RAG_STATUS = {
  GREEN: 'Green',
  AMBER: 'Amber',
  RED: 'Red',
};

export const DEV_STATUS = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  DONE: 'Done',
};

export const STAGE = {
  FUNNEL: 'Funnel',
  ANALYSIS: 'Analysis',
  BACKLOG: 'Backlog',
  CANDIDATE: 'Candidate',
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Done',
};

export const STAGE_VALUE = {
  FUNNEL: 'funnel',
  ANALYSIS: 'analysis',
  BACKLOG: 'backlog',
  CANDIDATE: 'candidate',
  PLANNED: 'planned',
  IN_PROGRESS: 'inProgress',
  DONE: 'done',
};

export const STAGE_SELECTS = [
  { label: 'Funnel', value: 'funnel' },
  { label: 'Analysis', value: 'analysis' },
  { label: 'Backlog', value: 'backlog' },
  { label: 'Candidate', value: 'candidate' },
  { label: 'Planned', value: 'planned' },
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Done', value: 'done' },
];

export const PRIORITY = [
  { key: 1, value: 'Very High' },
  { key: 2, value: 'High' },
  { key: 3, value: 'Medium' },
  { key: 4, value: 'Low' },
  { key: 5, value: 'Very Low' },
];

export const PRIORITY_LABELS = {
  1: 'Very High',
  2: 'High',
  3: 'Medium',
  4: 'Low',
  5: 'Very Low',
};
