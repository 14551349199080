import * as React from 'react';

function SvgHorizontalMore(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="... horizontal">
        <circle id="Ellipse 1" cx="10" cy="18" r="2" fill="#808080" />
        <circle id="Ellipse 3" cx="26" cy="18" r="2" fill="#808080" />
        <circle id="Ellipse 2" cx="18" cy="18" r="2" fill="#808080" />
      </g>
    </svg>
  );
}

export default SvgHorizontalMore;
