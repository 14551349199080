export const styles = (theme) => ({
  appBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 20,
    paddingLeft: 0,
    minHeight: 50,
    fontSize: 20,
  },
  addMilestoneButton: {
    marginRight: 15,
    height: 40,
    width: 150,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.color.purpulishPink,
    padding: '12px 14px',
    borderRadius: 22,
  },
  buttonText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeight.medium,
  },
  objectivesToggle: {
    fontSize: 13,
  },
  input: {
    color: theme.palette.color.slateGrey,
    borderRadius: 30,
    minWidth: 160,
    height: 40,
    '& .MuiSelect-select.MuiSelect-select': {
      display: 'flex',
      flexDirection: 'row-reverse',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.color.gunMetal,
    },
  },
  showAllDependencies: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
});
