import styled from '@emotion/styled';

import { lightColors } from '../../shared/styles/color';

export const NotesContainer = styled('div')({
  width: 320,
  padding: 12,
  border: `1px solid ${lightColors.tileBorder}`,
  borderRadius: 12,
  backgroundColor: 'white',
});

export const NotesArea = styled('div')({
  borderBottom: `1px solid ${lightColors.tileBorder}`,
  marginBottom: '10px',
  maxHeight: '280px',
  overflow: 'scroll',
});

export const Note = styled('div')({
  marginBottom: '10px',
  display: 'flex',
});

export const NoteInformationContainer = styled('div')({
  width: '100%',
});
