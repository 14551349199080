import * as React from 'react';

function SvgDependencies(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8125 5.875C16.7445 5.875 17.5 5.11948 17.5 4.1875C17.5 3.25552 16.7445 2.5 15.8125 2.5C14.8805 2.5 14.125 3.25552 14.125 4.1875C14.125 5.11948 14.8805 5.875 15.8125 5.875ZM15.8125 7.375C17.5729 7.375 19 5.94791 19 4.1875C19 2.42709 17.5729 1 15.8125 1C14.2837 1 13.0062 2.07633 12.6966 3.51257H7.74996C5.71175 3.51257 4.03184 5.04661 3.80181 7.0231C2.22329 7.21349 1 8.55764 1 10.1875C1 11.8391 2.2562 13.1974 3.86529 13.3589C4.25323 15.1476 5.84513 16.4874 7.74996 16.4874H12.6966C13.0062 17.9237 14.2837 19 15.8125 19C17.5729 19 19 17.5729 19 15.8125C19 14.0521 17.5729 12.625 15.8125 12.625C14.2309 12.625 12.9184 13.7769 12.668 15.2874H7.74996C6.47279 15.2874 5.39713 14.4246 5.07408 13.2501C6.40312 12.866 7.375 11.6403 7.375 10.1875C7.375 8.7083 6.36742 7.46443 5.00115 7.10479C5.18761 5.75334 6.34725 4.71257 7.74996 4.71257H12.668C12.9184 6.22315 14.2309 7.375 15.8125 7.375ZM15.8125 17.5C16.7445 17.5 17.5 16.7445 17.5 15.8125C17.5 14.8805 16.7445 14.125 15.8125 14.125C14.8805 14.125 14.125 14.8805 14.125 15.8125C14.125 16.7445 14.8805 17.5 15.8125 17.5ZM5.875 10.1875C5.875 11.1195 5.11948 11.875 4.1875 11.875C3.25552 11.875 2.5 11.1195 2.5 10.1875C2.5 9.25552 3.25552 8.5 4.1875 8.5C5.11948 8.5 5.875 9.25552 5.875 10.1875Z"
      />
    </svg>
  );
}

export default SvgDependencies;
