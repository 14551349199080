import * as React from 'react';

const SvgPriorityUrgent = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM4.59337 3.09416C4.53653 2.73245 4.69677 2.37097 5.00296 2.17019C5.62356 1.76324 6.42644 1.76324 7.04704 2.17019C7.35323 2.37097 7.51347 2.73245 7.45663 3.09416L6.92031 6.50712C6.85104 6.94793 6.47122 7.27273 6.025 7.27273C5.57878 7.27273 5.19896 6.94793 5.12969 6.50712L4.59337 3.09416ZM5 8.97727C5 8.41244 5.45789 7.95455 6.02273 7.95455C6.58756 7.95455 7.04545 8.41244 7.04545 8.97727C7.04545 9.54211 6.58756 10 6.02273 10C5.45789 10 5 9.54211 5 8.97727Z"
      fill="#FF4189"
    />
  </svg>
);

export default SvgPriorityUrgent;
