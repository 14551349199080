import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_STORIES_FOR_FEATURE } from './graphql';
import { Divider, Group, Text, UnstyledButton } from '@mantine/core';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { find, isEmpty } from 'lodash';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS, GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_CONNECTORS } from '../../Settings/GraphQL/connectors';
import { ClickableCard, DisplayID, IDWrapper } from '../../../components/Common/Elements';
import { getSprint, mapSprint } from '../../../components/Features/FeatureDetails/StoriesFieldGroup';
import { useTheme } from '@emotion/react';
import SvgStories from '../../../assets/images/Stories';

const getStatusCategoryColor = (category, theme) => {
  switch (category) {
    case 'Done':
      return theme.palette.color.done;
    case 'In Progress':
      return theme.palette.color.inProgress;
    case 'To Do':
      return theme.palette.color.toDo;
  }
};

const Stories = ({ feature, setLoading }) => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);

  const [getStories, { loading, data: { stories = [] } = {} }] = useLazyQuery(GET_STORIES_FOR_FEATURE, {
    variables: { connectorId: feature.team?.connectorId, epicId: feature.epicId, project: feature.team?.projectId },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'rest' },
  });

  const theme = useTheme();

  const handleJiraClick = (story) => {
    const pi = find(programIncrements, { id: feature.programIncrement }) || {};
    const team = find(teams, { id: feature.teamId }) || {};
    const connectorId = team.connectorId || pi.defaultConnectorId;
    const connector = find(connectors, { id: connectorId }) || {};

    const url =
      connector.type === 'azureDevops'
        ? `${connector.apiUrl}/${feature.team?.projectId}/_workitems/edit/${story.key}`
        : connector.apiUrl + '/browse/' + story.key;

    window.open(url, '_blank');
  };

  useEffect(() => {
    getStories();
  }, [getStories]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  if (isEmpty(stories)) return null;

  return (
    <>
      {stories.map((story) => {
        const jiraSprintId = getSprint(story);
        const sprintAndTeam = mapSprint(jiraSprintId, sprints);
        const team = find(teams, { id: Number(sprintAndTeam?.teamId) });

        return (
          <ClickableCard key={story.title} onClick={() => handleJiraClick(story)}>
            <IDWrapper>
              <SvgStories color={theme.palette.color.buttonIcon} />
              <DisplayID span={true} size="xs">
                {story.key}
              </DisplayID>
            </IDWrapper>
            <div style={{ padding: '10px' }}>
              <Text lineClamp={1} fw={450} size="sm">
                {story.name || story.fields?.summary}
              </Text>
              <Group>
                <Text span={true} size="xs">
                  Story Points: {story.storyPoints}
                </Text>
                <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
                <Text span={true} size="xs">
                  Sprint: {jiraSprintId ? (sprintAndTeam ? sprintAndTeam.sprint.name : 'Unknown') : 'Backlog'}
                </Text>
                <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
                <Text
                  span={true}
                  c={getStatusCategoryColor(story.fields?.status?.statusCategory?.name, theme)}
                  size="xs">
                  Status: {story.status || story.fields?.status?.name}
                </Text>
                {!!team && (
                  <>
                    <Divider orientation="vertical" sx={{ marginTop: '3px', marginBottom: '3px' }} />
                    <Text span={true} size="xs">
                      Team: {team.name}
                    </Text>
                  </>
                )}
              </Group>
            </div>
          </ClickableCard>
        );
      })}
    </>
  );
};

export default Stories;
