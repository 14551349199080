import { find, get, last, mean, orderBy, sumBy, takeWhile } from 'lodash';
import { getPiDates, date } from '../../../utils/helpers';

export const getRoadmapProjection = (filteredFeatures, filteredPIs) => {
  const doneFeatures = filteredFeatures.filter((feature) => feature.status === 'Done');
  const averagePiLength = Math.round(mean(filteredPIs.map((pi) => getPiDates(pi).length)));

  const velocity =
    Math.round(
      filteredPIs
        .filter((pi) => pi.status === 'complete')
        .map((pi) => {
          const piFeatures = doneFeatures.filter((feature) => feature.programIncrement === pi.id);
          return piFeatures.length;
        })
        .reduce((velocity, piVelocity) => (velocity > 0 ? (velocity + piVelocity) / 2 : piVelocity), 0),
    ) || 1;

  const plannedFeatures = orderBy(
    filteredFeatures.filter((feature) => {
      const pi = find(filteredPIs, { id: feature.programIncrement });
      return !pi || pi.status !== 'complete';
    }),
    ['programIncrement', (item) => get(item, 'wsjf', 0) || -1, (item) => get(item, 'priority')],
    ['asc', 'desc', 'asc'],
  ).map((feature, index) => ({ ...feature, order: index + 1 }));

  const notAssignedFeatures = plannedFeatures.filter((feature) => !feature.programIncrement);
  const unassignedFeatures = [...notAssignedFeatures];

  const getForecastFeatures = (features, piFeatures) =>
    unassignedFeatures.splice(0, velocity - (piFeatures?.length || 0));

  let forecastedFeatures = filteredPIs
    .filter((pi) => pi.status === 'planning')
    .map((pi) => {
      const { startDate, endDate } = getPiDates(pi);
      const piFeatures = plannedFeatures
        .filter((feature) => feature.programIncrement === pi.id)
        .map((feature) => ({ ...feature, planned: true }));

      if (piFeatures.length < velocity) {
        return getForecastFeatures(unassignedFeatures, piFeatures).map((feature) => ({
          ...feature,
          startDate,
          endDate,
        }));
      } else {
        return [];
      }
    })
    .flat();

  let previousPiEndDate = last(filteredPIs)?.sprints.length ? date(getPiDates(last(filteredPIs)).endDate) : null;

  while (unassignedFeatures.length && velocity > 0) {
    const startDate = previousPiEndDate && previousPiEndDate.plus({ days: 1 });
    const endDate = previousPiEndDate && previousPiEndDate.plus({ days: averagePiLength + 1 });
    const addedFeatures = getForecastFeatures(unassignedFeatures).map((feature) => ({
      ...feature,
      startDate,
      endDate,
    }));

    forecastedFeatures = forecastedFeatures.concat(addedFeatures);
    previousPiEndDate = previousPiEndDate && previousPiEndDate.plus({ days: averagePiLength + 1 });
  }

  return forecastedFeatures;
};
