import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Metric } from 'components/Dashboard/ChartElements';
import React, { useMemo } from 'react';
import { styles } from './styles';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from '@apollo/client';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import { useTheme } from '@mui/styles';
import { find, truncate, get } from 'lodash';

const useStyles = makeStyles(styles);
const colors = {
  toDoCount: '#ff7066',
  inProgressCount: '#ffbf55',
  doneCount: '#00afff',
};
const tooltipLabel = {
  toDoCount: 'To Do Story Points Count',
  inProgressCount: 'In Progress Story Points Count',
  doneCount: 'Done Story Points Count',
};

const FeatureProgression = (props) => {
  const classes = useStyles();
  const { data: { teams = [] } = {}, loading } = useQuery(GET_TEAMS);
  const theme = useTheme();

  const renderFeatureTick = (value) => {
    const feature = find(props.features, { id: value });
    return feature && `F-${value} : ${truncate(feature.name, { length: 40 })}`;
  };

  const updatedFeatures = useMemo(
    () =>
      props.features
        .filter((feature) => feature.teamId && feature.metrics)
        .filter((feature) => !props.team || feature.teamId === props.team)
        .sort((a, b) => a.teamId > b.teamId)
        .map((feature) => {
          return {
            id: feature.id,
            teamName: find(teams, { id: feature.teamId }).name,
            status: feature.status,
            name: feature.name,
            inProgressCount: get(feature, 'metrics.storyPoints.inProgress', 0),
            toDoCount: get(feature, 'metrics.storyPoints.toDo', 0),
            doneCount: get(feature, 'metrics.storyPoints.done', 0),
          };
        }),
    [teams, props.team, props.features],
  );

  const renderBar = () => (
    <ResponsiveBar
      theme={{
        fontSize: 14,
        textColor: theme.palette.text.secondary,
      }}
      animate={true}
      borderRadius={2}
      data={updatedFeatures}
      keys={['toDoCount', 'inProgressCount', 'doneCount']}
      colors={(bar) => colors[bar.id]}
      margin={{ top: 50, right: 0, bottom: 50, left: 330 }}
      padding={0.3}
      layout="horizontal"
      enableGridY={false}
      enableGridX={true}
      labelSkipWidth={5}
      labelTextColor="#fff"
      axisTop={{
        tickSize: 1,
        tickPadding: 10,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
      }}
      axisLeft={{
        format: renderFeatureTick,
        tickSize: 10,
        legendPosition: 'middle',
        legendOffset: 10,
        anchor: 'start',
      }}
      axisBottom={null}
      layers={['grid', 'axes', 'bars', 'markers', 'legends']}
      tooltip={({ id, value, color, data }) => (
        <Paper style={{ color, background: theme.palette.background.default, padding: '4px' }}>
          <strong style={{ color, background: theme.palette.background.default }}>
            <Typography>Name: {data.name}</Typography>
            <Typography>Team: {data.teamName}</Typography>
            <Typography>Status: {data.status}</Typography>
            <Typography>
              {tooltipLabel[id]} : {value}
            </Typography>
          </strong>
        </Paper>
      )}
      legends={[
        {
          data: ['toDoCount', 'inProgressCount', 'doneCount'].map((id) => ({
            color: colors[id],
            id,
            label: tooltipLabel[id],
          })),
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: -300,
          translateY: -50,
          itemsSpacing: 50,
          itemWidth: 200,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          symbolSize: 24,
        },
      ]}
    />
  );

  const features = props.features.filter(
    (feature) => (!props.team || feature.teamId === props.team) && feature.programIncrement === props.pi.id,
  );
  const featuresDone = features.filter((feature) => feature.status === 'Done');
  const storiesToDo = features.reduce((total, feature) => total + get(feature, 'metrics.storyCount.toDo', 0), 0);
  const storiesInProgress = features.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.inProgress', 0),
    0,
  );
  const storiesDone = features.reduce((total, feature) => total + get(feature, 'metrics.storyCount.done', 0), 0);
  const storyPointsToDo = features.reduce((total, feature) => total + get(feature, 'metrics.storyPoints.toDo', 0), 0);
  const storyPointsInProgress = features.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.inProgress', 0),
    0,
  );
  const storyPointsDone = features.reduce((total, feature) => total + get(feature, 'metrics.storyPoints.done', 0), 0);
  const defects = features.reduce((total, feature) => total + get(feature, 'metrics.defectsCount.total', 0), 0);
  const defectsDone = features.reduce((total, feature) => total + get(feature, 'metrics.defectsCount.done', 0), 0);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Metric title="Features" classes={classes} done={featuresDone.length} total={features.length} />
        <Metric title="Bugs" classes={classes} done={defectsDone} total={defects} />
        <Metric
          title="Stories"
          classes={classes}
          done={storiesDone}
          total={storiesToDo + storiesInProgress + storiesDone}
        />
        <Metric
          title="Story Points"
          classes={classes}
          done={storyPointsDone}
          total={storyPointsInProgress + storyPointsToDo + storyPointsDone}
        />
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <CircularProgress className={classes.progress} size={50} />
              </div>
            ) : (
              <div style={{ height: `${updatedFeatures.length * 40 + 100}px` }}>{renderBar()}</div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(FeatureProgression);
