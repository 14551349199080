export const styles = (theme) => ({
  root: {
    height: '100%',
  },
  appBar: {
    marginBottom: '5',
    marginTop: '0',
    paddingLeft: '0',
    minHeight: 50,
    fontSize: 20,
    color: '#426CB4',
  },
  lookupEditCell: {
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(1.25),
    paddingLeft: theme.spacing(),
  },
  dialog: {
    width: 'calc(100% - 16px)',
    marginTop: '10px',
  },
  inputRoot: {
    width: '100%',
  },
  progress: {
    position: 'absolute',
    fontSize: '20px',
    top: 'calc(45% - 10px)',
    left: 'calc(50% - 10px)',
  },
  progressBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .3)',
  },
});
