import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { config, useTransition } from '@react-spring/web';
import { DateTime } from 'luxon';
import { FlexDiv, PaperCard } from '../../../../../components/Common/Elements';
import { useForm } from 'react-hook-form';
import StoryForm from '../StoryForm';
import { useMutation } from '@apollo/client';
import { CREATE_STORIES_IN_EPIC } from '../../../graphql';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Dot } from '../../../../ValueStream/components/Common';

export const AddStoriesDialog = (props) => {
  const { onClose, open, pi, feature = {}, team = { name: 'Team A', id: 4 } } = props;
  const [storyCount, setStoryCount] = useState(['ID18293', 'ID18295']);
  const {
    control,
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { stories: {} },
    shouldUnregister: true,
  });

  const [createStories] = useMutation(CREATE_STORIES_IN_EPIC);

  const transition = useTransition(storyCount, {
    trail: 400 / 3,
    key: (item) => item,
    config: config.stiff,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  const handleClose = () => onClose();
  const submit = () => handleSubmit(onSubmit)();

  const onSubmit = (values) => {
    return createStories({
      variables: {
        projectKey: team.projectId || pi.defaultProjectKey,
        connectorId: team.connectorId,
        input: { stories: _.values(values.stories), team: team, feature: feature },
      },
    }).then((value) => onClose());
  };

  const addStory = () => setTimeout(() => setStoryCount([...storyCount, 'ID' + (Math.random() * 1000).toFixed()]), 900);
  const removeStory = (index) => {
    if (index === storyCount[storyCount.length - 2]) {
      const idToRemove = storyCount[storyCount.indexOf(index) + 1];
      setStoryCount(storyCount.filter((value) => value !== idToRemove));
    } else {
      setTimeout(() => setStoryCount(storyCount.filter((value) => value !== index)), 800);
    }
  };

  const options = pi?.sprints?.map((sprint) => {
    const startDate = DateTime.fromISO(sprint.startDate).toFormat('dd/LL');
    const endDate = DateTime.fromISO(sprint.endDate).toFormat('dd/LL');

    return {
      value: { id: sprint.id, jiraId: sprint.jiraIds[team.id] },
      label: sprint.name.includes('Sprint') ? sprint.name : `Sprint - ${sprint.name} (${startDate} - ${endDate})`,
    };
  });

  return (
    <Dialog onClose={handleClose} maxWidth={false} open={open}>
      <DialogTitle sx={{ backgroundColor: 'color.darkPaper', height: '90px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>Stories</Typography>
        <Typography sx={{ fontSize: '14px', color: 'color.subtitle' }}>
          You can add one or multiple stories to a feature
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: 'color.paper', height: '60vh', width: '50vw' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '15px', color: 'color.subtitle', margin: '16px' }}>
          Add Stories
        </Typography>
        <Grid container flexDirection="column" alignItems="center" sx={{ transition: 'width 300ms' }}>
          <PaperCard>
            <FlexDiv>
              <Typography sx={{ color: '#C1C1C4' }}>F-{feature?.id}</Typography>
              <Dot sx={{ marginLeft: '8px', marginRight: '8px', backgroundColor: '#C1C1C4' }} />
              <Typography sx={{ color: '#C1C2C4' }}>{team.name}</Typography>
            </FlexDiv>
            <Typography marginTop="8px" marginBottom="8px">
              {feature?.name}
            </Typography>
            <Typography>Due Date: 00/00/00</Typography>
          </PaperCard>
          <svg width={22} height={24} xmlns="http://www.w3.org/2000/svg">
            <line x1="11" y1="0" x2="11" y2={24} width="2" stroke="white" />
          </svg>
          {transition((style, value, ob1, index) => (
            <StoryForm
              style={style}
              key={value}
              setValue={setValue}
              id={value}
              index={index}
              options={options}
              removeStory={removeStory}
              control={control}
              reset={reset}
              unregister={unregister}
              register={register}
              addStory={addStory}
            />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ backgroundColor: 'color.innerRow', height: '70px', justifyContent: 'space-between', padding: '16px' }}>
        <Button
          variant="contained"
          disableElevation
          onClick={handleClose}
          color="primary"
          sx={{ borderRadius: '56px' }}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={submit} color="primary" sx={{ borderRadius: '56px' }}>
          Save
        </Button>
      </DialogActions>
      <Backdrop open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
