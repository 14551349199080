import { gql } from '@apollo/client';

export const CREATE_STORIES_IN_EPIC = gql`
  fragment StoryInput on REST {
    name: String
    sprint: Int
    sizing: Int
  }

  mutation CreateStories($connectorId: String!, $projectKey: String!, $epicId: String!, $input: [StoryInput]) {
    jiraStories(connectorId: $connectorId, epicId: $epicId, input: $input, projectKey: $projectKey)
      @rest(method: "POST", type: "Story", path: "/api/jira/{args.connectorId}/{args.projectKey}/issues") {
      id
      name
    }
  }
`;

export const GET_STORIES_FOR_TEAM = gql`
  query GetStoriesForTeam($teamId: Int) {
    stories: stories(where: { teamId: { _eq: $teamId } }, order_by: { id: asc }) {
      description
      featureId
      id
      name
      parentKey
      sprintId
      status
      storyKey
      storyPoints
      teamId
    }
  }
`;

export const SUBSCRIBE_STORIES_FOR_TEAM = gql`
  subscription GetStoriesForTeam($teamId: Int) {
    stories: stories(where: { teamId: { _eq: $teamId } }, order_by: { id: asc }) {
      description
      featureId
      id
      name
      parentKey
      sprintId
      status
      storyKey
      storyPoints
      teamId
    }
  }
`;
