import { Card, Flex, Grid, Group, Paper, rem, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { SvgIcon, Typography } from '@mui/material';
import React from 'react';
import { Dot } from '../components/Common';
import SvgTotal from '../../../assets/images/Total';
import SvgWarning from '../../../assets/images/Warning';
import SvgOnTrack from '../../../assets/images/OnTrack';
import { useTheme } from '@emotion/react';
import SvgMilestones from '../../../assets/images/Milestones';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import { Display2 } from './InitiativeDashboard.style';
import { DateTime } from 'luxon';
import ClockSvg from '../../../assets/images/Clock';
import DoneIcon from '@mui/icons-material/Done';
import { getInitiativeBgColor, getMilestoneRagStatus } from '../utils';
import classes from './MetricPaper.module.css';

export const MetricPaper = ({ icon, title, children, xs = 3, sx }) => {
  const theme = useTheme();

  return (
    <Grid.Col span={xs}>
      <Paper
        style={{
          height: '100%',
          width: '100%',
          border: `1px solid ${theme.palette.color.tileBorder}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.color.panel,
          ...sx,
          boxShadow: `0px 1px 0px 0px ${theme.palette.color.shadow}`,
        }}>
        <Grid.Col span={12}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text size="md" fw={700}>
              {title}
            </Text>
            {icon && (
              <ThemeIcon radius="xl" size={32} variant="light" color={'bigagilePink.0'}>
                {icon}
              </ThemeIcon>
            )}
          </div>
        </Grid.Col>
        <Grid p={8} align={'center'}>
          {children}
        </Grid>
      </Paper>
    </Grid.Col>
  );
};

export const MilestonesHealth = ({ title, milestones, initiatives, xs = 3 }) => {
  const theme = useTheme();
  const atRisk = milestones.filter((milestone) =>
    milestone.features.some((feature) => feature.feature.status !== 'Done' && feature.feature.ragStatus !== 'Green'),
  );
  const achieved = milestones.filter(
    (milestone) =>
      milestone.features.length && milestone.features.every((feature) => feature.feature.status === 'Done'),
  );
  const mantineTheme = useMantineTheme();

  return (
    <Grid.Col span={xs}>
      <Card
        style={{
          height: '100%',
          width: '100%',
          border: `1px solid ${theme.palette.color.tileBorder}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.color.panel,
          boxShadow: `0px 1px 0px 0px ${theme.palette.color.shadow}`,
        }}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between" mt="xs">
            <div>
              <Text size="sm" fw={500}>
                {title}
              </Text>
            </div>
            <ThemeIcon variant="light" radius={28} size={32} color={'bigagilePink.0'}>
              <SvgMilestones color={theme.palette.color.buttonIcon} />
            </ThemeIcon>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
            <Flex align={'center'}>
              <SvgTotal color={theme.palette.color.buttonIcon} sx={{ marginRight: '10px' }} />
              <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
                Total
              </Text>
            </Flex>
            <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
              {milestones.length}
            </Text>
          </Group>
          <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
            <Flex align={'center'}>
              <SvgWarning color={theme.palette.color.buttonIcon} />
              <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
                At Risk
              </Text>
            </Flex>
            <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
              {atRisk.length}
            </Text>
          </Group>
          <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
            <Flex align={'center'}>
              <SvgOnTrack color={theme.palette.color.buttonIcon} sx={{ marginRight: '10px' }} />
              <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
                On Track
              </Text>
            </Flex>
            <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
              {milestones.length - atRisk.length - achieved.length}
            </Text>
          </Group>
          <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
            <Flex align={'center'}>
              <SvgIcon component={DoneIcon} fontSize="small" style={{ color: theme.palette.color.buttonIcon }} />
              <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
                Achieved
              </Text>
            </Flex>
            <Text size="md" fw={600} style={{ marginLeft: '16px' }}>
              {achieved.length}
            </Text>
          </Group>
        </Card.Section>
      </Card>
    </Grid.Col>
  );
};

export const UpcomingMilestones = ({ title, milestones, xs = 3 }) => {
  const theme = useTheme();
  const mantineTheme = useMantineTheme();

  return (
    <Grid.Col span={xs}>
      <Card
        style={{
          height: '100%',
          width: '100%',
          border: `1px solid ${theme.palette.color.tileBorder}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.color.panel,
          boxShadow: `0px 1px 0px 0px ${theme.palette.color.shadow}`,
        }}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between" mt="xs">
            <div>
              <Text size="sm" fw={500}>
                {title}
              </Text>
            </div>
            <ThemeIcon variant="light" radius={28} size={32} color={'bigagilePink.0'}>
              <ClockSvg color={theme.palette.color.buttonIcon} />
            </ThemeIcon>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          {milestones.map((milestone) => (
            <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
              <Text size="sm" lineClamp={1}>
                {milestone.name}
              </Text>
              <div style={{ width: '95px' }}>
                <Display2
                  fullWidth
                  color={getMilestoneRagStatus(milestone, theme)}
                  bgColor={getMilestoneRagStatus(milestone, theme)}
                  variant="light">
                  {DateTime.fromISO(milestone.date).toFormat(DATE_FORMAT.date)}
                </Display2>
              </div>
            </Group>
          ))}
        </Card.Section>
      </Card>
    </Grid.Col>
  );
};

export const TopInitiatives = ({ title, initiatives, xs = 3 }) => {
  const theme = useTheme();
  const top3 = initiatives.slice(0, 4);
  const mantineTheme = useMantineTheme();

  return (
    <Grid.Col span={xs}>
      <Card
        style={{
          height: '100%',
          width: '100%',
          border: `1px solid ${theme.palette.color.tileBorder}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.color.panel,
          boxShadow: `0px 1px 0px 0px ${theme.palette.color.shadow}`,
        }}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between" mt="xs">
            <div>
              <Text size="sm" fw={500}>
                {title}
              </Text>
            </div>
            <ThemeIcon variant="light" radius={28} size={32} color={'bigagilePink.0'}>
              <ClockSvg color={theme.palette.color.buttonIcon} />
            </ThemeIcon>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          {top3.map((initiative) => (
            <Group justify="space-between" wrap="nowrap" spacing="xl" className={classes.root}>
              <Text size="sm" lineClamp={1}>
                {initiative.name}
              </Text>
              <div style={{ width: '95px' }}>
                <Display2
                  fullWidth
                  color={getInitiativeBgColor(initiative, theme)}
                  bgColor={getInitiativeBgColor(initiative, theme)}
                  variant="light">
                  {initiative.endDate?.toFormat(DATE_FORMAT.date) ?? 'NO DATE'}
                </Display2>
              </div>
            </Group>
          ))}
        </Card.Section>
      </Card>
    </Grid.Col>
  );
};

export const Metric = ({ value, name, dotColor, color, xs = 6 }) => {
  return (
    <Grid.Col span={xs} pb={0}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {dotColor && <Dot sx={{ backgroundColor: dotColor }} />}
        <Text size="sm" fz={32} fw={600} c={color}>
          {value}
        </Text>
      </div>
      <Typography variant={'body2'} color="#93a0b5">
        {name}
      </Typography>
    </Grid.Col>
  );
};
