import { PI_STATUSES, PI_STATUSES_DISPLAY } from './constants';
import { Chip, Typography } from '@mui/material';
import { last } from 'lodash';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import React from 'react';
import { piStatusColor } from '../../../utils/piColors';
import { Dot } from '../../ValueStream/components/Common';

function createData(item, features, theme) {
  const { id, name, status } = item;
  const lastSprint = last(item.sprints);
  const firstSprint = item.sprints[0];
  const label = getStatusLabel(item, lastSprint, firstSprint);
  const piFeatures = features.filter((feature) => feature.programIncrement === id);

  const isWrongState =
    (status === 'planning' && DateTime.now() > DateTime.fromISO(firstSprint?.startDate)) ||
    (status === 'active' && DateTime.now() > DateTime.fromISO(lastSprint?.endDate));

  return {
    id,
    name,
    status: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dot sx={{ backgroundColor: piStatusColor(status, theme), marginRight: '10px' }} />
        <Typography>{PI_STATUSES_DISPLAY[status]}</Typography>
        <Chip
          sx={{
            backgroundColor: isWrongState ? 'color.red' : 'color.icon',
            marginLeft: '10px',
            borderRadius: '4px',
            padding: '2px',
            height: '100%',
            '& .MuiChip-label': { padding: '0 3px 0 3px' },
          }}
          label={label}
        />
      </div>
    ),
    plannedIn: `${piFeatures.length} Features`,
  };
}

const getStatusLabel = (item, lastSprint, firstSprint) => {
  switch (item.status) {
    case 'active':
      return `ENDS: ${DateTime.fromISO(lastSprint?.endDate).toFormat(DATE_FORMAT.date)}`;
    case 'planning':
      return `STARTS: ${
        firstSprint?.startDate ? DateTime.fromISO(firstSprint?.startDate).toFormat(DATE_FORMAT.date) : 'Unknown'
      }`;
    case 'complete':
      return DateTime.fromISO(lastSprint?.endDate).toFormat(DATE_FORMAT.date);
  }
};

export function getRowsData(data, features, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, features, theme));
  }
  return rows;
}
