import * as React from 'react';
import { styled } from '@mui/system';
import { GrowingText } from '../Elements';

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const Root = styled('div')`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
`;

const Toggle = styled('div')(
  ({ theme, ...props }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: ${props.border ? 'calc(1.5em + 22px)' : undefined};
  min-width: 100px;
  background: null;
  border: ${props.border ? `1px solid ${props.open ? '#d147c3' : '#d2d2dc'}` : 'none'};
  border-radius: 3px;
  margin: 0.5em;
  padding: ${props.border ? '10px' : '0'};
  text-align: left;
  line-height: 1.5;
  color:  ${props.open ? '#d147c3' : 'text.secondary'};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    color: #d147c3;
    border:  ${props.border ? '1px solid #d147c3' : 'none'};
  }

  & .placeholder {
    opacity: 0.8;
  }
  `,
);

export default function TextInput({ placeholder, title, onChange, value, sx }) {
  return (
    <Root>
      <Toggle sx={{ fontSize: '14px', ...sx }}>
        <span style={{ width: '150px', color: '#93a0b5' }}>{title}: </span>
        <GrowingText sx={{ paddingTop: 0 }} placeholder={placeholder} value={value} onChange={onChange} />
      </Toggle>
    </Root>
  );
}
