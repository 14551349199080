import React, { useState } from 'react';

import { useSpring, animated } from '@react-spring/web';
import { BasicTooltip, useTooltip } from '@nivo/tooltip';
import { useTheme } from '@nivo/core';

const CustomBarComponent = ({
  bar: { x, y, height, width, data, ...barRest },
  label,
  style: { labelColor, labelOpacity, ...styleRest },
  ...rest
}) => {
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();

  const theme = useTheme();

  const detailedData = data?.data.detailedData;
  const selectedDateData = detailedData[data.id];

  const heightValue = data.value;
  const heightScalePercentage = height / heightValue;
  const internalHeightInPX = selectedDateData.entered * heightScalePercentage || 0;
  const clientHeightInPX = selectedDateData.total * heightScalePercentage || 0;

  const clientHeightYCoordinate = y + height - clientHeightInPX - 5;
  const internalHeightYCoordinate = y + height - internalHeightInPX - 5;

  const props = useSpring({
    to: { height: internalHeightInPX - (selectedDateData.total ? 2 : 0) },
    from: { height: 0 },
  });

  const props2 = useSpring({
    to: { height: clientHeightInPX },
    from: { height: 0 },
  });

  return (
    <g>
      {/*<rect fill={'#E8EAEC'} height={height} width={width} x={x} y={y} />*/}
      <animated.rect
        style={props}
        fill={'#edaa47'}
        width={width}
        rx="2"
        x={x}
        y={internalHeightYCoordinate - clientHeightInPX}
        onMouseEnter={(event) =>
          showTooltipFromEvent(<BasicTooltip id="Entered" value={selectedDateData.entered} />, event)
        }
        onMouseLeave={() => hideTooltip()}
        onMouseMove={(event) =>
          showTooltipFromEvent(<BasicTooltip id="Entered" value={selectedDateData.entered} />, event)
        }
      />
      <animated.rect
        style={props2}
        fill={data.id === 'planned' ? '#d2d2dc' : '#4f74cc'}
        width={width}
        rx="2"
        x={x}
        y={clientHeightYCoordinate}
        onMouseEnter={(event) =>
          showTooltipFromEvent(
            <BasicTooltip id={data.id === 'planned' ? 'Planned' : 'Completed'} value={selectedDateData.total} />,
            event,
          )
        }
        onMouseLeave={() => hideTooltip()}
        onMouseMove={(event) =>
          showTooltipFromEvent(
            <BasicTooltip id={data.id === 'planned' ? 'Planned' : 'Completed'} value={selectedDateData.total} />,
            event,
          )
        }
      />
      {data.id !== 'completed' && internalHeightInPX > 20 && (
        <animated.text
          x={x + width / 2}
          y={internalHeightYCoordinate - clientHeightInPX + internalHeightInPX / 2}
          textAnchor="middle"
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          fontSize={14}
          fontWeight={600}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: labelColor,
          }}>
          {selectedDateData.entered}
        </animated.text>
      )}
      {clientHeightInPX > 20 && (
        <animated.text
          x={x + width / 2}
          y={clientHeightYCoordinate + clientHeightInPX / 2}
          textAnchor="middle"
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          fontSize={14}
          fontWeight={600}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: data.id === 'planned' ? labelColor : 'white',
          }}>
          {data.id === 'completed' ? data.value : selectedDateData.total}
        </animated.text>
      )}
    </g>
  );
};

export default CustomBarComponent;
