import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
  useDroppable,
  closestCenter,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { Text, Badge, useMantineColorScheme } from '@mantine/core';
import { Grid, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import {
  GET_PORTFOLIO_STATES_CONFIG,
  GET_INITIATIVE_STATE_CONFIG,
  INSERT_CONFIGURATION,
  UPDATE_CONFIGURATION,
} from '../../GraphQL/configuration';
import { GET_ALL_INITIATIVES } from '../../../ValueStream/Initiatives/graphql';
import { GET_VALUE_STREAMS } from '../../Streams/graphql';
import { defaultPortfolioStateList } from './defaultStateList';
import Draggable from '../../../Delivery/components/Risks/draggable';
import { StyledPaper } from '../../../ValueStream/components/Common';
import { lightColors, color } from '../../../../shared/styles/color';
import { StateCard } from './styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SortableTitle } from './SortableTitle';

const useStyles = makeStyles();

const FIXED_COLUMN_ID = 'Unmapped status';

const ColumnArea = ({
  id,
  title,
  initiativeStates = [],
  valueStreams,
  onColumnTitleChange,
  setGridOrder,
  gridOrder,
  portfolioStates,
  initiatives,
}) => {
  const classes = useStyles();
  const { setNodeRef } = useDroppable({ id });
  const { colorScheme } = useMantineColorScheme();

  const [updateConfig] = useMutation(UPDATE_CONFIGURATION);
  const [insertConfig] = useMutation(INSERT_CONFIGURATION);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [titleError, setTitleError] = useState('');
  const [originalColumnTitle, setOriginalColumnTitle] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPortfolioStatesConfigUpdate = (updatedGridOrder) => {
    return updatedGridOrder.map((column) => ({
      name: column.name,
      rank: column.rank,
      states: column.states,
    }));
  };

  const updatePortfolioStatesConfig = (updatedGridOrder) => {
    const portfolioStatesConfigUpdate = getPortfolioStatesConfigUpdate(updatedGridOrder);
    const portfolioDataToSave = {
      columns: portfolioStatesConfigUpdate,
    };

    if (portfolioStates.length) {
      updateConfig({
        variables: { configuration: { value: portfolioDataToSave }, configurationId: portfolioStates[0].id },
        refetchQueries: [GET_PORTFOLIO_STATES_CONFIG, 'GetPortfolioStates'],
      });
    } else {
      insertConfig({
        variables: { configuration: { type: 'portfolioState', value: portfolioDataToSave } },
        refetchQueries: [GET_PORTFOLIO_STATES_CONFIG, 'GetPortfolioStates'],
      });
    }

    handleClose();
  };

  const handleAddColumn = () => {
    const newColumnId = `Name ${gridOrder.length + 1}`;
    const newColumn = {
      name: newColumnId,
      rank: gridOrder?.length,
      states: [],
    };

    setGridOrder((prevGridOrder) => {
      const index = prevGridOrder.findIndex((column) => column.name === id);
      const updatedGridOrder = [...prevGridOrder.slice(0, index + 1), newColumn, ...prevGridOrder.slice(index + 1)];

      updatePortfolioStatesConfig(updatedGridOrder);

      return updatedGridOrder;
    });
  };

  const deleteColumn = () => {
    const updatedGridOrder = gridOrder.filter((column) => column.name !== id);
    updatePortfolioStatesConfig(updatedGridOrder);
    setGridOrder(updatedGridOrder);
  };

  const handleTitleChange = (newTitle) => {
    if (newTitle === originalColumnTitle) {
      return;
    }

    if (
      gridOrder.some((column) => column.name === newTitle) ||
      newTitle === '' ||
      newTitle === 'Unmapped' ||
      newTitle === FIXED_COLUMN_ID
    ) {
      setTitleError('Title must be unique');
    } else {
      setTitleError('');
      onColumnTitleChange(newTitle, id, title);
    }
  };

  const initiativeCounts = {};

  initiatives
    .filter((initiative) => !initiative.isPortfolioState)
    .forEach((initiative) => {
      const stateKey = initiative.initiative_state;

      if (initiativeCounts[stateKey]) {
        initiativeCounts[stateKey] += 1;
      } else {
        initiativeCounts[stateKey] = 1;
      }
    });

  return (
    <StyledPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colorScheme === 'light' ? 'white' : '#111111',
        height: 'calc(100vh - 180px)',
        width: '220px',
        padding: '8px',
        overflow: 'auto',
        boxShadow: title === FIXED_COLUMN_ID ? '3px 0px 5px -1px rgba(0,0,0,0.75)' : '',
      }}>
      <div
        style={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          background: colorScheme === 'light' ? color.grey : '#242424',
          height: '32px',
          borderRadius: '4px',
          padding: '3px 6px',
          marginBottom: '8px',
        }}>
        {title !== FIXED_COLUMN_ID && (
          <SortableTitle id={id} type="column">
            <DragIndicatorIcon style={{ cursor: 'grab' }} />
          </SortableTitle>
        )}

        <div style={{ display: 'inline-block' }}>
          <Typography
            className={classes.title}
            contentEditable={title !== FIXED_COLUMN_ID ? true : false}
            suppressContentEditableWarning={true}
            variant="h6"
            onBlur={(e) => handleTitleChange(e.target.innerText.trim())}
            onFocus={(e) => setOriginalColumnTitle(e.target.innerText.trim())}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleTitleChange(e.target.innerText.trim());
                e.target.blur();
              }
            }}
            style={{
              fontSize: '12px',
              fontWeight: 450,
              cursor: 'text',
              height: '26px',
              lineHeight: '26px',
              width: '140px',
              paddingLeft: '2px',
              overflow: 'hidden',
              outline: 'none',
              marginBottom: '4px',
            }}>
            {title}
          </Typography>
          {titleError && (
            <Typography color="error" variant="caption" style={{ marginTop: '4px' }}>
              {titleError}
            </Typography>
          )}
        </div>

        <AddIcon onClick={handleClick} style={{ cursor: 'pointer' }} />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={handleAddColumn}>Add column</MenuItem>

          {title !== FIXED_COLUMN_ID && (
            <MenuItem disabled={!!initiativeStates.length} onClick={deleteColumn}>
              Delete column
            </MenuItem>
          )}
        </Menu>
      </div>
      <div
        ref={setNodeRef}
        style={{ flex: '1 1 auto', height: '0px', overflowY: 'auto', marginTop: titleError ? '20px' : '0px' }}>
        <SortableContext items={initiativeStates} strategy={rectSortingStrategy}>
          {sortBy(initiativeStates, 'name').map((state) => {
            const stream = valueStreams.find((stream) => stream.id === state.value_stream);

            const initiativeCount = initiativeCounts[state.name] || 0;

            return (
              <Draggable key={state.id} element={state} data={{ type: 'card', ...state }}>
                <div>
                  <div style={{ display: 'inline-block', height: '100%', width: '100%' }}>
                    <StateCard colorScheme={colorScheme}>
                      <Text size="14px" style={{ fontWeight: 450 }} editable={true}>
                        {state.name}
                      </Text>
                      <Text c="dimmed" size="12px" style={{ fontWeight: 450 }} mt={10}>
                        {initiativeCount} initiatives
                      </Text>
                      <Badge color={color.purpulishPink} radius="xl" style={{ marginTop: '10px', color: 'white' }}>
                        {stream.name}
                      </Badge>
                    </StateCard>
                  </div>
                </div>
              </Draggable>
            );
          })}
        </SortableContext>
      </div>
    </StyledPaper>
  );
};

const PortfolioStates = () => {
  const classes = useStyles();
  const { colorScheme } = useMantineColorScheme();

  const { data: { portfolioStates = [] } = {} } = useQuery(GET_PORTFOLIO_STATES_CONFIG);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);

  const [insertConfig] = useMutation(INSERT_CONFIGURATION);
  const [updateConfig] = useMutation(UPDATE_CONFIGURATION);

  const filteredInitiativesStates = useMemo(() => {
    return initiativeStates.filter((state) => state.inUse);
  }, [initiativeStates]);

  const portfolioStatesConfig = useMemo(
    () => (portfolioStates.length ? portfolioStates[0].value.columns : defaultPortfolioStateList),
    [portfolioStates],
  );

  const [gridOrder, setGridOrder] = useState(portfolioStatesConfig);

  const [activeId, setActiveId] = useState(null);
  const [titleChanged, setTitleChanged] = useState(false);

  const savePortfolioState = useCallback(
    (stateToSave) => {
      if (portfolioStates.length) {
        updateConfig({
          variables: { configuration: { value: stateToSave }, configurationId: portfolioStates[0].id },
          refetchQueries: [GET_PORTFOLIO_STATES_CONFIG, 'GetPortfolioStates'],
        });
      } else {
        insertConfig({
          variables: { configuration: { type: 'portfolioState', value: stateToSave } },
          refetchQueries: [GET_PORTFOLIO_STATES_CONFIG, 'GetPortfolioStates'],
        });
      }
    },
    [insertConfig, portfolioStates, updateConfig],
  );

  const onColumnTitleChange = useCallback(
    (newTitle, columnId, oldTitle) => {
      // Update the grid order with the new title
      const updatedGridOrder = gridOrder.map((column) => {
        if (column.name === columnId) {
          // Rename the column
          return { ...column, name: newTitle };
        } else if (column.name === newTitle) {
          // Remove column if it already exists (to avoid duplicates)
          return null;
        }
        return column;
      });

      // Find all states under the old title
      const statesToUpdate = [];
      updatedGridOrder.forEach((column) => {
        if (column.states) {
          column.states.forEach((state) => {
            if (state.portfolioState === oldTitle) {
              statesToUpdate.push({ ...state, portfolioState: newTitle });
            }
          });
        }
      });

      // Update the portfolioState for these states
      statesToUpdate.forEach((state) => {
        const dataToSave = {
          name: state.name,
          inUse: state.inUse,
          stateType: state.stateType,
          portfolioState: newTitle,
        };

        updateConfig({
          variables: { configuration: { value: dataToSave }, configurationId: state.id },
        });
      });

      // Update the portfolio states configuration with the new column name
      const portfolioStatesConfigUpdate = updatedGridOrder.map((column) => ({
        name: column.name,
        rank: column.rank,
        states: column.states,
      }));

      const portfolioDataToSave = {
        columns: portfolioStatesConfigUpdate,
      };

      savePortfolioState(portfolioDataToSave);

      setGridOrder(updatedGridOrder);
      setTitleChanged(true);
    },
    [gridOrder, savePortfolioState, updateConfig],
  );

  useEffect(() => {
    const activeValueStreams = valueStreams.filter((stream) => stream.isActive);

    const activeInitiativeStates = filteredInitiativesStates.filter((state) =>
      activeValueStreams.some((stream) => stream.id === state.value_stream),
    );

    const updatedGridOrder = portfolioStatesConfig.map((column) => ({ ...column, states: [] }));

    activeInitiativeStates.forEach((state) => {
      const columnName = state.portfolioState || FIXED_COLUMN_ID;
      const column = updatedGridOrder.find((col) => col.name === columnName);
      if (column) {
        column.states.push(state);
      }
    });

    setGridOrder(updatedGridOrder);
    setTitleChanged(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInitiativesStates, portfolioStatesConfig, valueStreams, titleChanged]);

  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } });
  const sensors = useSensors(mouseSensor);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.data.current.type === 'column' && over) {
        if (active.id !== FIXED_COLUMN_ID && over.id !== FIXED_COLUMN_ID && active.id !== over.id) {
          // Find the indices of the active and over columns
          const oldIndex = gridOrder.findIndex((item) => item.name === active.id);
          const newIndex = gridOrder.findIndex((item) => item.name === over.id);

          // Move the columns in the array
          const updatedGridOrder = arrayMove(gridOrder, oldIndex, newIndex).map((item, index) => ({
            ...item,
            rank: index,
          }));

          setGridOrder(updatedGridOrder);

          const data = { columns: updatedGridOrder };

          savePortfolioState(data);
        }
      } else if (active.data.current.type === 'initiativeState' && over) {
        const overColumnName = over.id;
        const activeCard = active.id;
        const activeCardData = active.data.current;

        const updatedGridOrder = gridOrder.map((column) => ({
          ...column,
          states: column.states.filter((card) => card.id !== activeCard),
        }));

        const newColumn = updatedGridOrder.find((col) => col.name === overColumnName);
        if (newColumn) {
          newColumn.states.push(activeCardData);
        }

        const dataToSave = {
          name: active.data.current.name,
          inUse: active.data.current.inUse,
          stateType: active.data.current.stateType,
          portfolioState: overColumnName,
        };

        updateConfig({
          variables: { configuration: { value: dataToSave }, configurationId: active.data.current.id },
          refetchQueries: [GET_INITIATIVE_STATE_CONFIG, 'GetInitiativeState'],
        });

        // Update the portfolio states configuration with the new column name
        const portfolioStatesConfigUpdate = updatedGridOrder.map((column) => ({
          name: column.name,
          rank: column.rank,
          states: column.states,
        }));

        const portfolioDataToSave = {
          columns: portfolioStatesConfigUpdate,
        };

        savePortfolioState(portfolioDataToSave);

        setGridOrder(updatedGridOrder);
      }

      setActiveId(null);
    },
    [gridOrder, portfolioStates, updateConfig, insertConfig],
  );

  const handleDragStart = useCallback((event) => {
    const { active } = event;
    const itemType = active.data.current?.type;

    setActiveId({ id: active.id, type: itemType, state: active.data.current });
  }, []);

  const onDragOver = useCallback((event) => {
    if (event.active) {
      setActiveId((prev) => ({ ...prev, state: event.active.id }));
    }
  }, []);

  const renderDragOverlayForColumn = useCallback(
    (column) => {
      const columnState = gridOrder.find((state) => state.name === column.state);

      if (!columnState) return null;

      return (
        <Grid item key={columnState.name}>
          <ColumnArea
            key={columnState.name}
            id={columnState.name}
            title={columnState.name}
            initiativeStates={columnState.states || []}
            valueStreams={valueStreams}
            initiatives={initiatives}
          />
        </Grid>
      );
    },
    [gridOrder, valueStreams],
  );

  const renderDragOverlayForCard = useCallback(
    (cardData) => {
      const cardDetails = filteredInitiativesStates.find((state) => state.id === cardData.id);
      const stream = valueStreams.find((stream) => stream.id === cardDetails.value_stream);

      return (
        <div style={{ display: 'inline-block', height: '100%', width: '100%' }}>
          <StateCard colorScheme={colorScheme}>
            <Text size="14px" style={{ fontWeight: 450 }}>
              {cardDetails.name}
            </Text>
            <Badge color={color.purpulishPink} radius="xl" style={{ marginTop: '32px', color: 'white' }}>
              {stream.name}
            </Badge>
          </StateCard>
        </div>
      );
    },
    [filteredInitiativesStates, valueStreams, colorScheme],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragOver={onDragOver}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}>
      <SortableContext items={gridOrder.filter((item) => item.name !== FIXED_COLUMN_ID)} strategy={rectSortingStrategy}>
        <div className={classes.root} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <div style={{ display: 'flex', alignItems: 'stretch', width: 'fit-content' }}>
            <div
              style={{
                position: 'sticky',
                left: 0,
                height: 'calc(100vh - 180px)',
                backgroundColor: colorScheme === 'light' ? '#f7f7f8' : '#111111',
                borderRight: `1px solid ${colorScheme === 'light' ? lightColors.tileBorder : '#2B2B2B'}`,
                paddingRight: '10px',
                marginRight: '10px',
                zIndex: 1,
                background: colorScheme === 'light' ? lightColors.background : '#111111',
              }}>
              <ColumnArea
                id={FIXED_COLUMN_ID}
                title="Unmapped status"
                initiativeStates={gridOrder.find((col) => col.name === FIXED_COLUMN_ID)?.states || []}
                valueStreams={valueStreams}
                initiatives={initiatives}
                setGridOrder={setGridOrder}
                gridOrder={gridOrder}
                portfolioStates={portfolioStates}
              />
            </div>

            <Grid className={classes.container} container wrap="nowrap" spacing={2} mt={0}>
              {gridOrder.slice(1).map((item) => (
                <Grid item key={item.name} pt={0} style={{ paddingTop: '0px' }}>
                  <ColumnArea
                    id={item.name}
                    title={item.name}
                    initiativeStates={item.states || []}
                    valueStreams={valueStreams}
                    onColumnTitleChange={onColumnTitleChange}
                    setGridOrder={setGridOrder}
                    gridOrder={gridOrder}
                    portfolioStates={portfolioStates}
                    initiatives={initiatives}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId && activeId.type === 'column'
          ? renderDragOverlayForColumn(activeId)
          : activeId && activeId.type === 'initiativeState'
          ? renderDragOverlayForCard(activeId)
          : null}
      </DragOverlay>
    </DndContext>
  );
};

export default PortfolioStates;
