import * as React from 'react';

function SvgReports(props) {
  return (
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd">
        <path
          d="M18.2 7a.8.8 0 01.8.8V17a2 2 0 01-2 2H5a2 2 0 01-2-2V7.8a.8.8 0 01.8-.8h14.4zM15 9H7v2h8V9z"
          opacity={0.9}
        />
        <rect x={1} y={3} width={20} height={3} rx={0.6} />
      </g>
    </svg>
  );
}

export default SvgReports;
