import { Grid, Paper, Typography, Grow } from '@mui/material';
import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import makeStyles from '@mui/styles/makeStyles';
import SvgReports from 'assets/images/Reports';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { styles } from './styles';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles(styles);

const AnimatedTypography = animated(Typography);

export const Metric = ({ title, done, total, icon }) => {
  const classes = useStyles();
  const animation = useSpring({
    from: { done: 0, total: 0 },
    to: { done: done, total: total },
  });

  const theme = useTheme();

  return (
    <Grid item xs={3}>
      <Grow in={true}>
        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <ListItemAvatar>
              <div className={classes.avatarItem}>{icon}</div>
            </ListItemAvatar>
            <div className={classes.typographyWrapper}>
              <div className={classes.typography}>
                <AnimatedTypography display="inline" variant="h6">
                  {animation.done.to((x) => x.toFixed(0))}
                </AnimatedTypography>{' '}
                /{' '}
                <AnimatedTypography display="inline" variant="h6">
                  {animation.total.to((x) => x.toFixed(0))}
                </AnimatedTypography>
              </div>
              <Typography className={classes.typographyTitle} variant="body2">
                {title}
              </Typography>
            </div>
          </div>
        </Paper>
      </Grow>
    </Grid>
  );
};
