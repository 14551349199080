import { isEmpty, last, some, sortBy } from 'lodash';
import * as d3 from 'd3';

export const calculateOutcome = (keyResult) => {
  if (!keyResult.updates?.length) {
    return 0;
  }

  const targetValue = keyResult.targetValue;
  const startingValue = keyResult.startingValue;
  const currentValue = last(sortBy(keyResult.updates, 'date')).value;

  return Math.round(((currentValue - startingValue) / (targetValue - startingValue)) * 100);
};

export const outcomeColor = (outcome, theme) => {
  switch (true) {
    case outcome >= 80:
      return theme.other.colours.green;
    case outcome >= 60:
      return theme.other.colours.amber;
    default:
      return theme.other.colours.red;
  }
};

export const getUserName = (user) => {
  return user?.firstName ? `${user.firstName} ${user.lastName}` : '';
};

export const getRagPercentage = (keyResult) => {
  if (isEmpty(keyResult.updates)) return 0;

  const expected = d3
    .scaleTime()
    .range([
      keyResult.startingValue,
      ...(keyResult.trajectory?.map((point) => point.expectedValue) || []),
      keyResult.targetValue,
    ])
    .domain([
      new Date(keyResult.startingDate),
      ...(keyResult.trajectory?.map((point) => new Date(point.date)) || []),
      new Date(keyResult.targetDate),
    ]);

  const actual = d3
    .scaleTime()
    .range([keyResult.startingValue, ...(keyResult.updates?.map((update) => update.value) || [])])
    .domain([new Date(keyResult.startingDate), ...(keyResult.updates?.map((update) => new Date(update.date)) || [])]);

  const expectedValue = expected(Date.now());
  const actualValue = actual(Date.now());

  return (actualValue / expectedValue) * 100;
};

export const getTrend = (keyResult) => {
  const expected = d3
    .scaleTime()
    .range([
      keyResult.startingValue,
      ...(keyResult.trajectory?.map((point) => point.expectedValue) || []),
      keyResult.targetValue,
    ])
    .domain([
      new Date(keyResult.startingDate),
      ...(keyResult.trajectory?.map((point) => new Date(point.date)) || []),
      new Date(keyResult.targetDate),
    ]);

  if (!keyResult.updates?.length || keyResult.updates.length === 1) {
    return 0;
  }
  const lastUpdate = last(sortBy(keyResult.updates, 'date'));
  const beforeLast = sortBy(keyResult.updates, 'date')[keyResult.updates.length - 2];

  const lastPercentage = (lastUpdate.value / expected(lastUpdate.date)) * 100;
  const beforeLastPercentage = (beforeLast.value / expected(beforeLast.date)) * 100;

  return lastPercentage > beforeLastPercentage ? 1 : -1;
};

export const getRagColorForKeyResult = (keyResult, theme) => {
  if (isEmpty(keyResult.updates)) return theme.other.colours.greyIcon;

  const ragPercentage = getRagPercentage(keyResult);
  return getRagColor(ragPercentage, theme);
};

export const getRagColor = (ragPercentage, theme) => {
  switch (true) {
    case ragPercentage >= 80:
      return theme.other.colours.green;
    case ragPercentage >= 60:
      return theme.other.colours.amber;
    case ragPercentage === 0:
      return theme.other.colours.greyIcon;
    default:
      return theme.other.colours.red;
  }
};

export const calculateOutput = (keyResult, features, initiatives) => {
  const keyResultFeatures = keyResult.features || [];
  const keyResultInitiatives = keyResult.initiatives || [];

  if (isEmpty(keyResultFeatures) && isEmpty(keyResultInitiatives)) return { status: 'Not Started', output: 0 };

  const outputFeatures = features.filter((feature) => keyResultFeatures.includes(feature.id));
  const outputInitiatives = initiatives.filter((initiative) => keyResultInitiatives.includes(initiative.id));

  const featureMetrics = outputFeatures.reduce(
    (acc, feature) => ({
      totalSc: acc.totalSc + (feature.metrics?.storyCount?.total || 0),
      doneSc: acc.doneSc + (feature.metrics?.storyCount?.done || 0),
      status: ['Done', 'In Progress'].includes(feature.status) ? 'In Progress' : acc.status,
    }),
    { totalSc: 0, doneSc: 0, status: 'Not Started' },
  );

  const initiativeMetrics = outputInitiatives.reduce(
    (acc, initiative) =>
      initiative.features.nodes.reduce(
        (total, feature) => ({
          totalSc: total.totalSc + (feature.metrics?.storyCount?.total || 0),
          doneSc: total.doneSc + (feature.metrics?.storyCount?.done || 0),
          status: ['Done', 'In Progress'].includes(feature.status) ? 'In Progress' : acc.status,
        }),
        acc,
      ),
    { totalSc: 0, doneSc: 0, status: 'Not Started' },
  );

  const status =
    featureMetrics.status === 'In Progress' || initiativeMetrics.status === 'In Progress'
      ? 'In Progress'
      : 'Not Started';
  const progress = Math.round(
    ((featureMetrics.doneSc + initiativeMetrics.doneSc) / (featureMetrics.totalSc + initiativeMetrics.totalSc)) * 100,
  );

  return { status: progress === 100 ? 'Done' : status, output: progress, outputFeatures, outputInitiatives };
};

export const getProgressColor = (progress, theme) => {
  switch (progress) {
    case 100:
      return theme.other.colours.done;
    case 0:
      return theme.other.colours.secondary;
    default:
      return theme.other.colours.lightBlue;
  }
};

export const colorForStatus = (status) => {
  switch (status) {
    case 'In Progress':
      return '#edaa47';
    case 'Not Started':
      return '#cc5348';
    case 'Done':
      return '#4f74cc';
  }
};
