export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    defaultSort: 'asc',
    numeric: true,
    align: 'left',
    width: '3%',
  },
  {
    id: 'priority',
    field: 'priority',
    label: 'P',
    align: 'left',
    width: '3%',
    filtering: false,
  },
  {
    id: 'name',
    field: 'name',
    label: 'Title',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '23%',
  },
  {
    id: 'owner',
    field: 'owner',
    label: 'Owner',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '10%',
  },
  {
    id: 'creator',
    field: 'creator',
    label: 'Submitted by',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '10%',
  },
  {
    id: 'valueStreams',
    field: 'valueStreams',
    label: 'Value stream',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '20%',
  },
  {
    id: 'state',
    field: 'state',
    label: 'State',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '7%',
  },
  {
    id: 'next_step',
    field: 'next_step',
    label: 'Next Step',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '12%',
  },
];
