import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Card, CardContent, InputAdornment, MenuItem, Zoom } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  AsyncAutoCompleteWrapper,
  Field,
  FormContext,
  renderSelectField,
  renderTextField,
} from 'components/Common/FormFieldsHooks';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_AZURE_BOARDS,
  GET_CONNECTORS,
  GET_JIRA_BOARDS,
  GET_JIRA_ISSUE_TYPES,
  INSERT_CONNECTOR,
  UPDATE_CONNECTOR,
} from '../../../containers/Settings/GraphQL/connectors';
import { getToolingName, removeIdField } from '../../../utils/helpers';
import { api } from '../../../utils/api';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { GET_EPICS } from '../../../containers/Product/Features/graphql';
import { Text } from '@mantine/core';
import { differenceBy, uniqBy } from 'lodash';
import { TransferList } from '../../Common/TransferList';

const required = (value) => (value ? undefined : 'Required');

const ConnectorDialog = (props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [insertConnector] = useMutation(INSERT_CONNECTOR);
  const [updateConnector] = useMutation(UPDATE_CONNECTOR);
  // const [deleteConnector] = useMutation(DELETE_CONNECTOR);
  const [featuresIssueTypes, setFeaturesIssueTypes] = useState(props.element?.featuresIssueTypes || []);
  const [bugsIssueTypes, setBugsIssueTypes] = useState(props.element?.defectsIssueTypes || []);
  const [risksIssueTypes, setRisksIssueTypes] = useState(props.element?.risksIssueTypes || []);
  const [debtsIssueTypes, setDebtsIssueTypes] = useState(props.element?.debtsIssueTypes || []);
  const [allIssueTypes, setAllIssueTypes] = useState([]);

  const { data: { issueTypes = [] } = {} } = useQuery(
    props?.element?.type === 'azureDevops' ? GET_JIRA_ISSUE_TYPES : GET_JIRA_ISSUE_TYPES,
    {
      variables: { connectorId: props.element?.id },
    },
  );

  useEffect(() => {
    if (issueTypes.length) {
      const formattedIssueTypes = differenceBy(
        issueTypes
          .filter((issueType) => !issueType.scope)
          .map((issueType) => ({ value: issueType.id, label: issueType.name })),
        [...bugsIssueTypes, ...risksIssueTypes, ...debtsIssueTypes, ...featuresIssueTypes],
        'value',
      );

      setAllIssueTypes(formattedIssueTypes);
    }
  }, [issueTypes]);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    shouldUnregister: true,
    defaultValues: props.element || {},
  });

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => resetDrawerDetails(),
  }));

  const onSubmit = (values) => {
    props?.element?.id
      ? updateConnector({
          variables: {
            connector: removeIdField(values, !values.password && 'password'),
            connectorId: props.element.id,
          },
        }).then(() => resetDrawerDetails())
      : insertConnector({
          refetchQueries: [GET_CONNECTORS, 'GetConnectors'],
          variables: { connector: values },
        }).then(() => resetDrawerDetails());
  };

  const connector = watch();

  const onChangeData = (data, setData, fieldName) => {
    setAllIssueTypes(uniqBy(data[0], 'value'));
    setData(data[1]);
    setValue(fieldName, data[1]);
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
            <Field name="name" renderField={renderTextField} label="Name" size={12} validate={required} />
            <Field name="type" renderField={renderSelectField} label="Type" size={12}>
              <MenuItem key="jira" value="jira">
                Jira
              </MenuItem>
              <MenuItem key="azure" value="azureDevops">
                Azure Devops
              </MenuItem>
            </Field>
            <Zoom unmountOnExit in={!!connector.type}>
              <div>
                <Field
                  name="apiUrl"
                  renderField={renderTextField}
                  label={`${getToolingName()} URL`}
                  helperText="For example, https://bigagile.atlassian.net"
                  size={12}
                  validate={required}
                />
              </div>
            </Zoom>
            <Field name="authenticationType" renderField={renderSelectField} label="Authentication" size={12}>
              <MenuItem key="basic" value="basic">
                Basic
              </MenuItem>
              <MenuItem key="pat" value="pat">
                Personal Access Token
              </MenuItem>
            </Field>
            <Zoom unmountOnExit in={!!connector.type && connector.authenticationType === 'basic'}>
              <div>
                <Field name="username" renderField={renderTextField} label="Username" size={12} validate={required} />
              </div>
            </Zoom>
            <Zoom unmountOnExit in={!!connector.type}>
              <div>
                <Field
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  renderField={renderTextField}
                  label={connector.authenticationType === 'basic' ? 'Password / Api Token' : 'Access Token'}
                  size={12}
                  validate={required}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          size="large">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Zoom>
            {connector.type === 'azureDevops' && (
              <AsyncAutoCompleteWrapper
                errors={errors}
                idField="name"
                label="Work Item Type for Stories"
                name="issueTypesMapping"
                multiple={false}
                control={control}
                size={12}
                fetchDataFunction={() => api.request(`/api/azure/${props.element.id}/${connector.projectId}/types`)}
              />
            )}
            <Text fw={600} size="md" style={{ margin: '12px 0' }}>
              Work Item Mapping
            </Text>
            <Text fw={600} size="sm" mt={10}>
              Features
            </Text>
            <TransferList
              value={[allIssueTypes, featuresIssueTypes]}
              onChange={(data) => onChangeData(data, setFeaturesIssueTypes, 'featuresIssueTypes')}
              sx={{ margin: '12px 0' }}
            />
            <Text fw={600} size="sm" mt={10}>
              Defects
            </Text>
            <TransferList
              value={[allIssueTypes, bugsIssueTypes]}
              onChange={(data) => onChangeData(data, setBugsIssueTypes, 'defectsIssueTypes')}
              sx={{ margin: '12px 0' }}
            />
            <Text fw={600} size="sm" mt={10}>
              Debts
            </Text>
            <TransferList
              value={[allIssueTypes, debtsIssueTypes]}
              onChange={(data) => onChangeData(data, setDebtsIssueTypes, 'debtsIssueTypes')}
              sx={{ margin: '12px 0' }}
            />
            <Text fw={600} size="sm" mt={10}>
              Risks
            </Text>
            <TransferList
              value={[allIssueTypes, risksIssueTypes]}
              onChange={(data) => onChangeData(data, setRisksIssueTypes, 'risksIssueTypes')}
              sx={{ margin: '12px 0' }}
            />
          </FormContext.Provider>
        </form>
      </CardContent>
    </Card>
  );
};

export default forwardRef(ConnectorDialog);
