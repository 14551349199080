import {
  BACKLOGS_TITLE,
  DEPENDENCIES_TITLE,
  FEATURES_TITLE,
  INITIATIVES_TITLE,
  INTEGRATIONS_TITLE,
  MILESTONES_TITLE,
  OBJECTIVES_TITLE,
  OKR_TITLE,
  PIS_TITLE,
  PROGRAMMES_TITLE,
  RISKS_TITLE,
  TEAMS_TITLE,
  USERS_TITLE,
  VALUE_STREAMS_TITLE,
  PRODUCTTHEME_TITLE,
  THINK_TANK,
} from './constants';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';

export function getTitle(type) {
  switch (type) {
    case 'feature':
      return FEATURES_TITLE;
    case 'objective':
      return OBJECTIVES_TITLE;
    case 'dependency':
      return DEPENDENCIES_TITLE;
    case 'milestone':
      return MILESTONES_TITLE;
    case 'risk':
      return RISKS_TITLE;
    case 'okr':
      return OKR_TITLE;
    case 'increment':
      return PIS_TITLE;
    case 'user':
      return USERS_TITLE;
    case 'initiative':
      return INITIATIVES_TITLE;
    case 'valueStream':
      return VALUE_STREAMS_TITLE;
    case 'programme':
      return PROGRAMMES_TITLE;
    case 'team':
      return TEAMS_TITLE;
    case 'integration':
      return INTEGRATIONS_TITLE;
    case 'backlog':
      return BACKLOGS_TITLE;
    case 'productTheme':
      return PRODUCTTHEME_TITLE;
    case 'thinkTank':
      return THINK_TANK;
  }
}

export const getTooltipMessage = (type, disabled) => {
  if (type === 'user' && disabled) {
    return 'You cannot create user when using an external identity provider';
  }
  if (['feature', 'objective', 'dependency', 'increment', 'risk'].includes(type) && disabled) {
    return `You cannot create ${type} without a programme`;
  }
  return null;
};

// TODO Re-write getSprintMilestones func
export function getSprintMilestones(pi, milestones, sprint, index) {
  const { startDate, endDate } = sprint;
  return orderBy(milestones, ['date'], ['asc']).filter(
    (milestone) =>
      DateTime.fromISO(startDate) <= DateTime.fromISO(milestone.date) &&
      DateTime.fromISO(milestone.date) <= DateTime.fromISO(endDate),
  );
}
