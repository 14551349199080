export const FEATURES = 'features';

export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    defaultSort: 'asc',
    numeric: false,
    align: 'center',
    width: '8%',
    smWidth: '10%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    numeric: false,
    align: 'left',
    width: '16%',
  },
  {
    id: 'initiative',
    field: 'initiative',
    label: 'Initiative',
    numeric: false,
    align: 'left',
    width: '16%',
    smWidth: '16%',
  },
  {
    id: 'assignedTo',
    field: 'assignedTo',
    label: 'Owner',
    numeric: false,
    align: 'left',
    width: '10%',
  },
  {
    id: 'stageName',
    field: 'stage',
    label: 'Stage',
    numeric: false,
    align: 'left',
    width: '7.5%',
  },
  {
    id: 'programIncrement',
    field: 'programIncrement',
    label: 'Increment',
    numeric: false,
    align: 'left',
    width: '7.5%',
  },
  {
    id: 'team',
    field: 'team',
    label: 'Team',
    numeric: false,
    align: 'left',
    width: '12%',
  },
  {
    id: 'estimatedSprint',
    field: 'estimatedSprint',
    label: 'Estimated Sprint',
    numeric: false,
    align: 'left',
    width: '12.5%',
  },
  {
    id: 'epicId',
    field: 'epicId',
    label: 'Tooling ID',
    numeric: false,
    align: 'left',
    width: '10%',
  },
  {
    id: 'progress',
    field: 'progress',
    label: '% Completion',
    numeric: false,
    align: 'left',
    width: '7.5%',
  },
];

export const csvColumns = [
  { label: 'Committed Sprint', field: 'committedSprint' },
  { label: 'Committed Sprint Date', field: 'committedSprintDate' },
  { label: 'Story Points To Do', field: 'storyPointsToDo' },
  { label: 'Story Points In Progress', field: 'storyPointsInProgress' },
  { label: 'Story Points Done', field: 'storyPointsDone' },
  { label: 'Percentage Done', field: 'percentageDone' },
  { label: 'Planned Release Date', field: 'plannedReleaseDate' },
  { label: 'Actual Release Date', field: 'releaseDate' },
  { label: 'Description', field: 'description' },
  { label: 'WSJF', field: 'wsjf' },
  { label: 'Business value', field: 'businessValue' },
  { label: 'Time Criticality', field: 'timeCriticality' },
  { label: 'Size', field: 'size' },
  { label: 'Risk Reduction', field: 'riskReduction' },
];

export const STAGE_MAPPING = [
  { name: 'Funnel', id: 'funnel' },
  { name: 'Analysis', id: 'analysis' },
  { name: 'Backlog', id: 'backlog' },
  { name: 'Candidate', id: 'candidate' },
  { name: 'Planned', id: 'planned' },
  { name: 'In Progress', id: 'inProgress' },
  { name: 'Done', id: 'done' },
];
